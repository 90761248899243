import React, { ReactNode } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import waveBackground from "~images/auth/wave-bg.svg";
import waveBackgroundMobile from "~images/auth/wave-mobile.svg";
import logo from "~images/auth/mg-lira-logo-white.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import environment from "~libs/environment";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  wave: {
    position: "absolute",
    width: "100%",
    minHeight: "100vh",
    margin: 0,
    backgroundRepeat: "no-repeat",
    padding: 0,
    "@media (min-width:1280px)": {
      backgroundPosition: "top",
      backgroundSize: "100%",
    },
    "@media (max-width:446px)": {
      backgroundImage: `url(${waveBackgroundMobile})`,
      backgroundSize: "100vw 30vh",
    },
    "@media (min-width:446px)": {
      backgroundImage: `url(${waveBackground})`,
      backgroundPosition: "-15vw top",
      backgroundSize: "160vw 125vh",
    },
    "@media (min-width:1024px) and (orientation:portrait)": {
      backgroundSize: "160vw 100vh",
      backgroundPosition: "0 top",
    },
    "@media (min-width:1024px) and (orientation:landscape)": {
      backgroundSize: "100rem 50rem",
      backgroundPosition: "0 top",
    },
    "@media (min-width:1600px)": {
      backgroundSize: "100% 90%",
      backgroundPosition: "0 top",
    },
    "@media (min-width:1920px)": {
      backgroundSize: "100% 90vh",
      backgroundPosition: "0 top",
    },
  },
  header: {
    zIndex: 1,
    display: "flex",
    textAlign: "left",
    maxWidth: "900px",
    flexDirection: "column",
    padding: "7vh 4vw",
    paddingLeft: "2vw",
    "@media (max-width:1024px)": {
      display: "none",
    },
  },
  brandIcon: {
    objectFit: "contain",
    alignSelf: "flex-start",
    height: "3em",
  },
  titleHeader: {
    zIndex: 1,
    display: "flex",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "2.5rem",
    lineHeight: "1.2",
    maxWidth: "35rem",
    marginTop: 30,
  },
  subtitleHeader: {
    zIndex: 1,
    color: "#ffffff",
    fontSize: "1.3rem",
    lineHeight: "1.2",
    maxWidth: "26rem",
    marginTop: 30,
  },
  loginBox: {
    zIndex: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "28rem",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "90vw",
    objectFit: "contain",
    paddingBottom: 10,
    paddingTop: "4em",
    "@media (max-width:1024px)": {
      paddingTop: 44,
    },
  },
  brandIconMobile: {
    height: 60,
    marginBottom: 44,
    "@media (min-width:1024px)": {
      display: "none",
    },
  },
  card: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    borderRadius: 16,
    width: "90%",
    boxShadow: "0 0 20px 0 #663ea836",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  logoEnvironmentMark: {
    zIndex: 1,
    display: "flex",
    fontWeight: "bold",
    fontSize: pxToRem(17),
    color: "#5fc7d7",
    margin: "-18px 0 0 118px",
  },
  logoEnvironmentMarkMobile: {
    zIndex: 1,
    display: "flex",
    fontWeight: "bold",
    fontSize: pxToRem(18),
    color: "#5fc7d7",
    margin: "-68px 0 40px 132px",
  },
});

interface WaveLayoutProps {
  isLoading?: boolean;
  children: ReactNode;
  title?: string;
}

const LayoutSignIn = ({ isLoading, children, title }: WaveLayoutProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ background: "#f0f2ff", minHeight: "100vh" }}>
      <div className={classes.headerContainer}>
        <div className={classes.wave} />
        <div style={{ display: "flex" }}>
          <div className={classes.header}>
            <img src={logo} className={classes.brandIcon} alt="" />
            {environment.name !== "production" && !isMobile && (
              <Typography className={classes.logoEnvironmentMark}>
                {"TEST"}
              </Typography>
            )}
            <Typography variant={"h3"} className={classes.titleHeader}>
              {"Bienvenido a Lira"}
            </Typography>
            <Typography variant={"h5"} className={classes.subtitleHeader}>
              {
                // "Te brindamos las herramientas para que puedas comprar y vender tu auto, asegurar tus proyectos y llegar a donde quieras."
              }
            </Typography>
          </div>
          <div className={classes.loginBox}>
            <img src={logo} className={classes.brandIconMobile} alt="" />
            {environment.name !== "production" && isMobile && (
              <Typography className={classes.logoEnvironmentMarkMobile}>
                {"TEST"}
              </Typography>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LayoutSignIn;
