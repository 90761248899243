import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, usuariosPath } from "~constants/referenceNames";
import {
  GetUsuariosResponse,
  GetUsuarioResponse,
  PostOnboardingResponse,
} from "./types.js";

const mockActive = false;

const usuarios = () => {
  return {
    getUsuario: (id: string): Promise<GetUsuarioResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[usuariosPath]}/${id}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getUsuarios: async (data: {
      skip: number;
      take: number;
      searchCriteria?: string;
      getOnlyActivos?: boolean;
    }): Promise<GetUsuariosResponse> => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      if (data.searchCriteria) {
        parameters += `&SearchCriteria=${data.searchCriteria}`;
      }

      if (data.getOnlyActivos !== null && data.getOnlyActivos !== undefined) {
        parameters += `&GetOnlyActivos=${data.getOnlyActivos}`;
      }

      const url = `${environment.segurosUrl}${paths[usuariosPath]}?${parameters}`;

      try {
        if (mockActive) {
          return getUsuariosMock(data);
        } else {
          const response = await fetch(url, request);
          errorUnlessOk(response);
          return await response.json();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    putUsuarios: (data: {
      id: string;
      roles: string[];
      oficinaId: string;
      activo: boolean;
    }): Promise<Response> => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[usuariosPath]}/${data.id}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postOnboarding: (): Promise<PostOnboardingResponse> => {
      const request = buildRequestWithAuth("POST", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}/onboarding`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

const getUsuariosMock = (data: {
  skip: number;
  take: number;
}): GetUsuariosResponse => {
  const usuarios = [
    {
      id: "1",
      nombre: "Juan",
      apellido: "Dahl1",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [
        { descripcion: "admin", key: "Administrador" },
        {
          descripcion: "polizas:emisionManual",
          key: "Emisión Manual de Pólizas",
        },
        { descripcion: "polizas:read", key: "Ver Pólizas" },
      ],
    },
    {
      id: "2",
      nombre: "Juan",
      apellido: "Dahl2",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [],
    },
    {
      id: "3",
      nombre: "Juan",
      apellido: "Dahl3",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [
        { descripcion: "admin", key: "Administrador" },
        { descripcion: "polizas:read", key: "Ver Pólizas" },
      ],
    },
    {
      id: "4",
      nombre: "Juan",
      apellido: "Dahl4",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [{ descripcion: "polizas:read", key: "Ver Pólizas" }],
    },
    {
      id: "5",
      nombre: "Juan",
      apellido: "Dahl5",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [{ descripcion: "admin", key: "Administrador" }],
    },
    {
      id: "6",
      nombre: "Juan",
      apellido: "Dahl6",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [
        { descripcion: "admin", key: "Administrador" },
        {
          descripcion: "polizas:emisionManual",
          key: "Emisión Manual de Pólizas",
        },
        { descripcion: "polizas:read", key: "Ver Pólizas" },
      ],
    },
    {
      id: "7",
      nombre: "Juan",
      apellido: "Dahl7",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scope: [],
    },
    {
      id: "8",
      nombre: "Juan",
      apellido: "Dahl8",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [
        { descripcion: "admin", key: "Administrador" },
        { descripcion: "polizas:read", key: "Ver Pólizas" },
      ],
    },
    {
      id: "9",
      nombre: "Juan",
      apellido: "Dahl9",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [{ descripcion: "polizas:read", key: "Ver Pólizas" }],
    },
    {
      id: "10",
      nombre: "Juan",
      apellido: "Dahl10",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [{ descripcion: "admin", key: "Administrador" }],
    },
    {
      id: "11",
      nombre: "Juan",
      apellido: "Dahl11",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [
        { descripcion: "admin", key: "Administrador" },
        {
          descripcion: "polizas:emisionManual",
          key: "Emisión Manual de Pólizas",
        },
        { descripcion: "polizas:read", key: "Ver Pólizas" },
      ],
    },
    {
      id: "12",
      nombre: "Juan",
      apellido: "Dahl12",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scope: [],
    },
    {
      id: "13",
      nombre: "Juan",
      apellido: "Dahl13",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [
        { descripcion: "admin", key: "Administrador" },
        { descripcion: "polizas:read", key: "Ver Pólizas" },
      ],
    },
    {
      id: "14",
      nombre: "Juan",
      apellido: "Dahl14",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [{ descripcion: "polizas:read", key: "Ver Pólizas" }],
    },
    {
      id: "15",
      nombre: "Juan",
      apellido: "Dahl15",
      email: "jdahl@mg-group.com.ar",
      oficina: "Casa Central",
      tipoUsuario: "Interno",
      scopes: [{ descripcion: "admin", key: "Administrador" }],
    },
  ];

  const take = data.take;
  const page = data.skip / take;
  const filtered = usuarios.slice(page * take, page * take + take);

  return {
    usuarios: filtered,
    totalRegistros: 15,
  };
};

export { usuarios };
