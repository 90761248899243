import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import {
  paths,
  solicitudesEmisionBackendPath,
} from "~constants/referenceNames";
import {
  GetSolicitudesEmisionRequest,
  GetSolicitudesEmisionResponse,
  GetSolicitudEmisionResponse,
  GetDatosPagoResponse,
  GetLogSolicitudResponse,
} from "./types";

const solicitudesEmision = () => {
  return {
    getSolicitudesEmision: (
      data: GetSolicitudesEmisionRequest
    ): Promise<GetSolicitudesEmisionResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      if (data.fechaDesde) {
        parameters += `&FechaDesde=${data.fechaDesde.toISOString()}`;
      }
      if (data.fechaHasta) {
        data.fechaHasta.setHours(23, 59, 59);
        parameters += `&FechaHasta=${data.fechaHasta.toISOString()}`;
      }
      if (data.searchCriteria) {
        parameters += `&SearchCriteria=${data.searchCriteria}`;
      }
      if (data.negocio) {
        parameters += `&Negocio=${data.negocio}`;
      }
      if (data.estado) {
        parameters += `&Estados=[${data.estado.join()}]`;
      }
      if (data.oficina) {
        parameters += `&OficinaId=${data.oficina}`;
      }
      if (data.dniCliente) {
        parameters += `&DNICliente=${data.dniCliente}`;
      }

      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getDetalleSolicitudEmision: (
      id: string
    ): Promise<GetSolicitudEmisionResponse> => {
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${id}`;
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getDatosPago: (id: string): Promise<GetDatosPagoResponse> => {
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${id}/datosPago`;
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getLogsUsuario: (id: string): Promise<GetLogSolicitudResponse> => {
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${id}/log`;
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    patchSolicitudEmision: (
      solicitudEmisionId: string,
      data: Partial<GetSolicitudEmisionResponse>
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${solicitudEmisionId}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    patchRechazarSolicitud: (
      solicitudEmisionId: string,
      data: { MotivoRechazo: string }
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${solicitudEmisionId}/rechazar`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { solicitudesEmision };
