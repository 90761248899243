import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListadoCotizaciones from "./listadoCotizaciones/ListadoCotizaciones";
import { getCotizaciones } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { multicotizacionesPath } from "~constants/referenceNames";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import { useHistory, useLocation } from "react-router-dom";
import {
  setPage,
  setTake,
  selectSearchCriteria,
  selectFechaDesde,
  selectFechaHasta,
  selectNegocio,
  selectEstado,
} from "./Filtros/actions";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import UnexpectedError from "~styled/errorPages/UnexpectedError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
  tableContainer: {
    padding: "5vh",
  },
  table: {
    backgroundColor: theme.colours.white,
    borderRadius: "8px 8px 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonFilter: {
    //display: "flex",
    width: "10vw",
    height: "5vh",
    borderRadius: "4px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    cotizaciones: state.cotizacionesReducer.cotizaciones,
    totalRegistros: state.cotizacionesReducer.totalRegistros,
    isLoading: state.cotizacionesReducer.isLoading,
    failed: state.cotizacionesReducer.failed,

    take: state.filtrosCotizacionesReducer.take,
    page: state.filtrosCotizacionesReducer.page,
    fechaDesde: state.filtrosCotizacionesReducer.fechaDesde,
    fechaHasta: state.filtrosCotizacionesReducer.fechaHasta,
    negocio: state.filtrosCotizacionesReducer.negocio,
    estado: state.filtrosCotizacionesReducer.estado,
    searchCriteria: state.filtrosCotizacionesReducer.searchCriteria,
  }));

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  {
    name: "negocio",
    nameUrl: "Negocio",
    action: selectNegocio,
  },
  {
    name: "estado",
    nameUrl: "Estado",
    action: selectEstado,
  },
  {
    name: "fechaDesde",
    nameUrl: "Desde",
    action: selectFechaDesde,
    isDate: true,
  },
  {
    name: "fechaHasta",
    nameUrl: "Hasta",
    action: selectFechaHasta,
    isDate: true,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const CotizacionesContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentState = useReduxSelector();
  const {
    page,
    take,
    isLoading,
    failed,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    negocio,
    estado,
  } = currentState;

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: multicotizacionesPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [
    dispatch,
    take,
    page,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    negocio,
    estado,
  ]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading && url) {
      dispatch(
        getCotizaciones(
          page * take,
          take,
          fechaDesde,
          fechaHasta,
          searchCriteria,
          negocio,
          estado
        )
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url]);

  const classes = useStyles();

  if (failed) return <UnexpectedError />;

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Cotizaciones"}
      selectedDefault={multicotizacionesPath}
    >
      <div className={classes.root}>
        <div className={classes.body}>
          <ListadoCotizaciones />
        </div>
      </div>
    </SectionLayout>
  );
};

export default CotizacionesContainer;
/*
const findDuplicates = arr => {
  let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1].id === sorted_arr[i].id) {
      results.push(sorted_arr[i]);
    }
  }
  return results.length;
};
*/
