import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import MenuIcon from "@material-ui/icons/Menu";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useGetNavbar } from "~components/views/inicio/Inicio";
import { DesktopNavBar } from "~styled/layouts/navbarLayout/NavbarLayout";
import { useScrollTrigger, Slide } from "@material-ui/core";
import ContentSection from "../../contentSection/ContentSection";

const drawerWidth = 180;
const TABS_HEIGHT = 48;
const NAV_BAR_HEIGHT = 56;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    backgroundColor: theme.colours.white,
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      zIndex: 2,
      paddingLeft: "30px",
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "50px",
    paddingLeft: "100px",
    paddingBottom: "10px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  drawerPaper: {
    alignItems: "flex-start",
    zIndex: 2,
  },
  titleAndIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingBottom: "10px",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "20px",
      paddingLeft: "20px",
    },
    flexGrow: 1,
    padding: "20px",
  },
  backButton: {
    color: theme.colours.white,
    display: "flex",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "8px",
    width: "60px",
  },
  columnDirection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  columnCentered: {
    padding: theme.typography.pxToRem(16),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 1,
  },
  titleText: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    color: theme.colours.violeta,
    margin: 2,
    whiteSpace: "nowrap",
    lineHeight: "0.6",
  },
  subtitleText: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    color: theme.colours.grey5,
    margin: 2,
    fontSize: "12px",
  },
  backButtonText: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    color: theme.colours.brownGrey2,
    fontSize: 13,
  },
  backIconButton: {
    borderRadius: 5,
    color: theme.colours.brownGrey2,
    padding: 2,
    fontSize: "0.8rem",
  },
  contentPadding: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "220px",
      paddingRight: "20px",
    },
  },
  contentSize: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100vw - 300px)",
    },
  },
  tabs: {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 999,
    height: TABS_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.2s ease-out",
  },
}));

const DetailsLayout = ({
  title,
  subtitle,
  Icon,
  children,
  data,
  backAction,
  selectedDefault,
  toolbarHeight = 0,
  fixed = false,
  tabsComponent,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const items = useGetNavbar();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();

  const handleBackRef = () => {
    if (backAction) backAction();
    else history.goBack();
  };

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  return (
    <div className={classes.root}>
      <Slide appear={false} direction="down" in={fixed || !isScrolled}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleMenuToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.columnDirection}>
              <div className={classes.backButton} onClick={handleBackRef}>
                <IconButton className={classes.backIconButton}>
                  <ArrowBackIosIcon
                    style={{
                      fontSize: "0.8rem",
                    }}
                  />
                  <div className={classes.backButtonText}>volver</div>
                </IconButton>
              </div>

              <div className={classes.titleAndIcon}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  {Icon && <Icon style={{ color: theme.colours.violeta }} />}

                  <div className={classes.columnDirection}>
                    <Typography className={classes.titleText} variant="h6">
                      {title}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                      {subtitle}
                    </Typography>
                  </div>
                </div>
                {data}
              </div>
              {tabsComponent && (
                <div
                  className={classes.tabs}
                  style={{
                    top: !isScrolled ? NAV_BAR_HEIGHT + toolbarHeight : 0,
                  }}
                >
                  <ContentSection>{tabsComponent()}</ContentSection>
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Slide>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileMenuOpen}
            onClose={handleMenuToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DesktopNavBar items={items} tabsComponent={tabsComponent} />
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DesktopNavBar items={items} tabsComponent={tabsComponent} />
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.contentPadding}>
          <div className={classes.toolbar} />
          <div className={classes.contentSize}>
            <SlideAnimation isForward firstLoad={true}>
              <div className={classes.columnCentered}>{children}</div>
            </SlideAnimation>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DetailsLayout;

const SlideAnimation = ({ isForward, children }) => {
  const location = useLocation();

  return (
    <Slide
      key={location.key}
      direction={isForward ? "left" : "right"}
      in={true}
    >
      {children}
    </Slide>
  );
};
