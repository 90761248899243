export const SELECCIONAR = "";
export const CHOQUE = "Choque";
export const DESTRUCCION_TOTAL = "Destrucción Total";
export const CRISTALES = "Cristales";
export const GRANIZO = "Granizo";
export const CERRADURA = "Cerradura";
export const ROBO_PARCIAL = "Robo Parcial";
export const ROBO_TOTAL = "Robo Total";
export const INCENDIO_TOTAL = "Incendio Total";
export const INCENDIO_PARCIAL = "Incendio Parcial";

const tipoSiniestroOptions = {
  [SELECCIONAR]: "Seleccionar",
  [CHOQUE]: "Choque",
  [DESTRUCCION_TOTAL]: "Destrucción Total",
  [CRISTALES]: "Cristales",
  [GRANIZO]: "Granizo",
  [CERRADURA]: "Cerradura",
  [ROBO_PARCIAL]: "Robo Parcial",
  [ROBO_TOTAL]: "Robo Total",
  [INCENDIO_TOTAL]: "Incendio Total",
  [INCENDIO_PARCIAL]: "Incendio Parcial",
};

const tipoSiniestroFilter = [
  { label: tipoSiniestroOptions[SELECCIONAR], value: SELECCIONAR },
  { label: tipoSiniestroOptions[CHOQUE], value: CHOQUE },
  { label: tipoSiniestroOptions[DESTRUCCION_TOTAL], value: DESTRUCCION_TOTAL },
  { label: tipoSiniestroOptions[CRISTALES], value: CRISTALES },
  { label: tipoSiniestroOptions[GRANIZO], value: GRANIZO },
  { label: tipoSiniestroOptions[CERRADURA], value: CERRADURA },
  { label: tipoSiniestroOptions[ROBO_PARCIAL], value: ROBO_PARCIAL },
  { label: tipoSiniestroOptions[ROBO_TOTAL], value: ROBO_TOTAL },
  { label: tipoSiniestroOptions[INCENDIO_TOTAL], value: INCENDIO_TOTAL },
  { label: tipoSiniestroOptions[INCENDIO_PARCIAL], value: INCENDIO_PARCIAL },
];

export { tipoSiniestroOptions, tipoSiniestroFilter };
