import { useState, useEffect, useRef, useCallback } from "react";

const useField = (defaultValue, validate, focus, isLoading, readOnly) => {
  const reference = useRef();
  const [loaded, setLoaded] = useState(false);

  const [field, setField] = useState({
    editing: false,
    valid: true,
  });

  const handleEdit = useCallback(
    (canEdit) => {
      setField((prev) => ({
        ...prev,
        editing: canEdit && !readOnly,
      }));
    },
    [readOnly]
  );

  useEffect(() => {
    if (!isLoading && !defaultValue) {
      handleEdit(true);
    }
  }, [isLoading, defaultValue, handleEdit]);

  useEffect(() => {
    setLoaded(false);
  }, [isLoading]);

  useEffect(() => {
    if (focus) {
      reference.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (!loaded) {
      setField((prev) => ({
        ...prev,
        value: defaultValue,
      }));

      setLoaded(true);
    }
  }, [validate, loaded, field, setField, defaultValue]);

  useEffect(() => {
    const applyValidation = (validationResult) => {
      if (field.valid !== validationResult) {
        setField((prev) => ({
          ...prev,
          valid: validationResult,
        }));
      }
    };
    if (validate) {
      Promise.resolve(validate(field.value)).then((result) =>
        applyValidation(result)
      );
    }
  }, [field, setField, validate]);

  return [field, setField, handleEdit, reference];
};

export default useField;
