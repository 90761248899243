const TODOS = "";
export const DEBITOTC = "DebitoConTarjetaCredito";
export const DEBITOCBU = "DebitoConCBU";
export const CUPONPAGO = "CuponPago";
export const DIRECTO = "Directo";

const formasPagoOptions = {
  [TODOS]: "Todas",
  [DEBITOTC]: "Débito con Tarjeta de Crédito",
  [DEBITOCBU]: "Débito con CBU",
  [CUPONPAGO]: "Cupón de pago",
  [DIRECTO]: "Directo",
};

const formasPago = [DEBITOTC, DEBITOCBU, CUPONPAGO, DIRECTO];

const formasPagoFilter = [
  { label: formasPagoOptions[TODOS], value: TODOS },
  { label: formasPagoOptions[DEBITOTC], value: DEBITOTC },
  { label: formasPagoOptions[DEBITOCBU], value: DEBITOCBU },
  { label: formasPagoOptions[CUPONPAGO], value: CUPONPAGO },
  { label: formasPagoOptions[DIRECTO], value: DIRECTO },
];

const formasPagoEndoso = [
  { label: formasPagoOptions[DEBITOTC], value: DEBITOTC },
  { label: formasPagoOptions[DEBITOCBU], value: DEBITOCBU },
  { label: formasPagoOptions[CUPONPAGO], value: CUPONPAGO },
  //{ label: formasPagoOptions[DIRECTO], value: DIRECTO }
];

export { formasPagoOptions, formasPago, formasPagoFilter, formasPagoEndoso };
