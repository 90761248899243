import React from "react";
import { negociosSegurosOptions, PAS } from "~constants/negocios";
import ColorLabel from "../colorLabel/ColorLabel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: { paddingRight: "10px" },
}));

const ColorLabelPas = ({ solicitudEmision }) => {
  const classes = useStyles();

  if (
    solicitudEmision?.codigoOrganizador &&
    solicitudEmision?.codigoProductor
  ) {
    return (
      <span className={classes.container}>
        <ColorLabel variant={"otros"} text={negociosSegurosOptions[PAS]} />
      </span>
    );
  } else return null;
};

export default ColorLabelPas;
