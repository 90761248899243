import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";

import ReceiptIcon from "@material-ui/icons/Receipt";
import { useSelector } from "react-redux";
import ItemCuotaCard from "~business/Cards/ItemCuotaCard";

const title = "Cuotas";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentPoliza = state.detallePolizaReducer.currentPoliza
      ? state.detallePolizaReducer.currentPoliza
      : {};
    return {
      cuotas: currentPoliza ? currentPoliza.cuotas : [],
    };
  });

const CuotasCard = ({ isLoading, displayError, onBlur }) => {
  const { cuotas } = useReduxSelector();

  return (
    <DetalleCard isLoading={isLoading} title={title} IconHeader={ReceiptIcon}>
      {cuotas &&
        Object.keys(cuotas).map((key) => (
          <ItemCuotaCard
            key={key}
            title={`Cuota ${key}`}
            index={cuotas[key].numeroCuota}
            cuota={{
              index: cuotas[key].numeroCuota,
              fechaVencimiento: { value: cuotas[key].fechaVencimiento },
              importe: { value: cuotas[key].premio },
              vigenciaDesde: { value: cuotas[key].fechaDesde },
              vigenciaHasta: { value: cuotas[key].fechaHasta },
            }}
            readOnly
          />
        ))}
    </DetalleCard>
  );
};

export default CuotasCard;
