import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import pxToRem from "~libs/pxToRem";
import TableLoading from "../tableLoading/TableLoading";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  tableCell: {
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    //color: theme.colours.violeta
  },
  tableCellCenter: {
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
    //color: theme.colours.violeta
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 10,
    paddingLeft: 10,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", marginLeft: pxToRem(30) },
}));

const TableBackoffice = ({
  isLoading,
  titles,
  children,
  isEmpty,
  emptyListText,
}) => {
  const classes = useStyles();

  if (isLoading) return <TableLoading length={5} />;
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {titles.map((title) => (
            <TableCell key={title} className={classes.tableCell}>
              <Typography
                color="textSecondary"
                className={classes.tableCellHeader}
              >
                {title}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classes.table}>
        <>
          {!isEmpty && children}
          {isEmpty && (
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={titles.length}>
                <Typography
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {emptyListText}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </>
      </TableBody>
    </Table>
  );
};

const TableBackofficeCell = ({
  children,
  value,
  type,
  timeFormat = "dd'/'MM'/'yyyy HH:mm",
  centered = false,
}) => {
  const classes = useStyles();

  return (
    <TableCell
      className={centered ? classes.tableCellCenter : classes.tableCell}
    >
      {type === "text" && (
        <>{value ?? <div className={classes.dash}>&mdash;</div>} </>
      )}
      {type === "date" && format(Date.parse(value), timeFormat, { locale: es })}
      {type === "custom" && children}
    </TableCell>
  );
};

export { TableBackoffice, TableBackofficeCell };
