import React from "react";
import DetalleCard from "../../../../business/Cards/DetalleCard.js";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import ColorLabelProductor from "~styled/colorLabelProductor/ColorLabelProductor";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemCustomCard from "~business/Cards/ItemCustomCard";
import { formasPagoOptions } from "~constants/formasPago";
import ColorLabelPas from "./../../../../styled/colorLabelPas/ColorLabelPas";
const moment = require("moment");

const title = "Póliza";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosPlan = state.detallePolizaReducer.currentPoliza
      ? state.detallePolizaReducer.currentPoliza.datosPlan
      : {};
    const currentPoliza = state.detallePolizaReducer.currentPoliza
      ? state.detallePolizaReducer.currentPoliza
      : {};

    return {
      ...datosPlan,
      negocio: currentPoliza.negocio,
      compania: datosPlan.compania,
      numeroPoliza: datosPlan.numeroPoliza,
      coberturaId: currentPoliza.coberturaId,
      coberturaNombre: currentPoliza.coberturaNombre,
      premio: datosPlan.premioTotal,
      vigenciaDesde: datosPlan.polizaVigenciaDesde,
      vigenciaHasta: datosPlan.polizaVigenciaHasta,
      formaPago: datosPlan.formaPago,
      productor: currentPoliza.productorId,
      codigoProductor: currentPoliza.codigoProductor,
      codigoOrganizador: currentPoliza.codigoOrganizador,
    };
  });

const DatosGeneralesCard = ({ isLoading, solicitudId }) => {
  const {
    negocio,
    compania,
    numeroPoliza,
    coberturaId,
    coberturaNombre,
    premio,
    vigenciaDesde,
    vigenciaHasta,
    formaPago,
    productor,
    codigoOrganizador,
    codigoProductor,
  } = useReduxSelector();

  //TODO: abstraer componente mostrar tarjeta de credito
  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemCustomCard isLoading={isLoading} label={"Negocio"}>
          <ColorLabelPas
            solicitudEmision={{ codigoProductor, codigoOrganizador }}
          />
          <ColorLabelNegocio negocio={negocio} />
          <ColorLabelProductor productor={productor} />
        </ItemCustomCard>
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Compañía"}
          value={compania}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Nº Póliza"}
          value={numeroPoliza}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Vigencia"}
          value={`${moment(vigenciaDesde)
            .local()
            .format("DD/MM/YYYY")} a ${moment(vigenciaHasta)
            .local()
            .format("DD/MM/YYYY")}`}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Cobertura"}
          value={`${coberturaNombre ?? "N/D"} (Id. ${coberturaId})`}
        />
        <ItemCurrencyCard
          readOnly
          isLoading={isLoading}
          label={"Premio Total"}
          value={premio}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Forma de Pago"}
          value={formasPagoOptions[formaPago]}
        />
      </DetalleCard>
    </>
  );
};

export default DatosGeneralesCard;
