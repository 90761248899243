import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { polizasPath } from "~constants/referenceNames";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import { getPolizas } from "./actions";
import {
  setTake,
  setPage,
  selectSearchCriteria,
  selectEstado,
  selectNegocio,
  selectFechaDesde,
  selectFechaHasta,
  setBuscarSoloParaRenovar,
  selectCompania,
} from "./Filtros/actions";
import { useLocation, useHistory } from "react-router-dom";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import ListadoPolizas from "./listadoPolizas/ListadoPolizas";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
  tableContainer: {
    padding: "5vh",
  },
  table: {
    backgroundColor: theme.colours.white,
    borderRadius: "8px 8px 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonFilter: {
    //display: "flex",
    width: "10vw",
    height: "5vh",
    borderRadius: "4px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.polizasReducer.isLoading,
    failed: state.polizasReducer.failed,
    reloadPolizas: state.polizasReducer.reloadPolizas,
    polizaAnulada: state.polizasReducer.polizaAnulada,

    take: state.filtrosPolizasReducer.take,
    page: state.filtrosPolizasReducer.page,
    fechaDesde: state.filtrosPolizasReducer.fechaDesde,
    fechaHasta: state.filtrosPolizasReducer.fechaHasta,
    searchCriteria: state.filtrosPolizasReducer.searchCriteria,
    estado: state.filtrosPolizasReducer.estado,
    negocio: state.filtrosPolizasReducer.negocio,
    soloParaRenovar: state.filtrosPolizasReducer.soloParaRenovar,
    compania: state.filtrosPolizasReducer.compania,
  }));

const params = [
  {
    name: "soloParaRenovar",
    nameUrl: "SoloParaRenovar",
    action: setBuscarSoloParaRenovar,
  },
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  { name: "estado", nameUrl: "Estado", action: selectEstado },
  { name: "negocio", nameUrl: "Negocio", action: selectNegocio },
  { name: "compania", nameUrl: "Compania", action: selectCompania },
  {
    name: "fechaDesde",
    nameUrl: "FechaDesde",
    action: selectFechaDesde,
    isDate: true,
  },
  {
    name: "fechaHasta",
    nameUrl: "FechaHasta",
    action: selectFechaHasta,
    isDate: true,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const PolizasContainer = () => {
  const currentState = useReduxSelector();
  const {
    page,
    take,
    isLoading,
    failed,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    estado,
    reloadPolizas,
    negocio,
    compania,
    soloParaRenovar,
    polizaAnulada,
  } = currentState;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: polizasPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [
    history,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    estado,
    negocio,
    soloParaRenovar,
    page,
    take,
    compania,
  ]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading && url) {
      dispatch(
        getPolizas({
          skip: take * page,
          take,
          fechaDesde,
          fechaHasta,
          searchCriteria,
          estado,
          negocio,
          soloParaRenovar,
          compania,
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url, reloadPolizas, polizaAnulada]);

  const classes = useStyles();

  if (failed) return <UnexpectedError />;

  return (
    <>
      <SectionLayout
        Icon={AssignmentIcon}
        title={"Pólizas"}
        selectedDefault={polizasPath}
      >
        <div className={classes.root}>
          <div className={classes.body}>{<ListadoPolizas />}</div>
        </div>
      </SectionLayout>
    </>
  );
};

export default PolizasContainer;
