import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  setPolizaFileName,
  setCuponeraFileName,
  setCedulaMercosurFileName,
  setTarjetaFileName,
  setIsProcessingPoliza,
  setIsProcessingCuponera,
  setIsProcessingCedulaMercosur,
  setIsProcessingTarjeta,
} from "../actions";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";

const title = "Documentación";

const adjuntos = {
  poliza: setPolizaFileName,
  cuponera: setCuponeraFileName,
  cedulaMercosur: setCedulaMercosurFileName,
  tarjeta: setTarjetaFileName,
};

const ImpresosCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const handleAdded = (event, adjunto) => {
    const action = adjuntos[adjunto];
    const value = `temp/${event.target.value}`;
    dispatch(
      action({
        value: value,
        valid: true,
      })
    );
  };

  const handleRemoved = (event, adjunto) => {
    const action = adjuntos[adjunto];
    dispatch(
      action({
        value: null,
        valid: false,
      })
    );
  };

  const handleProcessingPoliza = (value) => {
    dispatch(setIsProcessingPoliza(value));
  };

  const handleProcessingCuponera = (value) => {
    dispatch(setIsProcessingCuponera(value));
  };

  const handleProcessingCedulaMercosur = (value) => {
    dispatch(setIsProcessingCedulaMercosur(value));
  };

  const handleProcessingTarjeta = (value) => {
    dispatch(setIsProcessingTarjeta(value));
  };

  if (isLoading)
    return (
      <DetalleCard
        isLoading={isLoading}
        title={title}
        IconHeader={DescriptionIcon}
      />
    );

  return (
    <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
      <>
        <ItemFileUploaderCard
          label={"Póliza (requerido)"}
          onAdded={(event) => handleAdded(event, "poliza")}
          onRemoved={(event) => handleRemoved(event, "poliza")}
          onProcessStart={handleProcessingPoliza}
          acceptImage={false}
          acceptPdf={true}
        />
        <ItemFileUploaderCard
          label={"Tarjeta"}
          onAdded={(event) => handleAdded(event, "tarjeta")}
          onRemoved={(event) => handleRemoved(event, "cuponera")}
          onProcessStart={handleProcessingTarjeta}
          acceptImage={false}
          acceptPdf={true}
        />
        <ItemFileUploaderCard
          label={"Cédula Mercosur"}
          onAdded={(event) => handleAdded(event, "cedulaMercosur")}
          onRemoved={(event) => handleRemoved(event, "cedulaMercosur")}
          onProcessStart={handleProcessingCedulaMercosur}
          acceptImage={false}
          acceptPdf={true}
        />
        <ItemFileUploaderCard
          label={"Cuponera"}
          onAdded={(event) => handleAdded(event, "cuponera")}
          onRemoved={(event) => handleRemoved(event, "cuponera")}
          onProcessStart={handleProcessingCuponera}
          acceptImage={false}
          acceptPdf={true}
        />
      </>
    </DetalleCard>
  );
};

export default ImpresosCard;
