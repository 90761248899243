import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { format } from "date-fns";
import {
  DateRange,
  GetReporteEmisionesResponse,
  GetReporteAnulacionesResponse,
  GetReporteSiniestrosRequest,
  GetReporteSiniestrosResponse,
} from "./types";

const reportes = () => {
  return {
    getEmisiones: (data: DateRange): Promise<GetReporteEmisionesResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = `${environment.segurosUrl}/reportes/emisiones?Desde=${format(
        data.fechaDesde,
        "yyyy'-'MM'-'dd"
      )}&Hasta=${format(data.fechaHasta, "yyyy'-'MM'-'dd")}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getAnulaciones: (
      data: DateRange
    ): Promise<GetReporteAnulacionesResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = `${
        environment.segurosUrl
      }/reportes/anulaciones?Desde=${format(
        data.fechaDesde,
        "yyyy'-'MM'-'dd"
      )}&Hasta=${format(data.fechaHasta, "yyyy'-'MM'-'dd")}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getSiniestros: (
      data: GetReporteSiniestrosRequest
    ): Promise<GetReporteSiniestrosResponse> => {
      const dateWithoutTimezone = (date: Date) => {
        const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
        const withoutTimezone = new Date(date.valueOf() - tzoffset);
        return withoutTimezone;
      };

      const fechaDesdeUTCClear = data.fechaDesde
        ? dateWithoutTimezone(data.fechaDesde)
        : "";
      const fechaHastaUTCClear = data.fechaHasta
        ? dateWithoutTimezone(data.fechaHasta)
        : "";

      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Estado=${data.estado}`;

      if (data.searchCriteria) {
        parameters += `&SearchCriteria=${data.searchCriteria}`;
      }
      if (data.tipo && data.tipo !== "Seleccionar") {
        parameters += `&Tipo=${data.tipo}`;
      }
      if (fechaDesdeUTCClear) {
        parameters += `&FechaDesde=${fechaDesdeUTCClear.toISOString()}`;
      }

      if (fechaHastaUTCClear) {
        fechaHastaUTCClear.setUTCHours(23, 59, 59);
        parameters += `&FechaHasta=${fechaHastaUTCClear.toISOString()}`;
      }

      const url = `${environment.segurosUrl}/reportes/siniestros?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { reportes };
