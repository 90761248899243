import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemDateCard from "~business/Cards/ItemDateCard";
import DetalleCard from "~business/Cards/DetalleCard";
import Button from "@material-ui/core/Button";
import {
  getAnulaciones,
  setFechaDesdeAnulaciones,
  setFechaHastaAnulaciones,
} from "./actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.getAnulacionesReducer,
  }));

const AnulacionesCard = () => {
  const dispatch = useDispatch();
  const {
    fechaDesdeAnulacionesSelected,
    fechaHastaAnulacionesSelected,
    buttonEnabled,
    buttonText,
  } = useReduxSelector();

  const handleChangeFechaDesdeAnulaciones = (fechaDesdeAnulaciones) => {
    dispatch(setFechaDesdeAnulaciones(fechaDesdeAnulaciones));
  };
  const handleChangeFechaHastaAnulaciones = (fechaHastaAnulaciones) => {
    dispatch(setFechaHastaAnulaciones(fechaHastaAnulaciones));
  };
  const handleGetAnulaciones = () => {
    let data = {
      fechaDesde: fechaDesdeAnulacionesSelected.value,
      fechaHasta: fechaHastaAnulacionesSelected.value,
    };
    dispatch(getAnulaciones(data));
  };

  return (
    <>
      <DetalleCard title={"Anulaciones"}>
        <ItemDateCard
          label="Desde"
          value={fechaDesdeAnulacionesSelected.value}
          onChange={handleChangeFechaDesdeAnulaciones}
        />
        <ItemDateCard
          label="Hasta"
          value={fechaHastaAnulacionesSelected.value}
          onChange={handleChangeFechaHastaAnulaciones}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ width: "40%" }}
          onClick={handleGetAnulaciones}
          disabled={!buttonEnabled}
        >
          {buttonText}
        </Button>
      </DetalleCard>
    </>
  );
};

export default AnulacionesCard;
