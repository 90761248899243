import { useSelector } from "react-redux";

const useReduxSelector = () =>
  useSelector((state) => ({
    tokenPermissions: state.authenticatedViewReducer.tokenPermissions,
    roles: state.authenticatedViewReducer.roles,
  }));

const useTokenPermissions = (permissionRequired) => {
  const { tokenPermissions } = useReduxSelector();

  return tokenPermissions.includes(permissionRequired);
};

const useTokenAnyPermissions = (permissionRequired) => {
  const { tokenPermissions } = useReduxSelector();

  return tokenPermissions.some((permission) =>
    permissionRequired.includes(permission)
  );
};

const useTokenRoles = (rolRequired) => {
  const { roles } = useReduxSelector();

  return roles.includes(rolRequired);
};

export { useTokenPermissions, useTokenAnyPermissions, useTokenRoles };
