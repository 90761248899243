import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import PersonIcon from "@material-ui/icons/Person";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemEmailCard from "~business/Cards/ItemEmailCard";
import ItemDniCard from "~business/Cards/ItemDniCard";
import ItemDateCard from "~business/Cards/ItemDateCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const asegurado = state.detallePolizaReducer.currentPoliza
      ? state.detallePolizaReducer.currentPoliza.asegurado
      : {};

    return {
      ...asegurado,
    };
  });

const AseguradoCard = ({ isLoading, onBlur, displayError }) => {
  const {
    nombreCompleto,
    tipoDocumento,
    numeroDocumento,
    fechaNacimiento,
    domicilio,
    email,
    telefonoCelular,
    telefonoFijo,
  } = useReduxSelector();

  const dniFormatted = tipoDocumento
    ? `(${tipoDocumento}) ${numeroDocumento}`
    : numeroDocumento;

  const telefonoCelularFormatted = telefonoCelular
    ? `(CELULAR) ${telefonoCelular}`
    : null;
  const telefonoFijoFormatted = telefonoFijo ? `(FIJO) ${telefonoFijo}` : null;

  return (
    <DetalleCard title={"Asegurado"} IconHeader={PersonIcon}>
      <ItemInputCard
        readOnly
        isLoading={isLoading}
        label="Nombre"
        value={nombreCompleto}
      />
      <ItemDniCard
        isLoading={isLoading}
        label={"DNI"}
        value={dniFormatted}
        readOnly
      />
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha Nacimiento"}
        value={fechaNacimiento}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Domicilio"}
        value={domicilio}
        readOnly
      />
      <ItemEmailCard
        id="email"
        label="Email"
        isLoading={isLoading}
        value={email}
        readOnly
      />
      <ItemInputCard
        label={"Teléfono"}
        isLoading={isLoading}
        value={telefonoCelularFormatted ?? telefonoFijoFormatted}
        readOnly
      />
    </DetalleCard>
  );
};

export default AseguradoCard;
