import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CollapsableSection = ({ children, title = "", className }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div
        onClick={handleExpandClick}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          style={{
            fontSize: pxToRem(14),
            fontWeight: 800,
            lineHeight: 1.36,
            color: "#663ea8",
          }}
        >
          {title}
        </Typography>
        <IconButton
          className={expanded ? classes.expandOpen : classes.expand}
          onClick={handleExpandClick}
          size="small"
        >
          <ExpandMoreIcon color="primary" />
        </IconButton>
      </div>
      <Divider
        style={{
          backgroundColor: "#8C62D1",
          height: 2,
        }}
      />
      <Collapse in={expanded} timeout="auto">
        <div className={className}>{children}</div>
      </Collapse>
    </>
  );
};

export default CollapsableSection;

const useStyles = makeStyles((theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
  })
);
