import {
  errorUnlessOk,
  buildRequest,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, cargaPublicaPath } from "~constants/referenceNames";
import {
  PostSiniestroCargaPublicaRequest,
  PostSiniestroCargaPublicaResponse,
  GetPolizaCargaPublicaResponse,
  GetPolizasCargaPublicaRequest,
  GetPolizasCargaPublicaResponse,
} from "./types";

const siniestrosCargaPublica = () => {
  return {
    postSiniestro: (
      data: PostSiniestroCargaPublicaRequest
    ): Promise<PostSiniestroCargaPublicaResponse> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.cargaPublicaSiniestroUrl}${paths[cargaPublicaPath]}/cargaPublica`;

      console.log(url);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getPoliza: (id: string): Promise<GetPolizaCargaPublicaResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = `${environment.cargaPublicaSiniestroUrl}${paths[cargaPublicaPath]}/polizas/${id}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getPolizas: (
      data: GetPolizasCargaPublicaRequest
    ): Promise<GetPolizasCargaPublicaResponse> => {
      const request = buildRequest("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      if (data.searchCriteria) {
        parameters += `&SearchCriteria=${data.searchCriteria}`;
      }

      if (data.estado) {
        parameters += `&Estado=${data.estado}`;
      }

      const url = `${environment.cargaPublicaSiniestroUrl}${paths[cargaPublicaPath]}/polizas?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { siniestrosCargaPublica };
