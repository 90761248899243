import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { setNombre, setApellido, clearNombre, clearApellido } from "../actions";
import TextField from "@material-ui/core/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  buttonContinuar: {
    paddingTop: 24,
  },
  inputField: {
    marginBottom: 15,
  },
}));

const ContactoNombreStep = () => {
  const dispatch = useDispatch();
  const [nombre, setCampoNombre] = useState("");
  const [apellido, setCampoApellido] = useState("");

  useEffect(() => {
    dispatch(clearNombre());
    dispatch(clearApellido());
  }, [dispatch]);

  const canContinue = nombre.trim().length && apellido.trim().length;

  const handleNext = (nombre, apellido) => {
    dispatch(setNombre(nombre));
    dispatch(setApellido(apellido));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue)
      handleNext(nombre, apellido);
  };

  const classes = useStyles();
  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Ingresá el nombre completo del asegurado"}
        marginBottom={60}
      />
      <div className={classes.inputField}>
        <TextField
          fullWidth={true}
          onKeyDown={onKeyDown}
          autoFocus
          onFocus={(event) => event.target.select()}
          value={nombre}
          onChange={(event) => setCampoNombre(event.target.value)}
          placeholder="Nombre"
        ></TextField>
      </div>
      <div className={classes.inputField}>
        <TextField
          fullWidth={true}
          onKeyDown={onKeyDown}
          onFocus={(event) => event.target.select()}
          value={apellido}
          onChange={(event) => setCampoApellido(event.target.value)}
          placeholder="Apellido"
        ></TextField>
      </div>

      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            handleNext(nombre, apellido);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default ContactoNombreStep;
