import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContentSection from "~components/styled/contentSection/ContentSection";
import SingleButton from "~components/styled/toolboxButtons/SingleButton";

import {
  multicotizacionesPath,
  paths,
  usuariosPath,
  coberturasPath,
  solicitudesEmisionPath,
  esquemasPath,
  reportesPath,
  estadisticasPath,
  salirPath,
  novedadesPath,
  polizasPath,
  polizasMultiramaPath,
  siniestrosPath,
  solicitudesPendientesSucursalPath,
  multicotizadorPath,
  solicitudesHistoricasPath,
  configuracionPath,
} from "~constants/referenceNames";

import NavbarLayout from "~styled/layouts/navbarLayout/NavbarLayout";
import {
  ButtonGroup,
  ButtonGroupLabel,
  Button,
} from "~styled/toolboxButtons/ButtonGroup";

import addIcon from "~assets/icons/ic_nuevo.svg";
import addImage from "~assets/ilustrations/image-nueva-solicitud.svg";

import cotizacionesImage from "~assets/ilustrations/illus-comprar-auto.svg";

import homeActive from "~assets/icons/ic-home-active.svg";
import homeInActive from "~assets/icons/ic-home-inactive.svg";

import coberturasActive from "~assets/icons/ic-coberturas-active.svg";
import coberturasInactive from "~assets/icons/ic-coberturas-inactive.svg";

import usuariosActive from "~assets/icons/ic-usuarios-active.svg";
import usuariosInactive from "~assets/icons/ic-usuarios-inactive.svg";

import solicitudesPendientesActive from "~assets/icons/ic-solicitudes-pendientes-active.svg";
import solicitudesPendientesInactive from "~assets/icons/ic-solicitudes-pendientes-inactive.svg";

import solicitudesHistoricasActive from "~assets/icons/ic-solicitudes-historicas-active.svg";
import solicitudesHistoricasInactive from "~assets/icons/ic-solicitudes-historicas-inactive.svg";

import polizasActive from "~assets/icons/ic-polizas-active.svg";
import polizasInactive from "~assets/icons/ic-polizas-inactive.svg";

import siniestrosActive from "~assets/icons/ic-siniestros-active.svg";
import siniestrosInactive from "~assets/icons/ic-siniestros-inactive.svg";

import esquemasActive from "~assets/icons/ic-esquemas-active.svg";
import esquemasInactive from "~assets/icons/ic-esquemas-inactive.svg";

import novedadesActive from "~assets/icons/ic-novedades-active.svg";
import novedadesInactive from "~assets/icons/ic-novedades-inactive.svg";

import cotizacionesActive from "~assets/icons/ic-cotizaciones-active.svg";
import cotizacionesInactive from "~assets/icons/ic-cotizaciones-inactive.svg";

import multicotizadorActive from "~assets/icons/ic-multicotizador-active.svg";
import multicotizadorInactive from "~assets/icons/ic-multicotizador-inactive.svg";

import configuracionActive from "~assets/icons/ic-esquemas-active.svg";
import configuracionInactive from "~assets/icons/ic-esquemas-inactive.svg";

import reportesActive from "~assets/icons/ic-esquemas-active.svg";
import reportesInactive from "~assets/icons/ic-esquemas-inactive.svg";

import estadisticasActive from "~assets/icons/ic-solicitudes-historicas-active.svg";
import estadisticasInactive from "~assets/icons/ic-solicitudes-historicas-inactive.svg";

import ExitToApp from "~assets/icons/exit.svg";
import { useFeaturesEnabled } from "~hooks/useFeatureEnabled";
import { useDispatch, useSelector } from "react-redux";
import { getNovedades } from "../novedades/novedadesContainer/actions";
import { calculateDate, getDay } from "~libs/dateCalculator";
import { getSolicitudesEmision } from "../solicitudesEmision/listadoSolicitudes/actions";
import { setInitialStatePolizas } from "../polizas/polizasContainer/Filtros/actions";
import { setInitialStateNovedades } from "../novedades/novedadesContainer/Filtros/actions";
import { setInitialStateCotizaciones } from "../cotizaciones/listadoCotizacionesContainer/Filtros/actions";
import { setInitialStateUsuarios } from "../usuarios/Filtros/actions";
import { setInitialStateSolicitudes } from "../solicitudesEmision/listadoSolicitudes/Filtros/actions";
import { setInitialStateSolicitudesPendientesCompania } from "../solicitudesPendientesSucursal/listado/Filtros/actions";
import { clear as setInitialStateMulticotizador } from "~components/views/multicotizador/actions";
import { setInitialStateSolicitudesHistoricas } from "../solicitudesHistoricas/listado/filtros/actions";
import { setInitialStateFiltroMultirama } from "../polizasMultirama/polizasMultiramaContainer/filtros/actions";
import { setInitialStateSiniestros } from "../siniestros/siniestrosContainer/filtros/actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoadingNovedades: state.novedadesReducer.isLoading,
    novedadesSemanalesCount: state.novedadesReducer.totalRegistros,
    failed: state.novedadesReducer.failed,
    isLoadingSolicitudes: state.solicitudesEmisionReducer.isLoading,
    solicitudesSemanalesCount: state.solicitudesEmisionReducer.totalRegistros,
  }));

const Inicio = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    multicotizacionesIsEnabled,
    solicitudesEmisionIsEnabled,
    solicitudesEmisionSucursalIsEnabled,
    novedadesIsEnabled,
    coberturasIsEnabled,
    esquemasIsEnabled,
  } = useFeaturesEnabled();

  const {
    isLoadingNovedades,
    isLoadingSolicitudes,
    solicitudesSemanalesCount,
    novedadesSemanalesCount,
  } = useReduxSelector();

  useEffect(() => {
    if (!isLoadingNovedades)
      dispatch(
        getNovedades({
          skip: 0,
          take: 0,
          fechaNovedadDesde: calculateDate(new Date(), -getDay()),
          fechaNovedadHasta: new Date(),
        })
      );

    if (!isLoadingSolicitudes)
      dispatch(
        getSolicitudesEmision(
          "inicio",
          0,
          0,
          null,
          calculateDate(new Date(), -getDay()),
          new Date(),
          null,
          null
        )
      );

    //eslint-disable-next-line
  }, [dispatch]);

  const navbar = useGetNavbar();

  const handleClickButton = (menuIndex) => {
    const actionReset = navbar.find((item) => item.label === menuIndex)
      ?.actionReset;
    if (actionReset) {
      dispatch(actionReset());
    }
    history.push(paths[menuIndex]);
  };

  return (
    <NavbarLayout navbar={navbar}>
      <ContentSection>
        {solicitudesEmisionIsEnabled && (
          <SingleButton
            isLoading={isLoadingSolicitudes}
            onClick={() => handleClickButton(solicitudesEmisionPath)}
            label={"Solicitudes Pendientes"}
            subLabel={`${solicitudesSemanalesCount} Solicitudes de Emisión Pendientes esta semana`}
            image={addImage}
            icon={solicitudesPendientesActive}
            marginBottom={20}
          />
        )}

        {solicitudesEmisionSucursalIsEnabled && (
          <SingleButton
            isLoading={isLoadingSolicitudes}
            onClick={() => handleClickButton(solicitudesEmisionPath)}
            label={"Solicitudes Pendientes"}
            subLabel={"Ver las Solicitudes Pendientes"}
            image={addImage}
            icon={solicitudesPendientesActive}
            marginBottom={20}
          />
        )}

        {multicotizacionesIsEnabled && (
          <SingleButton
            onClick={() => handleClickButton(multicotizacionesPath)}
            label={"Cotizaciones"}
            subLabel={"Ver el historial de Cotizaciones"}
            image={cotizacionesImage}
            icon={cotizacionesActive}
            marginBottom={20}
          />
        )}

        {novedadesIsEnabled && (
          <SingleButton
            isLoading={isLoadingNovedades}
            onClick={() => handleClickButton(novedadesPath)}
            label={"Novedades"}
            subLabel={`${novedadesSemanalesCount} Novedades esta semana`}
            image={addImage}
            icon={novedadesActive}
            marginBottom={20}
          />
        )}

        {/* {featureFlags["endososNuevaVersion"] &&
          permissions.includes(endososPath) && (
            <SingleButton
              onClick={() => handleClickButton(endososPath)}
              label={"Endosos"}
              image={addImage}
              icon={novedadesActive}
              marginBottom={20}
            />
          )} */}

        {coberturasIsEnabled && esquemasIsEnabled && (
          <ButtonGroup marginBottom={35}>
            <Button onClick={() => handleClickButton(coberturasPath)}>
              <ButtonGroupLabel label={"Coberturas"} icon={coberturasActive} />
            </Button>
            <Button onClick={() => handleClickButton(esquemasPath)}>
              <ButtonGroupLabel label={"Esquemas"} icon={esquemasActive} />
            </Button>
            <Button onClick={() => handleClickButton(usuariosPath)}>
              <ButtonGroupLabel label={"Usuarios"} icon={usuariosActive} />
            </Button>
          </ButtonGroup>
        )}

        {coberturasIsEnabled && !esquemasIsEnabled && (
          <SingleButton
            onClick={() => handleClickButton(coberturasPath)}
            label={"Coberturas"}
            subLabel={"Administrá las coberturas de cada compañia"}
            image={addImage}
            icon={addIcon}
            marginBottom={20}
          />
        )}

        {!coberturasIsEnabled && esquemasIsEnabled && (
          <SingleButton
            onClick={() => handleClickButton(esquemasPath)}
            label={"Esquemas"}
            subLabel={"Administrá las compañias"}
            image={addImage}
            icon={addIcon}
            marginBottom={20}
          />
        )}
      </ContentSection>
    </NavbarLayout>
  );
};

export default Inicio;

export const useGetNavbar = () => {
  const {
    multicotizadorIsEnabled,
    multicotizacionesIsEnabled,
    solicitudesEmisionIsEnabled,
    solicitudesEmisionSucursalIsEnabled,
    polizasIsEnabled,
    polizasMultiramaIsEnabled,
    novedadesIsEnabled,
    coberturasIsEnabled,
    esquemasIsEnabled,
    usuariosIsEnabled,
    reportesIsEnabled,
    estadisticasIsEnabled,
    configuracionIsEnabled,
    siniestrosIsEnabled,
  } = useFeaturesEnabled();

  const navbarDefaults = [
    {
      label: "INICIO",
      to: "/",
      activeIcon: homeActive,
      inactiveIcon: homeInActive,
      position: 0,
      isEnabled: true,
    },
    {
      label: "SOLICITUDES PENDIENTES",
      to: paths[solicitudesEmisionPath],
      actionReset: setInitialStateSolicitudes,
      activeIcon: solicitudesPendientesActive,
      inactiveIcon: solicitudesPendientesInactive,
      position: 10,
      isEnabled:
        solicitudesEmisionIsEnabled && !solicitudesEmisionSucursalIsEnabled,
    },
    {
      label: "SOLICITUDES PENDIENTES",
      to: paths[solicitudesPendientesSucursalPath],
      actionReset: setInitialStateSolicitudesPendientesCompania,
      activeIcon: solicitudesPendientesActive,
      inactiveIcon: solicitudesPendientesInactive,
      position: 20,
      isEnabled: solicitudesEmisionSucursalIsEnabled,
    },
    {
      label: "MULTICOTIZADOR",
      actionReset: setInitialStateMulticotizador,
      to: paths[multicotizadorPath],
      activeIcon: multicotizadorActive,
      inactiveIcon: multicotizadorInactive,
      position: 30,
      isEnabled: multicotizadorIsEnabled,
    },
    {
      label: "COTIZACIONES",
      to: paths[multicotizacionesPath],
      actionReset: setInitialStateCotizaciones,
      activeIcon: cotizacionesActive,
      inactiveIcon: cotizacionesInactive,
      position: 40,
      isEnabled: multicotizacionesIsEnabled,
    },
    {
      label: "PÓLIZAS",
      actionReset: setInitialStatePolizas,
      to: paths[polizasPath],
      activeIcon: polizasActive,
      inactiveIcon: polizasInactive,
      position: 50,
      isEnabled: polizasIsEnabled,
    },
    {
      label: "SOLICITUDES HISTÓRICAS",
      to: paths[solicitudesHistoricasPath],
      actionReset: setInitialStateSolicitudesHistoricas,
      activeIcon: solicitudesHistoricasActive,
      inactiveIcon: solicitudesHistoricasInactive,
      position: 60,
      isEnabled:
        solicitudesEmisionIsEnabled || solicitudesEmisionSucursalIsEnabled,
    },
    {
      label: "PÓLIZAS MULTIRAMA",
      to: paths[polizasMultiramaPath],
      actionReset: setInitialStateFiltroMultirama,
      activeIcon: polizasActive,
      inactiveIcon: polizasInactive,
      position: 70,
      isEnabled: polizasMultiramaIsEnabled,
    },
    {
      label: "SINIESTROS",
      to: paths[siniestrosPath],
      actionReset: setInitialStateSiniestros,
      activeIcon: siniestrosActive,
      inactiveIcon: siniestrosInactive,
      position: 80,
      isEnabled: siniestrosIsEnabled,
    },
    {
      label: "NOVEDADES",
      to: paths[novedadesPath],
      actionReset: setInitialStateNovedades,
      activeIcon: novedadesActive,
      inactiveIcon: novedadesInactive,
      position: 90,
      isEnabled: novedadesIsEnabled,
    },
    {
      label: "COBERTURAS",
      to: paths[coberturasPath],
      activeIcon: coberturasActive,
      inactiveIcon: coberturasInactive,
      position: 100,
      isEnabled: coberturasIsEnabled,
    },
    {
      label: "ESQUEMAS",
      to: paths[esquemasPath],
      activeIcon: esquemasActive,
      inactiveIcon: esquemasInactive,
      position: 110,
      isEnabled: esquemasIsEnabled,
    },
    {
      label: "REPORTES",
      to: paths[reportesPath],
      activeIcon: reportesActive,
      inactiveIcon: reportesInactive,
      position: 115,
      isEnabled: reportesIsEnabled,
    },
    {
      label: "USUARIOS",
      to: paths[usuariosPath],
      actionReset: setInitialStateUsuarios,
      activeIcon: usuariosActive,
      inactiveIcon: usuariosInactive,
      position: 115,
      isEnabled: usuariosIsEnabled,
    },
    {
      label: "CONFIGURACIÓN",
      to: paths[configuracionPath],
      activeIcon: configuracionActive,
      inactiveIcon: configuracionInactive,
      position: 120,
      isEnabled: configuracionIsEnabled,
    },
    {
      label: "ESTADISTICAS",
      to: paths[estadisticasPath],
      activeIcon: estadisticasActive,
      inactiveIcon: estadisticasInactive,
      position: 120,
      isEnabled: estadisticasIsEnabled,
    },
    {
      label: "CAMBIAR CUENTA",
      to: paths[salirPath],
      inactiveIcon: ExitToApp,
      position: 125,
      isEnabled: true,
    },
  ];

  const result = navbarDefaults
    .filter((item) => item.isEnabled)
    .sort((a, b) => a.position - b.position);

  return result;

  //   const featureFlagItems = !featureFlags["endososNuevaVersion"]
  //   ? ["ENDOSOS"]
  //   : [];
  // const permissionsFeatureFlag = permissions.filter(
  //   (key) => !featureFlagItems.find((item) => key === item)
  // );

  // const result = permissionsFeatureFlag
  //   .map((perm) => navbarDefaults[perm])
  //   .sort((a, b) => a.position - b.position);
};
