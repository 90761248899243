import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import PeopleIcon from "@material-ui/icons/People";
import { Divider } from "@material-ui/core";
import ItemInputCard from "~business/Cards/ItemInputCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosTerceros = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro.terceros
      : [];
    return { terceros: datosTerceros };
  });

const title = "Datos de Terceros";

const DatosTercerosCard = ({ isLoading }) => {
  const {
    terceros,
  } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={PeopleIcon}>
        {isLoading && <DatosTercero isLoading={isLoading} />}
        {!isLoading && terceros.map((tercero, index) => {
          return (
            <>
              {index !== 0 && <Divider style={{ marginTop: "10px", paddingTop: "20px" }} />}
              <DatosTercero tercero={tercero} />
            </>
          )
        })}

      </DetalleCard>
    </>
  );
};

const DatosTercero = ({ isLoading = false, tercero }) => <>
  <ItemInputCard
    id={"nombreTercero"}
    isLoading={isLoading}
    label={"Nombre"}
    value={tercero?.nombre}
    readOnly
  />
  <ItemInputCard
    id={"apellidoTercero"}
    isLoading={isLoading}
    label={"Apellido"}
    value={tercero?.apellido}
    readOnly
  />
  <ItemInputCard
    id={"numeroDocumentoTercero"}
    isLoading={isLoading}
    label={"DNI"}
    value={tercero?.numeroDocumento}
    readOnly
  />
  <ItemInputCard
    id={"companiaTercero"}
    isLoading={isLoading}
    label={"Compañía"}
    value={tercero?.compania}
    readOnly
  />
  <ItemInputCard
    id={"numeroPolizaTercero"}
    isLoading={isLoading}
    label={"Número de Póliza"}
    value={tercero?.numeroPoliza}
    readOnly
  />
  <ItemInputCard
    id={"telefonoTercero"}
    isLoading={isLoading}
    label={"Teléfono"}
    value={tercero?.telefono}
    readOnly
  />
  <ItemInputCard
    id={"dominioTercero"}
    isLoading={isLoading}
    label={"Dominio"}
    value={tercero?.dominio}
    readOnly
  />
  <ItemInputCard
    id={"marcaTercero"}
    isLoading={isLoading}
    label={"Marca"}
    value={tercero?.marca}
    readOnly
  />
  <ItemInputCard
    id={"modeloTercero"}
    isLoading={isLoading}
    label={"Modelo"}
    value={tercero?.modelo}
    readOnly
  />
  <ItemInputCard
    id={"daniosTercero"}
    isLoading={isLoading}
    label={"Daños al Vehículo"}
    value={tercero?.danios}
    readOnly
  />
</>;

export default DatosTercerosCard;
