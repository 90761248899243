import amplitude from "amplitude-js";
import environment from "./environment";

// docs: https://developers.amplitude.com/

let disableAnalytics = environment.name === "localhost";
//disableAnalytics = false;

export const initAnalytics = appVersion => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().init(environment.aplitudeAPIkey);

  if (appVersion) amplitude.getInstance().setVersionName(appVersion);
};

export const logOutUserAnalytics = () => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().setUserId(null);
  //amplitude.getInstance().regenerateDeviceId();
};

export const logInUserAnalytics = (email, name, userId, appVersion) => {
  if (disableAnalytics) return; // disable analytics on localhost

  var userProperties = {
    email,
    nombre: name,
    userId,
    appVersion
  };

  amplitude.getInstance().setUserProperties(userProperties);
};

export const logEventAnalytics = (eventName, eventData) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().logEvent(eventName, eventData);
};
