import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import {
  GetPolizasMultiramaRequest,
  GetPolizasMultiramaResponse,
  GetPolizaMultiramaResponse,
  GetRamasResponse,
  GetAseguradoResponse,
  PostPolizaMultiRamaRequest,
  PostPolizaMultiRamaResponse,
  PatchAnularPolizaMultiramaRequest,
} from "./types";
import { paths, polizasMultiramaPath } from "~constants/referenceNames";

const polizasMultirama = () => {
  return {
    getPolizasMultirama: (
      data: GetPolizasMultiramaRequest
    ): Promise<GetPolizasMultiramaResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      if (data.SearchCriteria) {
        parameters += `&SearchCriteria=${data.SearchCriteria}`;
      }
      if (data.Compania) {
        parameters += `&Compania=${data.Compania}`;
      }
      if (data.Rama) {
        parameters += `&Rama=${data.Rama}`;
      }
      if (data.Estado) {
        parameters += `&Estado=${data.Estado}`;
      }
      if (data.FechaVigenciaDesde) {
        parameters += `&FechaVigenciaDesde=${data.FechaVigenciaDesde.toISOString()}`;
      }
      if (data.FechaVigenciaHasta) {
        data.FechaVigenciaHasta.setHours(23, 59, 59);
        parameters += `&FechaVigenciaHasta=${data.FechaVigenciaHasta.toISOString()}`;
      }

      const url = `${environment.segurosUrl}${paths[polizasMultiramaPath]}?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getPolizaMultirama: (id: string): Promise<GetPolizaMultiramaResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = `${environment.segurosUrl}${paths[polizasMultiramaPath]}/${id}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getRamas: (): Promise<GetRamasResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = `${environment.segurosUrl}/ramas`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getAsegurado: (documento: string): Promise<GetAseguradoResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = `${environment.segurosUrl}/asegurados/${documento}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    postPolizaMultirama: (
      data: PostPolizaMultiRamaRequest
    ): Promise<PostPolizaMultiRamaResponse> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasMultiramaPath]}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    patchAnularPoliza: (
      polizaId: string,
      data: PatchAnularPolizaMultiramaRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[polizasMultiramaPath]}/${polizaId}/anular`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { polizasMultirama };
