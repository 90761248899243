import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_COTIZACIONES_INITIAL_STATE");

const getCotizacionesInited = createAction("OBTENER_COTIZACIONES_INITED");

const getCotizacionesCompleted = createAction(
  "OBTENER_COTIZACIONES_COMPLETED",
  (response, isAnOldRequest) => {
    let cotizaciones = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      cotizaciones = response.multicotizaciones;
      totalRegistros = response.totalRegistros;
    }

    return { cotizaciones, totalRegistros, isAnOldRequest };
  }
);
const getCotizacionesFailed = createAction(
  "OBTENER_COTIZACIONES_FAILED",
  (error) => ({
    error,
  })
);

const getCotizaciones = (
  skip,
  take,
  fechaDesde,
  fechaHasta,
  searchCriteria,
  negocio,
  estado
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getCotizacionesInited());

      const data = {
        skip,
        take,
        fechaDesde,
        fechaHasta,
        searchCriteria,
        negocio,
        estado,
      };

      const response = await services.api.cotizaciones().getCotizaciones(data);
      dispatch(getCotizacionesCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getCotizacionesFailed(error));
    }
  };
};

const cotizacionesInitialState = {
  isLoading: false,
  cotizaciones: null,
  totalRegistros: 0,
};

const cotizacionesReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...cotizacionesInitialState,
        take: state.take,
        cotizaciones: null,
        isLoading: true,
      };
    },
    [getCotizacionesInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getCotizacionesCompleted]: (state, action) => {
      return {
        ...state,
        cotizaciones: action.payload.cotizaciones,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
        failed: false,
      };
    },
    [getCotizacionesFailed]: (state, action) => {
      return {
        ...state,
        isPageAlreadyLoad: false,
        isLoading: false,
        failed: true,
      };
    },
  },
  cotizacionesInitialState
);

export { cotizacionesReducer };
export { getCotizaciones, setInitialState };
