import { createAction, handleActions } from "redux-actions";
import { calculateDate } from "~libs/dateCalculator";

const setInitialStateSolicitudes = createAction(
  "SET_INITIAL_STATE_FILTRO_SOLICITUDES"
);

const setPage = createAction("SET_LISTADO_SOLICITUDESEMISION_PAGE", (page) => ({
  page,
}));

const setTake = createAction(
  "SET_LISTADO_SOLICITUDESEMISION_ROWS_PER_PAGE",
  (take) => ({ take })
);

const selectFechaDesde = createAction(
  "SELECT_FECHA_DESDE_FILTRO",
  (fechaDesde) => ({ fechaDesde })
);

const selectFechaHasta = createAction(
  "SELECT_FECHA_HASTA_FILTRO",
  (fechaHasta) => ({ fechaHasta })
);

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_FILTRO",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const selectOficina = createAction("SELECT_OFICINA_FILTRO", (oficina) => ({
  oficina,
}));

const selectEstado = createAction(
  "SELECT_ESTADO_FILTRO",
  (estado, isAdminUser) => ({
    estado,
    isAdminUser,
  })
);

const selectNegocio = createAction("SELECT_NEGOCIO_FILTRO", (negocio) => ({
  negocio,
}));

const filtrosSolicitudesInitialState = {
  take: 10,
  page: 0,
  fechaDesde: calculateDate(new Date(), -30),
  fechaHasta: new Date(),
  searchCriteria: "",
  estado: ["Pendiente,PendienteCompania,DatosIncompletos"],
  negocio: "",
  oficina: "",
};

const solicitudesEmisionFiltrosReducer = handleActions(
  {
    [setInitialStateSolicitudes]: (state, action) => {
      return {
        ...filtrosSolicitudesInitialState,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        take: action.payload.take,
      };
    },
    [selectFechaDesde]: (state, action) => {
      return {
        ...state,
        fechaDesde: action.payload.fechaDesde,
      };
    },
    [selectFechaHasta]: (state, action) => {
      return {
        ...state,
        fechaHasta: action.payload.fechaHasta,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [selectOficina]: (state, action) => {
      return {
        ...state,
        oficina: action.payload.oficina,
      };
    },
    [selectEstado]: (state, action) => {
      let hasAccessToDatosIncompletos =
        action.payload.isAdminUser &&
        action.payload.estado === "DatosIncompletos";

      let hasAccessToAllAdminStates =
        action.payload.isAdminUser &&
        action.payload.estado ===
          "Pendiente,PendienteCompania,DatosIncompletos";

      let hasAccessToAllNonAdminStates =
        !action.payload.isAdminUser &&
        action.payload.estado === "Pendiente,PendienteCompania";

      let estado =
        action.payload.estado !== "Pendiente" &&
        action.payload.estado !== "PendienteCompania" &&
        action.payload.estado !== "DatosIncompletos" &&
        !hasAccessToDatosIncompletos &&
        !hasAccessToAllAdminStates &&
        !hasAccessToAllNonAdminStates
          ? "Pendiente,PendienteCompania,DatosIncompletos"
          : action.payload.estado;
      return {
        ...state,
        estado: [estado],
      };
    },
    [selectNegocio]: (state, action) => {
      return {
        ...state,
        negocio: action.payload.negocio,
      };
    },
  },
  filtrosSolicitudesInitialState
);

export { solicitudesEmisionFiltrosReducer };
export {
  setPage,
  setTake,
  selectFechaDesde,
  selectFechaHasta,
  selectSearchCriteria,
  setInitialStateSolicitudes,
  selectEstado,
  selectNegocio,
  selectOficina,
};
