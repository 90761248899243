const TODOS = "";
export const SANCOR = "Sancor";
export const BERKLEY = "Berkley";
export const ZURICH = "Zurich";
export const TRIUNFO = "Triunfo";
export const RUS = "RUS";
export const MERCANTIL_ANDINA = "MercantilAndina";

const companiasSegurosOptions = {
  [TODOS]: "Todos",
  [SANCOR]: "Sancor",
  [BERKLEY]: "Berkley",
  [ZURICH]: "Zurich",
  [TRIUNFO]: "Triunfo",
  [RUS]: "Río Uruguay Seguros",
  [MERCANTIL_ANDINA]: "Mercantil Andina",
};

const companiasFilter = [
  { label: companiasSegurosOptions[TODOS], value: TODOS },
  { label: companiasSegurosOptions[SANCOR], value: SANCOR },
  { label: companiasSegurosOptions[BERKLEY], value: BERKLEY },
  { label: companiasSegurosOptions[ZURICH], value: ZURICH },
  { label: companiasSegurosOptions[TRIUNFO], value: TRIUNFO },
  { label: companiasSegurosOptions[RUS], value: RUS },
  { label: companiasSegurosOptions[MERCANTIL_ANDINA], value: MERCANTIL_ANDINA },
];

export { companiasSegurosOptions, companiasFilter };
