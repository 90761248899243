export const TODOS = "PolizaEmitida,Rechazada";
export const POLIZAEMITIDA = "PolizaEmitida";
export const RECHAZADA = "Rechazada";

const estadosSolicitudEmisionOptions = {
  [TODOS]: "Todos",
  [POLIZAEMITIDA]: "Póliza Emitida",
  [RECHAZADA]: "Rechazada",
};

const estadosSolicitudEmision = [POLIZAEMITIDA, RECHAZADA];

const estadosSolicitudEmisionFilter = [
  { label: estadosSolicitudEmisionOptions[TODOS], value: TODOS },
  {
    label: estadosSolicitudEmisionOptions[POLIZAEMITIDA],
    value: POLIZAEMITIDA,
  },
  {
    label: estadosSolicitudEmisionOptions[RECHAZADA],
    value: RECHAZADA,
  },
];

export {
  estadosSolicitudEmisionOptions,
  estadosSolicitudEmision,
  estadosSolicitudEmisionFilter,
};
