import { createAction, handleActions } from "redux-actions";

const getDetalleSolicitudEmisionInited = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_INITED"
);
const getDetalleSolicitudEmisionCompleted = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_COMPLETED",
  (response) => {
    let detalleSolicitudEmision = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      detalleSolicitudEmision = response;
    }

    return { detalleSolicitudEmision };
  }
);
const getDetalleSolicitudEmisionFailed = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_FAILED",
  (error) => ({
    error,
  })
);

const getDetalleSolicitudEmision = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleSolicitudEmisionInited());

      const response = await services.api
        .solicitudesEmision()
        .getDetalleSolicitudEmision(id);

      dispatch(getDetalleSolicitudEmisionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleSolicitudEmisionFailed(error));
    }
  };
};
const clearData = createAction("CLEAR_DATA_SH", () => ({}));

const getLogsUsuarioInited = createAction("OBTENER_LOGS_USUARIO_SH_INITED");

const getLogsUsuario = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getLogsUsuarioInited());

      const response = await services.api
        .solicitudesEmision()
        .getLogsUsuario(id);

      dispatch(getLogsUsuarioCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getLogsUsuarioFailed(error));
    }
  };
};

const getLogsUsuarioCompleted = createAction(
  "OBTENER_LOGS_USUARIO_SH_COMPLETED",
  (response) => {
    let logsUsuario = response;

    return { logsUsuario };
  }
);

const getLogsUsuarioFailed = createAction(
  "OBTENER_LOGS_USUARIO_SH_FAILED",
  (error) => ({
    error,
  })
);

const detalleSolicitudHistoricaReducerInitialState = {
  detalleSolicitudEmision: null,
  isLoading: true,
  loadingError: false,
  logsUsuario: null,
};

const detalleSolicitudHistoricaReducer = handleActions(
  {
    [getDetalleSolicitudEmisionInited]: (state, action) => {
      return {
        ...detalleSolicitudHistoricaReducerInitialState,
      };
    },
    [getDetalleSolicitudEmisionCompleted]: (state, action) => {
      return {
        ...state,
        detalleSolicitudEmision: action.payload.detalleSolicitudEmision,
        isLoading: false,
      };
    },
    [getLogsUsuarioInited]: (state, action) => {
      return {
        ...state,
        logsUsuario: null,
        isLoadingLog: true,
      };
    },
    [getLogsUsuarioCompleted]: (state, action) => {
      return {
        ...state,
        logsUsuario: action.payload.logsUsuario,
        isLoadingLog: false,
      };
    },
    [getLogsUsuarioFailed]: (state, action) => {
      return {
        ...state,
      };
    },
    [getDetalleSolicitudEmisionFailed]: (state, action) => {
      return {
        ...state,
        loadingError: true,
        errorMessage: action.payload.error.responseStatus.message,
      };
    },
  },
  detalleSolicitudHistoricaReducerInitialState
);

export {
  detalleSolicitudHistoricaReducer,
  getDetalleSolicitudEmision,
  clearData,
  getLogsUsuario,
};
