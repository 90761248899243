import { createAction, handleActions } from "redux-actions";

const getPolizaInited = createAction("OBTENER_POLIZA_MULTI_INITED");

const getPolizaCompleted = createAction(
  "OBTENER_POLIZA_MULTI_COMPLETED",
  (currentPoliza) => ({ currentPoliza })
);

const getPolizaFailed = createAction(
  "OBTENER_POLIZA_MULTI_FAILED",
  (error) => ({
    error,
  })
);

const getPoliza = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizaInited());

      const response = await services.api
        .polizasMultirama()
        .getPolizaMultirama(id);

      dispatch(getPolizaCompleted(response));
    } catch (error) {
      console.log(error);
      dispatch(getPolizaFailed(error));
    }
  };
};

const clearData = createAction("CLEAR_DATA_MULTIRAMA", () => ({}));

const detallePolizaMultiramaReducerInitialState = {
  isLoading: false,
  currentPoliza: null,
  failed: false,
};

const detallePolizaMultiramaReducer = handleActions(
  {
    [clearData]: (state, action) => {
      return {
        ...detallePolizaMultiramaReducerInitialState,
      };
    },
    [getPolizaInited]: (state, action) => {
      return {
        ...detallePolizaMultiramaReducerInitialState,
        isLoading: true,
      };
    },
    [getPolizaCompleted]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.currentPoliza,
        isLoading: false,
        failed: false,
      };
    },
    [getPolizaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
  },
  detallePolizaMultiramaReducerInitialState
);

export { detallePolizaMultiramaReducer };

export { getPoliza, clearData };
