import { createAction, handleActions } from "redux-actions";

const closeSuccessAnularPopUp = createAction("CLOSE_SUCCESS_ANULAR_POPUP");

const getPolizasMultiramaInited = createAction(
  "OBTENER_POLIZASMULTIRAMA_INITED"
);

const getPolizasMultiramaCompleted = createAction(
  "OBTENER_POLIZASMULTIRAMA_COMPLETED",
  (response) => {
    let polizas = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      polizas = response.polizas;
      totalRegistros = response.totalRegistros;
    }

    return { polizas, totalRegistros };
  }
);

const getPolizasMultiramaFailed = createAction(
  "OBTENER_POLIZASMULTIRAMA_FAILED",
  (error) => ({
    error,
  })
);

const getPolizasMultirama = ({
  skip,
  take,
  searchCriteria,
  compania,
  rama,
  estado,
  fechaVigenciaDesde,
  fechaVigenciaHasta,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizasMultiramaInited());

      const data = {
        skip,
        take,
        searchCriteria,
        compania,
        rama,
        estado,
        fechaVigenciaDesde,
        fechaVigenciaHasta,
      };

      const response = await services.api
        .polizasMultirama()
        .getPolizasMultirama(data);

      dispatch(getPolizasMultiramaCompleted(response));
    } catch (error) {
      console.log(error);
      dispatch(getPolizasMultiramaFailed(error));
    }
  };
};

const getRamasInited = createAction("OBTENER_RAMAS_INITED");

const getRamas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getRamasInited());

      const response = await services.api.polizasMultirama().getRamas();

      dispatch(getRamasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getRamasFailed(error));
    }
  };
};

const getRamasCompleted = createAction(
  "OBTENER_RAMAS_COMPLETED",
  (response) => {
    let ramas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      ramas = response;
    }

    return { ramas };
  }
);

const getRamasFailed = createAction("OBTENER_RAMAS_FAILED", (error) => ({
  error,
}));

const patchAnularPolizaInited = createAction("ANULAR_POLIZA_INITED");

const patchAnularPolizaCompleted = createAction("ANULAR_POLIZA_COMPLETED");

const patchAnularPolizaFailed = createAction(
  "ANULAR_POLIZA_FAILED",
  (error) => ({
    error,
  })
);

const patchAnularPoliza = (polizaId, motivo, fechaAnulacion) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchAnularPolizaInited());

      const data = {
        motivo,
        fechaAnulacion,
      };

      await services.api.polizasMultirama().patchAnularPoliza(polizaId, data);

      dispatch(patchAnularPolizaCompleted());
    } catch (error) {
      console.error(error);
      dispatch(patchAnularPolizaFailed(error));
    }
  };
};

const setInitialState = createAction("SET_POLIZAS_INITIAL_STATE");

const listadoPolizasMultiramaInitialState = {
  isLoading: false,
  failed: false,
  totalRegistros: 0,
  polizas: null,
  ramasFilter: null,
  ramasOptions: [],
  polizaAnulada: false,
};

const listadoPolizaMultiramaReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...listadoPolizasMultiramaInitialState,
      };
    },
    [getPolizasMultiramaInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getPolizasMultiramaCompleted]: (state, action) => {
      return {
        ...state,
        polizas: action.payload.polizas,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
        failed: false,
      };
    },
    [getPolizasMultiramaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [getRamasInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [getRamasCompleted]: (state, action) => {
      let ramasFilter = action.payload.ramas.ramas.map((rama) => ({
        label: rama.nombre,
        value: rama.id,
      }));

      ramasFilter.unshift({ label: "Todos", value: "" });

      let ramasOptions = {};
      ramasOptions[""] = "Todos";

      action.payload.ramas.ramas.forEach((rama) => {
        ramasOptions[rama.id] = rama.nombre;
      });

      return {
        ...state,
        ramasFilter: ramasFilter,
        ramasOptions: ramasOptions,
        failed: false,
      };
    },
    [getRamasFailed]: (state, action) => {
      return {
        ...state,
        loadingError: true,
      };
    },
    [patchAnularPolizaInited]: (state) => {
      return {
        ...state,
        polizaAnulada: false,
        isLoading: true,
      };
    },
    [patchAnularPolizaCompleted]: (state, action) => {
      return {
        ...state,
        polizaAnulada: true,
        isLoading: false,
      };
    },
    [patchAnularPolizaFailed]: (state) => {
      return {
        ...state,
        polizaAnulada: false,
        isLoading: false,
      };
    },
    [closeSuccessAnularPopUp]: (state, action) => {
      return {
        ...state,
        polizaAnulada: false,
      };
    },
  },
  listadoPolizasMultiramaInitialState
);

export { listadoPolizaMultiramaReducer };

export {
  getPolizasMultirama,
  getRamas,
  patchAnularPoliza,
  closeSuccessAnularPopUp,
};
