import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

import DetalleCard from "~business/Cards/DetalleCard.js";

import DescriptionIcon from "@material-ui/icons/Description";
// import {
//   setPolizaFileName,
//   setTarjetaFileName,
//   setCedulaMercosurFileName,
//   setCuponeraFileName,
// } from "../../actions";
import { useSelector } from "react-redux";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   body: {
//     display: "flex",
//     flexWrap: "wrap",
//     flexDirection: "row",
//   },
//   cardHeader: {
//     display: "flex",
//     flexWrap: "wrap",
//     padding: "2%",
//     borderBottom: "1px solid #6240a5",
//   },
//   cardContainer: {
//     display: "flex",
//     flexDirection: "column",
//     width: "100%",
//     alignItems: "stretch",
//   },
//   card: {
//     display: "flex",
//     flexDirection: "column",
//     paddingBottom: 50,
//     paddingRight: 10,
//     borderRadius: "5px",
//     boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
//     backgroundColor: theme.colours.white,
//     padding: "4%",
//     margin: "2%",
//   },
//   cardIcon: {
//     color: "#6240a5",
//     marginRight: "1%",
//   },
//   cardTitle: {
//     fontFamily: "Nunito",
//     fontSize: "1.3em",
//     fontWeight: "bold",
//     fontStretch: "normal",
//     fontStyle: "normal",
//     lineHeight: 1.36,
//     letterSpacing: "normal",
//     textAlign: "left",
//     color: "#6240a5",
//   },
//   importe: {
//     display: "block",
//     color: theme.palette.textColor,
//     "@media (max-width: 480px)": {
//       fontSize: "20px !important",
//       marginTop: "0",
//     },
//     "@media (min-width: 480px)": {
//       fontSize: "24px !important",
//       marginTop: "-4px",
//     },
//   },
//   tableContainer: {
//     backgroundColor: theme.colours.white,
//     overflow: "auto",
//     minHeight: 500,
//   },
//   progress: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   table: {
//     fontSize: 16,
//     color: "#405A84",
//   },
//   sinCotizaciones: {
//     color: theme.colours.brownGrey2,
//     lineHeight: "13px",
//     fontSize: "2vh",
//   },
//   iconButton: {
//     color: theme.colours.verdeMgBroker,
//     padding: 0,
//   },
//   tableCell: {
//     paddingLeft: 20,
//     paddingRight: 0,
//     fontSize: "12px",
//     fontStyle: "normal",
//     fontStretch: "normal",
//     lineHeight: "1.33",
//     letterSpacing: "normal",
//     textAlign: "left",
//   },
//   tableCellCenter: {
//     paddingLeft: 20,
//     paddingRight: 0,
//     fontSize: "12px",
//     fontStyle: "normal",
//     fontStretch: "normal",
//     lineHeight: "1.33",
//     letterSpacing: "normal",
//     textAlign: "center",
//     alignItems: "center",
//   },
//   tableCellHeader: {
//     fontSize: "small",
//     paddingRight: 0,
//     fontWeight: "bold",
//     fontStyle: "normal",
//     fontStretch: "normal",
//     lineHeight: "1.33",
//     letterSpacing: "normal",
//     textAlign: "left",
//     color: theme.colours.violeta,
//   },
//   dash: { display: "flex", justifyContent: "center" },
//   saveButton: {
//     marginTop: "5%",
//     marginLeft: "30%",
//     marginRight: "30%",
//   },
// }));

const title = "Documentación";

// const adjuntos = {
//   poliza: setPolizaFileName,
//   tarjeta: setTarjetaFileName,
//   cedulaMercosur: setCedulaMercosurFileName,
//   cuponera: setCuponeraFileName,
// };

const useReduxSelector = () =>
  useSelector((state) => {
    const certificados = state.detallePolizaNuevoReducer.currentPoliza
      ? state.detallePolizaNuevoReducer.currentPoliza.certificados
      : {};

    return {
      ...state.detallePolizaNuevoReducer,

      ubicacionCedulaMercosur: certificados.ubicacionCedulaMercosur,
      ubicacionCuponera: certificados.ubicacionCuponera,
      ubicacionPoliza: certificados.ubicacionPoliza,
      ubicacionTarjeta: certificados.ubicacionTarjeta,
      negocio: state.detallePolizaNuevoReducer.currentPoliza
        ? state.detallePolizaNuevoReducer.currentPoliza.negocio
        : undefined,
    };
  });

const ImpresosCard = ({ isLoading, idPoliza }) => {
  const state = useReduxSelector();
  const {
    ubicacionCedulaMercosur,
    ubicacionCuponera,
    ubicacionTarjeta,
    ubicacionPoliza,
    negocio,
  } = state;

  return (
    <DetalleCard
      isLoading={isLoading}
      title={title}
      IconHeader={DescriptionIcon}
    >
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={"Póliza"}
        value={ubicacionPoliza}
        readOnly
      />
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={"Tarjeta"}
        value={ubicacionTarjeta}
        readOnly
      />
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={"Cédula Mercosur"}
        value={ubicacionCedulaMercosur}
        readOnly
      />
      {negocio && negocio === "MercadoAbierto" && (
        <ItemFileUploaderCard
          isLoading={isLoading}
          label={"Cuponera"}
          value={ubicacionCuponera}
          readOnly
        />
      )}
    </DetalleCard>
  );
};

export default ImpresosCard;
