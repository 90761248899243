import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Layout from "~shared/layout/Layout";
import MainData from "./MainData";
import { getDetalleCotizacion } from "./actions";
import { paths, multicotizacionesPath } from "~constants/referenceNames";
import DetalleCotizacionTable from "~business/cotizaciones/detalleCotizacionTable/DetalleCotizacionTable";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import pxToRem from "~libs/pxToRem";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleCotizacion: state.detalleCotizacionReducer.detalleCotizacion,
    failed: state.detalleCotizacionReducer.failed,
  }));

const DetalleCotizacion = ({ match }) => {
  const { detalleCotizacion, failed } = useReduxSelector();
  const history = useHistory();

  const dispatch = useDispatch();
  const id = match.params.id;

  useEffect(() => {
    dispatch(getDetalleCotizacion(id));
  }, [dispatch, id]);

  const backAction = () => {
    history.push(paths[multicotizacionesPath]);
  };

  if (failed) return <UnexpectedError />;

  return (
    <Layout
      title={"Detalle de Cotización"}
      selectedDefault={multicotizacionesPath}
      subtitle={
        detalleCotizacion
          ? format(
              Date.parse(detalleCotizacion.fecha),
              "dd'/'MM'/'yyyy HH:mm",
              { locale: es }
            )
          : null
      }
      backAction={backAction}
      data={
        detalleCotizacion ? (
          <MainData detalleCotizacion={detalleCotizacion} />
        ) : null
      }
    >
      <div style={{ paddingTop: pxToRem(30) }}>
        <DetalleCotizacionTable detalleCotizacion={detalleCotizacion} />
      </div>
    </Layout>
  );
};

export default DetalleCotizacion;
