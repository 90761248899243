import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { polizasPath, paths } from "~constants/referenceNames";
import { useSelector } from "react-redux";
import { VIGENTE } from "~constants/polizaEstados";
import DetalleEndosoCard from "./DetalleEndosoCard";
import CollapsableSection from "~styled/collapsableSection/CollapsableSection";

import { Typography } from "@material-ui/core";
import empty from "~assets/ilustrations/empty-state.svg";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";

import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
    alignItems: "center",
  },
  buttonPanel: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 25,
    paddingBottom: 25,
    width: "100%",
  },
  endosoItem: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "24px",
  },
  endososList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  decription: {
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: "1.38",
    textAlign: "center",
    color: "#757575",
  },
  sinEndososContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.detallePolizaNuevoReducer.isLoading,
    endosos: state.detallePolizaNuevoReducer?.currentPoliza?.endosos,
    currentPoliza: state.detallePolizaNuevoReducer.currentPoliza,
  }));

const HistorialEndososTab = () => {
  const history = useHistory();
  const classes = useStyles();

  let { id } = useParams();
  const { endosos = [], currentPoliza, isLoading } = useReduxSelector();

  const canAddNewEndoso = currentPoliza?.estado === VIGENTE;
  const hasEndosos = endosos && endosos.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.buttonPanel}>
        {canAddNewEndoso && hasEndosos && (
          <div style={{ maxWidth: "25rem" }}>
            <CallToActionButton
              color="secondary"
              label={"Nuevo Endoso"}
              onClick={() => history.push(`${paths[polizasPath]}/${id}/endoso`)}
            />
          </div>
        )}
      </div>
      <div className={classes.endososList}>
        {isLoading &&
          [null, null, null].map((endoso, index) => (
            <div className={classes.endosoItem} key={`loading-${index}`}>
              <CollapsableSection title={`Detalle del endoso`}>
                <DetalleEndosoCard isLoading={isLoading} />
              </CollapsableSection>
            </div>
          ))}
        {!isLoading &&
          endosos
            .sort((e1, e2) => e2.orden - e1.orden)
            .map((endoso) => (
              <div
                className={classes.endosoItem}
                key={`endoso-${endoso.orden}`}
              >
                <CollapsableSection
                  title={`Detalle del endoso (${
                    endoso.fechaAplicacion
                      ? format(
                          Date.parse(endoso.fechaAplicacion),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )
                      : format(
                          Date.parse(endoso.fechaSolicitud),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )
                  })`}
                >
                  <DetalleEndosoCard
                    isLoading={isLoading}
                    endoso={{
                      ...endoso,
                      compania: currentPoliza?.datosPlan?.compania,
                    }}
                    previousEndoso={{}}
                  />
                </CollapsableSection>
              </div>
            ))}
        {canAddNewEndoso && !hasEndosos && (
          <div className={classes.sinEndososContainer}>
            <div className={classes.imageContainer}>
              <img
                src={empty}
                alt=""
                style={{ width: "15rem", height: "15rem" }}
              />
            </div>
            <Typography className={classes.decription}>
              {"Sin endosos"}
            </Typography>
            <div style={{ width: 600, paddingTop: 24 }}>
              <CallToActionButton
                variant="outlined"
                color="secondary"
                label={"Nuevo Endoso"}
                onClick={() =>
                  history.push(`${paths[polizasPath]}/${id}/endoso`)
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistorialEndososTab;
