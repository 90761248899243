import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import ItemLoadingCard from "../ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import Modal from "../Modal";
import VehiculoStepper from "./VehiculoStepper";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const errorText = "Requerido";

const ItemVehiculoCard = ({
  id,
  label,
  onChange,
  placeholder,
  displayError,
  focus,
  onBlur,
  readOnly,
  isLoading,
  marca,
  modelo,
  anio,
}) => {
  const classes = useStyles();

  const [edit, setEdit] = useState(false);

  const handleChangeValue = (anio, modelo, codia) => {
    setEdit(false);

    if (onChange)
      onChange({
        anio: { value: anio, isValid: true, focus: false },
        modelo: { value: modelo, isValid: true, focus: false },
        codia: { value: codia, isValid: true, focus: false },
      });
  };

  const showError = !modelo && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  const vehiculo = marca && modelo ? `(${anio}) ${marca} ${modelo}` : "";
  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        <Modal
          opened={edit}
          title="Selector de Modelo"
          onClose={() => setEdit(false)}
        >
          <VehiculoStepper marca={marca} onChange={handleChangeValue} />
        </Modal>
        {!edit && (
          <>
            <>
              {vehiculo ? (
                <Typography className={classes.value}>{vehiculo}</Typography>
              ) : (
                <div className={classes.value}>&mdash;</div>
              )}
            </>
            {!readOnly && (
              <div className={classes.buttonLink} onClick={() => setEdit(true)}>
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
          </>
        )}
        {showError && (
          <Tooltip title={errorText}>
            <div className={classes.buttonLink}>
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemVehiculoCard;
