import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { GetAgenciasResponse } from "./types";

const agencias = () => {
  return {
    getAgencias: async (): Promise<GetAgenciasResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}/agencias`;
      try {
        const response = await fetch(url, request);
        await errorUnlessOk(response);
        const data: GetAgenciasResponse = await response.json();
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };
};

export { agencias };
