import { combineReducers } from "redux";
import appReducer from "./appReducer";
import authReducer from "../components/auth/actions";
import { listadoCoberturasReducer } from "~components/views/coberturas/actions";
import { esquemasReducer } from "~components/views/esquemas/actions";
import { snackbarReducer } from "~shared/snackbarsProvider/actions";
import { detalleCotizacionReducer } from "~components/views/cotizaciones/detalle/actions";
import { cotizacionesReducer } from "~components/views/cotizaciones/listadoCotizacionesContainer/actions";
import { solicitudesEmisionReducer } from "~components/views/solicitudesEmision/listadoSolicitudes/actions";
import { solicitudesEmisionFiltrosReducer } from "~components/views/solicitudesEmision/listadoSolicitudes/Filtros/actions";
import { solicitudesPendientesSucursalReducer } from "~components/views/solicitudesPendientesSucursal/listado/actions";
import { solicitudesPendientesSucursalFiltrosReducer } from "~components/views/solicitudesPendientesSucursal/listado/Filtros/actions";
import { solicitudesHistoricasReducer } from "~components/views/solicitudesHistoricas/listado/actions";
import { solicitudesHistoricasFiltrosReducer } from "~components/views/solicitudesHistoricas/listado/filtros/actions";
import { detalleSolicitudEmisionReducer } from "~components/views/solicitudesEmision/detalle/actions";
import { detalleSolicitudPendienteSucursalReducer } from "~components/views/solicitudesPendientesSucursal/detalle/actions";
import { detalleSolicitudHistoricaReducer } from "~components/views/solicitudesHistoricas/detalle/actions";
import { filtrosCotizacionesReducer } from "~components/views/cotizaciones/listadoCotizacionesContainer/Filtros/actions";
import { tipoCoberturaReducer } from "~constants/actions";
import { usuariosReducer } from "~components/views/usuarios/actions";
import { novedadesReducer } from "~components/views/novedades/novedadesContainer/actions";
import { polizasReducer } from "~components/views/polizas/polizasContainer/actions";
import { usuarioEditorReducer } from "~components/views/usuarios/usuarioEditor/actions";
import { filtrosUsuariosReducer } from "~components/views/usuarios/Filtros/actions";
import { filtrosNovedadesReducer } from "~components/views/novedades/novedadesContainer/Filtros/actions";
import { filtrosPolizasReducer } from "~components/views/polizas/polizasContainer/Filtros/actions";
import { authenticatedViewReducer } from "~components/views/authenticatedViews/actions";
import { detalleNovedadReducer } from "~components/views/novedades/detalle/actions";
import { detallePolizaReducer } from "~components/views/polizas/detalle/actions";
import { detallePolizaNuevoReducer } from "~components/views/polizas/detalle(nuevo)/actions";
import { multicotizadorReducer } from "~components/views/multicotizador/actions";
import { subidaArchivosInfoautoReducer } from "~components/views/configuracion/SubidaArchivoInfoauto/actions";
import { agenciasReducer } from "~components/business/agenciaSelector/actions";
import { vehiculoMarcasReducer } from "~components/business/vehiculoMarcaSelector/actions";
import { vehiculoModelosReducer } from "~components/business/vehiculoModeloSelector/actions";
import codigoPostalSelectorReducer from "~components/business/codigoPostalSelector/actions";
import localidadSelectorReducer from "~components/business/localidadSelector/actions";
import { getEmisionesReducer } from "~components/views/reportes/Emisiones/actions";
import { getAnulacionesReducer } from "~components/views/reportes/Anulaciones/actions";
import { listadoEndososReducer } from "~components/views/endosos/actions";
import { renovacionPolizaReducer } from "~components/views/polizas/renovaciones/actions";
import { altaPolizaMultiramaReducer } from "~components/views/polizasMultirama/altaPoliza/actions";
import { detallePolizaMultiramaReducer } from "~components/views/polizasMultirama/detalle/actions";
import { listadoPolizaMultiramaReducer } from "~components/views/polizasMultirama/polizasMultiramaContainer/listadoPolizasMultirama/actions";
import { listadoPolizaMultiramaFiltroReducer } from "~components/views/polizasMultirama/polizasMultiramaContainer/filtros/actions";
import { listadoSiniestrosReducer } from "~components/views/siniestros/siniestrosContainer/listadoSiniestros/actions";
import { listadoSiniestrosFiltroReducer } from "~components/views/siniestros/siniestrosContainer/filtros/actions";
import { detalleSiniestroReducer } from "~components/views/siniestros/detalle/actions";
import { cargaSiniestroReducer } from "~components/views/siniestros/siniestrosContainer/cargaSiniestro/actions";
import { cargaSiniestroPublicaReducer } from "~components/views/siniestros/cargaPublica/actions";
import { estadisticasReducer } from "~components/views/estadisticas/actions";

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  listadoCoberturasReducer,
  esquemasReducer,
  estadisticasReducer,
  snackbarReducer,
  detalleCotizacionReducer,
  cotizacionesReducer,
  solicitudesEmisionReducer,
  solicitudesEmisionFiltrosReducer,
  solicitudesPendientesSucursalReducer,
  solicitudesPendientesSucursalFiltrosReducer,
  solicitudesHistoricasReducer,
  solicitudesHistoricasFiltrosReducer,
  detalleSolicitudEmisionReducer,
  detalleSolicitudPendienteSucursalReducer,
  detalleSolicitudHistoricaReducer,
  filtrosCotizacionesReducer,
  tipoCoberturaReducer,
  usuariosReducer,
  usuarioEditorReducer,
  filtrosUsuariosReducer,
  filtrosNovedadesReducer,
  filtrosPolizasReducer,
  authenticatedViewReducer,
  novedadesReducer,
  listadoEndososReducer,
  detalleNovedadReducer,
  detallePolizaReducer,
  detallePolizaNuevoReducer,
  agenciasReducer,
  vehiculoMarcasReducer,
  vehiculoModelosReducer,
  multicotizadorReducer,
  polizasReducer,
  codigoPostalSelectorReducer,
  localidadSelectorReducer,
  subidaArchivosInfoautoReducer,
  getEmisionesReducer,
  getAnulacionesReducer,
  renovacionPolizaReducer,
  altaPolizaMultiramaReducer,
  detallePolizaMultiramaReducer,
  listadoPolizaMultiramaReducer,
  listadoPolizaMultiramaFiltroReducer,
  listadoSiniestrosReducer,
  listadoSiniestrosFiltroReducer,
  detalleSiniestroReducer,
  cargaSiniestroReducer,
  cargaSiniestroPublicaReducer,
});

export default rootReducer;
