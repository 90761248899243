import { createAction, handleActions } from "redux-actions";

const getTiposCoberturasInited = createAction(
  "OBTENER_TIPOS_COBERTURAS_INITED"
);

const getTiposCoberturasCompleted = createAction(
  "OBTENER_TIPOS_COBERTURAS_COMPLETED",
  (response) => {
    let tiposCobertura = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      tiposCobertura = response.tipoCobertura;
    }

    return { tiposCobertura };
  }
);
const getTiposCoberturasFailed = createAction(
  "OBTENER_TIPOS_COBERTURAS_FAILED",
  (error) => ({ error })
);

const getTiposCobertura = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getTiposCoberturasInited());

      const response = await services.api.tiposCobertura().getTiposCobertura();

      dispatch(getTiposCoberturasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getTiposCoberturasFailed(error));
    }
  };
};

const tiposCoberturaInitialState = {
  failed: false,
  isLoading: false,
  tiposCobertura: [],
};

const tipoCoberturaReducer = handleActions(
  {
    [getTiposCoberturasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getTiposCoberturasCompleted]: (state, action) => {
      return {
        ...state,
        tiposCobertura: action.payload.tiposCobertura,
        isLoading: false,
        failed: false,
      };
    },
    [getTiposCoberturasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
  },
  tiposCoberturaInitialState
);

export { tipoCoberturaReducer };
export { getTiposCobertura };
