import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import {
  paths,
  homePath,
  coberturasPath,
  esquemasPath,
  multicotizacionesPath,
  salirPath,
  usuariosPath,
  solicitudesEmisionPath,
  novedadesPath,
  configuracionPath,
  estadisticasPath,
  polizasPath,
  solicitudesPendientesSucursalPath,
  multicotizadorPath,
  solicitudesHistoricasPath,
  reportesPath,
  endososPath,
  polizasMultiramaPath,
  altaPolizaMultiramaPath,
  siniestrosPath,
  cargaSiniestroPath,
  cargaPublicaPath,
} from "~constants/referenceNames";

import { useFeaturesEnabled } from "~hooks/useFeatureEnabled";
import { getTiposCobertura } from "~constants/actions";
import useGetPermissions from "./useGetPermissions";

import Inicio from "../inicio/Inicio";
import SolicitudesEmisionContainer from "../solicitudesEmision/listadoSolicitudes/SolicitudesEmisionContainer";
import SolicitudesPendientesSucursalContainer from "../solicitudesPendientesSucursal/listado/SolicitudesPendientesSucursalContainer";
import DetalleCotizacion from "~components/views/cotizaciones/detalle/DetalleCotizacion";
import CotizacionesContainer from "~components/views/cotizaciones/listadoCotizacionesContainer/CotizacionesContainer";
import AltaPolizaMultirama from "~components/views/polizasMultirama/altaPoliza/AltaPolizaMultirama";
import DetallePolizaMultiramaContainer from "~components/views/polizasMultirama/detalle/DetallePolizaMultiramaContainer";
import PolizasMultiramaContainer from "~components/views/polizasMultirama/polizasMultiramaContainer/PolizasMultiramaContainer";
import SiniestrosContainer from "~components/views/siniestros/siniestrosContainer/SiniestrosContainer";
import DetalleSiniestroContainer from "~components/views/siniestros/detalle/DetalleSiniestroContainer";
import CargaSiniestro from "~components/views/siniestros/siniestrosContainer/cargaSiniestro/CargaSiniestro";
import CargaSiniestroPublica from "~components/views/siniestros/cargaPublica/CargaSiniestroPublica";
import ListadoUsuariosContainer from "~components/views/usuarios/ListadoUsuariosContainer";
import DetalleSolicitudEmisionContainer from "../solicitudesEmision/detalle/DetalleSolicitudEmisionContainer";
import DetalleSolicitudPendienteSucursal from "../solicitudesPendientesSucursal/detalle/DetalleSolicitudPendienteSucursal";
import DetalleSolicitudHistoricaContainer from "../solicitudesHistoricas/detalle/DetalleSolicitudHistoricaContainer";
import NovedadesContainer from "../novedades/novedadesContainer/NovedadesContainer";
import ConfiguracionContainer from "../configuracion/ConfiguracionContainer";
import DetalleNovedadContainer from "../novedades/detalle/DetalleNovedadContainer";
import PolizasContainer from "../polizas/polizasContainer/PolizasContainer";

import DetallePolizaContainer from "../polizas/detalle/DetallePolizaContainer";
import RenovacionPoliza from "../polizas/renovaciones/RenovacionPoliza";
import MulticotizadorForm from "../multicotizador/MulticotizadorForm";
import SolicitudesHistoricasContainer from "../solicitudesHistoricas/listado/SolicitudesHistoricasContainer";
import PolizaEndoso from "../polizas/detalle(nuevo)/polizaEndoso/PolizaEndoso";
import DetallePolizaNuevo from "../polizas/detalle(nuevo)/DetallePoliza";
import ReportesContainer from "../reportes/ReportesContainer";
import EstadisticasContainer from "../estadisticas/EstadisticasContainer";
import EndososContainer from "../endosos/EndososContainer";
import Signout from "~components/auth/Signout";

import Error404 from "~styled/errorPages/InvalidUrl";
import UnexpectedError from "~styled/errorPages/UnexpectedError";

import CoberturasRoutes from "~routes/CoberturasRoutes";
import EsquemaRoutes from "~routes/EsquemaRoutes";
import { useFlags } from "launchdarkly-react-client-sdk";

const useReduxSelector = () =>
  useSelector((state) => ({
    permissions: state.authenticatedViewReducer.tokenPermissions,
    tiposCobertura: state.tipoCoberturaReducer.tiposCobertura,
    isLoading: state.tipoCoberturaReducer.isLoading,
    failed: state.tipoCoberturaReducer.failed,
  }));

const AuthenticatedViews = () => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();
  const urlSelected = sessionStorage.getItem("urlSelected");
  const { permissions, tiposCobertura, isLoading, failed } = useReduxSelector();

  const {
    multicotizadorIsEnabled,
    multicotizacionesIsEnabled,
    solicitudesEmisionIsEnabled,
    solicitudesEmisionSucursalIsEnabled,
    polizasIsEnabled,
    novedadesIsEnabled,
    coberturasIsEnabled,
    esquemasIsEnabled,
    usuariosIsEnabled,
    reportesIsEnabled,
    configuracionIsEnabled,
    estadisticasIsEnabled,
    polizasMultiramaIsEnabled,
    polizasMultiramaRegistrarIsEnabled,
    siniestrosIsEnabled,
  } = useFeaturesEnabled();

  useEffect(() => {
    const coberturasEmpty = tiposCobertura && tiposCobertura.length === 0;
    const hasPermissions = permissions && permissions.length > 0;

    if (!isLoading && !failed && coberturasEmpty && hasPermissions) {
      dispatch(getTiposCobertura());
    }
  }, [dispatch, permissions, tiposCobertura, isLoading, failed]);

  useEffect(() => {
    history.push(urlSelected ? urlSelected : paths[homePath]);

    //eslint-disable-next-line
  }, []);

  useGetPermissions();
  const endososNewVersionFlag = flags["endososNuevaVersion"];

  if (failed) return <UnexpectedError />;
  return (
    <Switch>
      <Route exact path={paths[homePath]}>
        <Inicio />
      </Route>

      {endososNewVersionFlag && permissions.includes(endososPath) && (
        <Route exact path={paths[endososPath]} component={EndososContainer} />
      )}

      {solicitudesEmisionIsEnabled && !solicitudesEmisionSucursalIsEnabled && (
        <RouteWithPermissions
          exact
          path={paths[solicitudesEmisionPath]}
          component={SolicitudesEmisionContainer}
          isEnabled={true}
        />
      )}

      {solicitudesEmisionIsEnabled && !solicitudesEmisionSucursalIsEnabled && (
        <RouteWithPermissions
          exact
          path={paths[solicitudesEmisionPath] + "/:id"}
          component={DetalleSolicitudEmisionContainer}
          isEnabled={true}
        />
      )}

      <RouteWithPermissions
        exact
        path={paths[solicitudesPendientesSucursalPath]}
        component={SolicitudesPendientesSucursalContainer}
        isEnabled={solicitudesEmisionSucursalIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[solicitudesPendientesSucursalPath] + "/:id"}
        component={DetalleSolicitudPendienteSucursal}
        isEnabled={solicitudesEmisionSucursalIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[multicotizadorPath]}
        component={MulticotizadorForm}
        isEnabled={multicotizadorIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[multicotizacionesPath]}
        component={CotizacionesContainer}
        isEnabled={multicotizacionesIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[multicotizacionesPath] + "/:id"}
        component={DetalleCotizacion}
        isEnabled={multicotizacionesIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[polizasPath]}
        component={PolizasContainer}
        isEnabled={polizasIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[polizasPath] + "/:id"}
        component={
          endososNewVersionFlag ? DetallePolizaNuevo : DetallePolizaContainer
        }
        isEnabled={polizasIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[polizasPath] + "/:id/renovar"}
        component={RenovacionPoliza}
        isEnabled={polizasIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[polizasPath] + "/:id/endoso"}
        component={PolizaEndoso}
        isEnabled={endososNewVersionFlag && polizasIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[solicitudesHistoricasPath]}
        component={SolicitudesHistoricasContainer}
        isEnabled={
          solicitudesEmisionIsEnabled || solicitudesEmisionSucursalIsEnabled
        }
      />

      <RouteWithPermissions
        exact
        path={paths[solicitudesHistoricasPath] + "/:id"}
        component={DetalleSolicitudHistoricaContainer}
        isEnabled={
          solicitudesEmisionIsEnabled || solicitudesEmisionSucursalIsEnabled
        }
      />

      <RouteWithPermissions
        exact
        path={paths[polizasMultiramaPath]}
        component={PolizasMultiramaContainer}
        isEnabled={polizasMultiramaIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[altaPolizaMultiramaPath]}
        component={AltaPolizaMultirama}
        isEnabled={polizasMultiramaRegistrarIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[polizasMultiramaPath] + "/:id"}
        component={DetallePolizaMultiramaContainer}
        isEnabled={polizasMultiramaIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[siniestrosPath]}
        component={SiniestrosContainer}
        isEnabled={siniestrosIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[cargaSiniestroPath]}
        component={CargaSiniestro}
        isEnabled={siniestrosIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[siniestrosPath] + "/:id"}
        component={DetalleSiniestroContainer}
        isEnabled={siniestrosIsEnabled}
      />

      <Route
        exact
        path={paths[cargaPublicaPath]}
        component={CargaSiniestroPublica}
      />

      <RouteWithPermissions
        exact
        path={paths[novedadesPath]}
        component={NovedadesContainer}
        isEnabled={novedadesIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[novedadesPath] + "/:id"}
        component={DetalleNovedadContainer}
        isEnabled={novedadesIsEnabled}
      />

      <RouteWithPermissions
        path={paths[coberturasPath]}
        component={CoberturasRoutes}
        isEnabled={coberturasIsEnabled}
      />

      <RouteWithPermissions
        path={paths[esquemasPath]}
        component={EsquemaRoutes}
        isEnabled={esquemasIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[reportesPath]}
        component={ReportesContainer}
        isEnabled={reportesIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[usuariosPath]}
        component={ListadoUsuariosContainer}
        isEnabled={usuariosIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[configuracionPath]}
        component={ConfiguracionContainer}
        isEnabled={configuracionIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[estadisticasPath]}
        component={EstadisticasContainer}
        isEnabled={estadisticasIsEnabled}
      />

      <RouteWithPermissions
        exact
        path={paths[salirPath]}
        component={Signout}
        isEnabled={true}
      />

      <Route
        exact
        path="/mg-homepage"
        render={() => (window.location = "https://www.mg-group.com.ar")}
      />

      <Route exact path="/signout" component={Signout} />

      <Route component={Error404} />
    </Switch>
  );
};

const RouteWithPermissions = ({ exact, path, component, isEnabled }) => {
  return isEnabled ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Error404 />
  );
};

export default AuthenticatedViews;
