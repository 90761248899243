import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_SOLICITUDESEMISION_INITIAL_STATE");

const getSolicitudesEmisionInited = createAction(
  "OBTENER_SOLICITUDESEMISION_INITED"
);

const getSolicitudesEmisionCompleted = createAction(
  "OBTENER_SOLICITUDESEMISION_COMPLETED",
  (response) => {
    let solicitudesEmision = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      solicitudesEmision = response.solicitudesEmision;
      totalRegistros = response.totalRegistros;
    }

    return { solicitudesEmision, totalRegistros };
  }
);

const getSolicitudesEmisionFailed = createAction(
  "OBTENER_SOLICITUDESEMISION_FAILED",
  (error) => ({
    error,
  })
);

const getSolicitudesEmision = (
  skip,
  take,
  searchCriteria,
  fechaDesde,
  fechaHasta,
  estado,
  dniCliente
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getSolicitudesEmisionInited());

      const data = {
        skip: skip,
        take: take,
        searchCriteria,
        fechaDesde,
        fechaHasta,
        estado,
        dniCliente,
        negocio: "MercadoAbierto",
      };

      const response = await services.api
        .solicitudesEmision()
        .getSolicitudesEmision(data);

      dispatch(getSolicitudesEmisionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getSolicitudesEmisionFailed(error));
    }
  };
};

const solicitudesEmisionInitialState = {
  isLoading: false,
  solicitudesEmision: null,
  totalRegistros: 0,
};

const solicitudesPendientesSucursalReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...solicitudesEmisionInitialState,
        solicitudesEmision: null,
        isLoading: false,
      };
    },
    [getSolicitudesEmisionInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getSolicitudesEmisionCompleted]: (state, action) => {
      return {
        ...state,
        solicitudesEmision: action.payload.solicitudesEmision,
        totalRegistros: action.payload.totalRegistros,
        isPageAlreadyLoad: false,
        isLoading: false,
        skip: state.skip + state.take,
      };
    },
    [getSolicitudesEmisionFailed]: (state, action) => {
      return {
        ...state,
        isPageAlreadyLoad: false,
        isLoading: false,
      };
    },
  },
  solicitudesEmisionInitialState
);

export { solicitudesPendientesSucursalReducer };
export { getSolicitudesEmision, setInitialState };
