import { createAction, handleActions } from "redux-actions";

const queueMessage = createAction(
  "SNACKBAR_QUEUE_MESSAGE",
  (text, variant = "error") => ({
    text,
    variant: variant,
    key: new Date().getTime(),
  }),
  () => ({ doNotSendToAnalytics: true })
);

const closeMessage = createAction(
  "SNACKBAR_CLOSE_MESSAGE",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const processQueue = createAction(
  "SNACKBAR_PROCESS_QUEUE",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const initialState = { queue: [], currentMessage: null, variant: "error" };

const snackbarReducer = handleActions(
  {
    [queueMessage]: (state, action) => {
      return {
        ...state,
        currentMessage: state.currentMessage ? null : action.payload,
        variant: action.payload.variant,
        queue: state.currentMessage
          ? [...state.queue, action.payload]
          : [...state.queue],
      };
    },
    [closeMessage]: (state, action) => {
      return {
        ...state,
        currentMessage: null,
      };
    },
    [processQueue]: (state, action) => {
      if (state.queue.length > 0) {
        const newQueue = state.queue.slice();
        const message = newQueue.shift();

        return {
          ...state,
          queue: newQueue,
          currentMessage: message,
        };
      } else {
        return {
          ...state,
          currentMessage: null,
        };
      }
    },
  },
  initialState
);

export { snackbarReducer };
export { queueMessage, processQueue, closeMessage };
