import {
  useTokenPermissions,
  useTokenAnyPermissions,
  useTokenRoles,
} from "~hooks/usePermissionsFromToken";

export const useFeaturesEnabled = () => {
  // Use this to access feature flag values.
  // const flags = useFlags();

  // Permissions from Token

  const multicotizadorIsEnabled = useTokenPermissions("multicotizador");

  const multicotizacionesIsEnabled = useTokenAnyPermissions([
    "multicotizaciones:readAll",
    "multicotizaciones:continuar",
  ]);

  const solicitudesEmisionIsEnabled = useTokenAnyPermissions([
    "solicitudesEmision:readAll",
    "solicitudesEmision:continuar",
  ]);

  const polizasIsEnabled = useTokenAnyPermissions([
    "polizas:readAll",
    "polizas:read",
    "polizas:emisionManual",
    "polizas:aplicarEndoso",
  ]);

  const siniestrosIsEnabled = useTokenAnyPermissions([
    "siniestros:readAll",
    "siniestros:read",
  ]);

  const siniestrosCasaCentralIsEnabled =
    useTokenPermissions("siniestros:readAll");

  const polizasMultiramaIsEnabled = useTokenAnyPermissions([
    "polizas:readAll",
    "polizas:read",
    "polizas:emisionManual",
  ]);

  const polizasMultiramaRegistrarIsEnabled = useTokenPermissions(
    "polizasMultirama:registrar"
  );

  const novedadesIsEnabled = useTokenPermissions("novedades:readAll");

  const coberturasIsEnabled = useTokenPermissions("coberturas:write");

  const esquemasIsEnabled = useTokenAnyPermissions([
    "esquemas:readAll",
    "esquemas:write",
  ]);

  const usuariosIsEnabled = useTokenAnyPermissions([
    "usuarios:readAll",
    "usuarios:write",
  ]);

  const oficinasIsEnabled = useTokenPermissions("oficinas:readAll");

  const rolesIsEnabled = useTokenPermissions("roles:readAll");

  const reportesIsEnabled = useTokenPermissions("reportes:readAll");

  // Roles from Token
  const estadisticasIsEnabled = useTokenRoles("admin");

  const configuracionIsEnabled = useTokenRoles("admin");

  const solicitudesEmisionSucursalIsEnabled = useTokenRoles("oficina");

  return {
    multicotizadorIsEnabled,
    multicotizacionesIsEnabled,
    solicitudesEmisionIsEnabled,
    solicitudesEmisionSucursalIsEnabled,
    polizasIsEnabled,
    novedadesIsEnabled,
    coberturasIsEnabled,
    esquemasIsEnabled,
    reportesIsEnabled,
    rolesIsEnabled,
    oficinasIsEnabled,
    usuariosIsEnabled,
    configuracionIsEnabled,
    estadisticasIsEnabled,
    polizasMultiramaIsEnabled,
    polizasMultiramaRegistrarIsEnabled,
    siniestrosIsEnabled,
    siniestrosCasaCentralIsEnabled,
  };
};
