import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Divider,
  IconButton,
  Tooltip,
  TableRow,
} from "@material-ui/core";
import { setTake, setPage } from "./actions";
import { Link } from "react-router-dom";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import { TableBackoffice } from "~styled/tableBackoffice/TableBackoffice";
import { APLICADO, RECHAZADO, tipoEndosoOptions } from "~constants/endosos";
import FiltrosEndosos from "./FiltrosEndosos";
import { paths, polizasPath } from "~constants/referenceNames";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    width: "100%",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  iconPdfButton: {
    color: "#b00020",
    padding: 0,
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    //color: theme.colours.violeta
  },
  tableCellCenter: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
    //color: theme.colours.violeta
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.listadoEndososReducer.take,
    page: state.listadoEndososReducer.page,
    estado: state.listadoEndososReducer.estado,

    endosos: state.listadoEndososReducer.endosos,
    totalRegistros: state.listadoEndososReducer.totalRegistros,
    isLoading: state.listadoEndososReducer.isLoading,
  }));

const getTitles = (estado) => {
  const titles = [
    "Estado",
    "Tipo",
    "N° Póliza",
    "Compañia",
    "Dominio",
    "DNI",
    "Titular",
    "Fecha Solicitud",
  ];

  if (!estado || estado === APLICADO) titles.push("Fecha Aplicación");
  if (!estado || estado === RECHAZADO) titles.push("Fecha Rechazo");

  titles.push("Detalle");
  return titles;
};

const ListadoEndosos = () => {
  const { page, take, estado, endosos, totalRegistros, isLoading } =
    useReduxSelector();
  const dispatch = useDispatch();
  const empty = totalRegistros === 0;

  const classes = useStyles();

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const getValueParsed = (value) => {
    if (!value) return <div className={classes.dash}>&mdash;</div>;

    return value;
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FiltrosEndosos />
          </div>
          <Divider></Divider>
          <TableBackoffice
            isLoading={isLoading}
            titles={getTitles(estado)}
            isEmpty={!isLoading && empty}
            emptyListText={emptyListText}
          >
            {endosos.map((endoso, index) => (
              <TableRow
                style={{ textDecoration: "none" }}
                key={`table-endosos-row-${index}`}
              >
                <TableCell className={classes.tableCell}>
                  {getValueParsed(endoso.estado)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(tipoEndosoOptions[endoso.tipoEndoso])}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(endoso.numeroPoliza)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(endoso.compania)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(endoso.dominio)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(endoso.dni)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(endoso.nombreAsegurado)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getValueParsed(
                    format(
                      Date.parse(endoso.fechaSolicitud),
                      "dd'/'MM'/'yyyy",
                      { locale: es }
                    )
                  )}
                </TableCell>
                {(!estado || estado === APLICADO) && (
                  <TableCell className={classes.tableCell}>
                    {getValueParsed(
                      endoso.fechaAplicacion
                        ? format(
                            Date.parse(
                              endoso.fechaAplicacion,
                              "dd'/'MM'/'yyyy",
                              { locale: es }
                            )
                          )
                        : null
                    )}
                  </TableCell>
                )}
                {(!estado || estado === RECHAZADO) && (
                  <TableCell className={classes.tableCell}>
                    {getValueParsed(
                      endoso.fechaRechazo
                        ? format(
                            Date.parse(endoso.fechaRechazo, "dd'/'MM'/'yyyy", {
                              locale: es,
                            })
                          )
                        : null
                    )}
                  </TableCell>
                )}
                <TableCell className={classes.tableCell}>
                  <Tooltip title={"Ver detalle de la Póliza"}>
                    <IconButton
                      className={classes.iconButton}
                      component={Link}
                      to={paths[polizasPath] + "/" + endoso.polizaId}
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBackoffice>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "2vh",
              paddingBottom: "2vh",
              width: "100%",
            }}
          >
            {!isLoading &&
              <Pagination
                rowsPerPage={take}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                totalRegistros={totalRegistros}
                options={paginationOptions}
              />
            }
          </div>
        </div>
      </Paper>
    </>
  );
};

export default ListadoEndosos;
