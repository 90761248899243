import { logEventAnalytics } from "~libs/amplitudeHelper";

const analyticsMiddleware = store => next => action => {
  try {
    console.debug("Analytics track", action.type, action.payload);

    const sendToAnalytics = !(action.meta && action.meta.doNotSendToAnalytics);

    if (sendToAnalytics) {
      logEventAnalytics(
        action.type,
        action.payload
          ? {
              ...action.payload
            }
          : null
      );
    }
  } catch (error) {
    console.error("Analytics error", error);
  }

  return next(action);
};

export default analyticsMiddleware;
