const getCondicionesFiscales = () => {
  let result = [
    {
      label: "Monotributista",
      value: "Monotributista",
    },
    {
      label: "Responsable Inscripto",
      value: "ResponsableInscripto",
    },
    {
      label: "Iva Exento",
      value: "IvaExento",
    },
  ];

  return result;
};

export default getCondicionesFiscales;
