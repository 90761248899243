import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DatosGenerales from "./cards/DatosGeneralesCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "4.5%",
    minWidth: "10px",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.detallePolizaMultiramaReducer.isLoading,
  }));

const DetallePolizaMultirama = () => {
  const classes = useStyles();
  const { isLoading } = useReduxSelector();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.cardsAndCuotasContainer}>
          <CardsColumn>
            <DatosGenerales isLoading={isLoading} />
          </CardsColumn>
        </div>
      </div>
    </div>
  );
};

export default DetallePolizaMultirama;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
