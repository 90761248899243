const localhostUrl = "http://localhost:5000";
const runLocal = false;
const environment = {
  name: process.env.REACT_APP_ENV,
  myUrl: process.env.REACT_APP_SPA_URL,
  presignerUrl: process.env.REACT_APP_API_PRESIGNER_URL,
  bucketName: process.env.REACT_APP_BUCKET_SEGUROS,
  bucketUrl: process.env.REACT_APP_BUCKET_SEGUROS_URL,
  vehiclesBucketName: process.env.REACT_APP_BUCKET_VEHICLES,

  creditRiskUrl: process.env.REACT_APP_API_CREDITRISK_URL,
  catalogosUrl: process.env.REACT_APP_API_CATALOGOS_URL,
  segurosUrl: runLocal ? localhostUrl : process.env.REACT_APP_API_SEGUROS_URL,
  cargaPublicaSiniestroUrl: runLocal
    ? localhostUrl
    : process.env.REACT_APP_API_CARGA_PUBLICA_SINIESTROS_URL,
  vehiclesUrl: process.env.REACT_APP_API_VEHICLES_URL,

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  },

  launchDarkly: {
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDKKEY,
  },

  aplitudeAPIkey: process.env.REACT_APP_AMPLITUDE_API_KEY,

  awsS3: {
    apiKey: process.env.REACT_APP_AWS_LIRA_S3_ACCESS_API_KEY,
    apiSecret: process.env.REACT_APP_AWS_LIRA_S3_ACCESS_API_SECRET,
  },

  awsS3alt: {
    apiKey: process.env.REACT_APP_AWS_LIRA_S3_ACCESS_API_KEY_ALT,
    apiSecret: process.env.REACT_APP_AWS_LIRA_S3_ACCESS_API_SECRET_ALT,
  },
};

export default environment;
