import { createAction, handleActions } from "redux-actions";
import { calculateDate } from "~libs/dateCalculator";

const setInitialStateSolicitudesHistoricas = createAction(
  "SET_INITIAL_STATE_FILTRO_SOLICITUDES"
);

const setPage = createAction("SET_LISTADO_SOLICITUDESEMISION_PAGE", (page) => ({
  page,
}));

const setTake = createAction(
  "SET_LISTADO_SOLICITUDESEMISION_ROWS_PER_PAGE",
  (take) => ({ take })
);

const selectFechaDesde = createAction(
  "SELECT_FECHA_DESDE_FILTRO",
  (fechaDesde) => ({ fechaDesde })
);

const selectFechaHasta = createAction(
  "SELECT_FECHA_HASTA_FILTRO",
  (fechaHasta) => ({ fechaHasta })
);

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_FILTRO",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const selectEstado = createAction("SELECT_ESTADO_FILTRO", (estado) => ({
  estado,
}));

const filtrosSolicitudesInitialState = {
  take: 10,
  page: 0,
  fechaDesde: calculateDate(new Date(), -30),
  fechaHasta: new Date(),
  searchCriteria: "",
  estado: ["PolizaEmitida", "Rechazada"],
};

const solicitudesHistoricasFiltrosReducer = handleActions(
  {
    [setInitialStateSolicitudesHistoricas]: (state, action) => {
      return {
        ...filtrosSolicitudesInitialState,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        take: action.payload.take,
      };
    },
    [selectFechaDesde]: (state, action) => {
      return {
        ...state,
        fechaDesde: action.payload.fechaDesde,
      };
    },
    [selectFechaHasta]: (state, action) => {
      return {
        ...state,
        fechaHasta: action.payload.fechaHasta,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [selectEstado]: (state, action) => {
      let estado =
        action.payload.estado !== "PolizaEmitida" &&
        action.payload.estado !== "Rechazada" &&
        action.payload.estado !== "PolizaEmitida,Rechazada"
          ? "PolizaEmitida,Rechazada"
          : action.payload.estado;

      return {
        ...state,
        estado: [estado],
      };
    },
  },
  filtrosSolicitudesInitialState
);

export { solicitudesHistoricasFiltrosReducer };
export {
  setPage,
  setTake,
  selectFechaDesde,
  selectFechaHasta,
  selectSearchCriteria,
  setInitialStateSolicitudesHistoricas,
  selectEstado,
};
