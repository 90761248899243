import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import PersonIcon from "@material-ui/icons/Person";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDniCard from "~business/Cards/ItemDniCard";
import ItemDateCard from "~business/Cards/ItemDateCard";

import { condicionesFiscalesOptions } from "~constants/condicionesFiscales";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosCliente = state.renovacionPolizaReducer.currentPoliza.asegurado;
    return {
      nombreCompleto: datosCliente.nombreCompleto,
      tipoDocumento: datosCliente.tipoDocumento,
      numeroDocumento: datosCliente.numeroDocumento,
      fechaNacimiento: datosCliente.fechaNacimiento,
      domicilio: datosCliente.domicilio,
      email: datosCliente.email,
      telefono: datosCliente.telefono,
      telefonoCelular: datosCliente.telefonoCelular,
      telefonoFijo: datosCliente.telefonoFijo,
      condicionFiscal: datosCliente.condicionFiscal,
    };
  });

const AseguradoCard = ({ isLoading, onBlur, displayError }) => {
  const {
    nombreCompleto,
    tipoDocumento,
    numeroDocumento,
    fechaNacimiento,
    domicilio,
    email,
    telefonoCelular,
    telefonoFijo,
    telefono,
    uso,
    condicionFiscal,
  } = useReduxSelector();

  const dniFormatted = tipoDocumento
    ? `(${tipoDocumento}) ${numeroDocumento}`
    : numeroDocumento;

  const telefonoCelularFormatted = telefonoCelular
    ? `(CELULAR) ${telefonoCelular}`
    : telefono;
  const telefonoFijoFormatted = telefonoFijo ? `(FIJO) ${telefonoFijo}` : null;

  return (
    <DetalleCard title={"Asegurado"} IconHeader={PersonIcon}>
      <ItemInputCard
        readOnly
        isLoading={isLoading}
        label="Nombre"
        value={nombreCompleto}
      />
      <ItemInputCard
        readOnly
        isLoading={isLoading}
        label="E-mail"
        value={email}
      />
      <ItemInputCard
        label={"Teléfono"}
        isLoading={isLoading}
        value={telefonoCelularFormatted ?? telefonoFijoFormatted}
        readOnly
      />
      <ItemDniCard
        isLoading={isLoading}
        label={"DNI"}
        value={dniFormatted}
        readOnly
      />
      {uso === "Comercial" && (
        <ItemInputCard
          label="Condición Fiscal"
          value={condicionesFiscalesOptions[condicionFiscal]}
        />
      )}
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha Nacimiento"}
        value={fechaNacimiento}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Domicilio"}
        value={domicilio}
        readOnly
      />
    </DetalleCard>
  );
};

export default AseguradoCard;
