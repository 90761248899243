import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setTake,
  selectSearchCriteria,
  selectEstado,
  selectNegocio,
  setBuscarSoloParaRenovar,
  selectCompania,
  selectFechaDesde,
  selectFechaHasta,
} from "./actions";
import Searcher from "~styled/searcher/searcher";
import { Typography } from "@material-ui/core";
import Pagination from "~styled/pagination/Pagination";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import DateRangePicker from "~styled/dateRangePicker/DateRangePicker";
import Selector from "~styled/selector/Selector";
import {
  estadosPolizasOptions,
  estadosPolizasFilter,
} from "~constants/polizaEstados";
import { negociosFilter, negociosSegurosOptions } from "~constants/negocios";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { companiasSegurosOptions, companiasFilter } from "~constants/companias";
import { calculateDate } from "~libs/dateCalculator";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: "1%",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  horizontalSpace: { paddingRight: "10px" },
  horizontalSpaceSearcher: { paddingRight: "10px", width: "100%" },
  searcherAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.polizasReducer.isLoading,
    totalRegistros: state.polizasReducer.totalRegistros,

    take: state.filtrosPolizasReducer.take,
    page: state.filtrosPolizasReducer.page,
    fechaDesde: state.filtrosPolizasReducer.fechaDesde,
    fechaHasta: state.filtrosPolizasReducer.fechaHasta,
    searchCriteria: state.filtrosPolizasReducer.searchCriteria,
    estado: state.filtrosPolizasReducer.estado,
    negocio: state.filtrosPolizasReducer.negocio,
    soloParaRenovar: state.filtrosPolizasReducer.soloParaRenovar,
    canRenovarPolizas: state.authenticatedViewReducer.canRenovarPolizas,
    compania: state.filtrosPolizasReducer.compania,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    isLoading,
    take,
    searchCriteria,
    estado,
    page,
    fechaDesde,
    fechaHasta,
    totalRegistros,
    negocio,
    soloParaRenovar,
    canRenovarPolizas,
    compania,
  } = useReduxSelector();
  const flags = useFlags();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangeInterval = (value) => {
    dispatch(setPage(0));
    dispatch(selectFechaDesde(calculateDate(new Date(), value)));
    dispatch(selectFechaHasta(new Date()));
  };

  const handleChangeSwitchRenovar = (event) => {
    dispatch(setPage(0));
    dispatch(setBuscarSoloParaRenovar(event.target.checked));

    if (event.target.checked) {
      dispatch(selectFechaDesde(calculateDate(new Date(), -60)));
      dispatch(selectFechaHasta(calculateDate(new Date(), 30)));
    } else {
      dispatch(selectFechaDesde(null));
      dispatch(selectFechaHasta(null));
    }
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                BUSCAR
              </Typography>
              <div className={classes.horizontalSpaceSearcher}>
                <Searcher
                  borderSquared
                  value={searchCriteria}
                  filter={searchCriteria}
                  placeholder="N° Póliza, DNI, Titular, Dominio, Nº Op."
                  onChange={(event) =>
                    handleChange(event, selectSearchCriteria)
                  }
                  onClear={() =>
                    handleChange(
                      { target: { value: "" } },
                      selectSearchCriteria
                    )
                  }
                />
              </div>
            </div>
            <div className={classes.dateSelectorAndTitle}>
              <Typography variant="h3" className={classes.label}>
                FECHA FIN VIGENCIA
              </Typography>
              <div className={classes.horizontalSpace}>
                <DateRangePicker
                  fechaDesde={fechaDesde}
                  fechaHasta={fechaHasta}
                  onChangeInterval={handleChangeInterval}
                  onChangeStartDate={(value) =>
                    handleChange({ target: { value } }, selectFechaDesde)
                  }
                  onChangeEndDate={(value) =>
                    handleChange({ target: { value } }, selectFechaHasta)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                ESTADO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={estadosPolizasOptions[estado]}
                  options={estadosPolizasFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectEstado)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            {flags["renovacionesLira"] && canRenovarPolizas && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={soloParaRenovar}
                      onChange={handleChangeSwitchRenovar}
                    />
                  }
                  label="Sólo para renovar"
                />
              </FormGroup>
            )}
          </div>
        </div>
      </>
      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                NEGOCIO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={negociosSegurosOptions[negocio]}
                  options={negociosFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectNegocio)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                COMPAÑÍA
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={companiasSegurosOptions[compania]}
                  options={companiasFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectCompania)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          {!isLoading &&
            <Pagination
              rowsPerPage={take}
              onChangeRowsPerPage={(value) =>
                handleChange({ target: { value } }, setTake)
              }
              page={page}
              onChangePage={handleChangePage}
              totalRegistros={totalRegistros}
              options={paginationOptions}
            />
          }
        </div>
      </>
    </div>
  );
};

export default Filtros;
