import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProvincia, clearProvincia } from "../actions";
import makeStyles from "@material-ui/styles/makeStyles";
import VehiculoProvinciaSelector from "~styled/vehiculoProvinciaSelector/VehiculoProvinciaSelector";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";
import { clearCodigosPostales } from "~business/codigoPostalSelector/actions";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    provinciaSelected: state.multicotizadorReducer.provinciaSelected,
  }));

const ProvinciaStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { provinciaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(clearProvincia());
    dispatch(clearCodigosPostales());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(setProvincia(event.target.value));
  };

  return (
    <MulticotizadorContent>
      <div className={classes.title}>
        <PrimaryTitle label="¿Dónde vive el asegurado?" marginBottom={32} />
      </div>
      <VehiculoProvinciaSelector
        value={provinciaSelected}
        onChange={handleChange}
      />
    </MulticotizadorContent>
  );
});

export default ProvinciaStep;
