import { queueMessage } from "~shared/snackbarsProvider/actions";
import { DATOS } from "~constants/endosos";
import { DEBITO_CBU, DEBITO_TARJETA_CREDITO } from "~constants/formaPago";

const baseDetailPoliza = "detallePoliza";
const baseEndosoPoliza = "endosoPoliza";

const detallePolizaInitialState = {
  currentPoliza: null,
  isLoading: false,
  failed: false,
  submitting: false,
  polizaHasChanged: false,
  polizaUpdated: false,
  tipo: DATOS,
  tabSelected: 0,

  //Datos Generales
  coberturaSelected: { value: null, valid: true, focus: false },
  premioTotalSelected: { value: undefined, valid: true, focus: false },
  formaPagoSelected: { value: null, valid: true, focus: false },
  cbuSelected: { value: null, valid: true, focus: false },
  tarjetaCreditoSelected: { value: null, valid: true, focus: false },
  marcaTarjetaCreditoSelected: { value: null, valid: true, focus: false },
  //Datos Anulacion
  motivoAnulacionSelected: {
    value: null,
    valid: true,
    focus: false,
  },
  //Datos Asegurado
  emailSelected: { value: null, valid: true, focus: false },
  telefonoSelected: { value: null, valid: true, focus: false },
  domicilioSelected: { value: null, valid: true, focus: false },
  //Datos Vehiculo
  anioSelected: { value: null, valid: true, focus: false },
  marcaSelected: { value: null, valid: true, focus: false },
  modeloSelected: { value: null, valid: true, focus: false },
  codiaSelected: { value: null, valid: true, focus: false },
  provinciaSelected: { value: null, valid: true, focus: false },
  localidadSelected: { value: null, valid: true, focus: false },
  codigoPostalSelected: { value: null, valid: true, focus: false },
  dominioSelected: { value: null, valid: true, focus: false },
  numeroMotorSelected: { value: null, valid: true, focus: false },
  numeroChasisSelected: { value: null, valid: true, focus: false },
  tieneGNC: { value: null, valid: true, focus: false },

  urlPolizaSelected: { value: null, valid: true, focus: false },
  urlTarjetaSelected: { value: null, valid: true, focus: false },
  urlCedulaMercosurSelected: { value: null, valid: true, focus: false },
  urlCuponeraSelected: { value: null, valid: true, focus: false },
};

export const detallePolizaNuevoReducer = (
  state = detallePolizaInitialState,
  action
) => {
  const isThisReducer =
    action.type.includes(baseDetailPoliza) ||
    action.type.includes(baseEndosoPoliza);
  if (action.reducer && isThisReducer) return action.reducer(state, action);

  return state;
};

export const clear = () => {
  return {
    type: `${baseEndosoPoliza}/clear`,
    payload: {},
    reducer: (state, action) => ({
      ...detallePolizaInitialState,
      tabSelected: state.tabSelected,
    }),
  };
};
export const clearChanges = () => {
  return {
    type: `${baseEndosoPoliza}/clearChanges`,
    payload: {},
    reducer: (state, action) => {
      const getFormatted = (value) => ({
        value: value,
        valid: true,
        focus: false,
      });
      const currentPoliza = state.currentPoliza;
      return {
        ...state,
        polizaHasChanged: false,

        coberturaSelected: getFormatted(currentPoliza.coberturaId),
        premioTotalSelected: getFormatted(currentPoliza.datosPlan.premioTotal),
        formaPagoSelected: getFormatted(currentPoliza.datosPlan.formaPago),
        //Datos vehiculo
        anioSelected: getFormatted(currentPoliza.datosVehiculo.anio),
        codiaSelected: getFormatted(currentPoliza.datosVehiculo.codia),
        codigoPostalSelected: getFormatted(
          currentPoliza.datosVehiculo.codigoPostal
        ),
        dominioSelected: getFormatted(currentPoliza.datosVehiculo.dominio),
        localidadSelected: getFormatted(currentPoliza.datosVehiculo.localidad),
        marcaSelected: getFormatted(currentPoliza.datosVehiculo.marca),
        modeloSelected: getFormatted(currentPoliza.datosVehiculo.modelo),
        numeroChasisSelected: getFormatted(
          currentPoliza.datosVehiculo.numeroChasis
        ),
        numeroMotorSelected: getFormatted(
          currentPoliza.datosVehiculo.numeroMotor
        ),
        provinciaSelected: getFormatted(currentPoliza.datosVehiculo.provincia),
        tieneGNC: getFormatted(currentPoliza.datosVehiculo.tieneGNC),
        //Datos Asegurado
        emailSelected: getFormatted(currentPoliza.asegurado.email),
        telefonoSelected: getFormatted(
          currentPoliza.asegurado.telefonoCelular ??
            currentPoliza.asegurado.telefonoFijo
        ),
        domicilioSelected: getFormatted(currentPoliza.asegurado.domicilio),
        //certificados
        urlCedulaMercosurSelected: getFormatted(
          currentPoliza.certificados.ubicacionCedulaMercosur
        ),
        urlCuponeraSelected: getFormatted(
          currentPoliza.certificados.ubicacionCuponera
        ),
        urlPolizaSelected: getFormatted(
          currentPoliza.certificados.ubicacionPoliza
        ),
        urlTarjetaSelected: getFormatted(
          currentPoliza.certificados.ubicacionTarjeta
        ),
      };
    },
  };
};

export const setTipo = (value) => {
  return {
    type: `${baseEndosoPoliza}/setTipo`,
    reducer: (state, action) => ({
      ...state,
      tipo: value,
      submitting: false,
    }),
  };
};

export const setTabSelected = (value) => {
  return {
    type: `${baseDetailPoliza}/setTabSelected`,
    reducer: (state, action) => ({
      ...state,
      tabSelected: value,
    }),
  };
};

//Datos Generales
export const setCobertura = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCobertura`,
    reducer: (state, action) => ({
      ...state,
      coberturaSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setPremio = (value) => {
  return {
    type: `${baseEndosoPoliza}/setPremio`,
    reducer: (state, action) => ({
      ...state,
      premioTotalSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setCotizacionId = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCotizacionId`,
    reducer: (state, action) => ({
      ...state,
      cotizacionId: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

const getPaymentMethodChanged = (prev, newValue) => {
  const debito = [DEBITO_CBU, DEBITO_TARJETA_CREDITO];
  if (debito.includes(prev) && !debito.includes(newValue)) return true;
  if (!debito.includes(prev) && debito.includes(newValue)) return true;
  return false;
};

export const setFormaPago = (value) => {
  return {
    type: `${baseEndosoPoliza}/setFormaPago`,
    payload: value,
    reducer: (state, action) => ({
      ...state,
      formaPagoSelected: { ...value, endosado: true },
      coberturaSelected: {
        ...state.coberturaSelected,
        valid: getPaymentMethodChanged(),
      },
      polizaHasChanged: true,
    }),
  };
};
export const setCBU = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCBU`,
    payload: value,
    reducer: (state, action) => ({
      ...state,
      cbuSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};
export const setTarjetaCredito = ({ tarjetaCredito, marcaTarjetaCredito }) => {
  return {
    type: `${baseEndosoPoliza}/setTarjetaCredito`,
    payload: { tarjetaCredito, marcaTarjetaCredito },
    reducer: (state, action) => ({
      ...state,
      tarjetaCreditoSelected: { ...tarjetaCredito, endosado: true },
      marcaTarjetaCreditoSelected: { ...marcaTarjetaCredito, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

//Anulacion
export const setMotivoAnulacion = (value) => {
  return {
    type: `${baseEndosoPoliza}/setMotivoAnulacion`,
    reducer: (state, action) => ({
      ...state,
      motivoAnulacionSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

//Datos Asegurado
export const setEmail = (value) => {
  return {
    type: `${baseEndosoPoliza}/setEmail`,
    reducer: (state, action) => ({
      ...state,
      emailSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setTelefono = (value) => {
  return {
    type: `${baseEndosoPoliza}/setTelefono`,
    reducer: (state, action) => ({
      ...state,
      telefonoSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setDomicilio = (value) => {
  return {
    type: `${baseEndosoPoliza}/setDomicilio`,
    reducer: (state, action) => ({
      ...state,
      domicilioSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

//Datos Vehiculo
export const setMarca = (value) => {
  return {
    type: `${baseEndosoPoliza}/setMarca`,
    reducer: (state, action) => ({
      ...state,
      marcaSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};

export const setAnio = (value) => {
  return {
    type: `${baseEndosoPoliza}/setAnio`,
    reducer: (state, action) => ({
      ...state,
      anioSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};
export const setModelo = (value) => {
  return {
    type: `${baseEndosoPoliza}/setModelo`,
    reducer: (state, action) => ({
      ...state,
      modeloSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};
export const setCodia = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCodia`,
    reducer: (state, action) => ({
      ...state,
      codiaSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};
export const setProvincia = (value) => {
  return {
    type: `${baseEndosoPoliza}/setProvincia`,
    reducer: (state, action) => ({
      ...state,
      provinciaSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },

      polizaHasChanged: true,
    }),
  };
};
export const setLocalidad = (value) => {
  return {
    type: `${baseEndosoPoliza}/setLocalidad`,
    reducer: (state, action) => ({
      ...state,
      localidadSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};
export const setCodigoPostal = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCodigoPostal`,
    reducer: (state, action) => ({
      ...state,
      codigoPostalSelected: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};
export const setDominio = (value) => {
  return {
    type: `${baseEndosoPoliza}/setDominio`,
    reducer: (state, action) => ({
      ...state,
      dominioSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};
export const setNumeroMotor = (value) => {
  return {
    type: `${baseEndosoPoliza}/setNumeroMotor`,
    reducer: (state, action) => ({
      ...state,
      numeroMotorSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};
export const setNumeroChasis = (value) => {
  return {
    type: `${baseEndosoPoliza}/setNumeroChasis`,
    reducer: (state, action) => ({
      ...state,
      numeroChasisSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setTieneGNC = (value) => {
  return {
    type: `${baseEndosoPoliza}/setTieneGNC`,
    reducer: (state, action) => ({
      ...state,
      tieneGNC: { ...value, endosado: true },
      coberturaSelected: { ...state.coberturaSelected, valid: false },
      polizaHasChanged: true,
    }),
  };
};

//certificados
export const setPolizaFileName = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCertificadoPoliza`,
    reducer: (state, action) => ({
      ...state,
      urlPolizaSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setTarjetaFileName = (value) => {
  return {
    type: `${baseEndosoPoliza}/setTarjeta`,
    reducer: (state, action) => ({
      ...state,
      urlTarjetaSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setCedulaMercosurFileName = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCedulaMercosur`,
    reducer: (state, action) => ({
      ...state,
      urlCedulaMercosurSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

export const setCuponeraFileName = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCuponera`,
    reducer: (state, action) => ({
      ...state,
      urlCuponeraSelected: { ...value, endosado: true },
      polizaHasChanged: true,
    }),
  };
};

//CHECK IF IT IS USED
export const setCurrentPoliza = (value) => {
  return {
    type: `${baseEndosoPoliza}/setCurrentPoliza`,
    reducer: (state, action) => ({
      ...state,
      currentPoliza: value,
    }),
  };
};

export const setSubmitting = (value) => {
  return {
    type: `${baseEndosoPoliza}/setSubmitting`,
    reducer: (state, action) => ({
      ...state,
      submitting: value,
    }),
  };
};

const getPolizaInited = (value) => {
  return {
    type: `${baseDetailPoliza}/getPolizaInited`,
    reducer: (state, action) => ({
      ...detallePolizaInitialState,
      tabSelected: state.tabSelected,
      isLoading: true,
    }),
  };
};

const getPolizaCompleted = (value) => {
  const getFormatted = (value) => ({
    value: value,
    valid: true,
    focus: false,
  });

  return {
    type: `${baseDetailPoliza}/getPolizaCompleted`,
    reducer: (state, action) => ({
      ...state,
      currentPoliza: value,
      //Datos Generales
      coberturaSelected: getFormatted(value.coberturaId),
      premioTotalSelected: getFormatted(value.datosPlan.premioTotal),
      formaPagoSelected: getFormatted(value.datosPlan.formaPago),
      //Datos vehiculo
      anioSelected: getFormatted(value.datosVehiculo.anio),
      codiaSelected: getFormatted(value.datosVehiculo.codia),
      codigoPostalSelected: getFormatted(value.datosVehiculo.codigoPostal),
      dominioSelected: getFormatted(value.datosVehiculo.dominio),
      localidadSelected: getFormatted(value.datosVehiculo.localidad),
      marcaSelected: getFormatted(value.datosVehiculo.marca),
      modeloSelected: getFormatted(value.datosVehiculo.modelo),
      numeroChasisSelected: getFormatted(value.datosVehiculo.numeroChasis),
      numeroMotorSelected: getFormatted(value.datosVehiculo.numeroMotor),
      provinciaSelected: getFormatted(value.datosVehiculo.provincia),
      tieneGNC: getFormatted(value.datosVehiculo.tieneGNC),
      //Datos Asegurado
      emailSelected: getFormatted(value.asegurado.email),
      telefonoSelected: getFormatted(
        value.asegurado.telefonoCelular ?? value.asegurado.telefonoFijo
      ),
      domicilioSelected: getFormatted(value.asegurado.domicilio),
      //certificados
      urlCedulaMercosurSelected: getFormatted(
        value.certificados.ubicacionCedulaMercosur
      ),
      urlCuponeraSelected: getFormatted(value.certificados.ubicacionCuponera),
      urlPolizaSelected: getFormatted(value.certificados.ubicacionPoliza),
      urlTarjetaSelected: getFormatted(value.certificados.ubicacionTarjeta),

      isLoading: false,
      failed: false,
    }),
  };
};

const getPolizaFailed = (value) => {
  return {
    type: `${baseDetailPoliza}/getPolizaFailed`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      failed: true,
    }),
  };
};

const postEndosarInited = (value) => {
  return {
    type: `${baseEndosoPoliza}/postEndosarInited`,
    reducer: (state, action) => ({
      ...state,
      isLoading: true,
      polizaUpdated: false,
      failed: false,
    }),
  };
};

const postEndosarCompleted = (value) => {
  return {
    type: `${baseEndosoPoliza}/postEndosarCompleted`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      polizaHasChanged: false,
      polizaUpdated: true,
    }),
  };
};

const postEndosarFailed = (value) => {
  return {
    type: `${baseEndosoPoliza}/postEndosarFailed`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      failed: true,
    }),
  };
};

export const getPoliza = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizaInited());

      const response = await services.api.polizas().getPoliza(id);

      dispatch(getPolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizaFailed(error));
    }
  };
};

export const postEndosar = (polizaId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postEndosarInited());

      //const impresos = ["UrlPoliza", "UrlTarjeta", "UrlCedula", "UrlCuponera"];

      /*    await impresos.forEach(async (impreso) => {
        if (data[impreso])
          data[impreso] = (
            await fileService("temp").download(data[impreso])
          ).url;
      });
      */
      const response = await services.api.polizas().postEndosar(polizaId, data);

      dispatch(postEndosarCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postEndosarFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al actualizar la póliza. Intente nuevamente."
        )
      );
    }
  };
};

const putAplicarEndosoInited = (value) => {
  return {
    type: `${baseEndosoPoliza}/putAplicarEndosoInited`,
    reducer: (state, action) => ({
      ...state,
      isLoading: true,
      polizaUpdated: false,
      failed: false,
    }),
  };
};

const putAplicarEndosoCompleted = (value) => {
  return {
    type: `${baseEndosoPoliza}/putAplicarEndosoCompleted`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      polizaHasChanged: false,
      polizaUpdated: true,
    }),
  };
};

const putAplicarEndosoFailed = (value) => {
  return {
    type: `${baseEndosoPoliza}/putAplicarEndosoFailed`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      failed: true,
    }),
  };
};

export const putAplicarEndoso = (polizaId, endosoId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(putAplicarEndosoInited());

      const response = await services.api
        .polizas()
        .putAplicarEndoso(polizaId, endosoId, data);

      dispatch(putAplicarEndosoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(putAplicarEndosoFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al actualizar la póliza. Intente nuevamente."
        )
      );
    }
  };
};

const putRechazarEndosoInited = (value) => {
  return {
    type: `${baseEndosoPoliza}/putRechazarEndosoInited`,
    reducer: (state, action) => ({
      ...state,
      isLoading: true,
      polizaUpdated: false,
      failed: false,
    }),
  };
};

const putRechazarEndosoCompleted = (value) => {
  return {
    type: `${baseEndosoPoliza}/putRechazarEndosoCompleted`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      polizaHasChanged: false,
      polizaUpdated: true,
    }),
  };
};

const putRechazarEndosoFailed = (value) => {
  return {
    type: `${baseEndosoPoliza}/putRechazarEndosoFailed`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      failed: true,
    }),
  };
};

export const putRechazarEndoso = (polizaId, endosoId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(putRechazarEndosoInited());

      const response = await services.api
        .polizas()
        .putRechazarEndoso(polizaId, endosoId, data);

      dispatch(putRechazarEndosoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(putRechazarEndosoFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al actualizar la póliza. Intente nuevamente."
        )
      );
    }
  };
};
