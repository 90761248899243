import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import SuccessIcon from "~images/ic_check_circle_24px@3x.png";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    width: "25%",
    alignSelf: "center",
    paddingBottom: "5%",
  },
  button: {
    alignSelf: "center",
  },
}));

const SuccessDialog = ({ openDialog, value }) => {
  const [open, setOpen] = useState(openDialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    //return <Redirect to={`${paths[solicitudesEmisionIndex]}`} />;
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className={classes.dialogTitle}>
            <img alt="" src={SuccessIcon} className={classes.icon} />
            {value}
          </div>
        </DialogTitle>
        <DialogActions>
          <div className={classes.button}>
            <Button onClick={handleClose} color="primary" autoFocus>
              Aceptar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessDialog;
