import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, esquemasPath } from "~constants/referenceNames";
import {
  GetCoberturasCompaniaResponse,
  GetEsquemasCotizacionesResponse,
  PatchCoberturaProductorRequest,
  PostEsquemaCotizacionesRequest,
} from "./types";

const esquemas = () => {
  return {
    getEsquemas: (data: any): Promise<GetEsquemasCotizacionesResponse> => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[esquemasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postEsquemas: (data: PostEsquemaCotizacionesRequest): Promise<Response> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[esquemasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchEsquemas: (
      data: PatchCoberturaProductorRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = environment.segurosUrl + paths[esquemasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getCoberturas: (
      compania: string,
      negocio: string
    ): Promise<GetCoberturasCompaniaResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[esquemasPath]}/${compania}?ProductorId=${negocio}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { esquemas };
