import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ItemInputCard from "~business/Cards/ItemInputCard";

import {
  getAsegurado,
  setAseguradoNombre,
  setAseguradoApellido,
  setAseguradoNumeroDocumento,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      nombreAsegurado: state.altaPolizaMultiramaReducer.aseguradoNombreSelected,
      apellidoAsegurado:
        state.altaPolizaMultiramaReducer.aseguradoApellidoSelected,
      dni: state.altaPolizaMultiramaReducer.aseguradoNumeroDocumentoSelected,
      aseguradoFromDocumento: state.altaPolizaMultiramaReducer.asegurado,
    };
  });

const title = "Datos del Asegurado";

const DatosAseguradoCard = ({ isLoading, isLoadingAsegurado }) => {
  const dispatch = useDispatch();

  const { nombreAsegurado, apellidoAsegurado, dni, aseguradoFromDocumento } =
    useReduxSelector();

  let timer = null;

  const handleBlurDni = (dni) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getAsegurado(dni));
    }, 200);
    dispatch(setSubmitting(false));
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  const handleChangeNombre = (nombre) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setAseguradoNombre(nombre));
    }, 200);
  };

  const handleChangeApellido = (apellido) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setAseguradoApellido(apellido));
    }, 200);
  };

  const handleChangeDni = (dni) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setAseguradoNumeroDocumento(dni));
    }, 200);
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemInputCard
          id={"dni"}
          isLoading={isLoading}
          label={"DNI"}
          value={dni.value}
          onChange={handleChangeDni}
          onBlur={() => handleBlurDni(dni.value)}
          focus={dni.focus}
          required
        />

        {!aseguradoFromDocumento && (
          <>
            <ItemInputCard
              id={"nombreAsegurado"}
              isLoading={isLoadingAsegurado}
              label={"Nombre Titular"}
              value={nombreAsegurado.value}
              onChange={handleChangeNombre}
              onBlur={handleBlur}
              focus={nombreAsegurado.focus}
              required
            />
            <ItemInputCard
              id={"apellido"}
              isLoading={isLoadingAsegurado}
              label={"Apellido Titular"}
              value={apellidoAsegurado.value}
              onChange={handleChangeApellido}
              onBlur={handleBlur}
              focus={apellidoAsegurado.focus}
              required
            />
          </>
        )}
        {aseguradoFromDocumento && (
          <>
            <ItemInputCard
              id={"nombreAsegurado"}
              isLoading={isLoadingAsegurado}
              label={"Nombre Titular"}
              value={nombreAsegurado.value}
              readOnly
            />
            <ItemInputCard
              id={"apellido"}
              isLoading={isLoadingAsegurado}
              label={"Apellido Titular"}
              value={apellidoAsegurado.value}
              readOnly
            />
          </>
        )}
      </DetalleCard>
    </>
  );
};

export default DatosAseguradoCard;
