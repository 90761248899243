import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import { endososPath } from "~constants/referenceNames";
import {
  selectSearchCriteria,
  selectEstado,
  setPage,
  setTake,
} from "./actions";

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  {
    name: "estado",
    nameUrl: "Estado",
    action: selectEstado,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const useEndososParamsToFilters = (page, take, searchCriteria, estado) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);

  const currentState = { page, take, searchCriteria, estado };
  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: endososPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [dispatch, take, page, searchCriteria, estado]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  return url;
};

export default useEndososParamsToFilters;
