import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_NOVEDADES_INITIAL_STATE");

const setCurrentNovedad = createAction(
  "SET_CURRENT_USUARIO",
  (currentNovedad) => ({
    currentNovedad,
  })
);

const getNovedadesInited = createAction("OBTENER_NOVEDADES_INITED");

const getNovedadesCompleted = createAction(
  "OBTENER_NOVEDADES_COMPLETED",
  (response, isAnOldRequest) => {
    let novedades = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      novedades = response.novedades;
      totalRegistros = response.totalRegistros;
    }

    return { novedades, totalRegistros, isAnOldRequest };
  }
);
const getNovedadesFailed = createAction(
  "OBTENER_NOVEDADES_FAILED",
  (error) => ({
    error,
  })
);

const getNovedades = ({
  skip,
  take,
  fechaNovedadDesde,
  fechaNovedadHasta,
  inicioVigenciaDesde,
  inicioVigenciaHasta,
  searchCriteria,
  negocio,
  compania,
  tipoNovedad,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getNovedadesInited());

      const data = {
        skip,
        take,
        searchCriteria,
        fechaNovedadDesde,
        fechaNovedadHasta,
        inicioVigenciaDesde,
        inicioVigenciaHasta,
        negocio,
        compania,
        tipoNovedad,
      };
      const response = await services.api.novedades().getNovedades(data);

      dispatch(getNovedadesCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getNovedadesFailed(error));
    }
  };
};

const novedadesInitialState = {
  novedades: null,
  currentUsuario: null,
  isLoading: false,
  failed: false,
  totalRegistros: 0,
};

const novedadesReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...novedadesInitialState,
        novedades: null,
      };
    },
    [setCurrentNovedad]: (state, action) => {
      return {
        ...state,
        currentUsuario: action.payload.currentUsuario,
      };
    },
    [getNovedadesInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getNovedadesCompleted]: (state, action) => {
      return {
        ...state,
        novedades: action.payload.novedades,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
        failed: false,
      };
    },
    [getNovedadesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
  },
  novedadesInitialState
);

export { novedadesReducer };
export { getNovedades, setInitialState, setCurrentNovedad };
