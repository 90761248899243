const TODOS = "";
export const PARTICULAR = "Particular";
export const COMERCIAL = "Comercial";

const usosVehiculoOptions = {
  [TODOS]: "Todos",
  [PARTICULAR]: "Particular",
  [COMERCIAL]: "Comercial",
};

const usosVehiculoFilter = [
  { label: usosVehiculoOptions[TODOS], value: TODOS },
  { label: usosVehiculoOptions[PARTICULAR], value: PARTICULAR },
  { label: usosVehiculoOptions[COMERCIAL], value: COMERCIAL },
];

export { usosVehiculoOptions, usosVehiculoFilter };
