import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import SecurityIcon from "@material-ui/icons/Security";
import ItemInputCard from "~business/Cards/ItemInputCard";

import {
  setNombreConductor,
  setApellidoConductor,
  setNumeroDocumentoConductor,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    nombreConductor: state.cargaSiniestroReducer.nombreConductorSelected,
    apellidoConductor: state.cargaSiniestroReducer.apellidoConductorSelected,
    numeroDocumentoConductor:
      state.cargaSiniestroReducer.numeroDocumentoConductorSelected,
  }));

const title = "Datos del Conductor";

const DatosConductorCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const { nombreConductor, apellidoConductor, numeroDocumentoConductor } =
    useReduxSelector();

  let timer = null;

  const handleChangeNombreConductor = (nombre) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNombreConductor(nombre));
    }, 200);
  };

  const handleChangeApellidoConductor = (apellido) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setApellidoConductor(apellido));
    }, 200);
  };

  const handleChangeNumeroDocumentoConductor = (dni) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroDocumentoConductor(dni));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={SecurityIcon}>
        <ItemInputCard
          id={"nombreConductor"}
          isLoading={isLoading}
          label={"Nombre"}
          value={nombreConductor.value}
          onChange={handleChangeNombreConductor}
          onBlur={handleBlur}
          focus={nombreConductor.focus}
          required
        />
        <ItemInputCard
          id={"apellido"}
          isLoading={isLoading}
          label={"Apellido"}
          value={apellidoConductor.value}
          onChange={handleChangeApellidoConductor}
          onBlur={handleBlur}
          focus={apellidoConductor.focus}
          required
        />
        <ItemInputCard
          id={"documento"}
          isLoading={isLoading}
          label={"Número de Documento"}
          value={numeroDocumentoConductor.value}
          onChange={handleChangeNumeroDocumentoConductor}
          onBlur={handleBlur}
          focus={numeroDocumentoConductor.focus}
          required
        />
      </DetalleCard>
    </>
  );
};

export default DatosConductorCard;
