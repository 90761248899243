import { createActions, handleActions, Action } from "redux-actions";

interface Agencia {
  value: string;
  label: string;
}
interface FetchAgenciasCompletedPayload {
  agencias: Agencia[];
}

interface FetchAgenciasFailedPayload {
  error: any;
}

interface AppState {
  isLoading: boolean;
  agencias: Agencia[];
}

interface Services {
  api: {
    agencias: () => {
      getAgencias: () => Promise<{ items: { id: string; nombre: string }[] }>;
    };
  };
}

const fetchAgencias = () => {
  return async (
    dispatch: (action: any) => void,
    getState: () => AppState,
    services: Services
  ) => {
    try {
      dispatch(fetchAgenciasInited({}));
      const response = await services.api.agencias().getAgencias();
      dispatch(fetchAgenciasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchAgenciasFailed(error));
    }
  };
};

const {
  agenciasReducer: {
    clear,
    fetchAgenciasInited,
    fetchAgenciasCompleted,
    fetchAgenciasFailed,
  },
} = createActions({
  agenciasReducer: {
    clear: () => ({}),
    setId: (value: string) => ({ value }),
    setNombre: (value: string) => ({ value }),
    fetchAgenciasInited: () => ({}),
    fetchAgenciasCompleted: (response: {
      items: { id: string; nombre: string }[];
    }) => {
      let agencias: Agencia[] = [];

      if (response?.items?.length) {
        agencias = response.items.map((x) => ({
          value: x.id,
          label: x.nombre,
        }));
      }

      return { agencias };
    },
    fetchAgenciasFailed: (error: any) => ({ error }),
  },
});

// Initial state type definition
const initialState: AppState = { isLoading: false, agencias: [] };

// Define the reducer using handleActions
export const agenciasReducer = handleActions<AppState, any>(
  {
    [clear.toString()]: (state: AppState, action: Action<{}>) => {
      return { ...initialState };
    },
    [fetchAgenciasInited.toString()]: (state: AppState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [fetchAgenciasCompleted.toString()]: (
      state: AppState,
      action: Action<FetchAgenciasCompletedPayload>
    ) => {
      return {
        ...state,
        agencias: [...action.payload.agencias],
        isLoading: false,
      };
    },
    [fetchAgenciasFailed.toString()]: (
      state: AppState,
      action: Action<FetchAgenciasFailedPayload>
    ) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  initialState
);

export default agenciasReducer;
export { clear, fetchAgencias };
