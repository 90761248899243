import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemDateCard from "~business/Cards/ItemDateCard";
import DetalleCard from "~business/Cards/DetalleCard";
import Button from "@material-ui/core/Button";
import { getEmisiones, setFechaDesde, setFechaHasta } from "./actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.getEmisionesReducer,
  }));

const EmisionesCard = () => {
  const dispatch = useDispatch();
  const {
    fechaDesdeSelected,
    fechaHastaSelected,
    buttonEnabled,
    buttonText,
  } = useReduxSelector();

  const handleChangeFechaDesde = (fechaDesde) => {
    dispatch(setFechaDesde(fechaDesde));
  };
  const handleChangeFechaHasta = (fechaHasta) => {
    dispatch(setFechaHasta(fechaHasta));
  };
  const handleGetEmisiones = () => {
    let data = {
      fechaDesde: fechaDesdeSelected.value,
      fechaHasta: fechaHastaSelected.value,
    };
    dispatch(getEmisiones(data));
  };

  return (
    <>
      <DetalleCard title={"Emisiones"}>
        <ItemDateCard
          label="Desde"
          value={fechaDesdeSelected.value}
          onChange={handleChangeFechaDesde}
        />
        <ItemDateCard
          label="Hasta"
          value={fechaHastaSelected.value}
          onChange={handleChangeFechaHasta}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ width: "40%" }}
          onClick={handleGetEmisiones}
          disabled={!buttonEnabled}
        >
          {buttonText}
        </Button>
      </DetalleCard>
    </>
  );
};

export default EmisionesCard;
