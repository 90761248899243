import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Searcher from "~styled/searcher/searcher";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import { setPage, setTake, selectSearchCriteria, setGetOnlyActivos } from "./actions";
import { permissionHandleUserActivo } from "~constants/usuarios";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%"
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5
  },
  horizontalSpace: { paddingRight: "10px" },
  horizontalSpaceSearcher: { paddingRight: "10px", minWidth: 260 },
  textItemContainer: { display: "flex", flexDirection: "column" }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    totalRegistros: state.usuariosReducer.totalRegistros,

    page: state.filtrosUsuariosReducer.page,
    take: state.filtrosUsuariosReducer.take,
    searchCriteria: state.filtrosUsuariosReducer.searchCriteria,
    getOnlyActivos: state.filtrosUsuariosReducer.getOnlyActivos,
    permissions: state.authenticatedViewReducer.tokenPermissions,
    isLoading: state.usuariosReducer.isLoading,
  }));

//const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const { take, searchCriteria, page, totalRegistros, getOnlyActivos , permissions, isLoading } = useReduxSelector();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangeGetOnlyActivos = (event) => {
    dispatch(setPage(0));
    dispatch(setGetOnlyActivos(event.target.checked));
  }

  const handleChangePage = value => {
    dispatch(setPage(value));
  };

  const canHandleActivo = permissions.includes(permissionHandleUserActivo);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <div className={classes.textItemContainer}>
          <Typography variant="h3" className={classes.label}>
            BUSQUEDA
          </Typography>
          <div className={classes.horizontalSpaceSearcher}>
            <Searcher
              filter={searchCriteria}
              placeholder="Nombre o Email"
              onChange={event => handleChange(event, selectSearchCriteria)}
              onClear={() =>
                handleChange({ target: { value: "" } }, selectSearchCriteria)
              }
            />
          </div>
        </div>
        
        {canHandleActivo &&
          <div className={classes.textItemContainer}>
            <Typography variant="h3" 
              className={classes.label}
              style={{ 
                paddingBottom: 0, 
                marginBotton: "-3px",
                marginLeft: 6,
              }}>
              SÓLO ACTIVOS
            </Typography>
            <div>
              <Switch
                checked={getOnlyActivos}
                onChange={handleChangeGetOnlyActivos}
              />
            </div>
          </div>   
      }
      </div>
      
      {!isLoading &&
        <Pagination
          rowsPerPage={take}
          onChangeRowsPerPage={value =>
            handleChange({ target: { value } }, setTake)
          }
          page={page}
          onChangePage={handleChangePage}
          totalRegistros={totalRegistros}
          options={paginationOptions}
        />
      }
    </div>
  );
};

export default Filtros;
