import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DetalleSolicitudCardItem from "../DetalleSolicitudCardItem";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "stretch",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },

  dash: { display: "flex", justifyContent: "center" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
  }));

const MotivoRechazoCard = () => {
  const classes = useStyles();
  const { detalleSolicitudEmision } = useReduxSelector();

  return (
    <>
      {detalleSolicitudEmision.estado === "Rechazada" && (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <CancelIcon className={classes.cardIcon} />
            <Typography className={classes.cardTitle}>
              {"Información sobre rechazo"}
            </Typography>
          </div>
          <DetalleSolicitudCardItem
            caption="Motivo"
            value={
              detalleSolicitudEmision.motivoRechazo !== null ? (
                detalleSolicitudEmision.motivoRechazo
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default MotivoRechazoCard;
