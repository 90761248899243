import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPoliza, clearData } from "./actions";
import { getUsuarios } from "../altaPoliza/actions";
import { paths, polizasMultiramaPath } from "~constants/referenceNames";
import Documentacion from "./Documentacion";
import DetallePolizaMultirama from "./DetallePolizaMultirama";
import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";

const DetallePolizaMultiramaContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getPoliza(id));

    return () => {
      dispatch(clearData());
    };
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getUsuarios(0, 10, ""));

    //eslint-disable-next-line
  }, [dispatch]);

  const backAction = () => {
    history.push(paths[polizasMultiramaPath]);
  };

  return (
    <DetailsLayout
      title={"Detalle de la Póliza"}
      backAction={backAction}
      subtitle={"Multirama"}
      selectedDefault={polizasMultiramaPath}
      tabsComponent={() => (
        <DetailsTabs
          defaultValue={0}
          labels={[
            <TabIndex text="DETALLE" />,
            <TabIndex text="DOCUMENTACIÓN" />,
          ]}
          onChange={(e) => {
            setCurrentTab(parseInt(e.target.value));
          }}
        />
      )}
    >
      {currentTab === 0 && <DetallePolizaMultirama />}
      {currentTab === 1 && <Documentacion />}
    </DetailsLayout>
  );
};

const TabIndex = ({ text }) => {
  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>{text}</span>
    </>
  );
};

export default DetallePolizaMultiramaContainer;
