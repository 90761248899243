let getLocalStorage = function () {
  return window && window.localStorage
    ? window.localStorage
    : { currentLang: "es" };
};

const localRepository = {
  clear: function () {
    getLocalStorage().clear();
  },

  accessToken: {
    get: function () {
      return getLocalStorage().accessToken
        ? getLocalStorage().accessToken
        : null;
    },
    set: function (accessToken) {
      getLocalStorage().accessToken = accessToken;
    },
  },

  featureFlags: {
    get: function () {
      return getLocalStorage().featureFlags
        ? JSON.parse(getLocalStorage().featureFlags)
        : {};
    },
    set: function (featureFlags) {
      getLocalStorage().featureFlags = JSON.stringify(featureFlags);
    },
  },

  mostUsedProv: {
    get: function () {
      return localStorage.getItem("mostUsedProv")
        ? JSON.parse(localStorage.getItem("mostUsedProv"))
        : [];
    },
    set: function (state) {
      const mostUs = this.get();
      const prov = mostUs.filter(
        (prov) => prov.label === state.label && state.provId === prov.provId
      );
      if (prov.length > 0) prov[0].count++;
      else mostUs.push({ ...state, count: 0 });
      localStorage.setItem("mostUsedProv", JSON.stringify(mostUs));
    },
    clear: () => {
      localStorage.removeItem("mostUsedProv");
    },
  },
  mostUsedLoc: {
    get: function () {
      return localStorage.getItem("mostUsedLoc")
        ? JSON.parse(localStorage.getItem("mostUsedLoc"))
        : [];
    },
    set: function (state) {
      const mostUs = this.get();
      const loc = mostUs.filter((loc) => loc.label === state.label);
      if (loc.length > 0) loc[0].count++;
      else mostUs.push({ ...state, count: 0 });
      localStorage.setItem("mostUsedLoc", JSON.stringify(mostUs));
    },
    clear: () => {
      localStorage.removeItem("mostUsedLoc");
    },
  },
};

export default localRepository;
