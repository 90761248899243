import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import PhotoIcon from "@material-ui/icons/Photo";
// import {
//   setDocumentoUnoFileName,
//   setDocumentoDosFileName,
//   setDocumentoTresFileName,
//   setDocumentoCuatroFileName,
// } from "../actions";
import { useSelector } from "react-redux";
// import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GalleryWithLightbox from "~styled/gallery/GalleryWithLightBox";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: "2%",
      borderBottom: "1px solid #8B8B8B",
    },
    noDocumentsText: {
      fontFamily: "Nunito",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#8B8B8B",
    },
  };
});

const title = "Documentación";

// const partes = {
//   documentoUno: setDocumentoUnoFileName,
//   documentoDos: setDocumentoDosFileName,
//   documentoTres: setDocumentoTresFileName,
//   documentoCuatro: setDocumentoCuatroFileName,
// };

const useReduxSelector = () =>
  useSelector((state) => {
    const siniestro = state.detalleSiniestroReducer
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    return {
      ...siniestro,
    };
  });

const DocumentacionCard = ({ isLoading, IconHeader }) => {
  const { documentacion } = useReduxSelector();
  const classes = useStyles();

  if (isLoading)
    return (
      <DetalleCard
        isLoading={isLoading}
        title={title}
        IconHeader={IconHeader}
      />
    );

  let documentos = [];

  if (documentacion) {
    documentos = documentacion.map((documento) => ({
      src: documento,
      thumbnail: documento,
      thumbnailWidth: 80,
      thumbnailHeight: 80,
    }));
  }

  const documentosLoaded = documentos.length > 0;

  return (
    <DetalleCard isLoading={isLoading} title={title} IconHeader={PhotoIcon}>
      {documentosLoaded && <GalleryWithLightbox imagenes={documentos} />}
      {!documentosLoaded && (
        <div className={classes.container}>
          <Typography className={classes.noDocumentsText}>
            {"No se han cargado documentos."}
          </Typography>
        </div>
      )}
    </DetalleCard>
  );
};

export default DocumentacionCard;
