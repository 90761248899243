import React, { useState } from "react";
import DetalleCotizacionTable from "../../cotizaciones/detalleCotizacionTable/DetalleCotizacionTable";
import useSeguros from "./useSeguros";
import PolizaDetail from "../../polizaDetail/PolizaDetail";

const DETALLE_COTIZACION = "DETALLE_COTIZACION";
const DETALLE_POLIZA = "DETALLE_POLIZA";

interface CoberturaStepperProps {
  onChange?: (importe: number, idExterno: string, cotizacionId: string) => void;
  compania?: string;
  codia: number;
  anio: number;
  codigoPostal: string;
  localidad: string;
  provincia: number;
  uso?: string;
  condicionFiscal?: string;
  tieneGNC?: boolean;
  formaDePago?: string;
}

interface Poliza {
  importe: number;
  idExterno: string;
}

const CoberturaStepper: React.FC<CoberturaStepperProps> = ({
  onChange,
  compania,
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
}) => {
  const [currentPoliza, setCurrentPoliza] = useState<Poliza | null>(null);

  const handleOpen = (value: Poliza | null) => {
    setCurrentPoliza(value);
  };

  const handleContratar = (value: Poliza) => {
    if (onChange && value)
      onChange(value.importe, value.idExterno, cotizacionId);
  };

  const { isLoading, cotizaciones, cotizacionId } = useSeguros({
    codia,
    anio,
    codigoPostal,
    localidad,
    provincia,
    uso,
    condicionFiscal,
    tieneGNC,
    formaDePago,
  });

  const detalle = cotizaciones
    ? {
        ...cotizaciones,
        companias: cotizaciones?.companias.filter(
          (comp) => comp.companiaId === compania
        ),
      }
    : null;

  const step = getStep(currentPoliza);
  return (
    <div style={{ minHeight: "90vh" }}>
      {step === DETALLE_COTIZACION && (
        <DetalleCotizacionTable
          isPending={isLoading}
          onClick={handleOpen}
          detalleCotizacion={detalle}
        />
      )}
      {step === DETALLE_POLIZA && currentPoliza && (
        <div onClick={() => handleOpen(currentPoliza)}>
          <PolizaDetail
            poliza={currentPoliza}
            onClose={() => handleOpen(null)}
            onContratar={() => handleContratar(currentPoliza)}
          />
        </div>
      )}
    </div>
  );
};

export default CoberturaStepper;

const getStep = (currentPoliza: Poliza | null): string => {
  if (!currentPoliza) return DETALLE_COTIZACION;
  return DETALLE_POLIZA;
};
