import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, endososPath } from "~constants/referenceNames";
import { GetEndososRequest, GetEndososResponse } from "./types";

const endosos = () => {
  return {
    getEndosos: ({
      take,
      skip,
      searchCriteria,
      estado,
    }: GetEndososRequest): Promise<GetEndososResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${skip}&Take=${take}`;

      parameters = searchCriteria
        ? parameters + `&SearchCriteria=${searchCriteria}`
        : parameters;

      parameters = estado ? parameters + `&Estado=${estado}` : parameters;

      const url = `${environment.segurosUrl}${paths[endososPath]}?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};
export { endosos };
