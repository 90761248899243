import React, { useState, useRef, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import ItemLoadingCard from "./ItemLoadingCard";
import DniInput from "~styled/input/DniInput";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: pxToRem(270),
    width: "50%",
  },

  dash: { display: "flex", justifyContent: "center" },
  icon: {
    color: "#6240a5",
  },
  input: {
    padding: "5%",
    width: "100%",
    color: "#424242",
    "& .MuiOutlinedInput-input": {
      padding: 0,
      textAlign: "right",
    },
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
}));

const errorText = "Requerido";

const ItemDniItem = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  displayError,
  focus,
  onBlur,
  formatted = false,
  isLoading,
  readOnly,
}) => {
  const classes = useStyles();
  const reference = useRef();
  const [localValue, setValue] = useState(value);
  const [valid, setValid] = useState(handleValidate(value));

  useEffect(() => {
    if (focus) {
      reference.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (!isLoading) {
      setValid(handleValidate(value));
      setValue(value);
    }
    //eslint-disable-next-line
  }, [isLoading]);

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const handleChangeValue = (value) => {
    const isDataValid = handleValidate(value);
    setValid(isDataValid);
    setValue(value);

    if (onChange) onChange({ value: value, valid: isDataValid, focus: false });
  };

  const showError = !valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {!readOnly && (
          <TextField
            id={id}
            style={{ width: "100%" }}
            onChange={(event) => handleChangeValue(event.target.value)}
            onBlur={handleBlur}
            value={localValue}
            variant="outlined"
            placeholder={placeholder}
            error={showError}
            inputRef={reference}
            InputProps={{
              className: classes.input,
              inputComponent: DniInput,
            }}
          />
        )}
        {readOnly && (
          <Typography className={classes.value}>
            {getValueParsed(value, formatted)}
          </Typography>
        )}

        {showError && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemDniItem;

const handleValidate = (value) => {
  const isDataValid = value && value.length > 7;

  return isDataValid;
};

const getValueParsed = (value, formatted) => {
  if (!value) return <div>&mdash;</div>;

  if (!formatted) return value;
  return (
    value.slice(0, 2) +
    " " +
    value.slice(2, value.length - 3) +
    " " +
    value.slice(value.length - 3)
  );
};
