import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import EmailValidator from "email-validator";
import validateDominio from "~libs/dominioValidator";

const moment = require("moment");

const getDetalleSolicitudEmisionInited = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_INITED"
);
const getDetalleSolicitudEmisionCompleted = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_COMPLETED",
  (response) => {
    let detalleSolicitudEmision = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      detalleSolicitudEmision = response;
    }

    return { detalleSolicitudEmision };
  }
);
const getDetalleSolicitudEmisionFailed = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_FAILED",
  (error) => ({
    error,
  })
);

const getDetalleSolicitudEmision = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleSolicitudEmisionInited());
      dispatch(clearCuotas());
      const response = await services.api
        .solicitudesEmision()
        .getDetalleSolicitudEmision(id);

      dispatch(getDetalleSolicitudEmisionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleSolicitudEmisionFailed(error));
    }
  };
};

const postEmisionManualInited = createAction("PROCESAR_EMISION_MANUAL_INITED");
const postEmisionManualCompleted = createAction(
  "PROCESAR_EMISION_MANUAL_COMPLETED",
  (response) => { }
);
const postEmisionManualFailed = createAction(
  "PROCESAR_EMISION_MANUAL_FAILED",
  (error) => ({
    error,
  })
);

const postEmisionManual = (solicitudEmisionId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postEmisionManualInited());

      const response = await services.api
        .emisionesManuales()
        .postEmisionManual(solicitudEmisionId, data);

      dispatch(postEmisionManualCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postEmisionManualFailed(error));
      dispatch(
        queueMessage("Ocurrió un error al crear la póliza. Intente nuevamente.")
      );
    }
  };
};

const patchRechazarSolicitudInited = createAction("RECHAZAR_SOLICITUD_INITED");
const patchRechazarSolicitudCompleted = createAction(
  "RECHAZAR_SOLICITUD_COMPLETED",
  (response) => { }
);
const patchRechazarSolicitudFailed = createAction(
  "RECHAZAR_SOLICITUD_FAILED",
  (error) => ({
    error,
  })
);

const patchRechazarSolicitud = (solicitudEmisionId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchRechazarSolicitudInited());

      const response = await services.api
        .solicitudesEmision()
        .patchRechazarSolicitud(solicitudEmisionId, data);

      dispatch(patchRechazarSolicitudCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(patchRechazarSolicitudFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al rechazar la solicitud. Intente nuevamente."
        )
      );
    }
  };
};

const getDatosPagoInited = createAction("OBTENER_DATOS_PAGO_INITED");
const getDatosPagoCompleted = createAction(
  "OBTENER_DATOS_PAGO_COMPLETED",
  (response) => {
    let datosPago = "";

    if (typeof response !== "undefined" && response !== null) {
      datosPago = response.datoDePago;
    }

    return { datosPago };
  }
);
const getDatosPagoFailed = createAction(
  "OBTENER_DATOS_PAGO_FAILED",
  (error) => ({
    error,
  })
);

const setEmail = createAction("SET_EMAIL", (emailSelected) => ({
  emailSelected,
}));
const setDominio = createAction("SET_DOMINIO", (dominioSelected) => ({
  dominioSelected,
}));
const setVigenciaDesde = createAction(
  "SET_VIGENCIA_DESDE",
  (vigenciaDesdeSelected) => ({
    vigenciaDesdeSelected,
  })
);
const setVigenciaHasta = createAction(
  "SET_VIGENCIA_HASTA",
  (vigenciaHastaSelected) => ({
    vigenciaHastaSelected,
  })
);

const setVigencia = createAction("SET_VIGENCIA", (vigenciaSelected) => ({
  vigenciaSelected,
}));

const setCantidadCuotas = createAction(
  "SET_CANTIDAD_CUOTAS",
  (cantidadCuotasSelected) => ({
    cantidadCuotasSelected,
  })
);
const setNroMotor = createAction("SET_NRO_MOTOR", (nroMotorSelected) => ({
  nroMotorSelected,
}));
const setNroChasis = createAction("SET_NRO_CHASIS", (nroChasisSelected) => ({
  nroChasisSelected,
}));
const setPremioTotal = createAction(
  "SET_PREMIO_TOTAL",
  (premioTotalSelected) => ({ premioTotalSelected })
);
const setSumaAsegurada = createAction(
  "SET_SUMA_ASEGURADA",
  (sumaAseguradaSelected) => ({ sumaAseguradaSelected })
);
const setNumeroPoliza = createAction(
  "SET_NUMERO_POLIZA",
  (numeroPolizaSelected) => ({
    numeroPolizaSelected,
  })
);
const setIsProcessingPoliza = createAction(
  "SET_PROCESSING_POLIZA",
  (isProcessingPoliza) => ({
    isProcessingPoliza,
  })
);
const setIsProcessingTarjeta = createAction(
  "SET_PROCESSING_TARJETA",
  (isProcessingTarjeta) => ({
    isProcessingTarjeta,
  })
);
const setIsProcessingCedulaMercosur = createAction(
  "SET_PROCESSING_CEDULA_MERCOSUR",
  (isProcessingCedulaMercosur) => ({
    isProcessingCedulaMercosur,
  })
);
const setIsProcessingCuponera = createAction(
  "SET_PROCESSING_CUPONERA",
  (isProcessingCuponera) => ({
    isProcessingCuponera,
  })
);
const setPolizaFileName = createAction(
  "SET_POLIZA_FILENAME",
  (polizaFileNameSelected) => ({
    polizaFileNameSelected,
  })
);
const setTarjetaFileName = createAction(
  "SET_TARJETA_FILENAME",
  (tarjetaFileNameSelected) => ({
    tarjetaFileNameSelected,
  })
);

const setCedulaMercosurFileName = createAction(
  "SET_CEDULA_MERCOSUR_FILENAME",
  (cedulaMercosurFileNameSelected) => ({
    cedulaMercosurFileNameSelected,
  })
);

const setCuponeraFileName = createAction(
  "SET_CUPONERA_FILENAME",
  (cuponeraFileNameSelected) => ({
    cuponeraFileNameSelected,
  })
);

const setFotoFrontalFileName = createAction(
  "SET_FOTO_FRONTAL_FILENAME",
  (fotoFrontalFileNameSelected) => ({
    fotoFrontalFileNameSelected,
  })
);

const setFotoTraseraFileName = createAction(
  "SET_FOTO_TRASERA_FILENAME",
  (fotoTraseraFileNameSelected) => ({
    fotoTraseraFileNameSelected,
  })
);

const setFotoLateralIzquierdoFileName = createAction(
  "SET_FOTO_LATERAL_IZQUIERDO_FILENAME",
  (fotoLateralIzquierdoFileNameSelected) => ({
    fotoLateralIzquierdoFileNameSelected,
  })
);

const setFotoLateralDerechoFileName = createAction(
  "SET_FOTO_LATERAL_DERECHO_FILENAME",
  (fotoLateralDerechoFileNameSelected) => ({
    fotoLateralDerechoFileNameSelected,
  })
);

const setCuota = createAction("SET_CUOTA", (cuotaSelected) => ({
  cuotaSelected,
}));

const setBase64File = createAction("SET_BASE64_FILE", (base64File) => ({
  base64File,
}));

const getDatosPago = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDatosPagoInited());

      const response = await services.api.solicitudesEmision().getDatosPago(id);

      dispatch(getDatosPagoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDatosPagoFailed(error));
    }
  };
};

const clearDatosPago = createAction("CLEAR_DATOS_PAGO", (datosPago) => ({
  datosPago,
}));

const getLogsUsuarioInited = createAction(
  "OBTENER_LOGS_USUARIO_SOLICITUD_EMISION_INITED"
);

const getLogsUsuario = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getLogsUsuarioInited());

      const response = await services.api
        .solicitudesEmision()
        .getLogsUsuario(id);

      dispatch(getLogsUsuarioCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getLogsUsuarioFailed(error));
    }
  };
};

const getLogsUsuarioCompleted = createAction(
  "OBTENER_LOGS_USUARIO_SOLICITUD_EMISION_COMPLETED",
  (response) => {
    let logsUsuario = response;

    return { logsUsuario };
  }
);

const getLogsUsuarioFailed = createAction(
  "OBTENER_LOGS_USUARIO_SOLICITUD_EMISION_FAILED",
  (error) => ({
    error,
  })
);

const clearBase64File = createAction("CLEAR_BASE64_FILE", () => ({}));

const clearData = createAction("CLEAR_DATA", () => ({}));
const clearCuotas = createAction("CLEAR_CUOTAS", () => ({}));

const setSubmitting = createAction("SET_SUBMITTING", (submitting) => ({
  submitting,
}));

const detalleSolicitudEmisionInitialState = {
  detalleSolicitudEmision: {
    datosCliente: {},
    datosEmision: {},
    datosOriginador: {},
    datosVehiculo: {},
    fotosVehiculo: {},
  },
  isLoading: true,
  isLoadingLog: true,
  polizaCreada: false,
  creatingPoliza: false,
  datosPago: null,
  emailSelected: { value: "", valid: false, focus: false },
  dominioSelected: { value: "", valid: false, focus: false },
  vigenciaDesdeSelected: { value: new Date(), valid: true, focus: false },
  vigenciaHastaSelected: { value: new Date(), valid: false, focus: false },
  nroMotorSelected: { value: "", valid: false, focus: false },
  nroChasisSelected: { value: "", valid: false, focus: false },
  premioTotalSelected: { value: 0, valid: false, focus: false },
  sumaAseguradaSelected: { value: 0, valid: false, focus: false },
  numeroPolizaSelected: { value: "", valid: false, focus: false },
  polizaFileNameSelected: { value: null, valid: false, focus: false },
  isProcessingPoliza: false,
  isProcessingTarjeta: false,
  isProcessingCedulaMercosur: false,
  isProcessingCuponera: false,
  tarjetaFileNameSelected: { value: null, valid: false, focus: false },
  cedulaMercosurFileNameSelected: { value: null, valid: false, focus: false },
  cuponeraFileNameSelected: { value: null, valid: false, focus: false },
  vigenciaSelected: { value: 6, valid: true },
  cantidadCuotasSelected: { value: 6, valid: true },
  cuotasSelected: {},
  submitting: false,
  loadingError: false,
  solicitudRechazada: false,
  base64Files: {},
  downloadFiles: false,
  fotoFrontalFileNameSelected: { value: null, valid: false, focus: false },
  fotoTraseraFileNameSelected: { value: null, valid: false, focus: false },
  fotoLateralIzquierdoFileNameSelected: {
    value: null,
    valid: false,
    focus: false,
  },
  fotoLateralDerechoFileNameSelected: {
    value: null,
    valid: false,
    focus: false,
  },
};

const detalleSolicitudEmisionReducer = handleActions(
  {
    [getDetalleSolicitudEmisionInited]: (state, action) => {
      return {
        ...detalleSolicitudEmisionInitialState,
      };
    },
    [getDetalleSolicitudEmisionCompleted]: (state, action) => {
      const detalle = action.payload.detalleSolicitudEmision;
      return {
        ...state,
        detalleSolicitudEmision: detalle,
        isLoading: false,
        dominioSelected: {
          value: detalle.datosVehiculo.dominio,
          valid:
            detalle.datosVehiculo.dominio &&
            validateDominio(detalle.datosVehiculo.dominio),
          focus: false,
        },
        emailSelected: {
          value: detalle.datosCliente.email,
          valid:
            detalle.datosCliente.email &&
            EmailValidator.validate(detalle.datosCliente.email),
          focus: false,
        },
        nroChasisSelected: {
          value: detalle.datosVehiculo.numeroChasis,
          valid: detalle.datosVehiculo.numeroChasis ? true : false,
          focus: false,
        },
        nroMotorSelected: {
          value: detalle.datosVehiculo.numeroMotor,
          valid: detalle.datosVehiculo.numeroMotor ? true : false,
          focus: false,
        },
        sumaAseguradaSelected: {
          value: detalle.datosPoliza
            ? detalle.datosPoliza.sumaAsegurada
            : detalle.datosVehiculo.sumaAsegurada,
          valid: detalle.datosPoliza
            ? detalle.datosPoliza.sumaAsegurada !== 0
            : detalle.datosVehiculo.sumaAsegurada !== 0,
          focus: false,
        },
        vigenciaSelected: {
          value:
            detalle.datosPoliza && detalle.datosPoliza.vigencia !== 0
              ? detalle.datosPoliza.vigencia
              : detalle.cantidadCuotas,
          valid: true,
          focus: false,
        },
        cantidadCuotasSelected: {
          value:
            detalle.datosPoliza && detalle.datosPoliza.vigencia !== 0
              ? detalle.datosPoliza.cantidadCuotas
              : detalle.cantidadCuotas,
          valid: true,
          focus: false,
        },
        vigenciaDesdeSelected: {
          value:
            detalle.datosPoliza && detalle.datosPoliza.fechaVigenciaDesde
              ? detalle.datosPoliza.fechaVigenciaDesde
              : detalle.vigenciaDesde,
          valid: true,
          focus: false,
        },
        vigenciaHastaSelected: {
          value:
            detalle.datosPoliza && detalle.datosPoliza.fechaVigenciaDesde
              ? moment(detalle.datosPoliza.fechaVigenciaDesde).add(
                detalle.datosPoliza.cantidadCuotas,
                "months"
              )
              : moment(detalle.vigenciaDesde).add(detalle.cantidadCuotas, "months"),
          valid: true,
          focus: false,
        },
        premioTotalSelected: {
          value: detalle.datosPoliza
            ? detalle.datosPoliza.premioTotal
            : detalle.datosEmision.precioInformado * detalle.cantidadCuotas,
          valid: true,
          focus: false,
        },
        codigoOrganizadorSelected: detalle.codigoOrganizador,
        codigoProductorSelected: detalle.codigoProductor,
        codigoVendedorSelected: detalle.codigoVendedor,
      };
    },
    [getDetalleSolicitudEmisionFailed]: (state, action) => {
      return {
        ...state,
        loadingError: true,
      };
    },
    [postEmisionManualInited]: (state, action) => {
      return {
        ...state,
        creatingPoliza: true,
        isLoading: true,
      };
    },
    [postEmisionManualCompleted]: (state, action) => {
      return {
        ...state,
        polizaCreada: true,
        creatingPoliza: false,
        isLoading: false,
      };
    },
    [postEmisionManualFailed]: (state, action) => {
      return {
        ...state,
        creatingPoliza: false,
        isLoading: false,
      };
    },
    [patchRechazarSolicitudInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [patchRechazarSolicitudCompleted]: (state, action) => {
      return {
        ...state,
        solicitudRechazada: true,
      };
    },
    [patchRechazarSolicitudFailed]: (state, action) => {
      return {
        ...state,
      };
    },
    [getDatosPagoInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [getDatosPagoCompleted]: (state, action) => {
      return {
        ...state,
        datosPago: action.payload.datosPago,
      };
    },
    [getDatosPagoFailed]: (state, action) => {
      return {
        ...state,
      };
    },
    [getLogsUsuarioInited]: (state, action) => {
      return {
        ...state,
        logsUsuario: null,
        isLoadingLog: true,
      };
    },
    [getLogsUsuarioCompleted]: (state, action) => {
      return {
        ...state,
        logsUsuario: action.payload.logsUsuario,
        isLoadingLog: false,
      };
    },
    [getLogsUsuarioFailed]: (state, action) => {
      return {
        ...state,
      };
    },
    [clearDatosPago]: (state, action) => {
      return {
        ...state,
        datosPago: null,
      };
    },
    [clearData]: (state, action) => {
      return {
        ...detalleSolicitudEmisionInitialState,
      };
    },
    [setEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: action.payload.emailSelected,
      };
    },
    [setDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: action.payload.dominioSelected,
      };
    },
    [setVigenciaDesde]: (state, action) => {
      const vigenciaDesde = action.payload.vigenciaDesdeSelected;
      const vigenciaHasta = new Date(vigenciaDesde.value);
      const vigencia = state.vigenciaSelected;

      return {
        ...state,
        vigenciaDesdeSelected: vigenciaDesde,
        vigenciaHastaSelected: {
          value: new Date(
            vigenciaHasta.setMonth(vigenciaHasta.getMonth() + vigencia.value)
          ),
          valid: true,
          focus: false,
        },
      };
    },
    [setVigenciaHasta]: (state, action) => {
      return {
        ...state,
        vigenciaHastaSelected: action.payload.vigenciaHastaSelected,
      };
    },
    [setVigencia]: (state, action) => {
      const vigencia = action.payload.vigenciaSelected;
      const vigenciaDesde = state.vigenciaDesdeSelected;
      return {
        ...state,
        vigenciaSelected: vigencia,
        vigenciaHastaSelected: {
          value: moment(vigenciaDesde.value).add(vigencia.value, "months"),
          valid: true,
          focus: false,
        },
      };
    },
    [setCantidadCuotas]: (state, action) => {
      return {
        ...state,
        cantidadCuotasSelected: action.payload.cantidadCuotasSelected,
      };
    },
    [setNroMotor]: (state, action) => {
      return {
        ...state,
        nroMotorSelected: action.payload.nroMotorSelected,
      };
    },
    [setNroChasis]: (state, action) => {
      return {
        ...state,
        nroChasisSelected: action.payload.nroChasisSelected,
      };
    },
    [setPremioTotal]: (state, action) => {
      return {
        ...state,
        premioTotalSelected: action.payload.premioTotalSelected,
      };
    },
    [setSumaAsegurada]: (state, action) => {
      return {
        ...state,
        sumaAseguradaSelected: action.payload.sumaAseguradaSelected,
      };
    },
    [setNumeroPoliza]: (state, action) => {
      return {
        ...state,
        numeroPolizaSelected: action.payload.numeroPolizaSelected,
      };
    },
    [setPolizaFileName]: (state, action) => {
      return {
        ...state,
        polizaFileNameSelected: action.payload.polizaFileNameSelected,
      };
    },
    [setTarjetaFileName]: (state, action) => {
      return {
        ...state,
        tarjetaFileNameSelected: action.payload.tarjetaFileNameSelected,
      };
    },
    [setCedulaMercosurFileName]: (state, action) => {
      return {
        ...state,
        cedulaMercosurFileNameSelected:
          action.payload.cedulaMercosurFileNameSelected,
      };
    },
    [setCuponeraFileName]: (state, action) => {
      return {
        ...state,
        cuponeraFileNameSelected: action.payload.cuponeraFileNameSelected,
      };
    },
    [setIsProcessingPoliza]: (state, action) => {
      return {
        ...state,
        isProcessingPoliza: action.payload.isProcessingPoliza,
      };
    },
    [setIsProcessingTarjeta]: (state, action) => {
      return {
        ...state,
        isProcessingTarjeta: action.payload.isProcessingTarjeta,
      };
    },
    [setIsProcessingCedulaMercosur]: (state, action) => {
      return {
        ...state,
        isProcessingCedulaMercosur: action.payload.isProcessingCedulaMercosur,
      };
    },
    [setIsProcessingCuponera]: (state, action) => {
      return {
        ...state,
        isProcessingCuponera: action.payload.isProcessingCuponera,
      };
    },
    [setFotoFrontalFileName]: (state, action) => {
      return {
        ...state,
        fotoFrontalFileNameSelected: action.payload.fotoFrontalFileNameSelected,
      };
    },
    [setFotoTraseraFileName]: (state, action) => {
      return {
        ...state,
        fotoTraseraFileNameSelected: action.payload.fotoTraseraFileNameSelected,
      };
    },
    [setFotoLateralIzquierdoFileName]: (state, action) => {
      return {
        ...state,
        fotoLateralIzquierdoFileNameSelected:
          action.payload.fotoLateralIzquierdoFileNameSelected,
      };
    },
    [setFotoLateralDerechoFileName]: (state, action) => {
      return {
        ...state,
        fotoLateralDerechoFileNameSelected:
          action.payload.fotoLateralDerechoFileNameSelected,
      };
    },
    [setCuota]: (state, action) => {
      let id = action.payload.cuotaSelected.id;
      state.cuotasSelected[id] = action.payload.cuotaSelected;
      return {
        ...state,
        cuotasSelected: state.cuotasSelected,
      };
    },
    [setBase64File]: (state, action) => {
      let id = action.payload.base64File.id;
      state.base64Files[id] = action.payload.base64File;
      let filesCount = Object.keys(state.base64Files).length;
      return {
        ...state,
        base64Files: state.base64Files,
        downloadFiles: filesCount === 4,
      };
    },
    [clearBase64File]: (state, action) => {
      return {
        ...state,
        base64Files: {},
        downloadFiles: false,
      };
    },
    [setSubmitting]: (state, action) => {
      return {
        ...state,
        submitting: action.payload.submitting,
        emailSelected: { ...state.emailSelected, focus: false },
        dominioSelected: { ...state.dominioSelected, focus: false },
        nroMotorSelected: { ...state.nroMotorSelected, focus: false },
        nroChasisSelected: { ...state.nroChasisSelected, focus: false },
        premioTotalSelected: { ...state.premioTotalSelected, focus: false },
        numeroPolizaSelected: { ...state.numeroPolizaSelected, focus: false },
        vigenciaDesdeSelected: { ...state.vigenciaDesdeSelected, focus: false },
        vigenciaHastaSelected: { ...state.vigenciaHastaSelected, focus: false },
      };
    },
    [clearCuotas]: (state, action) => {
      return {
        ...state,
        cuotasSelected: {},
      };
    },
  },
  detalleSolicitudEmisionInitialState
);

export {
  detalleSolicitudEmisionReducer,
  getDetalleSolicitudEmision,
  postEmisionManual,
  patchRechazarSolicitud,
  getDatosPago,
  getLogsUsuario,
  clearDatosPago,
  setEmail,
  setDominio,
  setNroChasis,
  setNroMotor,
  setPremioTotal,
  setSumaAsegurada,
  setNumeroPoliza,
  setPolizaFileName,
  setTarjetaFileName,
  setCedulaMercosurFileName,
  setCuponeraFileName,
  setCuota,
  setVigenciaDesde,
  setVigenciaHasta,
  setVigencia,
  clearData,
  setSubmitting,
  clearCuotas,
  setBase64File,
  clearBase64File,
  setFotoFrontalFileName,
  setFotoTraseraFileName,
  setFotoLateralDerechoFileName,
  setFotoLateralIzquierdoFileName,
  setCantidadCuotas,
  setIsProcessingPoliza,
  setIsProcessingTarjeta,
  setIsProcessingCedulaMercosur,
  setIsProcessingCuponera,
};
