import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/styles/makeStyles";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import AddBoxIcon from "@material-ui/icons/AddBox";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";

import {
  getDescripcionCobertura,
  putDescripcionCobertura,
  postDescripcionCobertura,
  setCompaniaSelected,
} from "./actions";

import DescripcionEditor from "./DescripcionEditor";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import Pagination from "~styled/pagination/Pagination";
import ChipCobertura from "~shared/chipCobertura/ChipCobertura";
import TableLoading from "~styled/tableLoading/TableLoading";
import { paginationOptions } from "~constants/pagination";
import { coberturasPath, paths } from "~constants/referenceNames";
import { useHistory, useLocation } from "react-router-dom";
import TabSelector from "~business/coberturas/tabSelector/TabSelector";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import UnexpectedError from "~styled/errorPages/UnexpectedError";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "0px 0px 0px 20px",
    borderBottom: "solid 1px #CCCCE4",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    alignItems: "flex-end",
  },
  tableCell: {
    display: "inine-flex",
    padding: "0px 0px 0px 20px",
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    // color: theme.colours.violeta
  },
  chip: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  tableColumnTipoCobertura: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  iconTipoCobertura: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "200px",
  },
  button: {
    padding: 0,
    borderRadius: 0,
  },
  pagination: {
    display: "flex",
    padding: "10px",
    width: "100%",
    justifyContent: "flex-end",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    descripcionCoberturas: state.listadoCoberturasReducer.descripcionCoberturas,
    companiaSelected: state.listadoCoberturasReducer.companiaSelected,
    failed: state.listadoCoberturasReducer.failed,

    tiposCobertura: state.tipoCoberturaReducer.tiposCobertura,
  }));

const CoberturasContainer = ({ companiaId }) => {
  const { descripcionCoberturas, companiaSelected, failed } =
    useReduxSelector();

  const history = useHistory();
  const [currentPoliza, setCurrentPoliza] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const list = [];

  const [isNew, setIsNew] = useState(false);

  const isLoading = !companiaSelected;

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getDescripcionCobertura());
  }, [dispatch]);

  const handleChange = (value) => {
    const url = `${paths[coberturasPath]}/${value}`;
    if (location.pathname !== url) {
      history.push(url);
    }
  };

  useEffect(() => {
    if (descripcionCoberturas) {
      const compania = isLoading
        ? null
        : descripcionCoberturas.companias.find((compania) => {
            return compania.idCompania.includes(companiaId);
          });

      dispatch(setCompaniaSelected(compania));
      setPage(0);
    }
    //eslint-disable-next-line
  }, [descripcionCoberturas, companiaId]);

  const handleEdit = (rowData) => {
    if (rowData && rowData.tableData) list.splice(rowData.tableData.id, 1);
    return () => {
      setIsNew(false);
      setCurrentPoliza(rowData);
      setIsOpened(true);
    };
  };

  const handleAddNew = () => {
    setCurrentPoliza(null);
    setIsNew(true);
    setIsOpened(true);
  };

  const [isOpened, setIsOpened] = useState(false);

  const handleClose = () => {
    setCurrentPoliza(null);
    setIsOpened(false);
  };

  const handleAceptar =
    (row, id, nombre, descripcion, tipoCobertura, negocios) => () => {
      setCurrentPoliza(null);

      setIsOpened(false);

      let index = 0;

      if (isNew) {
        row = [];
        row.id = id;
      } else {
        index = companiaSelected.coberturasAutos.indexOf(row);
      }

      row.nombre = nombre;
      row.descripcion = descripcion;
      row.tipoCobertura = tipoCobertura;
      row.negocios = negocios;
      if (!isValid(row)) {
        dispatch(queueMessage("Debes completar todos los campos requeridos."));
        return;
      }

      if (isNew) {
        dispatch(
          postDescripcionCobertura(
            row.id,
            row.nombre,
            row.descripcion,
            row.tipoCobertura,
            companiaSelected.idCompania,
            row.negocios
          )
        );
      } else {
        dispatch(
          putDescripcionCobertura(
            row.id,
            row.nombre,
            row.descripcion,
            row.tipoCobertura,
            companiaSelected.idCompania,
            row.negocios
          )
        );

        //actualizo cobertura en ui
        companiaSelected.coberturasAutos[index] = row;
        dispatch(setCompaniaSelected(companiaSelected));
      }
    };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    //setPage(0);
  };

  const isValid = (data) => {
    if (data.id == null) return false;
    if (data.nombre == null) return false;
    if (data.tipoCobertura == null) return false;
    // TODO: validate
    return true;
  };

  const totalRegistros = companiaSelected
    ? companiaSelected.coberturasAutos.length
    : 0;

  const classes = useStyles();

  if (failed) return <UnexpectedError />;

  return (
    <SectionLayout Icon={VerifiedUserIcon} title={"Coberturas"}>
      <>
        {(currentPoliza || isNew) && (
          <DescripcionEditor
            isOpened={isOpened}
            poliza={currentPoliza}
            onClickAceptar={handleAceptar}
            compania={companiaSelected}
            onClose={handleClose}
            isNew={isNew}
          />
        )}
        <div className={classes.root}>
          <Paper className={classes.paper}>
            {isLoading && <TabSelector list={["", "", ""]} />}
            {!isLoading && (
              <TabSelector
                value={companiaSelected.idCompania}
                onChange={handleChange}
                list={descripcionCoberturas.companias}
              />
            )}
            <div className={classes.tableContainer}>
              <div className={classes.tableHeader}>
                <div className={classes.button}>
                  {!isLoading && (
                    <Tooltip title="Añadir">
                      <IconButton
                        className={classes.button}
                        onClick={handleAddNew}
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                {!isLoading && (
                  <div className={classes.pagination}>
                    <Pagination
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      totalRegistros={totalRegistros}
                      options={paginationOptions}
                    />
                  </div>
                )}
              </div>
              {isLoading && <TableLoading length={5} />}
              {!isLoading && (
                <ListadoCoberturas
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleEdit={handleEdit}
                />
              )}

              {!isLoading && (
                <div className={classes.pagination}>
                  <Pagination
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    totalRegistros={totalRegistros}
                    options={paginationOptions}
                  />
                </div>
              )}
            </div>
          </Paper>
        </div>
      </>
    </SectionLayout>
  );
};

export default CoberturasContainer;

const ListadoCoberturas = ({ page, rowsPerPage, handleEdit }) => {
  const { tiposCobertura, companiaSelected } = useReduxSelector();

  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellHeader}>Id</TableCell>
          <TableCell className={classes.tableCellHeader} align="center">
            Nombre
          </TableCell>
          <TableCell className={classes.tableCellHeader} align="center">
            Tipo Cobertura
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companiaSelected.coberturasAutos
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => (
            <TableRow key={row.id}>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableCell}
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleEdit(row)}
                  style={{ textDecoration: "underline" }}
                >
                  {row.id}
                </Link>
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {row.nombre}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                <div className={classes.tableColumnTipoCobertura}>
                  <div className={classes.iconTipoCobertura}>
                    <ChipCobertura
                      label={getLabel(tiposCobertura, row.tipoCobertura)}
                      tipoCobertura={row.tipoCobertura}
                    />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const getLabel = (tiposCobertura, tipoCobertura) => {
  return tiposCobertura.find((tipo) => {
    return tipo.id === tipoCobertura;
  })
    ? tiposCobertura.find((tipo) => {
        return tipo.id === tipoCobertura;
      }).nombre
    : "Sin tipo";
};
