import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_USUARIOS_INITIAL_STATE");

const getOficinasInited = createAction("OBTENER_OFICINAS_INITED");
const getRolesInited = createAction("GET_ROLES_INITED");

const getOficinas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getOficinasInited());

      const response = await services.api.oficinas().getOficinas();

      dispatch(getOficinasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getOficinasFailed(error));
    }
  };
};

const getOficinasCompleted = createAction(
  "OBTENER_OFICINAS_COMPLETED",
  (response) => {
    let oficinas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      oficinas = response.oficinas;
    }

    return { oficinas };
  }
);

const getOficinasFailed = createAction("OBTENER_OFICINAS_FAILED", (error) => ({
  error,
}));

const getRoles = () => {
  return async (dispach, getState, services) => {
    try {
      dispach(getRolesInited());
      const response = await services.api.roles().getRoles();
      dispach(getRolesCompleted(response));
    } catch (error) {
      console.error(error);
      dispach(getRolesFailed(error));
    }
  };
};

const getRolesCompleted = createAction("GET_ROLES_COMPLETED", (response) => {
  let roles = [];
  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0
  ) {
    roles = response.items;
  }
  
  return { roles };
});

const getRolesFailed = createAction("GET_ROLES_FAILED", (error) => ({ error }));

const usuarioEditorInitialState = {
  usuario: null,
  oficinas: [],
  isLoading: true,
  failed: false,
  roles: [],
};

const usuarioEditorReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...usuarioEditorInitialState,
        usuario: null,
        oficinas: [],
      };
    },
    [getOficinasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getOficinasCompleted]: (state, action) => {
      return {
        ...state,
        oficinas: action.payload.oficinas,
        isLoading: false,
        failed: false,
      };
    },
    [getOficinasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [getRolesInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getRolesCompleted]: (state, action) => {
      return {
        ...state,
        roles: action.payload.roles,
        isLoading: false,
        failed: false,
      };
    },
    [getRolesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
  },
  usuarioEditorInitialState
);

export { usuarioEditorReducer };
export { getOficinas, getRoles };
