import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { setDni, clearDni } from "../actions";

import TextField from "@material-ui/core/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import DniInput from "~components/styled/input/DniInput";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  buttonContinuar: {
    paddingTop: 24,
  },
}));

const DniStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [documento, setDocumento] = useState("");

  useEffect(() => {
    dispatch(clearDni());
  }, [dispatch]);

  const canContinue = documento.length > 6 && documento.length < 9;
  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) dispatch(setDni(documento));
  };

  return (
    <div className={classes.cotizador}>
      <div className={classes.title}>
        <PrimaryTitle label="Ingresá el DNI del asegurado" marginBottom={60} />
      </div>

      <TextField
        fullWidth={true}
        onFocus={(event) => event.target.select()}
        autoFocus
        value={documento}
        type="tel"
        onKeyDown={onKeyDown}
        onChange={(event) => {
          const value = event.target.value.replace(/\s/g, "");

          setDocumento(value);
        }}
        placeholder="Ej: 11 111 111"
        InputProps={{
          inputComponent: DniInput,
        }}
      />
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            dispatch(setDni(documento));
          }}
        />
      </div>
    </div>
  );
};

export default DniStep;
