import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import CancelIcon from "@material-ui/icons/Cancel";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import { motivoAnulacionOptions } from "~constants/endosos";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosAnulacion = state.detallePolizaNuevoReducer.currentPoliza
      ? state.detallePolizaNuevoReducer.currentPoliza.datosAnulacion
      : {};

    return {
      ...datosAnulacion,
    };
  });

const title = "Informacion sobre Anulación";

const DatosAnulacionCard = ({ isLoading }) => {
  const { fechaAnulacion, motivoAnulacion } = useReduxSelector();

  return (
    <DetalleCard title={title} IconHeader={CancelIcon}>
      <ItemDateCard
        readOnly
        isLoading={isLoading}
        label="Fecha Anulación"
        value={fechaAnulacion}
      />
      <ItemInputCard
        label="Motivo Anulación"
        isLoading={isLoading}
        value={motivoAnulacionOptions[motivoAnulacion]}
        readOnly
      />
    </DetalleCard>
  );
};

export default DatosAnulacionCard;
