import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postDatosContacto } from "../actions";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";

const useReduxSelector = () =>
  useSelector((state) => ({
    currentState: state.multicotizadorReducer,
  }));

export const SendPostSolicitudEmisionStep = () => {
  const dispatch = useDispatch();
  const { currentState } = useReduxSelector();
  const { sendPostSolicitudEmision } = currentState;
  useEffect(() => {
    if (sendPostSolicitudEmision) {
      dispatch(postDatosContacto(currentState));
    }

    //eslint-disable-next-line
  }, [dispatch]);

  return <LoadingViewGears />;
};
