import { paths } from "~constants/referenceNames";
import getUrlParam from "~libs/urlParams";
import isNullOrEmpty from "~libs/isNullOrEmpty";

const moment = require("moment");

export const getUrlWithParameters = ({ params, currentState, index }) => {
  const page = currentState.page;
  const take = currentState.take;

  let parameters = "";

  params.map((parameter) => {
    const value = currentState[parameter.name];

    if (value) {
      const valueParsed = parameter.isDate
        ? moment(value).local().format("L")
        : value;

      parameters = isNullOrEmpty(parameters)
        ? `${parameter.nameUrl}=${valueParsed}`
        : parameters + `&${parameter.nameUrl}=${valueParsed}`;
    }
    return true;
  });

  parameters = parameters + `&Skip=${page * take}`;
  let url = paths[index] + "?" + parameters;

  return url;
};

export const updateUrlParameters = ({
  params,
  location,
  dispatch,
  setPage,
}) => {
  params.map((parameter) => {
    const value = getUrlParam(location, parameter.nameUrl);

    if (value)
      dispatch(
        parameter.isDate
          ? parameter.action(moment(value, "DD/MM/YYYY").toDate())
          : parameter.action(value)
      );

    return value;
  });

  const takeParameter = getUrlParam(location, "Take");
  const skipParameter = getUrlParam(location, "Skip");
  if (skipParameter) {
    const page =
      takeParameter !== 0 ? Math.floor(skipParameter / takeParameter) : 0;
    dispatch(setPage(page));
  }
};
