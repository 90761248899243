import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearContratarCurrentPoliza,
  setContratarCurrentPoliza,
  setCurrentPoliza,
} from "../actions";
import PolizaDetail from "~business/polizaDetail/PolizaDetail";
import MulticotizadorContent from "./MulticotizadorContent";

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      currentPoliza: state.multicotizadorReducer.currentPoliza.value,
    };
  });

const DetallePolizaStep = () => {
  const dispatch = useDispatch();
  const { currentPoliza } = useReduxSelector();

  useEffect(() => {
    dispatch(clearContratarCurrentPoliza());
  }, [dispatch]);

  const handleContratar = () => {
    dispatch(
      setContratarCurrentPoliza({
        value: currentPoliza,
        open: true,
        contratada: true,
      })
    );
  };

  const handleOpen = (poliza) => {
    dispatch(
      setCurrentPoliza({ value: poliza, open: true, contratada: false })
    );
  };

  if (currentPoliza)
    return (
      <MulticotizadorContent>
        <PolizaDetail
          poliza={currentPoliza}
          onClick={handleOpen}
          onContratar={handleContratar}
        />
      </MulticotizadorContent>
    );

  return null;
};

export default DetallePolizaStep;
