import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postPedidoCotizacion } from "../actions";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import { useHistory } from "react-router-dom";

const useReduxSelector = () =>
  useSelector((state) => ({
    currentState: state.multicotizadorReducer,
  }));

export const SendPostCotizacionesStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentState } = useReduxSelector();
  const { currentPoliza, cotizacionId } = currentState;

  useEffect(() => {
    if (!currentPoliza.open && !cotizacionId)
      dispatch(postPedidoCotizacion(currentState));
    else history.goBack();

    //eslint-disable-next-line
  }, [dispatch]);

  return <LoadingViewGears />;
};
