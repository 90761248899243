import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import CardDetalle from "~styled/cardDetalle/CardDetalle";
import HeaderDetallePolizas from "~business/polizas/headerDetallePolizas/HeaderDetallePolizas";
import { negociosSegurosOptions } from "~constants/negocios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: "4.5%",
    flexDirection: "column",
    alignItems: "center",
  },
  cardItem: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    currentNovedad: state.detalleNovedadReducer.currentNovedad,
    isLoading: state.detalleNovedadReducer.isLoading,
    failed: state.detalleNovedadReducer.failed,
  }));

const DetalleNovedad = () => {
  const classes = useStyles();
  const { currentNovedad, isLoading, failed } = useReduxSelector();

  const filtered = currentNovedad
    ? Object.keys(currentNovedad)
        .filter((key) => key !== "cuotas")
        .reduce((obj, key) => {
          obj[key] = currentNovedad[key];
          return obj;
        }, {})
    : null;

  if (failed) return <UnexpectedError />;
  return (
    <div className={classes.root}>
      <HeaderDetallePolizas
        isLoading={!currentNovedad}
        nombre={currentNovedad ? currentNovedad.nombreCompletoAsegurado : null}
        numeroDocumento={
          currentNovedad ? currentNovedad.numeroDocumentoAsegurado : null
        }
        numeroPoliza={currentNovedad ? currentNovedad.numeroPoliza : null}
        operacion={null}
        negocio={
          currentNovedad ? negociosSegurosOptions[currentNovedad.negocio] : null
        }
      />

      <div className={classes.cardItem}>
        <CardDetalle
          IconHeader={VerifiedUserIcon}
          isLoading={isLoading || !currentNovedad}
          title="Datos de la Novedad"
          data={currentNovedad ? filtered : null}
        />
      </div>
    </div>
  );
};

export default DetalleNovedad;
