import React from "react";
import {
  estadoSiniestrosOptions,
  INGRESADO,
  PENDIENTE_COMPANIA,
  RECHAZADO,
  RESUELTO,
} from "~constants/estadoSiniestros";
import ColorLabel from "../colorLabel/ColorLabel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dash: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

const ColorLabelEstadoSiniestro = ({ estado, abbreviated, bold }) => {
  const classes = useStyles();

  const isIngresado = estado === INGRESADO;

  const isPendienteCompania = estado === PENDIENTE_COMPANIA;

  const isResuelto = estado === RESUELTO;

  const isRechazado = estado === RECHAZADO;

  return (
    <>
      {isIngresado && (
        <ColorLabel
          variant={"otros"}
          text={abbreviated ? "IN" : estadoSiniestrosOptions[INGRESADO]}
          bold={bold}
        />
      )}
      {isPendienteCompania && (
        <ColorLabel
          variant={"warning"}
          text={
            abbreviated ? "PC" : estadoSiniestrosOptions[PENDIENTE_COMPANIA]
          }
          bold={bold}
        />
      )}
      {isResuelto && (
        <ColorLabel
          variant={"success"}
          text={abbreviated ? "RS" : estadoSiniestrosOptions[RESUELTO]}
          bold={bold}
        />
      )}
      {isRechazado && (
        <ColorLabel
          variant={"danger"}
          text={abbreviated ? "RZ" : estadoSiniestrosOptions[RECHAZADO]}
          bold={bold}
        />
      )}
      {!isIngresado && !isPendienteCompania && !isResuelto && !isRechazado && (
        <ColorLabel
          text={
            abbreviated ? (
              <div className={classes.dash}>&mdash;</div>
            ) : (
              "Indeterminado"
            )
          }
          bold={bold}
        />
      )}
    </>
  );
};

export default ColorLabelEstadoSiniestro;
