import { createAction, handleActions } from "redux-actions";

const getDetalleCotizacionInited = createAction(
  "OBTENER_DETALLE_COTIZACION_INITED"
);
const getDetalleCotizacionCompleted = createAction(
  "OBTENER_DETALLE_COTIZACION_COMPLETED",
  (response) => {
    let detalleCotizacion = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      detalleCotizacion = response;
    }

    return { detalleCotizacion };
  }
);
const getDetalleCotizacionFailed = createAction(
  "OBTENER_DETALLE_COTIZACION_FAILED",
  (error) => ({
    error,
  })
);

const getDetalleCotizacion = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleCotizacionInited());

      const response = await services.api
        .cotizaciones()
        .getDetalleCotizacion(id);

      dispatch(getDetalleCotizacionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleCotizacionFailed(error));
    }
  };
};

const detalleCotizacionInitialState = {
  esquemas: [],
  hasChanged: false,
  esquemaSelected: null,
  failed: false,
};

const detalleCotizacionReducer = handleActions(
  {
    [getDetalleCotizacionInited]: (state, action) => {
      return {
        ...detalleCotizacionInitialState,
      };
    },
    [getDetalleCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        detalleCotizacion: action.payload.detalleCotizacion,
        failed: false,
      };
    },
    [getDetalleCotizacionFailed]: (state, action) => {
      return {
        ...state,
        failed: true,
      };
    },
  },
  detalleCotizacionInitialState
);

export { detalleCotizacionReducer };
export { getDetalleCotizacion };
