import { handleActions, createAction } from "redux-actions";

const setTipo = createAction("SET_TIPO_CARGA_SINIESTRO", (tipoSelected) => ({
  tipoSelected,
}));

const setCompania = createAction(
  "SET_COMPANIA_CARGA_SINIESTRO",
  (companiaSelected) => ({
    companiaSelected,
  })
);

const setNumeroSiniestroCompania = createAction(
  "SET_NUMERO_SINIESTRO_COMPANIA_CARGA_SINIESTRO",
  (numeroSiniestroCompaniaSelected) => ({
    numeroSiniestroCompaniaSelected,
  })
);

const setNumeroPoliza = createAction(
  "SET_NUMERO_POLIZA_CARGA_SINIESTRO",
  (numeroPolizaSelected) => ({
    numeroPolizaSelected,
  })
);

const setFecha = createAction("SET_FECHA_CARGA_SINIESTRO", (fechaSelected) => ({
  fechaSelected,
}));

const setHora = createAction("SET_HORA_CARGA_SINIESTRO", (horaSelected) => ({
  horaSelected,
}));

const setCalle = createAction("SET_CALLE_CARGA_SINIESTRO", (calleSelected) => ({
  calleSelected,
}));

const setAltura = createAction(
  "SET_ALTURA_CARGA_SINIESTRO",
  (alturaSelected) => ({
    alturaSelected,
  })
);

const setLocalidad = createAction(
  "SET_LOCALIDAD_CARGA_SINIESTRO",
  (localidadSelected) => ({
    localidadSelected,
  })
);

const setProvincia = createAction(
  "SET_PROVINCIA_CARGA_SINIESTRO",
  (provinciaSelected) => ({
    provinciaSelected,
  })
);

const setCodigoPostal = createAction(
  "SET_CODIGO_POSTAL_CARGA_SINIESTRO",
  (codigoPostalSelected) => ({
    codigoPostalSelected,
  })
);

const setLesionesFisicas = createAction(
  "SET_LESIONES_FISICAS_CARGA_SINIESTRO",
  (lesionesFisicasSelected) => ({
    lesionesFisicasSelected,
  })
);

const setComoOcurrio = createAction(
  "SET_COMO_OCURRIO_CARGA_SINIESTRO",
  (comoOcurrioSelected) => ({
    comoOcurrioSelected,
  })
);

const setDaniosVehiculo = createAction(
  "SET_DANIOS_VEHICULO_CARGA_SINIESTRO",
  (daniosVehiculoSelected) => ({
    daniosVehiculoSelected,
  })
);

const setNombreConductor = createAction(
  "SET_NOMBRE_CONDUCTOR_CARGA_SINIESTRO",
  (nombreConductorSelected) => ({
    nombreConductorSelected,
  })
);

const setApellidoConductor = createAction(
  "SET_APELLIDO_CONDUCTOR_CARGA_SINIESTRO",
  (apellidoConductorSelected) => ({
    apellidoConductorSelected,
  })
);

const setNumeroDocumentoConductor = createAction(
  "SET_DNI_CONDUCTOR_CARGA_SINIESTRO",
  (numeroDocumentoConductorSelected) => ({
    numeroDocumentoConductorSelected,
  })
);

const setDocumentos = createAction(
  "SET_DOCUMENTOS_CARGA_SINIESTRO",
  (documentosSelected) => ({
    documentosSelected,
  })
);

const setDominio = createAction(
  "SET_DOMINIO_CARGA_SINIESTRO",
  (dominioSelected) => ({
    dominioSelected,
  })
);

const setModeloAnio = createAction(
  "SET_MODELO_ANIO_CARGA_SINIESTRO",
  (modeloAnioSelected) => ({
    modeloAnioSelected,
  })
);

const setMarca = createAction("SET_MARCA_CARGA_SINIESTRO", (marcaSelected) => ({
  marcaSelected,
}));

const setModelo = createAction(
  "SET_MODELO_CARGA_SINIESTRO",
  (modeloSelected) => ({
    modeloSelected,
  })
);

const setNumeroDocumentoAsegurado = createAction(
  "SET_NUMERO_DOCUMENTO_ASEGURADO_CARGA_SINIESTRO",
  (numeroDocumentoAseguradoSelected) => ({
    numeroDocumentoAseguradoSelected,
  })
);

const setNombreAsegurado = createAction(
  "SET_NOMBRE_ASEGURADO_CARGA_SINIESTRO",
  (nombreAseguradoSelected) => ({
    nombreAseguradoSelected,
  })
);

const setMail = createAction("SET_MAIL_CARGA_SINIESTRO", (mailSelected) => ({
  mailSelected,
}));

const setTelefono = createAction(
  "SET_TELEFONO_CARGA_SINIESTRO",
  (telefonoSelected) => ({
    telefonoSelected,
  })
);

const setTerceros = createAction(
  "SET_TERCEROS_CARGA_SINIESTRO",
  (terceros) => ({
    terceros,
  })
);

const setDocumentosFileName = createAction(
  "SET_DOCS_SINIESTRO_FILENAME",
  (documentosFileNameSelected) => ({
    documentosFileNameSelected,
  })
);

const setIsProcessingDocumentos = createAction(
  "SET_PROCESSING_DOCUMENTOS_SINIESTRO",
  (isProcessingDocumentos) => ({
    isProcessingDocumentos,
  })
);

const setSubmitting = createAction(
  "SET_SUBMITTING_CARGA_SINIESTRO",
  (submitting) => ({
    submitting,
  })
);

const clearData = createAction("CLEAR_DATA_CARGA_SINIESTRO");

const clearLocalidad = createAction("CLEAR_LOCALIDAD_CARGA_SINIESTRO");

const postSiniestroInited = createAction("POST_SINIESTRO_INITED");

const postSiniestroCompleted = createAction(
  "POST_SINIESTRO_COMPLETED",
  (response) => { }
);

const postSiniestroFailed = createAction("POST_SINIESTRO_FAILED", (error) => ({
  error,
}));

const postSiniestro = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postSiniestroInited());

      const response = await services.api.siniestros().postSiniestro(data);

      dispatch(postSiniestroCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postSiniestroFailed(error));
    }
  };
};

const getPolizasInited = createAction("OBTENER_POLIZAS_SINIESTRO_INITED");

const getPolizasCompleted = createAction(
  "OBTENER_POLIZAS_SINIESTRO_COMPLETED",
  (response) => {
    let polizas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      polizas = response.polizas;
    }

    return { polizas };
  }
);
const getPolizasFailed = createAction(
  "OBTENER_POLIZAS_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const getPolizas = ({
  skip,
  take,
  fechaDesde,
  fechaHasta,
  searchCriteria,
  negocio,
  estado,
  soloParaRenovar,
  compania,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizasInited());

      const data = {
        skip,
        take,
        fechaDesde,
        fechaHasta,
        searchCriteria,
        estado,
        negocio,
        soloParaRenovar,
        compania,
      };

      const response = await services.api.polizas().getPolizas(data);

      dispatch(getPolizasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizasFailed(error));
    }
  };
};

const getPolizaInited = createAction("OBTENER_POLIZA_SINIESTRO_INITED");

const getPolizaCompleted = createAction(
  "OBTENER_POLIZA_SINIESTRO_COMPLETED",
  (polizaVinculada) => ({ polizaVinculada })
);

const getPolizaFailed = createAction(
  "OBTENER_POLIZA_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const getPoliza = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizaInited());

      const response = await services.api.polizas().getPoliza(id);

      dispatch(getPolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizaFailed(error));
    }
  };
};

const fetchLocalidadesInited = createAction(
  "OBTENER_LOCALIDADES_SINIESTRO_INITED"
);

const fetchLocalidadesCompleted = createAction(
  "OBTENER_LOCALIDADES_SINIESTRO_COMPLETED",
  (localidades) => ({ localidades })
);

const fetchLocalidadesFailed = createAction(
  "OBTENER_LOCALIDADES_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const fetchLocalidades = (provinciaId) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchLocalidadesInited());
      const response = await services.api
        .provincias()
        .getLocalidades(provinciaId);

      const localidades = response.localidades
        .filter((v, i) => response.localidades.indexOf(v) === i)
        .filter((i) => i !== "")
        .map((item) => ({
          value: item,
          label: item,
        }));
      dispatch(fetchLocalidadesCompleted(localidades));
    } catch (error) {
      console.error(error);
      dispatch(fetchLocalidadesFailed(error));
    }
  };
};

const fetchCodigosPostalesInited = createAction("OBTENER_CP_SINIESTRO_INITED");

const fetchCodigosPostalesCompleted = createAction(
  "OBTENER_CP_SINIESTRO_COMPLETED",
  (codigosPostales) => ({ codigosPostales })
);

const fetchCodigosPostalesFailed = createAction(
  "OBTENER_CP_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const fetchCodigosPostales = (provinciaId, localidad) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCodigosPostalesInited());
      const response = await services.api
        .provincias()
        .getCodigosPostales(provinciaId, localidad);
      dispatch(fetchCodigosPostalesCompleted(response.codigosPostales));
    } catch (error) {
      console.error(error);
      dispatch(fetchCodigosPostalesFailed(error));
    }
  };
};

const cargaSiniestroReducerInitialState = {
  tipoSelected: { value: "", valid: false, focus: false },
  companiaSelected: { value: "", valid: false, focus: false },
  numeroSiniestroCompaniaSelected: { value: "", valid: false, focus: false },
  numeroPolizaSelected: { value: "", valid: false, focus: false },
  idPolizaSelected: { value: "", valid: false, focus: false },
  sucursalSelected: { value: "", valid: false, focus: false },
  oficinaIdSelected: { value: "", valid: false, focus: false },
  fechaSelected: { value: null, valid: true, focus: false },
  horaSelected: { value: "", valid: false, focus: false },
  calleSelected: { value: "", valid: false, focus: false },
  alturaSelected: { value: "", valid: false, focus: false },
  localidadSelected: { value: "", valid: false, focus: false },
  provinciaSelected: { value: "", valid: false, focus: false },
  codigoPostalSelected: { value: "", valid: false, focus: false },
  lesionesFisicasSelected: { value: "", valid: false, focus: false },
  comoOcurrioSelected: { value: "", valid: false, focus: false },
  daniosVehiculoSelected: { value: "", valid: false, focus: false },
  nombreConductorSelected: { value: "", valid: false, focus: false },
  apellidoConductorSelected: { value: "", valid: false, focus: false },
  numeroDocumentoConductorSelected: { value: "", valid: false, focus: false },
  dominioSelected: { value: "", valid: false, focus: false },
  modeloAnioSelected: { value: "", valid: false, focus: false },
  marcaSelected: { value: "", valid: false, focus: false },
  modeloSelected: { value: "", valid: false, focus: false },
  terceros: [],
  numeroDocumentoAseguradoSelected: { value: "", valid: false, focus: false },
  nombreAseguradoSelected: { value: "", valid: false, focus: false },
  mailSelected: { value: "", valid: false, focus: false },
  telefonoSelected: { value: "", valid: false, focus: false },
  documentosSelected: [],
  localidades: [],
  codigosPostales: [],
  polizaVinculada: null,
  polizasFilter: [],
  submitting: false,
  isLoading: false,
  isLoadingPoliza: false,
  creatingSiniestro: false,
  siniestroCreado: false,
  isProcessingDocumentos: false,
};

const cargaSiniestroReducer = handleActions(
  {
    [setTipo]: (state, action) => {
      const tipoSelected = {
        ...action.payload.tipoSelected,
        valid: action.payload.tipoSelected.value !== "",
        focus: action.payload.tipoSelected.value === "",
      };
      return {
        ...state,
        tipoSelected: tipoSelected,
      };
    },
    [setCompania]: (state, action) => {
      return {
        ...state,
        companiaSelected: action.payload.companiaSelected,
      };
    },
    [setNumeroSiniestroCompania]: (state, action) => {
      return {
        ...state,
        numeroSiniestroCompaniaSelected:
          action.payload.numeroSiniestroCompaniaSelected,
      };
    },
    [setNumeroPoliza]: (state, action) => {
      return {
        ...state,
        numeroPolizaSelected: action.payload.numeroPolizaSelected,
      };
    },
    [setFecha]: (state, action) => {
      return {
        ...state,
        fechaSelected: action.payload.fechaSelected,
      };
    },
    [setHora]: (state, action) => {
      return {
        ...state,
        horaSelected: action.payload.horaSelected,
      };
    },
    [setCalle]: (state, action) => {
      return {
        ...state,
        calleSelected: action.payload.calleSelected,
      };
    },
    [setAltura]: (state, action) => {
      return {
        ...state,
        alturaSelected: action.payload.alturaSelected,
      };
    },
    [setLocalidad]: (state, action) => {
      return {
        ...state,
        localidadSelected: {
          value: action.payload.localidadSelected.label,
          valid: true,
          focus: false,
        },
      };
    },
    [setProvincia]: (state, action) => {
      return {
        ...state,
        provinciaSelected: {
          value: action.payload.provinciaSelected.label,
          valid: true,
          focus: false,
          searchId: action.payload.provinciaSelected.value,
        },
      };
    },
    [setCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostalSelected: {
          value: action.payload.codigoPostalSelected.label,
          valid: true,
          focus: false,
        },
      };
    },
    [setLesionesFisicas]: (state, action) => {
      return {
        ...state,
        lesionesFisicasSelected: action.payload.lesionesFisicasSelected,
      };
    },
    [setComoOcurrio]: (state, action) => {
      return {
        ...state,
        comoOcurrioSelected: action.payload.comoOcurrioSelected,
      };
    },
    [setDaniosVehiculo]: (state, action) => {
      return {
        ...state,
        daniosVehiculoSelected: action.payload.daniosVehiculoSelected,
      };
    },
    [setNombreConductor]: (state, action) => {
      return {
        ...state,
        nombreConductorSelected: action.payload.nombreConductorSelected,
      };
    },
    [setApellidoConductor]: (state, action) => {
      return {
        ...state,
        apellidoConductorSelected: action.payload.apellidoConductorSelected,
      };
    },
    [setNumeroDocumentoConductor]: (state, action) => {
      return {
        ...state,
        numeroDocumentoConductorSelected:
          action.payload.numeroDocumentoConductorSelected,
      };
    },
    [setDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: action.payload.dominioSelected,
      };
    },
    [setModeloAnio]: (state, action) => {
      return {
        ...state,
        modeloAnioSelected: action.payload.modeloAnioSelected,
      };
    },
    [setMarca]: (state, action) => {
      return {
        ...state,
        marcaSelected: action.payload.marcaSelected,
      };
    },
    [setModelo]: (state, action) => {
      return {
        ...state,
        modeloSelected: action.payload.modeloSelected,
      };
    },
    [setNumeroDocumentoAsegurado]: (state, action) => {
      return {
        ...state,
        numeroDocumentoAseguradoSelected:
          action.payload.numeroDocumentoAseguradoSelected,
      };
    },
    [setTerceros]: (state, action) => {
      return {
        ...state,
        terceros: action.payload.terceros,
      };
    },
    [setNombreAsegurado]: (state, action) => {
      return {
        ...state,
        nombreAseguradoSelected: action.payload.nombreAseguradoSelected,
      };
    },
    [setMail]: (state, action) => {
      return {
        ...state,
        mailSelected: action.payload.mailSelected,
      };
    },
    [setTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: action.payload.telefonoSelected,
      };
    },
    [setDocumentosFileName]: (state, action) => {
      return {
        ...state,
        documentosSelected: action.payload.documentosFileNameSelected,
      };
    },
    [setIsProcessingDocumentos]: (state, action) => {
      return {
        ...state,
        isProcessingDocumentos: action.payload.isProcessingDocumentos,
      };
    },
    [setSubmitting]: (state, action) => {
      return {
        ...state,
        submitting: action.payload.submitting,
      };
    },
    [postSiniestroInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        creatingSiniestro: true,
      };
    },
    [postSiniestroCompleted]: (state, action) => {
      return {
        ...state,
        siniestroCreado: true,
        isLoading: false,
        creatingSiniestro: false,
      };
    },
    [postSiniestroFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        creatingSiniestro: false,
      };
    },
    [getPolizasInited]: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getPolizasCompleted]: (state, action) => {
      let polizasFilter = action.payload.polizas.map((poliza) => ({
        label: `${poliza.marca} ${poliza.modelo} ${poliza.dominio} ${poliza.estado}`,
        value: poliza.id,
      }));

      return {
        ...state,
        polizasFilter: polizasFilter,
        isLoading: false,
        failed: false,
        reloadPolizas: false,
      };
    },
    [getPolizasFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
        reloadPolizas: false,
      };
    },
    [getPolizaInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getPolizaCompleted]: (state, action) => {
      return {
        ...state,
        polizaVinculada: action.payload.polizaVinculada,
        isLoading: false,
        failed: false,
        nombreAseguradoSelected: {
          value: action.payload.polizaVinculada.asegurado.nombreCompleto,
          valid: true,
          focus: false,
        },
        idPolizaSelected: {
          value: action.payload.polizaVinculada.id,
          valid: true,
          focus: false,
        },
        sucursalSelected: {
          value: action.payload.polizaVinculada.sucursalNombre,
          valid: true,
          focus: false,
        },
        oficinaIdSelected: {
          value: action.payload.polizaVinculada.oficinaId,
          valid: true,
          focus: false,
        },
        numeroDocumentoAseguradoSelected: {
          value: action.payload.polizaVinculada.asegurado.numeroDocumento,
          valid: true,
          focus: false,
        },
        mailSelected: {
          value: action.payload.polizaVinculada.asegurado.email
            ? action.payload.polizaVinculada.asegurado.email
            : "",
          valid: true,
          focus: false,
        },
        telefonoSelected: {
          value: action.payload.polizaVinculada.asegurado.telefonoCelular
            ? action.payload.polizaVinculada.asegurado.telefonoCelular
            : action.payload.polizaVinculada.asegurado.telefonoFijo
              ? action.payload.polizaVinculada.asegurado.telefonoFijo
              : "",
          valid: true,
          focus: false,
        },
        dominioSelected: {
          value: action.payload.polizaVinculada.datosVehiculo.dominio,
          valid: true,
          focus: false,
        },
        modeloAnioSelected: {
          value: action.payload.polizaVinculada.datosVehiculo.anio,
          valid: true,
          focus: false,
        },
        marcaSelected: {
          value: action.payload.polizaVinculada.datosVehiculo.marca,
          valid: true,
          focus: false,
        },
        modeloSelected: {
          value: action.payload.polizaVinculada.datosVehiculo.modelo,
          valid: true,
          focus: false,
        },
        numeroPolizaSelected: {
          value: action.payload.polizaVinculada.datosPlan.numeroPoliza,
          valid: true,
          focus: false,
        },
        companiaSelected: {
          value: action.payload.polizaVinculada.datosPlan.compania,
          valid: true,
          focus: false,
        },
      };
    },
    [getPolizaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [fetchLocalidadesInited]: (state) => {
      return {
        ...state,
      };
    },
    [fetchLocalidadesCompleted]: (state, action) => {
      return {
        ...state,
        localidades: action.payload.localidades,
        localidadSelected: {
          value: action.payload.localidades[0].label,
          valid: true,
          focus: false,
        },
      };
    },
    [fetchLocalidadesFailed]: (state) => {
      return {
        ...state,
      };
    },
    [fetchCodigosPostalesInited]: (state) => {
      return {
        ...state,
      };
    },
    [fetchCodigosPostalesCompleted]: (state, action) => {
      const codigosPostales = action.payload.codigosPostales.map((item) => ({
        value: item,
        label: item,
      }));
      return {
        ...state,
        codigosPostales: codigosPostales,
      };
    },
    [fetchCodigosPostalesFailed]: (state) => {
      return {
        ...state,
      };
    },
    [clearLocalidad]: (state, action) => {
      return {
        ...state,
        localidadSelected: {
          value: "",
          valid: false,
          focus: false,
        },
      };
    },
    [clearData]: (state, action) => {
      return {
        ...cargaSiniestroReducerInitialState,
      };
    },
  },
  cargaSiniestroReducerInitialState
);

export { cargaSiniestroReducer };

export {
  clearData,
  clearLocalidad,
  setSubmitting,
  postSiniestro,
  setTipo,
  setCompania,
  setNumeroSiniestroCompania,
  setNumeroPoliza,
  setCalle,
  setAltura,
  setHora,
  setFecha,
  setLocalidad,
  setProvincia,
  setCodigoPostal,
  setLesionesFisicas,
  setComoOcurrio,
  setDaniosVehiculo,
  setNombreConductor,
  setApellidoConductor,
  setNumeroDocumentoConductor,
  setDominio,
  setModeloAnio,
  setMarca,
  setModelo,
  setNumeroDocumentoAsegurado,
  setNombreAsegurado,
  setMail,
  setTelefono,
  setTerceros,
  setDocumentos,
  setDocumentosFileName,
  setIsProcessingDocumentos,
  getPolizas,
  getPoliza,
  fetchCodigosPostales,
  fetchLocalidades,
};
