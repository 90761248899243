import React, { useEffect, useState } from "react";
import { paths, esquemasPath } from "~constants/referenceNames";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import ListadoEsquemas from "../views/esquemas/ListadoEsquemas";

const EsquemaRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  const [esquemaId, setEsquemaId] = useState(null);

  useEffect(() => {
    const defaultPath = `${paths[esquemasPath]}/Prendarios`;
    const pathname = location.pathname.split("/");
    if (pathname[2]) {
      setEsquemaId(pathname[2]);
    } else {
      if (location.pathname !== defaultPath) {
        history.replace(defaultPath);
      }
    }
  }, [history, location.pathname]);

  return (
    <Switch>
      <Route path={paths[esquemasPath]}>
        <ListadoEsquemas esquemaId={esquemaId} />
      </Route>
    </Switch>
  );
};

export default EsquemaRoutes;
