export const siniestrosCompanias = [
  { value: "Afianzadora", label: "Afianzadora" },
  { value: "Agrosalta", label: "Agrosalta" },
  { value: "Alba", label: "Alba" },
  { value: "Allianz", label: "Allianz" },
  { value: "Antartida", label: "Antártida" },
  { value: "Anticipar", label: "Anticipar" },
  { value: "ArgosMutualTpp", label: "Argos Mutual Tpp" },
  { value: "ArgosPatrimoniales", label: "Argos Patrimoniales" },
  { value: "AseguradoraTotal", label: "Aseguradora Total" },
  { value: "AseguradoresDeCauciones", label: "Aseguradores De Cauciones" },
  { value: "AsociacionMutualDan", label: "Asociación Mutual Dan" },
  { value: "Asociart", label: "Asociart" },
  { value: "Assekuransa", label: "Assekuransa" },
  { value: "Assurant", label: "Assurant" },
  { value: "Asv", label: "Asv" },
  { value: "BbvaConsolidar", label: "Bbva Consolidar" },
  { value: "Beneficio", label: "Beneficio" },
  { value: "BerkleyArt", label: "Berkley Art" },
  { value: "Berkley", label: "Berkley" },
  { value: "BhnPatrimoniales", label: "Bhn Patrimoniales" },
  { value: "BhnVida", label: "Bhn Vida" },
  { value: "BinariaRetiro", label: "Binaria Retiro" },
  { value: "BinariaVida", label: "Binaria Vida" },
  { value: "Bonacorsi", label: "Bonacorsi" },
  { value: "Boston", label: "Boston" },
  { value: "Bradesco", label: "Bradesco" },
  {
    value: "CajaDePrevisionYSegMedico",
    label: "Caja De Previsión Y Seg.Medico",
  },
  { value: "CajaDeTucuman", label: "Caja De Tucumán" },
  { value: "Caja", label: "Caja" },
  { value: "Caledonia", label: "Caledonia" },
  { value: "CaminosProtegidosPat", label: "Caminos Protegidos Pat." },
  { value: "Cardif", label: "Cardif" },
  { value: "Caruso", label: "Caruso" },
  { value: "Certeza", label: "Certeza" },
  { value: "Cesce", label: "Cesce" },
  { value: "Chubb", label: "Chubb" },
  { value: "Cnp", label: "Cnp" },
  { value: "Coface", label: "Coface" },
  { value: "Colon", label: "Colon" },
  { value: "Comarseg", label: "Comarseg" },
  { value: "Confiar", label: "Confiar" },
  { value: "Confluencia", label: "Confluencia" },
  { value: "CooperacionMutualPatronal", label: "Cooperacion Mutual Patronal" },
  { value: "Copan", label: "Copan" },
  { value: "Cosena", label: "Cosena" },
  { value: "CredicoopRetiro", label: "Credicoop Retiro" },
  { value: "CreditoYCaucion", label: "Credito Y Caucion" },
  { value: "CreditosYGarantias", label: "Créditos Y Garantías" },
  { value: "CruzSuiza", label: "Cruz Suiza" },
  { value: "Digna", label: "Digna" },
  { value: "Dulce", label: "Dulce" },
  { value: "Edificar", label: "Edificar" },
  { value: "EquitativaDelPlata", label: "Equitativa Del Plata" },
  { value: "Escudo", label: "Escudo" },
  { value: "EstrellaRetiro", label: "Estrella Retiro" },
  { value: "Euroamerica", label: "Euroamérica" },
  { value: "Evolucion", label: "Evolución" },
  { value: "ExpertaArt", label: "Experta Art" },
  { value: "ExpertaRetiro", label: "Experta Retiro" },
  { value: "FederacionPatronalPat", label: "Federacion Patronal Pat." },
  { value: "FederacionPatronalRetiro", label: "Federacion Patronal Retiro" },
  { value: "Federada", label: "Federada" },
  { value: "FianzasYCredito", label: "Fianzas Y Crédito" },
  { value: "Finisterre", label: "Finisterre" },
  { value: "Foms", label: "Foms" },
  { value: "GalenoArt", label: "Galeno Art" },
  { value: "GalenoLife", label: "Galeno Life" },
  { value: "GaliciaPatrimoniales", label: "Galicia Patrimoniales" },
  { value: "GaliciaRetiro", label: "Galicia Retiro" },
  { value: "GarantiaMutualTpp", label: "Garantía Mutual Tpp" },
  { value: "Gestion", label: "Gestión" },
  { value: "Hamburgo", label: "Hamburgo" },
  { value: "Hanseatica", label: "Hanseatica" },
  { value: "Hdi", label: "Hdi" },
  { value: "HolandoSudamericana", label: "Holando Sudamericana" },
  { value: "Horizonte", label: "Horizonte" },
  { value: "HsbcRetiro", label: "Hsbc Retiro" },
  { value: "HsbcVida", label: "Hsbc Vida" },
  {
    value: "IamInstAseguradorMercantil",
    label: "Iam – Inst. Asegurador Mercantil",
  },
  { value: "InstitutoDeEntreRiosPat", label: "Instituto De Entre Ríos Pat." },
  {
    value: "InstitutoDeEntreRiosRetiro",
    label: "Instituto De Entre Ríos Retiro",
  },
  { value: "InstitutoDeJujuy", label: "Instituto De Jujuy" },
  { value: "InstitutoDeSalta", label: "Instituto De Salta" },
  { value: "InstitutoDeSeguros", label: "Instituto De Seguros" },
  { value: "Insur", label: "Insur" },
  { value: "Integrity", label: "Intégrity" },
  { value: "Internacional", label: "Internacional" },
  { value: "IunigoArgentina", label: "Iúnigo Argentina" },
  { value: "JuncalPatrimoniales", label: "Juncal Patrimoniales" },
  { value: "LatinAmerica", label: "Latín America" },
  { value: "LatitudSur", label: "Latitud Sur" },
  { value: "Libra", label: "Libra" },
  { value: "LiderMotos", label: "Lider Motos" },
  { value: "Liderar", label: "Liderar" },
  { value: "LiderarArt", label: "Liderar Art" },
  { value: "LuzYFuerza", label: "Luz Y Fuerza" },
  { value: "Mañana", label: "Mañana" },
  { value: "MapfrePatrimoniales", label: "Mapfre Patrimoniales" },
  { value: "MapfreVida", label: "Mapfre Vida" },
  { value: "MercantilAndina", label: "Mercantil Andina" },
  { value: "MercantilAseguradora", label: "Mercantil Aseguradora" },
  { value: "Meridional", label: "Meridional" },
  { value: "MetlifePatrimoniales", label: "Metlife Patrimoniales" },
  { value: "MetlifeRetiro", label: "Metlife Retiro" },
  { value: "MetropolMutuaTpp", label: "Metropol Mutua Tpp" },
  { value: "MetropolPatrimoniales", label: "Metropol Patrimoniales" },
  { value: "MutualPetrolerosArt", label: "Mutual Petroleros Art" },
  { value: "NacionPatrimoniales", label: "Nación Patrimoniales" },
  { value: "NacionRetiro", label: "Nación Retiro" },
  { value: "Nativa", label: "Nativa" },
  { value: "Nivel", label: "Nivel" },
  { value: "Noble", label: "Noble" },
  { value: "Norte", label: "Norte" },
  { value: "Nsa", label: "Nsa" },
  { value: "Nueva", label: "Nueva" },
  { value: "Omint", label: "Omint" },
  { value: "OmintArt", label: "Omint Art" },
  { value: "Opcion", label: "Opción" },
  { value: "Orbis", label: "Orbis" },
  { value: "Origenes", label: "Origenes" },
  { value: "OrigenesRetiro", label: "Orígenes Retiro" },
  { value: "Pacifico", label: "Pacífico" },
  { value: "Parana", label: "Paraná" },
  { value: "Perseverancia", label: "Perseverancia" },
  { value: "PeugeotCitroën", label: "Peugeot Citroën" },
  { value: "Pieve", label: "Pieve" },
  { value: "Plenaria", label: "Plenaria" },
  { value: "PorVida", label: "Por Vida" },
  { value: "PrevencionArt", label: "Prevención Art" },
  { value: "Previnca", label: "Previnca" },
  { value: "Previsora", label: "Previsora" },
  { value: "ProductoresDeFrutas", label: "Productores De Frutas" },
  { value: "Progreso", label: "Progreso" },
  { value: "ProteccionMutualTpp", label: "Protección Mutual Tpp" },
  { value: "Providencia", label: "Providencia" },
  { value: "ProvinciaArt", label: "Provincia Art" },
  { value: "ProvinciaPatrimoniales", label: "Provincia Patrimoniales" },
  { value: "ProvinciaVida", label: "Provincia Vida" },
  { value: "ProyeccionRetiro", label: "Proyección Retiro" },
  { value: "Prudencia", label: "Prudencia" },
  { value: "Prudential", label: "Prudential" },
  { value: "QbeLaBuenosAires", label: "Qbe La Buenos Aires" },
  { value: "Qualia", label: "Qualia" },
  { value: "ReconquistaArt", label: "Reconquista Art" },
  { value: "RUS", label: "Río Uruguay" },
  { value: "RivadaviaMutual", label: "Rivadavia Mutual" },
  { value: "RivadaviaPatrimoniales", label: "Rivadavia Patrimoniales" },
  { value: "SanCristobalPatrimoniales", label: "San Cristóbal Patrimoniales" },
  { value: "SanCristobalRetiro", label: "San Cristóbal Retiro" },
  { value: "SanGerman", label: "San German" },
  { value: "SanMarino", label: "San Marino" },
  { value: "SanPatricio", label: "San Patricio" },
  { value: "Sancor", label: "Sancor" },
  { value: "SantaLucia", label: "Santa Lucía" },
  { value: "SantisimaTrinidad", label: "Santísima Trinidad" },
  { value: "SegundaArt", label: "Segunda Art" },
  { value: "SegundaPatrimoniales", label: "Segunda Patrimoniales" },
  { value: "SegundaPersonas", label: "Segunda Personas" },
  { value: "SegundaRetiro", label: "Segunda Retiro" },
  { value: "Segurcoop", label: "Segurcoop" },
  { value: "Segurometal", label: "Segurometal" },
  { value: "SegurosMedicos", label: "Seguros Médicos" },
  { value: "Sentir", label: "Sentir" },
  { value: "SmgPatrimoniales", label: "Smg Patrimoniales" },
  { value: "SmgRetiro", label: "Smg Retiro" },
  { value: "SmgVida", label: "Smg Vida" },
  { value: "Smsv", label: "Smsv" },
  { value: "SolNaciente", label: "Sol Naciente" },
  { value: "Starr", label: "Starr" },
  { value: "Sumicli", label: "Sumicli" },
  { value: "Supervielle", label: "Supervielle" },
  { value: "Sura", label: "Sura" },
  { value: "Surco", label: "Surco" },
  { value: "SwissMedicalArt", label: "Swiss Medical Art" },
  { value: "TerritorialVida", label: "Territorial Vida" },
  { value: "Testimonio", label: "Testimonio" },
  { value: "Tpc", label: "Tpc" },
  { value: "TrayectoriaSeguros", label: "Trayectoria Seguros" },
  { value: "TresProvincias", label: "Tres Provincias" },
  { value: "Triunfo", label: "Triunfo" },
  { value: "Tutelar", label: "Tutelar" },
  { value: "Victoria", label: "Victoria" },
  { value: "Virginia", label: "Virginia" },
  { value: "Warranty", label: "Warranty" },
  { value: "XlInsurance", label: "Xl Insurance" },
  { value: "ZurichInternationalLife", label: "Zurich International Life" },
  { value: "Zurich", label: "Zurich" },
  { value: "ZurichRetiro", label: "Zurich Retiro" },
  { value: "ZurichSantander", label: "Zurich Santander" },
];
