import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import ItemSearchableInputCard from "~business/Cards/ItemSearchableInputCard";

import { getPolizas, getPoliza } from "../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    "@media (max-width:1024px)": {
      width: "90%",
      
    },
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    margin: "2%",
    width: "100%",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    polizas: state.cargaSiniestroPublicaReducer.polizasFilter,
  }));

const BusquedaCard = ({ isLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [busqueda, setBusqueda] = useState({
    value: "",
    valid: false,
    focus: false,
  });

  const { polizas } = useReduxSelector();

  let timer = null;

  const handleChangeBusqueda = (busqueda) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setBusqueda(busqueda);
    }, 200);
  };

  const handleClickBusqueda = () => {
    dispatch(
      getPolizas({
        skip: 0,
        take: 10,
        fechaDesde: null,
        fechaHasta: null,
        searchCriteria: busqueda.value,
        estado: "Vigente",
        negocio: "",
        soloParaRenovar: false,
        compania: "",
      })
    );
  };

  const handleChangePoliza = (poliza) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getPoliza(poliza.value));
    }, 200);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.card}>
            <ItemSearchableInputCard
              id={"busquedaPoliza"}
              isLoading={isLoading}
              label={"Búsqueda de Póliza"}
              value={busqueda.value}
              onChange={handleChangeBusqueda}
              onChangeItem={handleChangePoliza}
              onClick={handleClickBusqueda}
              tooltipContent={"Buscar Póliza"}
              placeholder={"Buscar por DNI o Dominio"}
              items={polizas}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusquedaCard;
