import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import pxToRem from "~libs/pxToRem";
import fileService from "~libs/fileService";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    fontSize: pxToRem(16),
    marginBottom: 0,
    border: "1px dashed hsl(0, 0%, 74%)",
    borderRadius: 5,
    padding: 10,
    "& .filepond--file-action-button": {
      cursor: "pointer",
    },
    "& .filepond--drop-label": {
      color: "hsl(0, 0%, 74%)",
    },
    "& .filepond--root .filepond--drop-label": { minHeight: "3em" },
    "& .filepond--drop-label label": { fontSize: pxToRem(16) },
    "& .filepond--drop-label.filepond--drop-label label": {
      padding: "0.5em 0",
    },
    "& .filepond--panel-root": { backgroundColor: "transparent" },
    "& .filepond--image-preview-overlay-idle": {
      color: "rgba(40, 40, 40, 0.85)",
    },
    "& .filepond--image-preview-overlay-success": {
      color: "hsl(189, 55%, 47%)",
    },
    "& .filepond--image-preview-overlay-failure": {
      color: "rgb(196, 78, 71)",
    },
    "@media (min-width: 650px)": {
      "& .filepond--item": {
        width: "calc(50% - 0.5em)",
      },
    },
  },
}));

const tempPath = "temp";
const fileServiceTemp = fileService(tempPath);

const MultipleFileUpload = ({
  multiple,
  acceptPdf = true,
  acceptImage = false,
  onAdded,
  onRemoved,
  onProcessStart,
  acceptedMimeTypes = [],
  cargaPublica,
}) => {
  const [uploadedFileName, setUploadedFileName] = useState([]);
  const [uploadedFileNameRemoved, setUploadedFileNameRemoved] = useState(null);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const mobileIdleLabel = acceptPdf
    ? 'Tocá <span class="filepond--label-action">acá</span> para seleccionar un archivo PDF'
    : acceptImage
    ? 'Tocá <span class="filepond--label-action">acá</span> para sacar una foto'
    : 'Tocá <span class="filepond--label-action">acá</span> para seleccionar un archivo';
  const desktopIdleLabel = acceptPdf
    ? 'Arrastrá o <span class="filepond--label-action">Seleccioná</span> archivos PDF'
    : acceptImage
    ? 'Arrastrá o <span class="filepond--label-action">Seleccioná</span> documentos'
    : 'Arrastrá o <span class="filepond--label-action">Seleccioná</span> archivos';

  let fileTypes = acceptImage
    ? ["image/*"]
    : acceptPdf
    ? ["application/pdf"]
    : acceptedMimeTypes;

  return (
    <FilePond
      credits={false}
      acceptedFileTypes={fileTypes.length > 0 ? fileTypes.join(",") : null}
      allowMultiple={multiple}
      imagePreviewHeight={115}
      labelIdle={isMobile ? mobileIdleLabel : desktopIdleLabel}
      labelFileProcessing={"Cargando..."}
      labelFileProcessingComplete={"Carga completa"}
      labelFileProcessingError={"Error procesando"}
      labelTapToUndo={"Tap para deshacer"}
      labelTapToCancel={"Tap para cancelar"}
      labelTapToRetry={"Tap para reintentar"}
      labelFileTypeNotAllowed={"Tipo de archivo no permitido"}
      className={classes.root}
      server={{
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort
        ) => {
          try {
            if (!cargaPublica) {
              const uploadedFileName = await fileServiceTemp.upload(
                file,
                load,
                progress
              );
              setUploadedFileName((prevState) => [
                ...prevState,
                uploadedFileName,
              ]);
            } else {
              const uploadedFileName = await fileServiceTemp.uploadPublico(
                file,
                load,
                progress
              );
              setUploadedFileName((prevState) => [
                ...prevState,
                uploadedFileName,
              ]);
            }
          } catch (e) {
            console.error(e);
            error(e.message);
          }
        },
        restore: async (serverId, load, error, progress, abort, headers) => {
          load(serverId);
          await fileServiceTemp.upload(serverId, load, progress);
        },
        revert: async (serverId, load, error) => {
          try {
            load();
            setUploadedFileNameRemoved(serverId);
            setUploadedFileName(
              uploadedFileName.filter((doc) => doc !== serverId)
            );
            if (!cargaPublica) await fileServiceTemp.delete(serverId);
          } catch (e) {
            console.error(e);
            error(e);
          }
        },
      }}
      onaddfilestart={(file) => {
        if (onProcessStart) onProcessStart(true);
      }}
      onprocessfiles={(error, file) => {
        if (onProcessStart) onProcessStart(false);
        if (!error && onAdded) {
          onAdded({
            target: {
              value: uploadedFileName,
            },
          });
        }
      }}
      onerror={(error) => {
        if (error) onProcessStart(false);
      }}
      onremovefile={(error, file) => {
        if (!error && onRemoved) {
          onRemoved({
            target: {
              value: uploadedFileNameRemoved,
            },
          });
        }
      }}
    />
  );
};

export default MultipleFileUpload;
