import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CBUStep from "./CBUStep";
import TarjetaCreditoStep from "./TarjetaCreditoStep";
import { clearValidadorDatosPago } from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    formaDePagoSelected: state.multicotizadorReducer.formaDePagoSelected,
  }));

const DatosPagoStep = () => {
  const { formaDePagoSelected } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
  }, [dispatch]);

  if (formaDePagoSelected.value === "DebitoConCBU") return <CBUStep />;
  if (formaDePagoSelected.value === "DebitoConTarjetaCredito")
    return <TarjetaCreditoStep />;

  return <div></div>;
};

export default DatosPagoStep;
