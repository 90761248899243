import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setVehiculoMarca, clearVehiculoMarca } from "../actions";
import makeStyles from "@material-ui/styles/makeStyles";
import VehiculoMarcaSelector from "../../../business/vehiculoMarcaSelector/VehiculoMarcaSelector";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    marcaSelected: state.multicotizadorReducer.marcaSelected,
  }));

const MarcaStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { marcaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(clearVehiculoMarca());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(setVehiculoMarca(event.target.value));
  };

  return (
    <MulticotizadorContent>
      <div className={classes.title}>
        <PrimaryTitle
          label="Ingresá la marca del vehículo del asegurado"
          marginBottom={32}
        />
      </div>
      <VehiculoMarcaSelector value={marcaSelected} onChange={handleChange} />
    </MulticotizadorContent>
  );
});

export default MarcaStep;
