import { createAction, handleActions } from "redux-actions";
import { calculateDate } from "~libs/dateCalculator";

const setInitialStateFiltroMultirama = createAction(
  "SET_POLIZAS_FILTROS_INITIAL_STATE_POLIZAS_MULTI"
);

const setPage = createAction(
  "SET_LISTADO_POLIZAS_PAGE_POLIZAS_MULTI",
  (page) => ({
    page,
  })
);

const setTake = createAction(
  "SET_LISTADO_POLIZAS_ROWS_PER_PAGE_POLIZAS_MULTI",
  (take) => ({
    take,
  })
);

const selectFechaVigenciaDesde = createAction(
  "SELECT_FECHA_DESDE_FILTRO_POLIZAS_MULTI",
  (fechaDesde) => ({ fechaDesde })
);

const selectFechaVigenciaHasta = createAction(
  "SELECT_FECHA_HASTA_FILTRO_POLIZAS_MULTI",
  (fechaHasta) => ({ fechaHasta })
);

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_POLIZAS_MULTI",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const selectRama = createAction("SELECT_RAMA_POLIZAS_MULTI", (rama) => ({
  rama,
}));

const selectCompania = createAction(
  "SELECT_NEGOCIO_POLIZAS_MULTI",
  (compania) => ({
    compania,
  })
);

const selectEstado = createAction("SELECT_ESTADO_POLIZAS_MULTI", (estado) => ({
  estado,
}));

const clearData = createAction("CLEAR_DATA_FILTRO_POLIZAS_MULTI");

const listadoPolizasMultiramaFiltroInitialState = {
  take: 10,
  page: 0,
  searchCriteria: "",
  fechaVencimientoDesde: calculateDate(new Date(), -30),
  fechaVencimientoHasta: new Date(),
  rama: "",
  compania: "",
  estado: "",
};

const listadoPolizaMultiramaFiltroReducer = handleActions(
  {
    [setInitialStateFiltroMultirama]: (state, action) => {
      return {
        ...listadoPolizasMultiramaFiltroInitialState,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        take: action.payload.take,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [selectCompania]: (state, action) => {
      return {
        ...state,
        compania: action.payload.compania,
      };
    },
    [selectRama]: (state, action) => {
      return {
        ...state,
        rama: action.payload.rama,
      };
    },
    [selectEstado]: (state, action) => {
      return {
        ...state,
        estado: action.payload.estado,
      };
    },
    [clearData]: (state, action) => {
      return {
        ...listadoPolizasMultiramaFiltroInitialState,
      };
    },
  },
  listadoPolizasMultiramaFiltroInitialState
);

export { listadoPolizaMultiramaFiltroReducer };
export {
  setTake,
  setPage,
  selectSearchCriteria,
  selectFechaVigenciaHasta,
  selectFechaVigenciaDesde,
  setInitialStateFiltroMultirama,
  selectRama,
  selectCompania,
  selectEstado,
  clearData,
};
