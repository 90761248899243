const getMGUser = (auth0User) => {
  if (!auth0User) return null;

  const userInfo = auth0User["http://mg-group.com.ar/userInfo"];

  const isAuthorized =
    userInfo.seguros &&
    userInfo.seguros.roles &&
    userInfo.seguros.roles.length > 0 &&
    userInfo.seguros.permissions &&
    userInfo.seguros.permissions.length > 0;
  return {
    sub: auth0User.sub,
    email: userInfo.email,
    preferred_username: userInfo.name,
    permissions: userInfo.roles,
    seguros: userInfo.seguros,
    isAuthorized: isAuthorized,
  };
};

export default getMGUser;
