import { createTheme } from "@material-ui/core/styles";

const purple1 = "#663ea8"; /* "hsl(263, 46%, 45%)"; */
const purple2 = "#6240a5";
const purple3 = "#503181";
const purple4 = "#e9ebf6";

const verdeMgBroker = "#4aba7c";
const blue1 = "#45459c";
const blue2 = "#45459c";
const white = "#fff";

const theme = createTheme({
  colours: {
    white: "#FFFFFF",
    verdeMgBroker: "#4aba7c",
    violeta: "#45459c",
    grey2: "#bfbfbf",
    grey5: "#5b5d60",
    grey600: "#757575",
    grey900: "#212121",
    brownGrey: "#8e8e8e",
    brownGrey2: "#a7a7a7",
    lightIndigo: "#6868bf",
    orange: "#feb236",
  },
  palette: {
    primary: {
      light: purple1,
      main: purple2,
      dark: purple3,
      contrastText: purple4,
    },
    secondary: {
      light: verdeMgBroker,
      main: verdeMgBroker,
      dark: verdeMgBroker,
      contrastText: white,
    },
  },
  typography: {
    fontFamily: '"Nunito", Helvetica, Arial, sans-serif',
  },
  textField: {
    borderColor: blue1,
    floatingLabelShrinkColor: blue2,
  },
  breakpoints: {
    mobile: "@media (max-width: 1023px)",
    desktop: "@media (min-width: 1024px)",
  },
});

export default theme;
