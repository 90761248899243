const TODOS = "";
export const ANULACION = "Anulacion";
export const EMISION = "Emision";
export const SINIESTRO = "Siniestro";
export const MODIFICACION = "Modificacion";
export const RENOVACION = "Renovacion";
export const INDETERMINADO = "Indeterminado";

const tipoNovedadSegurosOptions = {
  [TODOS]: "Todos",
  [ANULACION]: "Anulacion",
  [EMISION]: "Emision",
  [SINIESTRO]: "Siniestro",
  [MODIFICACION]: "Modificacion",
  [RENOVACION]: "Renovacion",
  [INDETERMINADO]: "Indeterminado"
};

const tiposNovedadesFilter = [
  { label: tipoNovedadSegurosOptions[TODOS], value: TODOS },
  { label: tipoNovedadSegurosOptions[ANULACION], value: ANULACION },
  { label: tipoNovedadSegurosOptions[EMISION], value: EMISION },
  { label: tipoNovedadSegurosOptions[SINIESTRO], value: SINIESTRO },
  { label: tipoNovedadSegurosOptions[MODIFICACION], value: MODIFICACION },
  { label: tipoNovedadSegurosOptions[RENOVACION], value: RENOVACION },
  { label: tipoNovedadSegurosOptions[INDETERMINADO], value: INDETERMINADO }
];

export { tipoNovedadSegurosOptions, tiposNovedadesFilter };
