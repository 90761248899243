import { useEffect } from "react";
import { selectTokenPermissions, selectRoles } from "./actions";
import { useDispatch } from "react-redux";
import getMGUser from "~libs/getMGUser";
import jwt_decode from "jwt-decode";
import localRepository from "../../../localRepository";
import { useAuth0 } from "../../Auth0/useAuth";

const useGetPermissions = () => {
  const dispatch = useDispatch();

  let { authState, renewSession } = useAuth0();

  const token = localRepository.accessToken.get();

  useEffect(() => {
    const auth0TokenData = jwt_decode(token);

    const user = getMGUser(auth0TokenData);

    if (user.seguros) {
      dispatch(selectRoles(user.seguros.roles));
      dispatch(selectTokenPermissions(user.seguros.permissions));
    } else renewSession();

    //eslint-disable-next-line
  }, [dispatch, authState, token]);
};

export default useGetPermissions;
