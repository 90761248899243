import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import { condicionesFiscalesOptions } from "~constants/condicionesFiscales";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import ColorLabelProductor from "~styled/colorLabelProductor/ColorLabelProductor";
import DetalleSolicitudCardItem from "../DetalleSolicitudCardItem";
import NumberFormat from "react-number-format";
import ColorLabelPas from "~styled/colorLabelPas/ColorLabelPas";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },
  negocioContainer: {
    display: "flex",
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
    isLoading: state.detalleSolicitudHistoricaReducer.isLoading,
    loadingError: state.detalleSolicitudHistoricaReducer.loadingError,
    errorMessage: state.detalleSolicitudHistoricaReducer.errorMessage,
  }));

const AseguradoCard = () => {
  const classes = useStyles();
  const { detalleSolicitudEmision } = useReduxSelector();
  return (
    <div>
      {detalleSolicitudEmision.datosCliente && (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <PersonIcon className={classes.cardIcon} />
            <Typography className={classes.cardTitle}>{"Asegurado"}</Typography>
          </div>
          <DetalleSolicitudCardItem
            caption="Negocio"
            value={
              <span className={classes.negocioContainer}>
                <ColorLabelPas solicitudEmision={detalleSolicitudEmision} />
                <ColorLabelNegocio negocio={detalleSolicitudEmision.negocio} />
                <ColorLabelProductor
                  productor={detalleSolicitudEmision.productor}
                />
              </span>
            }
          />
          <DetalleSolicitudCardItem
            caption="Nombre"
            value={
              detalleSolicitudEmision.datosCliente.nombre !== null ? (
                detalleSolicitudEmision.datosCliente.nombre
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />

          <DetalleSolicitudCardItem
            caption="Email"
            value={
              detalleSolicitudEmision.datosCliente.email !== null ? (
                detalleSolicitudEmision.datosCliente.email
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Teléfono"
            value={
              detalleSolicitudEmision.datosCliente.telefono !== null ? (
                detalleSolicitudEmision.datosCliente.telefono
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="DNI"
            value={
              detalleSolicitudEmision.datosCliente.dni !== null ? (
                <NumberFormat
                  value={detalleSolicitudEmision.datosCliente.dni}
                  displayType={"text"}
                />
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="CUIL"
            value={detalleSolicitudEmision.datosCliente.cuil}
          />
          {detalleSolicitudEmision.datosVehiculo.uso === "Comercial" && (
            <DetalleSolicitudCardItem
              caption="Condición Fiscal"
              value={
                detalleSolicitudEmision.datosCliente.condicionFiscal ? (
                  condicionesFiscalesOptions[
                    detalleSolicitudEmision.datosCliente.condicionFiscal
                  ]
                ) : (
                  <span className={classes.dash}>&mdash;</span>
                )
              }
            />
          )}
          <DetalleSolicitudCardItem
            caption="Fecha de Nacimiento"
            value={moment(detalleSolicitudEmision.datosCliente.fechaNacimiento)
              .utc()
              .format("DD/MM/YYYY")}
          />
          <DetalleSolicitudCardItem
            caption="Dirección"
            value={
              detalleSolicitudEmision.datosCliente.direccion !== null ? (
                detalleSolicitudEmision.datosCliente.direccion.toUpperCase()
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />

          <DetalleSolicitudCardItem
            caption="Certificado RC"
            value={
              detalleSolicitudEmision.certificadoRCUrl !== null ? (
                <a
                  href={detalleSolicitudEmision.certificadoRCUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver Certificado
                </a>
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default AseguradoCard;
