import React, { useEffect, useState } from "react";
import { getSolicitudesEmision } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import ListadoSolicitudesPendientesSucursal from "./ListadoSolicitudesPendientesSucursal";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { solicitudesPendientesSucursalPath } from "~constants/referenceNames";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import { useHistory, useLocation } from "react-router-dom";
import {
  setTake,
  setPage,
  selectSearchCriteria,
  selectFechaDesde,
  selectFechaHasta,
  selectEstado,
} from "./Filtros/actions";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.solicitudesPendientesSucursalFiltrosReducer.take,
    page: state.solicitudesPendientesSucursalFiltrosReducer.page,
    fechaDesde: state.solicitudesPendientesSucursalFiltrosReducer.fechaDesde,
    fechaHasta: state.solicitudesPendientesSucursalFiltrosReducer.fechaHasta,
    searchCriteria:
      state.solicitudesPendientesSucursalFiltrosReducer.searchCriteria,
    estado: state.solicitudesPendientesSucursalFiltrosReducer.estado,
  }));

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  {
    name: "fechaDesde",
    nameUrl: "Desde",
    action: selectFechaDesde,
    isDate: true,
  },
  {
    name: "fechaHasta",
    nameUrl: "Hasta",
    action: selectFechaHasta,
    isDate: true,
  },
  {
    name: "estado",
    nameUrl: "Estado",
    action: selectEstado,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const SolicitudesPendientesSucursalContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentState = useReduxSelector();

  const {
    take,
    page,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    estado,
  } = currentState;

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: solicitudesPendientesSucursalPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [dispatch, take, page, fechaDesde, fechaHasta, searchCriteria, estado]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(
        getSolicitudesEmision(
          page * take,
          take,
          searchCriteria,
          fechaDesde,
          fechaHasta,
          estado
        )
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url]);

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Solicitudes Pendientes"}
      selectedDefault={solicitudesPendientesSucursalPath}
    >
      <ListadoSolicitudesPendientesSucursal />
    </SectionLayout>
  );
};

export default SolicitudesPendientesSucursalContainer;
