import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import { tipoSiniestroFilter } from "~constants/tipoSiniestro";

import {
  setTipo,
  setNumeroPoliza,
  setCompania,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    tipo: state.cargaSiniestroPublicaReducer.tipoSelected,
    compania: state.cargaSiniestroPublicaReducer.companiaSelected,
    numeroPoliza: state.cargaSiniestroPublicaReducer.numeroPolizaSelected,
    poliza: state.cargaSiniestroPublicaReducer.polizaVinculada,
  }));

const title = "Datos del Siniestro";

const DatosSiniestroCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const { tipo, numeroPoliza, compania, poliza } = useReduxSelector();

  let timer = null;

  const handleChangeCompania = (compania) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCompania(compania));
    }, 200);
  };

  const handleChangeNumeroPoliza = (numeroPoliza) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroPoliza(numeroPoliza));
    }, 200);
  };

  const handleChangeTipo = (tipo) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setTipo(tipo));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemSelectorCard
          id={"tipo"}
          isLoading={isLoading}
          label={"Tipo de Siniestro"}
          value={tipo.value}
          type="selector"
          variant="big"
          onChange={handleChangeTipo}
          options={tipoSiniestroFilter}
          required
        />
        {!poliza && (
          <>
            <ItemInputCard
              id={"compania"}
              isLoading={isLoading}
              label={"Compañía"}
              value={compania.value}
              onChange={handleChangeCompania}
              onBlur={handleBlur}
              focus={compania.focus}
              required
            />

            <ItemInputCard
              id={"numeroPoliza"}
              isLoading={isLoading}
              label={"Número de Póliza"}
              value={numeroPoliza.value}
              onChange={handleChangeNumeroPoliza}
              onBlur={handleBlur}
              focus={numeroPoliza.focus}
              required
            />
          </>
        )}
        {poliza && (
          <>
            <ItemInputCard
              id={"compania"}
              isLoading={isLoading}
              label={"Compañía"}
              value={compania.value}
              readOnly
            />
            <ItemInputCard
              id={"numeroPoliza"}
              isLoading={isLoading}
              label={"Número de Póliza"}
              value={numeroPoliza.value}
              readOnly
            />
          </>
        )}
      </DetalleCard>
    </>
  );
};

export default DatosSiniestroCard;
