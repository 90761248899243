import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loadingRow: {
    display: "flex",
    paddingBottom: "8px",
    minWidth: "50%",
    minHeight: "45px",
    alignContent: "center",
    alignItems: "flex-end",
    justifyContent: "center",
  },
}));

const TableLoading = ({ columns = 5, length }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Array.apply(null, Array(length)).map((_, index) => (
        <div key={`table-cotizaciones-loading-divider-${index}`}>
          <div
            className={classes.loadingRow}
            key={"table-cotizaciones-loading-" + index}
          >
            {Array.apply(null, { length: columns }).map((_, col) => (
              <LoadingItem
                key={`table-cotizaciones-loading-item-${index}-${col}`}
              />
            ))}
          </div>
          <Divider></Divider>
        </div>
      ))}
    </div>
  );
};

export default TableLoading;
const LoadingItem = ({ header }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ContentLoader
        style={{ width: "80%", height: "80%" }}
        height={30}
        width={300}
        speed={1}
        backgroundColor="#f7f8ff"
        foregroundColor="#d9e8df"
      >
        <rect x="35" y="10" rx="4" ry="4" width="250" height="20" />
      </ContentLoader>
    </div>
  );
};
