const tiposVehiculo = [
  "CAB",
  "COL",
  "CUP",
  "FUA",
  "FUB",
  "JEE",
  "LIV",
  "MBU",
  "MIV",
  "PB4",
  "PES",
  "PKA",
  "PKB",
  "RUR",
  "SED",
  "SPE",
  "VAN",
  "WA4",
  "WAG"
];

export default tiposVehiculo;