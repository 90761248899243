import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setValidadorDatosPago, clearValidadorDatosPago } from "../actions";
import isCBUValid from "~libs/CBUValidator";
import TextField from "@material-ui/core/TextField";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import CBUInput from "~styled/input/CBUInput";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const CBUStep = () => {
  const dispatch = useDispatch();
  const [cbu, setCampoCBU] = useState("");

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
  }, [dispatch]);

  const canContinue = cbu.length > 0 && isCBUValid(cbu);

  const handleNext = (value) => {
    //dispatch(setCBU({ value, selected: true }));
    dispatch(setValidadorDatosPago({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(cbu);
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Ingresá el CBU de la cuenta con la que realizas el pago"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        //type="email"
        value={cbu}
        onChange={(event) => setCampoCBU(event.target.value)}
        placeholder="Ej: 9999999999999999999999"
        InputProps={{
          inputComponent: CBUInput,
        }}
      ></TextField>
      <div style={{ paddingTop: 24 }}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            handleNext(cbu);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default CBUStep;
