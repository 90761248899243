export const TODOS = "Pendiente,PendienteCompania,DatosIncompletos";
export const PENDIENTE = "Pendiente";
export const PENDIENTECOMPANIA = "PendienteCompania";
export const DATOSINCOMPLETOS = "DatosIncompletos";

const estadosSolicitudEmisionAdminOptions = {
  [TODOS]: "Todos",
  [PENDIENTE]: "Pendiente de Emisión",
  [PENDIENTECOMPANIA]: "Pendiente en Compañía",
  [DATOSINCOMPLETOS]: "Datos Incompletos",
};

const estadosSolicitudEmisionAdmin = [
  PENDIENTE,
  PENDIENTECOMPANIA,
  DATOSINCOMPLETOS,
];

const estadosSolicitudEmisionAdminFilter = [
  { label: estadosSolicitudEmisionAdminOptions[TODOS], value: TODOS },
  {
    label: estadosSolicitudEmisionAdminOptions[PENDIENTE],
    value: PENDIENTE,
  },
  {
    label: estadosSolicitudEmisionAdminOptions[PENDIENTECOMPANIA],
    value: PENDIENTECOMPANIA,
  },
  {
    label: estadosSolicitudEmisionAdminOptions[DATOSINCOMPLETOS],
    value: DATOSINCOMPLETOS,
  },
];

export {
  estadosSolicitudEmisionAdminOptions,
  estadosSolicitudEmisionAdmin,
  estadosSolicitudEmisionAdminFilter,
};
