import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, novedadesPath } from "~constants/referenceNames";
import {
  GetNovedadResponse,
  GetNovedadesRequest,
  GetNovedadesResponse,
} from "./types";

let mockActive = false;
let mockActiveNovedades = false;

const novedades = () => {
  return {
    getNovedad: async (id: string): Promise<GetNovedadResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let url = environment.segurosUrl + paths[novedadesPath] + "/" + id;

      if (mockActive) {
        return getNovedadMock();
      } else {
        try {
          const response = await fetch(url, request);
          errorUnlessOk(response);
          return response.json();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },

    getNovedades: async (
      data: GetNovedadesRequest
    ): Promise<GetNovedadesResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.negocio
        ? parameters + `&Negocio=${data.negocio}`
        : parameters;

      parameters = data.compania
        ? parameters + `&Compania=${data.compania}`
        : parameters;

      parameters = data.tipoNovedad
        ? parameters + `&TipoNovedad=${data.tipoNovedad}`
        : parameters;

      parameters = data.fechaNovedadDesde
        ? parameters +
          `&FechaNovedadDesde=${data.fechaNovedadDesde.toISOString()}`
        : parameters;

      if (data.fechaNovedadHasta) {
        data.fechaNovedadHasta.setHours(23, 59, 59);
        parameters =
          parameters +
          `&FechaNovedadHasta=${data.fechaNovedadHasta.toISOString()}`;
      }

      parameters = data.inicioVigenciaDesde
        ? parameters +
          `&InicioVigenciaDesde=${data.inicioVigenciaDesde.toISOString()}`
        : parameters;

      if (data.inicioVigenciaHasta) {
        data.inicioVigenciaHasta.setHours(23, 59, 59);
        parameters =
          parameters +
          `&InicioVigenciaHasta=${data.inicioVigenciaHasta.toISOString()}`;
      }

      parameters =
        parameters +
        "&SortBy=FechaCreacionNovedad:desc,PolizaVigenciaDesde:asc";

      let url =
        environment.segurosUrl + paths[novedadesPath] + "?" + parameters;

      try {
        if (mockActiveNovedades) {
          return getNovedadesMock(data);
        } else {
          const response = await fetch(url, request);
          errorUnlessOk(response);
          return await response.json();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };
};

export { novedades };

const getNovedadMock = (): GetNovedadResponse => {
  return {
    Id: "58d619e7-cec4-4e6b-9ecd-cbe48a71d3bc",
    PolizaId: "poliza-id",
    Negocio: "MercadoAbierto",
    TipoNovedad: "Emision",
    Compania: "Sancor",
    Rama: "Automotores",
    NumeroPoliza: "8439954",
    Orden: 8,
    Dominio: "OZY103",
    FechaCreacionNovedad: new Date("2019-06-27T18:43:54.942Z"),
    FechaEmision: new Date("2019-03-28T03:00:00.000Z"),
    FechaRenovacion: undefined,
    PolizaVigenciaDesde: new Date("2019-03-28T03:00:00.000Z"),
    PolizaVigenciaHasta: new Date("2019-09-28T03:00:00.000Z"),
    FechaAnulacion: undefined,
    CantidadCuotas: 6,
    PrimaTotal: 8736.6,
    PremioTotal: 12575.0,
    RenuevaA: undefined,
    CodigoOrganizador: "102224",
    CodigoProductor: "227128",
    TipoCobertura: "9",
    TipoDocumentoAsegurado: "DNI",
    NumeroDocumentoAsegurado: "92969018",
    NombreCompletoAsegurado: "SAAVEDRA ARAVENA SAMUEL IVAN",
    Cuotas: [
      {
        NumeroCuota: 1,
        Prima: 1456.1,
        Premio: 2100.0,
        FechaDesde: new Date("2019-03-28T03:00:00.000Z"),
        FechaHasta: new Date("2019-04-28T03:00:00.000Z"),
        FechaVencimiento: new Date("2019-03-28T03:00:00.000Z"),
      },
    ],
    UrlPoliza: "http://example.com/poliza",
    UrlTarjeta: "http://example.com/tarjeta",
    UrlCedulaMercosur: "http://example.com/cedula",
    UrlCuponera: "http://example.com/cuponera",
    SumaAsegurada: 10000,
  };
};

const getNovedadesMock = (data): GetNovedadesResponse => {
  const novedades = [
    {
      Id: "2653903c-bdc9-4ed0-afef-1794ce6cb179",
      PolizaId: "some-poliza-id",
      IdOrigen: "a275a5aa-009d-4586-ae23-e2412b256745",
      Negocio: "MercadoAbierto",
      TipoNovedad: "Siniestro",
      Compania: "Sancor",
      Rama: "Automotores",
      NumeroPoliza: "8946730",
      Orden: 1,
      Dominio: "LWV662",
      SumaAsegurada: 355000.0,
      FechaCreacionNovedad: new Date("2020-01-03T20:12:54.407Z"),
      FechaEmision: undefined,
      FechaRenovacion: undefined,
      PolizaVigenciaDesde: new Date("2020-01-23T03:00:00.000Z"),
      PolizaVigenciaHasta: new Date("2020-07-23T03:00:00.000Z"),
      FechaAnulacion: undefined,
      CantidadCuotas: 5,
      PrimaTotal: undefined,
      PremioTotal: undefined,
      RenuevaA: undefined,
      CodigoOrganizador: "102446",
      CodigoProductor: "228075",
      TipoCobertura: "Total",
      TipoDocumentoAsegurado: "DNI",
      NumeroDocumentoAsegurado: "30707621",
      NombreCompletoAsegurado: "ALVAREZ PEREZ MARIBEL",
      Cuotas: [],
      UrlPoliza: "http://example.com/poliza",
      UrlTarjeta: "http://example.com/tarjeta",
      UrlCedulaMercosur: "http://example.com/cedula",
      UrlCuponera: "http://example.com/cuponera",
      UbicacionTarjeta: undefined,
      UbicacionPoliza: undefined,
    },
  ];

  return {
    Novedades: novedades,
    TotalRegistros: novedades.length,
  };
};
