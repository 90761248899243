import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { paths, solicitudesEmisionPath } from "~constants/referenceNames";
import { condicionesFiscalesOptions } from "~constants/condicionesFiscales";
import {
  getDetalleSolicitudEmision,
  patchSolicitudEmision,
  setEmail,
  setDominio,
  setNroChasis,
  setNroMotor,
  setVigenciaDesde,
  clearData,
  setSubmitting,
  patchRechazarSolicitud,
  setFotoFrontalFileName,
  setFotoTraseraFileName,
  setFotoLateralIzquierdoFileName,
  setFotoLateralDerechoFileName,
} from "./actions";
import Layout from "~shared/layout/Layout";
import NumberFormat from "react-number-format";
import DetalleSolicitudCardItem from "./DetalleSolicitudCardItem";
import DetalleSolicitudCardItemEditable from "./DetalleSolicitudCardItemEditable";
import { Typography, Tooltip } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import ColorLabelProductor from "~styled/colorLabelProductor/ColorLabelProductor";
import ColorLabelPas from "~styled/colorLabelPas/ColorLabelPas";
import { formasPagoOptions } from "~constants/formasPago";
import Button from "@material-ui/core/Button";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import SuccessDialog from "./SuccessDialog";
import PhotoIcon from "@material-ui/icons/Photo";
import GalleryWithLightbox from "~styled/gallery/GalleryWithLightBox";
import validateDominio from "~libs/dominioValidator";
import EmailValidator from "email-validator";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import CustomMessageError from "~styled/errorPages/CustomMessageError";
import PopupRechazar from "../PopupRechazar";
import {
  estadosSolicitudEmisionOptions,
  estadosSolicitudEmisionFilter,
} from "../listado/Filtros/estados";
import FileUpload from "~styled/fileUpload/FileUpload";
import { useParams, useHistory } from "react-router";
import { Alert, AlertTitle } from "@material-ui/lab";
import getMarcasTarjetaCredito from "~components/business/marcaTarjetaCreditoSelector/getMarcasTarjetaCredito";
import Link from "@material-ui/core/Link";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1.5%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "stretch",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },
  importe: {
    display: "block",
    color: theme.palette.textColor,
    "@media (max-width: 480px)": {
      fontSize: "20px !important",
      marginTop: "0",
    },
    "@media (min-width: 480px)": {
      fontSize: "24px !important",
      marginTop: "-4px",
    },
  },
  tableContainer: {
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellCenter: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", justifyContent: "center" },
  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  btnRechazar: {
    marginRight: "2%",
  },
  impresosCardBody: {
    paddingTop: "2%",
    display: "flex",
    flexDirection: "column",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  rechazoWarning: {
    margin: "2%",
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
  },
  negocioContainer: {
    display: "flex",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudPendienteSucursalReducer.detalleSolicitudEmision,
    isLoading: state.detalleSolicitudPendienteSucursalReducer.isLoading,
    polizaCreada: state.detalleSolicitudPendienteSucursalReducer.polizaCreada,
    creatingPoliza:
      state.detalleSolicitudPendienteSucursalReducer.creatingPoliza,
    emailSelected: state.detalleSolicitudPendienteSucursalReducer.emailSelected,
    dominioSelected:
      state.detalleSolicitudPendienteSucursalReducer.dominioSelected,
    vigenciaDesdeSelected:
      state.detalleSolicitudPendienteSucursalReducer.vigenciaDesdeSelected,
    nroChasisSelected:
      state.detalleSolicitudPendienteSucursalReducer.nroChasisSelected,
    nroMotorSelected:
      state.detalleSolicitudPendienteSucursalReducer.nroMotorSelected,
    cuotasSelected:
      state.detalleSolicitudPendienteSucursalReducer.cuotasSelected,
    submitting: state.detalleSolicitudPendienteSucursalReducer.submitting,
    loadingError: state.detalleSolicitudPendienteSucursalReducer.loadingError,
    solicitudRechazada:
      state.detalleSolicitudPendienteSucursalReducer.solicitudRechazada,
    fotoFrontalFileNameSelected:
      state.detalleSolicitudPendienteSucursalReducer
        .fotoFrontalFileNameSelected,
    fotoTraseraFileNameSelected:
      state.detalleSolicitudPendienteSucursalReducer
        .fotoTraseraFileNameSelected,
    fotoLateralIzquierdoFileNameSelected:
      state.detalleSolicitudPendienteSucursalReducer
        .fotoLateralIzquierdoFileNameSelected,
    fotoLateralDerechoFileNameSelected:
      state.detalleSolicitudPendienteSucursalReducer
        .fotoLateralDerechoFileNameSelected,
  }));

const getPeriodoEnMeses = (periodo) => {
  if (periodo === "Anual") return 12;
  if (periodo === "Semestral") return 6;
  if (periodo === "Cuatrimestral") return 4;
  if (periodo === "Trimestral") return 3;

  return 0;
};

const DetalleSolicitudPendienteSucursal = (props) => {
  const {
    detalleSolicitudEmision,
    isLoading,
    polizaCreada,
    emailSelected,
    dominioSelected,
    vigenciaDesdeSelected,
    nroChasisSelected,
    nroMotorSelected,
    submitting,
    loadingError,
    solicitudRechazada,
    fotoFrontalFileNameSelected,
    fotoTraseraFileNameSelected,
    fotoLateralIzquierdoFileNameSelected,
    fotoLateralDerechoFileNameSelected,
  } = useReduxSelector();

  const [premio, setPremio] = useState(0);
  const [resumenDatosPago, setResumenDatosPago] = useState("");
  const [isPopupRechazarOpened, setIsPopupRechazarOpened] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const { id } = useParams();
  let history = useHistory();

  const handleChangeDominio = (dominio) => {
    if (dominio.value.length <= 7) {
      dominio.value = dominio.value.toUpperCase();
      dispatch(setDominio(dominio));
    }
  };

  const handleChangeNroMotor = (nroMotor) => {
    nroMotor.value = nroMotor.value.toUpperCase();
    dispatch(setNroMotor(nroMotor));
  };

  const handleChangeVigenciaDesde = (vigDesde) => {
    dispatch(setVigenciaDesde(vigDesde));
  };

  const handleChangeNroChasis = (nroChasis) => {
    nroChasis.value = nroChasis.value.toUpperCase();
    dispatch(setNroChasis(nroChasis));
  };

  const handleRechazar = () => {
    setIsPopupRechazarOpened(true);
  };

  const handleClosePopupRechazo = () => {
    setIsPopupRechazarOpened(false);
  };

  const handleConfirmarRechazo = (motivoRechazo) => {
    let data = {
      motivoRechazo,
    };
    dispatch(patchRechazarSolicitud(id, data));
    setIsPopupRechazarOpened(false);
  };

  const handleChangeEmail = (email) => {
    dispatch(setEmail(email));
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  const handleEnviarACasaCentral = () => {
    dispatch(setSubmitting(true));

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    const inputs = [
      { setFocusFunction: setEmail, ...emailSelected },
      { setFocusFunction: setDominio, ...dominioSelected },
      { setFocusFunction: setNroChasis, ...nroChasisSelected },
      { setFocusFunction: setNroMotor, ...nroMotorSelected },
    ];

    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          value: inputToFocus.value,
          valid: inputToFocus.valid,
          focus: true,
        })
      );

    if (inputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    if (
      moment(vigenciaDesdeSelected.value)
        .startOf("day")
        .diff(moment(new Date()).local().startOf("day"), "days") < 0
    ) {
      dispatch(
        queueMessage(
          "La fecha de inicio de vigencia debe ser mayor o igual a hoy"
        )
      );
      return;
    }

    let fotosVehiculo = null;

    if (!detalleSolicitudEmision.fotosVehiculo) {
      let seHanCargadoTodasLasFotos =
        fotoFrontalFileNameSelected.value !== null &&
        fotoTraseraFileNameSelected.value !== null &&
        fotoLateralIzquierdoFileNameSelected.value !== null &&
        fotoLateralDerechoFileNameSelected.value !== null;

      if (!seHanCargadoTodasLasFotos) {
        dispatch(queueMessage("Deben cargarse todas las fotos del vehículo"));
        return;
      }

      fotosVehiculo = seHanCargadoTodasLasFotos
        ? [
            fotoFrontalFileNameSelected.value,
            fotoTraseraFileNameSelected.value,
            fotoLateralIzquierdoFileNameSelected.value,
            fotoLateralDerechoFileNameSelected.value,
          ]
        : null;
    }

    let data = {
      dominio: dominioSelected.value,
      numeroMotor: nroMotorSelected.value,
      numeroChasis: nroChasisSelected.value,
      email: emailSelected.value,
      fotosVehiculo: fotosVehiculo,
      vigenciaDesde: moment(vigenciaDesdeSelected.value).local(),
    };
    dispatch(patchSolicitudEmision(id, data));
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetalleSolicitudEmision(id));

    return () => {
      dispatch(clearData());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!detalleSolicitudEmision) return;

    //eslint-disable-next-line
  }, [vigenciaDesdeSelected]);

  useEffect(() => {
    if (!detalleSolicitudEmision) return;

    if (detalleSolicitudEmision.estado === "DatosIncompletos")
      setReadOnly(false);

    setPremio(
      getPeriodoEnMeses(detalleSolicitudEmision.datosEmision.periodo) *
        detalleSolicitudEmision.datosEmision.precioInformado
    );

    if (detalleSolicitudEmision.vigenciaDesde !== null) {
      dispatch(
        setVigenciaDesde({
          value: moment(detalleSolicitudEmision.vigenciaDesde),
          valid: true,
          focus: false,
        })
      );
    }

    if (detalleSolicitudEmision.datosVehiculo.dominio)
      dispatch(
        setDominio({
          value: detalleSolicitudEmision.datosVehiculo.dominio,
          valid: true,
          focus: false,
        })
      );

    if (detalleSolicitudEmision.datosCliente.email)
      dispatch(
        setEmail({
          value: detalleSolicitudEmision.datosCliente.email,
          valid: true,
          focus: false,
        })
      );

    if (detalleSolicitudEmision.datosVehiculo.numeroChasis)
      dispatch(
        setNroChasis({
          value: detalleSolicitudEmision.datosVehiculo.numeroChasis,
          valid: true,
          focus: false,
        })
      );

    if (detalleSolicitudEmision.datosVehiculo.numeroMotor)
      dispatch(
        setNroMotor({
          value: detalleSolicitudEmision.datosVehiculo.numeroMotor,
          valid: true,
          focus: false,
        })
      );

    let resumenDatosPagoTemp = "";

    let marcaTCElement = getMarcasTarjetaCredito().find(
      (element) =>
        element.value ===
        detalleSolicitudEmision.datosEmision.marcaTarjetaCredito
    );

    let ciaTC =
      detalleSolicitudEmision.datosEmision.marcaTarjetaCredito && marcaTCElement
        ? marcaTCElement.label
        : "";
    let ultimosDigitosDatosPago =
      detalleSolicitudEmision.datosEmision.ultimosDigitosDatosPago;

    resumenDatosPagoTemp +=
      detalleSolicitudEmision.datosEmision.formaPago ===
      "DebitoConTarjetaCredito"
        ? `${ciaTC} `
        : "";

    resumenDatosPagoTemp +=
      ultimosDigitosDatosPago !== null ? `*****${ultimosDigitosDatosPago}` : "";

    setResumenDatosPago(resumenDatosPagoTemp);
    //eslint-disable-next-line
  }, [detalleSolicitudEmision]);

  let marca =
    !isLoading && detalleSolicitudEmision.datosVehiculo.marca
      ? detalleSolicitudEmision.datosVehiculo.marca.charAt(0) +
        detalleSolicitudEmision.datosVehiculo.marca.toLowerCase().slice(1)
      : "";

  let modelo =
    !isLoading && detalleSolicitudEmision.datosVehiculo.modelo
      ? detalleSolicitudEmision.datosVehiculo.modelo.charAt(0) +
        detalleSolicitudEmision.datosVehiculo.modelo.toLowerCase().slice(1)
      : "";

  let vehiculo = `${marca} ${modelo}`;

  const photos = [];

  if (!isLoading) {
    if (detalleSolicitudEmision.fotosVehiculo)
      detalleSolicitudEmision.fotosVehiculo.map((fotoVehiculo) => {
        let photo = {
          src: fotoVehiculo,
          thumbnail: fotoVehiculo,
          thumbnailWidth: 80,
          thumbnailHeight: 80,
        };

        photos.push(photo);
        return photos;
      });
  }

  if (
    !isLoading &&
    !estadosSolicitudEmisionFilter.some(
      (estadoValido) => estadoValido.value === detalleSolicitudEmision.estado
    )
  ) {
    return (
      <CustomMessageError
        errorMessage={
          <div className={classes.errorMessage}>
            {`La solicitud se encuentra en estado ${detalleSolicitudEmision.estado}.`}
            <Link
              href="#"
              onClick={() =>
                history.push(`/riesgos/automotores/solicitudesHistoricas/${id}`)
              }
            >
              Ver en bandeja de solicitudes históricas
            </Link>
          </div>
        }
      />
    );
  }

  const backAction = () => {
    history.push(paths[solicitudesEmisionPath]);
  };

  if (loadingError) return <UnexpectedError />;

  return (
    <>
      <Layout
        fixed={!readOnly}
        title={
          readOnly
            ? "Detalle de la solicitud"
            : "Completar Solicitud de Emisión"
        }
        subtitle={
          detalleSolicitudEmision
            ? estadosSolicitudEmisionOptions[detalleSolicitudEmision.estado]
            : null
        }
        selectedDefault={solicitudesEmisionPath}
        backAction={backAction}
        data={
          !readOnly && (
            <div className={classes.btnFinalizar}>
              <Tooltip title={"Rechazar Solicitud"}>
                <Button
                  className={classes.btnRechazar}
                  variant="outlined"
                  color="secondary"
                  onClick={handleRechazar}
                >
                  RECHAZAR
                </Button>
              </Tooltip>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEnviarACasaCentral}
              >
                Enviar a Emitir
              </Button>
            </div>
          )
        }
      >
        <div className={classes.root}>
          {isLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LoadingViewGears />
            </div>
          )}
          {!isLoading && (
            <div>
              <div className={classes.body}>
                <PopupRechazar
                  isOpened={isPopupRechazarOpened}
                  solicitudId={id}
                  nombreSolicitante={
                    detalleSolicitudEmision
                      ? detalleSolicitudEmision.datosOriginador.nombre
                      : null
                  }
                  creadaHace={moment(
                    detalleSolicitudEmision.fechaCreacion
                  ).fromNow()}
                  onClickAceptar={handleConfirmarRechazo}
                  onClose={handleClosePopupRechazo}
                />
                <SuccessDialog
                  openDialog={solicitudRechazada}
                  value={"Solicitud rechazada con éxito"}
                />
                <SuccessDialog
                  openDialog={polizaCreada}
                  value={"La solicitud fue enviada a emitir"}
                />
                <div className={classes.cardContainer}>
                  {detalleSolicitudEmision &&
                    detalleSolicitudEmision.estado === "DatosIncompletos" &&
                    detalleSolicitudEmision.motivoRechazo !== null && (
                      <Alert
                        severity="warning"
                        className={classes.rechazoWarning}
                      >
                        <AlertTitle>Motivo rechazo</AlertTitle>
                        {detalleSolicitudEmision.motivoRechazo}
                      </Alert>
                    )}
                  {detalleSolicitudEmision.datosCliente && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <PersonIcon className={classes.cardIcon} />
                        <Typography className={classes.cardTitle}>
                          {"Asegurado"}
                        </Typography>
                      </div>
                      <DetalleSolicitudCardItem
                        caption="Negocio"
                        value={
                          <div className={classes.negocioContainer}>
                            <ColorLabelPas
                              solicitudEmision={detalleSolicitudEmision}
                            />
                            <ColorLabelNegocio
                              negocio={detalleSolicitudEmision.negocio}
                            />
                            <ColorLabelProductor
                              productor={detalleSolicitudEmision.productor}
                            />
                          </div>
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="Nombre"
                        value={
                          detalleSolicitudEmision.datosCliente.nombre !==
                          null ? (
                            detalleSolicitudEmision.datosCliente.nombre
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      {readOnly && (
                        <DetalleSolicitudCardItem
                          caption="E-Mail"
                          value={
                            detalleSolicitudEmision.datosCliente.email !==
                            null ? (
                              detalleSolicitudEmision.datosCliente.email
                            ) : (
                              <div className={classes.dash}>&mdash;</div>
                            )
                          }
                        />
                      )}
                      {!readOnly && (
                        <DetalleSolicitudCardItemEditable
                          caption="E-Mail"
                          onChange={handleChangeEmail}
                          onBlur={handleBlur}
                          value={emailSelected.value}
                          id={"email"}
                          placeholder={"micorreo@mail.com"}
                          onValidate={EmailValidator.validate}
                          displayError={submitting}
                          focus={emailSelected.focus}
                        />
                      )}

                      <DetalleSolicitudCardItem
                        caption="Teléfono"
                        value={
                          detalleSolicitudEmision.datosCliente.telefono !==
                          null ? (
                            detalleSolicitudEmision.datosCliente.telefono
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="DNI"
                        value={detalleSolicitudEmision.datosCliente.dni}
                      />
                      <DetalleSolicitudCardItem
                        caption="CUIL"
                        value={detalleSolicitudEmision.datosCliente.cuil}
                      />
                      {detalleSolicitudEmision.datosVehiculo.uso ===
                        "Comercial" && (
                        <DetalleSolicitudCardItem
                          caption="Condición Fiscal"
                          value={
                            detalleSolicitudEmision.datosCliente
                              .condicionFiscal ? (
                              condicionesFiscalesOptions[
                                detalleSolicitudEmision.datosCliente
                                  .condicionFiscal
                              ]
                            ) : (
                              <div className={classes.dash}>&mdash;</div>
                            )
                          }
                        />
                      )}
                      <DetalleSolicitudCardItem
                        caption="Fecha de nacimiento"
                        value={moment(
                          detalleSolicitudEmision.datosCliente.fechaNacimiento
                        )
                          .utc()
                          .format("DD/MM/YYYY")}
                      />
                      <DetalleSolicitudCardItem
                        caption="Dirección"
                        value={
                          detalleSolicitudEmision.datosCliente.direccion !==
                          null ? (
                            detalleSolicitudEmision.datosCliente.direccion.toUpperCase()
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="Certificado RC"
                        value={
                          detalleSolicitudEmision.certificadoRCUrl !== null ? (
                            <a
                              href={detalleSolicitudEmision.certificadoRCUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver Certificado
                            </a>
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                    </div>
                  )}
                  {detalleSolicitudEmision.datosEmision && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <VerifiedUserIcon className={classes.cardIcon} />
                        <Typography className={classes.cardTitle}>
                          {"Datos para Emisión"}
                        </Typography>
                      </div>
                      <DetalleSolicitudCardItem
                        caption="Compañía"
                        value={detalleSolicitudEmision.datosEmision.compania}
                      />
                      <DetalleSolicitudCardItem
                        caption="Cobertura seleccionada"
                        value={
                          detalleSolicitudEmision.datosEmision
                            .coberturaSeleccionada
                        }
                      />
                      {detalleSolicitudEmision.codigoOrganizador && (
                        <DetalleSolicitudCardItem
                          caption="Código organizador"
                          value={detalleSolicitudEmision.codigoOrganizador}
                        />
                      )}
                      {detalleSolicitudEmision.codigoProductor && (
                        <DetalleSolicitudCardItem
                          caption="Código productor"
                          value={detalleSolicitudEmision.codigoProductor}
                        />
                      )}
                      {detalleSolicitudEmision.codigoVendedor && (
                        <DetalleSolicitudCardItem
                          caption="Código vendedor"
                          value={detalleSolicitudEmision.codigoVendedor}
                        />
                      )}
                      <DetalleSolicitudCardItem
                        caption="Precio"
                        value={
                          detalleSolicitudEmision.datosEmision
                            .precioInformado ? (
                            <NumberFormat
                              id="importeSolicitado"
                              displayType={"text"}
                              value={
                                detalleSolicitudEmision.datosEmision
                                  .precioInformado
                              }
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={0}
                              prefix={"$"}
                            />
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="Periodo"
                        value={
                          detalleSolicitudEmision.datosEmision.periodo !==
                          null ? (
                            detalleSolicitudEmision.datosEmision.periodo
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="Premio total"
                        value={
                          premio !== null ? (
                            <NumberFormat
                              value={premio}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              isNumericString
                              prefix="$"
                              decimalScale={0}
                            />
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      {readOnly && (
                        <DetalleSolicitudCardItem
                          caption="Vigencia Desde"
                          value={moment(vigenciaDesdeSelected.value).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      )}
                      {!readOnly && (
                        <DetalleSolicitudCardItemEditable
                          caption="Vigencia Desde"
                          onChange={handleChangeVigenciaDesde}
                          onBlur={handleBlur}
                          value={vigenciaDesdeSelected.value}
                          id={"vigenciaDesde"}
                          type={"date"}
                          displayError={submitting}
                          focus={vigenciaDesdeSelected.focus}
                        />
                      )}
                      <DetalleSolicitudCardItem
                        caption="Forma de pago"
                        value={
                          detalleSolicitudEmision.datosEmision.formaPago !==
                          null ? (
                            formasPagoOptions[
                              detalleSolicitudEmision.datosEmision.formaPago
                            ]
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      {(detalleSolicitudEmision.datosEmision.formaPago ===
                        "DebitoConCBU" ||
                        detalleSolicitudEmision.datosEmision.formaPago ===
                          "DebitoConTarjetaCredito") && (
                        <DetalleSolicitudCardItem
                          caption="Datos de pago"
                          value={resumenDatosPago}
                        />
                      )}
                    </div>
                  )}
                  {detalleSolicitudEmision.datosOriginador && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <PersonIcon className={classes.cardIcon} />
                        <Typography className={classes.cardTitle}>
                          {"Originador"}
                        </Typography>
                      </div>
                      {detalleSolicitudEmision.negocio === "MercadoAbierto" && (
                        <>
                          <DetalleSolicitudCardItem
                            caption="Nombre"
                            value={
                              detalleSolicitudEmision.datosOriginador.nombre !==
                              null ? (
                                detalleSolicitudEmision.datosOriginador.nombre
                              ) : (
                                <div className={classes.dash}>&mdash;</div>
                              )
                            }
                          />
                          <DetalleSolicitudCardItem
                            caption="E-Mail"
                            value={
                              detalleSolicitudEmision.datosOriginador.email !==
                              null ? (
                                detalleSolicitudEmision.datosOriginador.email
                              ) : (
                                <div className={classes.dash}>&mdash;</div>
                              )
                            }
                          />
                          <DetalleSolicitudCardItem
                            caption="Teléfono"
                            value={
                              detalleSolicitudEmision.datosOriginador
                                .telefono !== null ? (
                                detalleSolicitudEmision.datosOriginador.telefono
                              ) : (
                                <div className={classes.dash}>&mdash;</div>
                              )
                            }
                          />
                        </>
                      )}

                      {detalleSolicitudEmision.negocio === "Prendarios" && (
                        <DetalleSolicitudCardItem
                          caption="Operación"
                          value={
                            detalleSolicitudEmision.operacionId !== null ? (
                              detalleSolicitudEmision.operacionId
                            ) : (
                              <div className={classes.dash}>&mdash;</div>
                            )
                          }
                        />
                      )}

                      <DetalleSolicitudCardItem
                        caption="Oficina"
                        value={
                          detalleSolicitudEmision.datosOriginador
                            .nombreOficina !== null ? (
                            detalleSolicitudEmision.datosOriginador
                              .nombreOficina
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="Canal Originador"
                        value={
                          detalleSolicitudEmision.datosOriginador
                            .nombreAgencia !== null ? (
                            detalleSolicitudEmision.datosOriginador
                              .nombreAgencia
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                      <DetalleSolicitudCardItem
                        caption="Fecha creación"
                        value={moment(
                          detalleSolicitudEmision.fechaCreacion
                        ).format("DD/MM/YYYY HH:mm")}
                      />
                      <DetalleSolicitudCardItem
                        caption="Origen"
                        value={
                          detalleSolicitudEmision.datosOriginador.origen !==
                          null ? (
                            detalleSolicitudEmision.datosOriginador.origen
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                    </div>
                  )}
                </div>
                <div className={classes.cardContainer}>
                  {detalleSolicitudEmision.datosVehiculo && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <DriveEtaIcon className={classes.cardIcon} />
                        <Typography className={classes.cardTitle}>
                          {"Vehículo"}
                        </Typography>
                      </div>
                      <DetalleSolicitudCardItem
                        caption="Vehículo"
                        value={`(${
                          detalleSolicitudEmision.datosVehiculo.anio
                        }) ${vehiculo.toUpperCase()}`}
                      />
                      <DetalleSolicitudCardItem
                        caption="Localidad"
                        value={`(CP ${detalleSolicitudEmision.datosVehiculo.codigoPostal}) ${detalleSolicitudEmision.datosVehiculo.nombreLocalidad} - ${detalleSolicitudEmision.datosVehiculo.nombreProvincia}`}
                      />
                      <DetalleSolicitudCardItem
                        caption="Uso"
                        value={detalleSolicitudEmision.datosVehiculo.uso}
                      />
                      <DetalleSolicitudCardItem
                        caption="Tiene GNC"
                        value={
                          detalleSolicitudEmision.datosVehiculo.tieneGNC ===
                          true
                            ? "Si"
                            : detalleSolicitudEmision.datosVehiculo.tieneGNC ===
                              false
                            ? "No"
                            : null
                        }
                      />

                      {readOnly && (
                        <DetalleSolicitudCardItem
                          caption="Dominio"
                          value={
                            detalleSolicitudEmision.datosVehiculo.dominio !==
                            null ? (
                              detalleSolicitudEmision.datosVehiculo.dominio
                            ) : (
                              <div className={classes.dash}>&mdash;</div>
                            )
                          }
                        />
                      )}
                      {!readOnly && (
                        <DetalleSolicitudCardItemEditable
                          caption="Dominio"
                          onChange={handleChangeDominio}
                          onBlur={handleBlur}
                          value={dominioSelected.value}
                          id={"dominio"}
                          placeholder={"AA000AA"}
                          onValidate={validateDominio}
                          displayError={submitting}
                          focus={dominioSelected.focus}
                        />
                      )}
                      {readOnly && (
                        <DetalleSolicitudCardItem
                          caption="Número de motor"
                          value={
                            detalleSolicitudEmision.datosVehiculo
                              .numeroMotor !== null ? (
                              detalleSolicitudEmision.datosVehiculo.numeroMotor
                            ) : (
                              <div className={classes.dash}>&mdash;</div>
                            )
                          }
                        />
                      )}
                      {!readOnly && (
                        <DetalleSolicitudCardItemEditable
                          caption="Número de motor"
                          onChange={handleChangeNroMotor}
                          onBlur={handleBlur}
                          value={nroMotorSelected.value}
                          id={"nroMotor"}
                          displayError={submitting}
                          focus={nroMotorSelected.focus}
                        />
                      )}
                      {readOnly && (
                        <DetalleSolicitudCardItem
                          caption="Número de chasis"
                          value={
                            detalleSolicitudEmision.datosVehiculo
                              .numeroChasis !== null ? (
                              detalleSolicitudEmision.datosVehiculo.numeroChasis
                            ) : (
                              <div className={classes.dash}>&mdash;</div>
                            )
                          }
                        />
                      )}
                      {!readOnly && (
                        <DetalleSolicitudCardItemEditable
                          caption="Número de chasis"
                          onChange={handleChangeNroChasis}
                          onBlur={handleBlur}
                          value={nroChasisSelected.value}
                          id={"nroChasis"}
                          displayError={submitting}
                          focus={nroChasisSelected.focus}
                        />
                      )}
                      <DetalleSolicitudCardItem
                        caption="Suma asegurada"
                        value={
                          detalleSolicitudEmision.datosVehiculo
                            .sumaAsegurada ? (
                            <NumberFormat
                              id="sumaAsegurada"
                              displayType={"text"}
                              value={
                                detalleSolicitudEmision.datosVehiculo
                                  .sumaAsegurada
                              }
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={0}
                              prefix={"$"}
                            />
                          ) : (
                            <div className={classes.dash}>&mdash;</div>
                          )
                        }
                      />
                    </div>
                  )}
                  {detalleSolicitudEmision.fotosVehiculo && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <PhotoIcon className={classes.cardIcon} />
                        <Typography className={classes.cardTitle}>
                          {"Fotos del Vehículo"}
                        </Typography>
                      </div>
                      <div>
                        <GalleryWithLightbox imagenes={photos} />
                      </div>
                    </div>
                  )}
                  {!detalleSolicitudEmision.fotosVehiculo && !readOnly && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <PhotoIcon className={classes.cardIcon} />
                        <Typography className={classes.cardTitle}>
                          {"Fotos del Vehículo"}
                        </Typography>
                      </div>
                      <div className={classes.impresosCardBody}>
                        <Typography className={classes.caption}>
                          {"Parte frontal"}
                        </Typography>
                        <FileUpload
                          onAdded={(event) =>
                            dispatch(
                              setFotoFrontalFileName({
                                value: `temp/${event.target.value}`,
                                valid: true,
                              })
                            )
                          }
                          onRemoved={(event) =>
                            dispatch(
                              setFotoFrontalFileName({
                                value: null,
                                valid: false,
                              })
                            )
                          }
                          acceptImage={true}
                          acceptPdf={false}
                        />
                      </div>
                      <div className={classes.impresosCardBody}>
                        <Typography className={classes.caption}>
                          {"Parte trasera"}
                        </Typography>
                        <FileUpload
                          onAdded={(event) =>
                            dispatch(
                              setFotoTraseraFileName({
                                value: `temp/${event.target.value}`,
                                valid: true,
                              })
                            )
                          }
                          onRemoved={(event) =>
                            dispatch(
                              setFotoTraseraFileName({
                                value: null,
                                valid: false,
                              })
                            )
                          }
                          acceptImage={true}
                          acceptPdf={false}
                        />
                      </div>
                      <div className={classes.impresosCardBody}>
                        <Typography className={classes.caption}>
                          {"Lateral izquierdo"}
                        </Typography>
                        <FileUpload
                          onAdded={(event) =>
                            dispatch(
                              setFotoLateralIzquierdoFileName({
                                value: `temp/${event.target.value}`,
                                valid: true,
                              })
                            )
                          }
                          onRemoved={(event) =>
                            dispatch(
                              setFotoLateralIzquierdoFileName({
                                value: null,
                                valid: false,
                              })
                            )
                          }
                          acceptImage={true}
                          acceptPdf={false}
                        />
                      </div>
                      <div className={classes.impresosCardBody}>
                        <Typography className={classes.caption}>
                          {"Lateral derecho"}
                        </Typography>
                        <FileUpload
                          onAdded={(event) =>
                            dispatch(
                              setFotoLateralDerechoFileName({
                                value: `temp/${event.target.value}`,
                                valid: true,
                              })
                            )
                          }
                          onRemoved={(event) =>
                            dispatch(
                              setFotoLateralDerechoFileName({
                                value: null,
                                valid: false,
                              })
                            )
                          }
                          acceptImage={true}
                          acceptPdf={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default DetalleSolicitudPendienteSucursal;
