import React, { Fragment, useEffect, useMemo } from "react";
import Signin from "~components/auth/Signin";
import { Callback } from "~components/Auth0/Callback";
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import theme from "~css/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import SnackbarsProvider from "~shared/snackbarsProvider/SnackbarsProvider";
import { useSelector } from "react-redux";
import { useAuth0, useRefreshToken } from "~components/Auth0/useAuth";
import localRepository from "./localRepository";
import AuthenticatedViews from "~components/views/authenticatedViews/AuthenticatedViews";
import { useUrlManager } from "~components/views/authenticatedViews/useUrlManager";
import LoadingViewAuthenticated from "~components/views/authenticatedViews/LoadingViewAuthenticated";
import usePostOnboarding from "~components/views/authenticatedViews/usePostOnboarding";
import InvalidUser from "~styled/errorPages/InvalidUser";
import useHotjar from "./useHotjar";
import { ReactQueryConfigProvider } from "react-query";
import CargaSiniestroPublica from "~components/views/siniestros/cargaPublica/CargaSiniestroPublica";
import { withLDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import LoadingView from "~styled/loadingView/LoadingView";
import environment from "~libs/environment";

const PublicViews = ({ url }) => {
  const history = useHistory();
  useUrlManager();

  useEffect(() => {
    if (url) history.push(url);
  }, [url, history]);

  return (
    <Switch>
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/loading" component={LoadingViewAuthenticated} />
      <Route exact path="/callback" component={Callback} />
      <Route exact path="/forbiddenAccess" component={InvalidUser} />
      <Route
        exact
        path="/denuncia-tu-siniestro"
        component={CargaSiniestroPublica}
      />
      <Route
        exact
        path="/mg-homepage"
        render={() => (window.location = "https://www.mg-group.com.ar")}
      />
      <Redirect to={url ? url : "/signin"} />
    </Switch>
  );
};

const useReduxSelector = () =>
  useSelector((state) => ({
    permissions: state.authenticatedViewReducer.tokenPermissions,
    isAuthorized: state.authReducer.isAuthorized,
    validated: state.authReducer.validated,
  }));

const App = () => {
  const ldClient = useLDClient();
  const { permissions, validated, isAuthorized } = useReduxSelector();
  const token = localRepository.accessToken.get();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  let { isAuthenticated, authState } = useAuth0();

  const firstLoggin = !isAuthenticated && !isLoggedIn;

  const forbiddenAccess = isAuthenticated && validated && !isAuthorized;

  const userWithPermissions = authState.user && authState.user.isAuthorized;

  const loadingPermissions =
    !forbiddenAccess && permissions && permissions.length === 0;

  const processingUserInfo =
    (!isAuthenticated || loadingPermissions) && isLoggedIn;

  useRefreshToken();
  usePostOnboarding();
  useHotjar();

  const queryConfig = useMemo(
    () =>
      (token = "") => {
        return {
          queries: {
            queryFnParamsFilter: (args) => {
              args.push(token); //Add token to the end of parameters list
              return args;
            },
          },
        };
      },
    []
  );
  return (
    <Fragment>
      <CssBaseline />
      <BrowserRouter>
        <ReactQueryConfigProvider config={queryConfig(token)}>
          <ThemeProvider theme={theme}>
            {ldClient && (
              <>
                <SnackbarsProvider />
                {firstLoggin && <PublicViews />}
                {processingUserInfo && <LoadingViewAuthenticated />}

                {isAuthenticated && userWithPermissions && token && (
                  <AuthenticatedViews token={token} />
                )}
                {forbiddenAccess && <PublicViews url="/forbiddenAccess" />}
              </>
            )}
            {!ldClient && <LoadingView />}
          </ThemeProvider>
        </ReactQueryConfigProvider>
      </BrowserRouter>
    </Fragment>
  );
};

export default withLDProvider({
  clientSideID: environment.launchDarkly.sdkKey,
})(App);
