import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { setTieneGNC, clearTieneGNC } from "../actions";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";
import Typography from "@material-ui/core/Typography";
import checkIcon from "~assets/icons/ic-check.svg";
import closeIcon from "~assets/icons/ic-close.svg";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    width: "100%",
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));

const TieneGncStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearTieneGNC());
  }, [dispatch]);

  return (
    <>
      <div className={classes.cotizador}>
        <div className={classes.title}>
          <PrimaryTitle label="¿Tiene GNC?" marginBottom={60} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: 20,
          }}
        >
          <div style={{ marginRight: 5, width: "100%" }}>
            <CheckboxButton
              onClick={() =>
                dispatch(setTieneGNC({ value: true, selected: true }))
              }
            >
              <div className={classes.container}>
                <img
                  alt=""
                  src={checkIcon}
                  style={{ height: 58, marginBottom: 17 }}
                />
                <Typography className={classes.btnLabel}>Sí</Typography>
              </div>
            </CheckboxButton>
          </div>

          <div style={{ marginLeft: 5, width: "100%" }}>
            <CheckboxButton
              onClick={() =>
                dispatch(setTieneGNC({ value: false, selected: true }))
              }
            >
              <div className={classes.container}>
                <img
                  alt=""
                  src={closeIcon}
                  style={{ height: 63, marginBottom: 17 }}
                />
                <Typography className={classes.btnLabel}>No</Typography>
              </div>
            </CheckboxButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default TieneGncStep;
