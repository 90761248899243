import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import PersonIcon from "@material-ui/icons/Person";
import { useDispatch, useSelector } from "react-redux";
import { setEmail } from "../actions.js";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemEmailCard from "~business/Cards/ItemEmailCard";
import ItemDniCard from "~business/Cards/ItemDniCard";
import ItemCuilCard from "~business/Cards/ItemCuilCard";
import ItemDateCard from "~business/Cards/ItemDateCard";

import { condicionesFiscalesOptions } from "~constants/condicionesFiscales";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detalleSolicitudEmisionReducer;
    const datosCliente = detalle.detalleSolicitudEmision.datosCliente;
    const datosVehiculo = detalle.detalleSolicitudEmision.datosVehiculo;
    return {
      nombreCompleto: datosCliente.nombre,
      tipoDocumento: datosCliente.tipoDocumento,
      numeroDocumento: datosCliente.dni,
      cuil: datosCliente.cuil,
      fechaNacimiento: datosCliente.fechaNacimiento,
      domicilio: datosCliente.direccion,
      email: detalle.emailSelected,
      telefono: datosCliente.telefono,
      uso: datosVehiculo.uso,
      condicionFiscal: datosCliente.condicionFiscal,
    };
  });

const AseguradoCard = ({ isLoading, onBlur, displayError }) => {
  const dispatch = useDispatch();

  const {
    nombreCompleto,
    tipoDocumento,
    numeroDocumento,
    cuil,
    fechaNacimiento,
    domicilio,
    email,
    telefonoCelular,
    telefonoFijo,
    telefono,
    uso,
    condicionFiscal,
  } = useReduxSelector();

  let timer = null;
  const handleChangeEmail = (email) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setEmail(email));
    }, 200);
  };

  const dniFormatted = tipoDocumento
    ? `(${tipoDocumento}) ${numeroDocumento}`
    : numeroDocumento;

  const telefonoCelularFormatted = telefonoCelular
    ? `(CELULAR) ${telefonoCelular}`
    : telefono;
  const telefonoFijoFormatted = telefonoCelular
    ? `(FIJO) ${telefonoFijo}`
    : null;

  return (
    <DetalleCard title={"Asegurado"} IconHeader={PersonIcon}>
      <ItemInputCard
        readOnly
        isLoading={isLoading}
        label="Nombre"
        value={nombreCompleto}
      />
      <ItemEmailCard
        id="email"
        label="E-mail"
        isLoading={isLoading}
        value={email.value}
        onChange={handleChangeEmail}
        onBlur={onBlur}
        placeholder="micorreo@mail.com"
        displayError={displayError}
        focus={email.focus}
        required
      />
      <ItemInputCard
        label={"Teléfono"}
        isLoading={isLoading}
        value={telefonoCelularFormatted ?? telefonoFijoFormatted}
        readOnly
      />
      <ItemDniCard
        isLoading={isLoading}
        label={"DNI"}
        value={dniFormatted}
        readOnly
      />
      <ItemCuilCard
        isLoading={isLoading}
        label={"CUIL"}
        value={cuil}
        readOnly
      />
      {uso === "Comercial" && (
        <ItemInputCard
          label="Condición Fiscal"
          value={condicionesFiscalesOptions[condicionFiscal]}
        />
      )}
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha Nacimiento"}
        value={fechaNacimiento}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Domicilio"}
        value={domicilio}
        readOnly
      />
    </DetalleCard>
  );
};

export default AseguradoCard;
