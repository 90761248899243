import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import {
  paths,
  solicitudesEmisionBackendPath,
} from "~constants/referenceNames";
import { PostEmisionManualRequest, PostEmisionManualResponse } from "./types";

const emisionesManuales = () => {
  return {
    postEmisionManual: (
      solicitudEmisionId: string,
      data: PostEmisionManualRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${solicitudEmisionId}/poliza`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { emisionesManuales };
