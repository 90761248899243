import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, multicotizacionesPath } from "~constants/referenceNames";
import {
  CotizacionesData,
  GetMulticotizacionesResponse,
  MulticotizacionResponse,
} from "./types";

const cotizaciones = () => {
  return {
    getCotizaciones: (
      data: CotizacionesData
    ): Promise<GetMulticotizacionesResponse> => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.fechaDesde
        ? parameters + `&FechaDesde=${data.fechaDesde.toISOString()}`
        : parameters;
      if (data.fechaHasta) {
        data.fechaHasta.setHours(23, 59, 59);
        parameters =
          parameters + `&FechaHasta=${data.fechaHasta.toISOString()}`;
      }
      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;
      parameters = data.negocio
        ? parameters + `&Negocio=${data.negocio}`
        : parameters;
      parameters = data.estado
        ? parameters + `&Estado=${data.estado}`
        : parameters;

      const url =
        environment.segurosUrl +
        paths[multicotizacionesPath] +
        "?" +
        parameters;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getDetalleCotizacion: (data: string): Promise<MulticotizacionResponse> => {
      const id = data;
      const url =
        environment.segurosUrl +
        paths[multicotizacionesPath] +
        "/" +
        id +
        "?SoloEnEsquema=false";

      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { cotizaciones };
