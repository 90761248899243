import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_DETALLE_NOVEDADES_INITIAL_STATE");

const setCurrentNovedad = createAction(
  "SET_CURRENT_USUARIO",
  currentNovedad => ({
    currentNovedad
  })
);

const getNovedadInited = createAction("OBTENER_NOVEDAD_INITED");

const getNovedadCompleted = createAction(
  "OBTENER_NOVEDAD_COMPLETED",
  currentNovedad => ({ currentNovedad })
);

const getNovedadFailed = createAction("OBTENER_NOVEDAD_FAILED", error => ({
  error
}));

const getNovedad = id => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getNovedadInited());

      const response = await services.api.novedades().getNovedad(id);

      dispatch(getNovedadCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getNovedadFailed(error));
    }
  };
};

const detalleNovedadInitialState = {
  currentNovedad: null,
  isLoading: false,
  failed: false
};

const detalleNovedadReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...detalleNovedadInitialState,
        take: state.take,
        novedades: null
      };
    },
    [setCurrentNovedad]: (state, action) => {
      return {
        ...state,
        currentUsuario: action.payload.currentUsuario
      };
    },
    [getNovedadInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [getNovedadCompleted]: (state, action) => {
      return {
        ...state,
        currentNovedad: action.payload.currentNovedad,
        isLoading: false,
        failed: false
      };
    },
    [getNovedadFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true
      };
    }
  },
  detalleNovedadInitialState
);

export { detalleNovedadReducer };
export { getNovedad, setInitialState, setCurrentNovedad };
