import React, { useState, memo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import filterItems from "~libs/filterItems";
import SearcherList from "~styled/searcherList/SearcherList";
import ListView from "~styled/listView/ListView";
import getVehiculosUsos from "./getVehiculosUsos";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

const usosList = getVehiculosUsos();

const VehiculoUsoSelector = memo(({ value, onChange }) => {
  const [filter, setFilter] = useState("");

  const handleItem = (value) => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.searcher}>
        <SearcherList
          onClear={() => handleItem({ value: null, label: null })}
          filter={filter}
          onChange={handleFilterChange}
          placeholder={"Buscar uso"}
        />
        <ListView
          onItemSelected={handleItem}
          list={filter === "" ? usosList : filterItems(usosList, filter)}
        />
      </div>
    </>
  );
});

export default VehiculoUsoSelector;
