import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DatosTiempoCard from "./DatosTiempoCard";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import { getEstadisticas } from "./actions"
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "4.5%",
        minWidth: "10px",
    },
    body: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
    },
    cardsAndCuotasContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        "@media (max-width:1024px)": {
            width: "100%",
            flexWrap: "wrap",
        },
    },
    cards: {
        display: "flex",
        paddingBottom: "30px",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "50%",
        "@media (max-width:1024px)": {
            width: "100%",
            flexWrap: "wrap",
        },
    },
}));

const useReduxSelector = () =>
    useSelector((state) => ({
        estadisticas: state.estadisticasReducer.estadisticas,
        totalRegistros: state.estadisticasReducer.totalRegistros,
        isLoading: state.estadisticasReducer.isLoading,
        failed: state.estadisticasReducer.failed,
    }));

const TiempoCard = () => {
    const dispatch = useDispatch();
    const currentState = useReduxSelector();
    const {
        isLoading,
        failed,
    } = currentState;
    const classes = useStyles();
    useEffect(() => {
        if (!isLoading) {
            dispatch(getEstadisticas());
        }
        //eslint-disable-next-line
    }, [dispatch]);

    if (failed) return <UnexpectedError />;

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                <div className={classes.cardsAndCuotasContainer}>
                    <CardsColumn>
                        <DatosTiempoCard isLoading={isLoading} />
                    </CardsColumn>
                </div>
            </div>
        </div>
    );
};

export default TiempoCard;

const CardsColumn = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.cards}>{children}</div>;
};
