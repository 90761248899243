import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, datosPagoPath } from "~constants/referenceNames";
import { GetInformacionPagoRequest, GetInformacionPagoResponse } from "./types";

const datosPago = () => {
  return {
    getInformacionDatosPago: (
      id: GetInformacionPagoRequest["id"]
    ): Promise<GetInformacionPagoResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = `${environment.segurosUrl}${paths[datosPagoPath]}/${id}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export default datosPago;
