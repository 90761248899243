import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DatePicker from "~styled/datePicker/DatePicker";
import CurrencyInputField from "~styled/currencyInputField/CurrencyInputField";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  textFieldValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    width: "40%",
  },
  numericFieldValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    width: "94%",
  },
  datePicker: {
    width: "40%",
  },
  dash: { display: "flex", justifyContent: "center" },
  icon: {
    color: "#6240a5",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "13em",
  },
}));

const DetalleSolicitudCardItemEditable = ({
  caption,
  onChange,
  type,
  value,
  id,
  required,
  placeholder,
  onValidate,
  displayError,
  focus,
  onBlur,
}) => {
  const classes = useStyles();

  const [valid, setValid] = useState(false);
  const errorText = "Requerido";

  const reference = useRef();

  useEffect(() => {
    if (type === "date") setValid(true);
  }, [type]);

  useEffect(() => {
    if (focus) {
      reference.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    handleChangeValue(value);
    //eslint-disable-next-line
  }, [value]);

  const validate = (data) => {
    let isValid = data !== null && data !== "";

    if (type === "date") {
      let isValidDate = moment(data).isValid();
      isValid = isValid && isValidDate;
    }

    let customValidationIsValid = !onValidate || onValidate(data);

    return isValid && customValidationIsValid;
  };

  const handleChangeValue = (value) => {
    const isDataValid = validate(value);
    setValid(isDataValid);

    // setTouched(false);
    // setErrorText(validation.error);

    onChange({ value: value, valid: isDataValid, focus: false });
  };

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const showError = !valid && displayError;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{caption}</Typography>
      {type === "date" && (
        <DatePicker
          onChange={handleChangeValue}
          onBlur={handleBlur}
          value={value}
          className={classes.datePicker}
          id={id}
          error={showError}
          helperText={showError ? errorText : ""}
          reference={reference}
        />
      )}
      {type === "currency" && (
        <CurrencyInputField
          value={value}
          onChange={(event) => handleChangeValue(event.target.value)}
          onBlur={handleBlur}
          inputProps={{ className: classes.input }}
          id={id}
          hasError={showError}
          required={required}
          helperText={showError ? errorText : ""}
          reference={reference}
        />
      )}
      {!type && (
        <TextField
          inputProps={{ className: classes.input }}
          id="input-with-icon-textfield"
          onChange={(event) => handleChangeValue(event.target.value)}
          onBlur={handleBlur}
          value={value}
          variant="outlined"
          placeholder={placeholder}
          error={showError}
          helperText={showError ? errorText : ""}
          inputRef={reference}
        />
      )}
    </div>
  );
};

export default DetalleSolicitudCardItemEditable;
