import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_SOLICITUDESEMISION_INITIAL_STATE");

const getSolicitudesEmisionInited = createAction(
  "OBTENER_SOLICITUDESEMISION_INITED"
);

const getSolicitudesEmisionCompleted = createAction(
  "OBTENER_SOLICITUDESEMISION_COMPLETED",
  (response) => {
    let solicitudesEmision = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      solicitudesEmision = response.solicitudesEmision;
      totalRegistros = response.totalRegistros;
    }

    return { solicitudesEmision, totalRegistros };
  }
);

const getSolicitudesInicioCompleted = createAction(
  "OBTENER_SOLICITUDESINICIO_COMPLETED",
  (response) => {
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      totalRegistros = response.totalRegistros;
    }

    return { totalRegistros };
  }
);

const getSolicitudesEmisionFailed = createAction(
  "OBTENER_SOLICITUDESEMISION_FAILED",
  (error) => ({
    error,
  })
);

const getSolicitudesEmision = (
  pestania,
  skip,
  take,
  searchCriteria,
  fechaDesde,
  fechaHasta,
  estado,
  negocio,
  oficina,
  dniCliente
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getSolicitudesEmisionInited());

      const data = {
        skip: skip,
        take: take,
        searchCriteria,
        fechaDesde,
        fechaHasta,
        estado: estado,
        negocio,
        dniCliente,
        oficina,
      };

      const response = await services.api
        .solicitudesEmision()
        .getSolicitudesEmision(data);

      pestania === "solicitud" // "solicitud" es desde pestania SOLICITUDES PENDIENTES, "inicio" desde INICIO ya que colisionan los endpoints
        ? dispatch(getSolicitudesEmisionCompleted(response))
        : dispatch(getSolicitudesInicioCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getSolicitudesEmisionFailed(error));
    }
  };
};

const getOficinasInited = createAction("OBTENER_OFICINAS_INITED");

const getOficinas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getOficinasInited());

      const response = await services.api.oficinas().getOficinas();

      dispatch(getOficinasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getOficinasFailed(error));
    }
  };
};

const getOficinasCompleted = createAction(
  "OBTENER_OFICINAS_COMPLETED",
  (response) => {
    let oficinas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      oficinas = response.oficinas;
    }

    return { oficinas };
  }
);

const getOficinasFailed = createAction("OBTENER_OFICINAS_FAILED", (error) => ({
  error,
}));

const solicitudesEmisionInitialState = {
  isLoading: false,
  solicitudesEmision: [],
  totalRegistros: 0,
  oficinasOptions: null,
  oficinasFilter: [],
};

const solicitudesEmisionReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...solicitudesEmisionInitialState,
        solicitudesEmision: null,
        isLoading: false,
      };
    },
    [getSolicitudesEmisionInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getSolicitudesEmisionCompleted]: (state, action) => {
      return {
        ...state,
        solicitudesEmision: action.payload.solicitudesEmision,
        totalRegistros: action.payload.totalRegistros,
        isPageAlreadyLoad: false,
        isLoading: false,
        skip: state.skip + state.take,
      };
    },
    [getSolicitudesInicioCompleted]: (state, action) => {
      return {
        ...state,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
      };
    },
    [getSolicitudesEmisionFailed]: (state, action) => {
      return {
        ...state,
        isPageAlreadyLoad: false,
        isLoading: false,
      };
    },
    [getOficinasInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [getOficinasCompleted]: (state, action) => {
      let oficinasFilter = action.payload.oficinas.map((oficina) => ({
        label: oficina.nombre,
        value: oficina.id,
      }));

      oficinasFilter.push({ label: "Todas", value: "" });

      let oficinasOptions = {};
      oficinasOptions[""] = "Todas";

      action.payload.oficinas.forEach((oficina) => {
        oficinasOptions[oficina.id] = oficina.nombre;
      });

      return {
        ...state,
        oficinasFilter: oficinasFilter,
        oficinasOptions: oficinasOptions,
      };
    },
    [getOficinasFailed]: (state, action) => {
      return {
        ...state,
      };
    },
  },
  solicitudesEmisionInitialState
);

export { solicitudesEmisionReducer };
export { getSolicitudesEmision, setInitialState, getOficinas };
