import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import PersonIcon from "@material-ui/icons/Person";
import ItemInputCard from "~business/Cards/ItemInputCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    return {
      ...currentSiniestro,
    };
  });

const title = "Datos del Asegurado";

const DatosAseguradoCard = ({ isLoading }) => {
  const { email, telefono, nombreAsegurado, numeroDocumentoAsegurado } =
    useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={PersonIcon}>
        <ItemInputCard
          id={"numeroDocumentoAsegurado"}
          isLoading={isLoading}
          label={"DNI"}
          value={numeroDocumentoAsegurado}
          readOnly
        />
        <ItemInputCard
          id={"nombreAsegurado"}
          isLoading={isLoading}
          label={"Nombre"}
          value={nombreAsegurado}
          readOnly
        />
        <ItemInputCard
          id={"email"}
          isLoading={isLoading}
          label={"Correo Electrónico"}
          value={email}
          readOnly
        />
        <ItemInputCard
          id={"telefono"}
          isLoading={isLoading}
          label={"Teléfono"}
          value={telefono}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DatosAseguradoCard;
