import { createAction, handleActions } from "redux-actions";

const setInitialStateUsuarios = createAction(
  "SET_INITIAL_STATE_FILTRO_USUARIOS"
);

const setPage = createAction("SET_LISTADO_USUARIOS_PAGE", page => ({
  page
}));

const setTake = createAction("SET_LISTADO_USUARIOS_ROWS_PER_PAGE", take => ({
  take
}));

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_FILTRO_USUARIOS",
  searchCriteria => ({
    searchCriteria
  })
);

const setGetOnlyActivos = createAction("SET_GET_ONLY_INACTIVOS", getOnlyActivos => ({ getOnlyActivos }));

const filtrosUsuariosInitialState = {
  page: 0,
  take: 10,
  searchCriteria: "",
  getOnlyActivos: true,
};

const filtrosUsuariosReducer = handleActions(
  {
    [setInitialStateUsuarios]: (state, action) => {
      return {
        ...filtrosUsuariosInitialState
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        page: 0,
        take: action.payload.take,
        isLoading: false
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria
      };
    },
    [setGetOnlyActivos]: (state, action) => {
      return {
        ...state,
        getOnlyActivos: action.payload.getOnlyActivos
      }
    }
  },
  filtrosUsuariosInitialState
);

export { filtrosUsuariosReducer };
export { selectSearchCriteria, setInitialStateUsuarios, setPage, setTake, setGetOnlyActivos };
