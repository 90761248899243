export const INGRESADO = "Ingresado";
export const PENDIENTE_COMPANIA = "PendienteCompania";
export const RESUELTO = "Resuelto";
export const RECHAZADO = "Rechazado";

const estadoSiniestrosOptions = {
  [INGRESADO]: "Ingresado",
  [PENDIENTE_COMPANIA]: "Pendiente en Compañía",
  [RESUELTO]: "Resuelto",
  [RECHAZADO]: "Rechazado",
};

const estadoSiniestrosFilter = [
  { label: estadoSiniestrosOptions[INGRESADO], value: INGRESADO },
  {
    label: estadoSiniestrosOptions[PENDIENTE_COMPANIA],
    value: PENDIENTE_COMPANIA,
  },
  { label: estadoSiniestrosOptions[RESUELTO], value: RESUELTO },
  { label: estadoSiniestrosOptions[RECHAZADO], value: RECHAZADO },
];

export { estadoSiniestrosOptions, estadoSiniestrosFilter };
