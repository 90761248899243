export const TRIMESTRAL = 3;
export const CUATRIMESTRAL = 4;
export const SEMESTRAL = 6;
export const ANUAL = 12;

const vigenciasSolicitudEmisionOptions = {
  [TRIMESTRAL]: "Trimestral",
  [CUATRIMESTRAL]: "Cuatrimestral",
  [SEMESTRAL]: "Semestral",
  [ANUAL]: "Anual",
};

const vigenciasSolicitudEmision = [TRIMESTRAL, CUATRIMESTRAL, SEMESTRAL, ANUAL];

const vigenciasSolicitudEmisionFilter = [
  {
    label: vigenciasSolicitudEmisionOptions[TRIMESTRAL],
    value: TRIMESTRAL,
  },
  {
    label: vigenciasSolicitudEmisionOptions[CUATRIMESTRAL],
    value: CUATRIMESTRAL,
  },
  {
    label: vigenciasSolicitudEmisionOptions[SEMESTRAL],
    value: SEMESTRAL,
  },
  {
    label: vigenciasSolicitudEmisionOptions[ANUAL],
    value: ANUAL,
  },
];

export {
  vigenciasSolicitudEmisionOptions,
  vigenciasSolicitudEmision,
  vigenciasSolicitudEmisionFilter,
};
