import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const DetalleSolicitudCardItem = ({ caption, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{caption}</Typography>
      <Typography className={classes.value}>
        {value ? value : <div className={classes.dash}>&mdash;</div>}
      </Typography>
    </div>
  );
};

export default DetalleSolicitudCardItem;
