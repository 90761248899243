import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_POLIZAS_INITIAL_STATE");

const getPolizasInited = createAction("OBTENER_POLIZAS_INITED");

const closeSuccessAnularPopUp = createAction("CLOSE_SUCCESS_ANULAR_POPUP");

const getPolizasCompleted = createAction(
  "OBTENER_POLIZAS_COMPLETED",
  (response) => {
    let polizas = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      polizas = response.polizas;
      totalRegistros = response.totalRegistros;
    }

    return { polizas, totalRegistros };
  }
);
const getPolizasFailed = createAction("OBTENER_POLIZAS_FAILED", (error) => ({
  error,
}));

const patchAnularPolizaInited = createAction("ANULAR_POLIZA_INITED");

const patchAnularPolizaCompleted = createAction(
  "ANULAR_POLIZA_COMPLETED",
  (response) => {}
);
const patchAnularPolizaFailed = createAction(
  "ANULAR_POLIZA_FAILED",
  (error) => ({
    error,
  })
);

const getPolizas = ({
  skip,
  take,
  fechaDesde,
  fechaHasta,
  searchCriteria,
  negocio,
  estado,
  soloParaRenovar,
  compania,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizasInited());

      const data = {
        skip,
        take,
        fechaDesde,
        fechaHasta,
        searchCriteria,
        estado,
        negocio,
        soloParaRenovar,
        compania,
      };
      const response = await services.api.polizas().getPolizas(data);

      dispatch(getPolizasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizasFailed(error));
    }
  };
};

const patchAnularPoliza = (polizaId, motivo, fechaAnulacion) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchAnularPolizaInited());

      const data = {
        motivo,
        fechaAnulacion,
      };
      await services.api.polizas().patchAnularPoliza(polizaId, data);

      dispatch(patchAnularPolizaCompleted());
    } catch (error) {
      console.error(error);
      dispatch(patchAnularPolizaFailed(error));
    }
  };
};

const polizasInitialState = {
  polizas: null,
  isLoading: false,
  failed: false,
  totalRegistros: 0,
  page: 0,
  take: 10,
  reloadPolizas: false,
  polizaAnulada: false,
};

const polizasReducer = handleActions(
  {
    [setInitialState]: () => {
      return {
        ...polizasInitialState,
        polizas: null,
      };
    },
    [getPolizasInited]: (state) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getPolizasCompleted]: (state, action) => {
      return {
        ...state,
        polizas: action.payload.polizas,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
        failed: false,
        reloadPolizas: false,
      };
    },
    [getPolizasFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
        reloadPolizas: false,
      };
    },
    [patchAnularPolizaInited]: (state) => {
      return {
        ...state,
        polizaAnulada: false,
        isLoading: true,
      };
    },
    [patchAnularPolizaCompleted]: (state, action) => {
      return {
        ...state,
        polizaAnulada: true,
        isLoading: false,
      };
    },
    [patchAnularPolizaFailed]: (state) => {
      return {
        ...state,
        polizaAnulada: false,
        isLoading: false,
      };
    },
    [closeSuccessAnularPopUp]: (state, action) => {
      return {
        ...state,
        polizaAnulada: false,
      };
    },
  },
  polizasInitialState
);

export { polizasReducer };
export {
  getPolizas,
  patchAnularPoliza,
  setInitialState,
  closeSuccessAnularPopUp,
};
