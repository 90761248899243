export const ANULACION = "Anulacion";
export const FORMA_PAGO = "CambioFormaPago";
export const DATOS = "DatosIncorrectos";
export const TODOS = "Todos";

const tipoEndosoOptions = {
  [ANULACION]: "Anulación",
  [FORMA_PAGO]: "Cambio forma de pago",
  [DATOS]: "Correción de datos",
};

const tipoEndosoFilter = [
  { label: tipoEndosoOptions[ANULACION], value: ANULACION },
  { label: tipoEndosoOptions[FORMA_PAGO], value: FORMA_PAGO },
  { label: tipoEndosoOptions[DATOS], value: DATOS },
];

export const VENTA_UNIDAD = "VentaUnidad";
export const CAMBIO_COMPANIA = "CambioCompania";
export const MAL_SERVICIO = "MalServicio";
export const NO_ESPECIFICA = "NoEspecifica";

const motivoAnulacionFilter = [
  { label: "Venta de unidad", value: VENTA_UNIDAD },
  { label: "Cambio de compañia", value: CAMBIO_COMPANIA },
  { label: "Mal servicio", value: MAL_SERVICIO },
  { label: "No especifica", value: NO_ESPECIFICA },
];

const motivoAnulacionOptions = {
  [null]: "Selecciona un motivo",
  [VENTA_UNIDAD]: "Venta de unidad",
  [CAMBIO_COMPANIA]: "Cambio de compañia",
  [MAL_SERVICIO]: "Mal servicio",
  [NO_ESPECIFICA]: "No especifica",
};

const tieneGNCFilter = [
  { label: "SI", value: true },
  { label: "NO", value: false },
  { label: "No Informado", value: null },
];

const tieneGNCOptions = {
  [true]: "SI",
  [false]: "NO",
  [null]: "No Informado",
};

export const RECHAZADO = "Rechazado";
export const PENDIENTE = "Pendiente";
export const APLICADO = "Aplicado";

const estadoEndosoOptions = {
  [RECHAZADO]: RECHAZADO,
  [PENDIENTE]: PENDIENTE,
  [APLICADO]: APLICADO,
  [null]: "Todos",
};

const estadoEndosoFilter = [
  { label: TODOS, value: null },
  { label: PENDIENTE, value: PENDIENTE },
  { label: APLICADO, value: APLICADO },
  { label: RECHAZADO, value: RECHAZADO },
];

export {
  tipoEndosoOptions,
  tipoEndosoFilter,
  motivoAnulacionFilter,
  motivoAnulacionOptions,
  tieneGNCFilter,
  tieneGNCOptions,
  estadoEndosoOptions,
  estadoEndosoFilter,
};
