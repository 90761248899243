import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCodigoPostal, clearCodigoPostal } from "../actions";
import makeStyles from "@material-ui/styles/makeStyles";
import CodigoPostalSelector from "~business/codigoPostalSelector/CodigoPostalSelector";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    width: "100%",
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    codigoPostalSelected: state.multicotizadorReducer.codigoPostalSelected,
    provinciaSelected: state.multicotizadorReducer.provinciaSelected,
  }));

const CodigoPostalStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { codigoPostalSelected, provinciaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(clearCodigoPostal());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(setCodigoPostal(event.target.value));
  };

  return (
    <>
      <div className={classes.cotizador}>
        <div className={classes.title}>
          <PrimaryTitle label="¿Cuál es el Código Postal?" marginBottom={32} />
        </div>
        <CodigoPostalSelector
          value={codigoPostalSelected}
          provinciaId={provinciaSelected}
          onChange={handleChange}
        />
      </div>
    </>
  );
});

export default CodigoPostalStep;
