import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { getSiniestro, clearData } from "./actions";
import { paths, siniestrosPath } from "~constants/referenceNames";
import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";
import DetalleSiniestro from "./DetalleSiniestro";
import Comentarios from "./Comentarios";

const useReduxSelector = () =>
  useSelector((state) => ({
    comentarioNuevo: state.detalleSiniestroReducer.comentarioNuevo,
  }));

const DetalleSiniestroContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();
  const { comentarioNuevo } = useReduxSelector();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getSiniestro(id));

    return () => {
      if (comentarioNuevo) dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [comentarioNuevo]);

  const backAction = () => {
    history.push(paths[siniestrosPath]);
  };

  return (
    <DetailsLayout
      title="Detalle de Siniestro"
      backAction={backAction}
      tabsComponent={() => (
        <DetailsTabs
          defaultValue={0}
          labels={[
            <TabIndex text="DETALLE" />,
            <TabIndex text="COMENTARIOS" />,
          ]}
          onChange={(e) => {
            setCurrentTab(parseInt(e.target.value));
          }}
        />
      )}
    >
      {currentTab === 0 && <DetalleSiniestro />}
      {currentTab === 1 && <Comentarios />}
    </DetailsLayout>
  );
};

export default DetalleSiniestroContainer;

const TabIndex = ({ text }) => {
  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>{text}</span>
    </>
  );
};
