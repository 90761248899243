export const FALTA_PAGO = "FaltaPago";
export const DANIOS_NO_CUBIERTOS = "DaniosNoCubiertos";
export const FRAUDE = "Fraude";
export const DUPLICADO = "Duplicado";

const motivoRechazoSiniestroOptions = {
  [FALTA_PAGO]: "Falta de Pago",
  [DANIOS_NO_CUBIERTOS]: "Daños No Cubiertos",
  [FRAUDE]: "Fraude",
  [DUPLICADO]: "Duplicado",
};

const motivoRechazoSiniestroFilter = [
  { label: motivoRechazoSiniestroOptions[FALTA_PAGO], value: FALTA_PAGO },
  {
    label: motivoRechazoSiniestroOptions[DANIOS_NO_CUBIERTOS],
    value: DANIOS_NO_CUBIERTOS,
  },
  { label: motivoRechazoSiniestroOptions[FRAUDE], value: FRAUDE },
  { label: motivoRechazoSiniestroOptions[DUPLICADO], value: DUPLICADO },
];

export { motivoRechazoSiniestroOptions, motivoRechazoSiniestroFilter };
