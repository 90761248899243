import React, { useState, memo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { List, ListItem, ListItemText } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles((theme) => ({
  selectorRoot: {
    display: "flex",
    flexDirection: "column",
    background: theme.colours.white,
    //zIndex: 1,
    position: "relative",
  },
  selectorExpanded: {
    display: "flex",
    padding: "0px 0px 0px 10px",
    borderRadius: "5px 5px 0px 0px",
    borderLeft: "solid 1px #e4e4e6",
    borderRight: "solid 1px #e4e4e6",
    borderTop: "solid 1px #e4e4e6",
    backgroundColor: theme.colours.white,
    justifyContent: "center",
    alignItems: "center",
    //height: "30px",
  },
  selectorNotExpanded: {
    display: "flex",
    padding: "0px 0px 0px 10px",
    borderRadius: "5px",
    border: "solid 1px #e4e4e6",
    backgroundColor: theme.colours.white,
    justifyContent: "center",
    alignItems: "center",
    //height: "30px",
  },
  input: {
    display: "flex",
    padding: "0px 20px 0px 0px",
    flex: 1,
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.brownGrey,
  },
  iconExpand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "solid 1px #e4e4e6",
    borderRadius: "0px 5px 5px 0px",
    padding: "0px 10px 0px 10px",
    color: theme.colours.brownGrey2,
    height: "100%",
  },
  iconButton: {
    color: theme.colours.violeta,
    padding: 0,
  },

  listPaper: {
    border: "solid 1px #e4e4e6",
    borderRadius: "0 0 5px 5px",
    display: "block",
    position: "absolute",
    zIndex: 1,
    width: "100%",
    background: theme.colours.white,
    top: "29px",
  },
  root: { padding: 0 },
  textItem: {
    display: "flex",
    flex: 1,
    fontSize: 12,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.brownGrey,
    paddingLeft: 0,
    margin: 0,
  },
}));

const Selector = ({
  value,
  onChange,
  options,
  error,
  disabled,
  onClickDisabled,
  variant = "normal",
}) => {
  const [optionsExpanded, setOptionsExpanded] = useState(false);

  const handleChange = (newValue) => {
    setOptionsExpanded(false);
    if (onChange) onChange(newValue);
  };

  const handleClickAway = () => {
    setOptionsExpanded(false);
  };

  const height = variant === "big" ? 40 : 30;
  const fontSize = variant === "big" ? 14 : 12;
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.selectorRoot}>
        <IconButton
          onClick={() => {
            disabled ? onClickDisabled() : setOptionsExpanded(!optionsExpanded);
          }}
          style={
            error
              ? { height: pxToRem(height), border: "solid 1px #f77221" }
              : { height: pxToRem(height) }
          }
          className={
            optionsExpanded
              ? classes.selectorExpanded
              : classes.selectorNotExpanded
          }
        >
          <div className={classes.input} style={{ fontSize: fontSize }}>
            {value}
          </div>
          <div className={classes.iconExpand}>
            <ExpandMoreIcon style={{ width: "1.8rem", height: "100%" }} />
          </div>
        </IconButton>
        {optionsExpanded && (
          <ListView
            list={options}
            onItemSelected={handleChange}
            value={value}
            variant={variant}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Selector;

const ListView = memo(
  ({ list, onItemSelected, emptyListText, value, variant }) => {
    const classes = useStyles();

    const height = variant === "big" ? 40 : 30;

    return (
      <div className={classes.listPaper} style={{ top: pxToRem(height - 1) }}>
        <List className={classes.root}>
          {list.map((item) => (
            <ListViewItem
              key={`lisViewItem-${item.label}`}
              value={item.value}
              label={item.label}
              onItemSelected={onItemSelected}
            />
          ))}
          {list.length === 0 && (
            <ListItem className={classes.item}>
              <ListItemText
                className={classes.textItem}
                primary={emptyListText}
                disableTypography
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
);

const ListViewItem = memo(({ value, label, onItemSelected }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      divider
      onClick={() => {
        onItemSelected(value);
      }}
      style={{ padding: 8 }}
    >
      <ListItemText
        className={classes.textItem}
        primary={label}
        disableTypography
      />
    </ListItem>
  );
});
