import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const putDescripcionCoberturaInited = createAction(
  "MODIFICAR_DESCRIPCION_COBERTURAS_INITED"
);
const putDescripcionCoberturaCompleted = createAction(
  "MODIFICAR_DESCRIPCION_COBERTURAS_COMPLETED"
);
const putDescripcionCoberturaFailed = createAction(
  "MODIFICAR_DESCRIPCION_COBERTURAS_FAILED",
  error => ({ error })
);

const postDescripcionCoberturaInited = createAction(
  "AGREGAR_DESCRIPCION_COBERTURAS_INITED"
);
const postDescripcionCoberturaCompleted = createAction(
  "AGREGAR_DESCRIPCION_COBERTURAS_COMPLETED",
  (response) => {
    let cobertura = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      cobertura = response;
    }

    return { cobertura };
  }
);
const postDescripcionCoberturaFailed = createAction(
  "AGREGAR_DESCRIPCION_COBERTURAS_FAILED",
  error => ({ error })
);

const getDescripcionCoberturaInited = createAction(
  "OBTENER_DESCRIPCION_COBERTURAS_INITED"
);
const getDescripcionCoberturaCompleted = createAction(
  "OBTENER_DESCRIPCION_COBERTURAS_COMPLETED",
  response => {
    let descripcionCoberturas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      descripcionCoberturas = response;
    }

    return { descripcionCoberturas };
  }
);
const getDescripcionCoberturaFailed = createAction(
  "OBTENER_DESCRIPCION_COBERTURAS_FAILED",
  error => ({ error })
);

const updateDescripcionesCoberturas = createAction(
  "UPDATE_DESCRIPCION_COBERTURAS",
  descripcionCoberturas => ({ descripcionCoberturas })
);

const setCompaniaSelected = createAction(
  "SET_COMPANIA_SELECTED",
  companiaSelected => ({ companiaSelected })
);

const putDescripcionCobertura = (
  id,
  nombre,
  descripcion,
  tipoCobertura,
  compania,
  negocios
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(
        putDescripcionCoberturaInited({
          id,
          nombre,
          descripcion,
          tipoCobertura,
          compania,
          negocios
        })
      );

      const data = {
        id,
        nombre,
        descripcion,
        tipoCobertura,
        compania,
        negocios
      };
      await services.api.coberturas().putCoberturas(data);

      dispatch(putDescripcionCoberturaCompleted());
    } catch (error) {
      console.error(error);
      dispatch(putDescripcionCoberturaFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al guardar los cambios. Intente nuevamente."
        )
      );
    }
  };
};

const postDescripcionCobertura = (
  id,
  nombre,
  descripcion,
  tipoCobertura,
  compania,
  negocios
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(
        postDescripcionCoberturaInited({
          id,
          nombre,
          descripcion,
          tipoCobertura,
          compania,
          negocios
        })
      );

      const data = {
        id,
        nombre,
        descripcion,
        tipoCobertura,
        compania,
        negocios
      };

      const cobertura = await services.api.coberturas().postCoberturas(data);
      
      data.id = cobertura.id;
      dispatch(postDescripcionCoberturaCompleted(data));
    } catch (error) {
      console.error(error);
      dispatch(postDescripcionCoberturaFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al guardar los cambios. Intente nuevamente."
        )
      );
    }
  };
};

const listadoCoberturasInitialState = {
  descripcionCoberturas: [],
  failed: false
};

const listadoCoberturasReducer = handleActions(
  {
    [putDescripcionCoberturaInited]: (state, action) => {
      return {
        ...state
      };
    },
    [putDescripcionCoberturaCompleted]: (state, action) => {
      return {
        ...state
      };
    },
    [putDescripcionCoberturaFailed]: (state, action) => {
      return {
        ...state
      };
    },
    [postDescripcionCoberturaInited]: (state, action) => {
      return {
        ...state
      };
    },
    [postDescripcionCoberturaCompleted]: (state, action) => {
      const { compania, ...nuevaCobertura } = action.payload.cobertura;
      const index = state.descripcionCoberturas.companias.findIndex( x => x.idCompania === compania);
      const descripcionCoberturas = {...state.descripcionCoberturas};
      descripcionCoberturas.companias[index].coberturasAutos.push(nuevaCobertura);      
      
      return {
        ...state,
        descripcionCoberturas: descripcionCoberturas
      };
    },
    [postDescripcionCoberturaFailed]: (state, action) => {
      return {
        ...state
      };
    },
    [getDescripcionCoberturaInited]: (state, action) => {
      return {
        ...state
      };
    },
    [getDescripcionCoberturaCompleted]: (state, action) => {
      return {
        ...state,
        descripcionCoberturas: action.payload.descripcionCoberturas,
        companiaSelected: action.payload.descripcionCoberturas.companias[0],
        failed: false
      };
    },
    [getDescripcionCoberturaFailed]: (state, action) => {
      return {
        ...state,
        failed: true
      };
    },
    [updateDescripcionesCoberturas]: (state, action) => {
      return {
        ...state,
        descripcionCoberturas: action.payload.descripcionCoberturas
      };
    },
    [setCompaniaSelected]: (state, action) => {
      return {
        ...state,
        companiaSelected: action.payload.companiaSelected
      };
    }
  },
  listadoCoberturasInitialState
);

const getDescripcionCobertura = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDescripcionCoberturaInited());

      const response = await services.api.coberturas().getCoberturas();

      dispatch(getDescripcionCoberturaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDescripcionCoberturaFailed(error));
    }
  };
};

export { listadoCoberturasReducer };
export {
  putDescripcionCobertura,
  postDescripcionCobertura,
  getDescripcionCobertura,
  updateDescripcionesCoberturas,
  setCompaniaSelected
};
