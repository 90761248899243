import { createAction, handleActions } from "redux-actions";

const setInitialStatePolizas = createAction("SET_INITIAL_STATE_FILTRO_POLIZAS");

const setPage = createAction("SET_LISTADO_POLIZAS_PAGE", (page) => ({
  page,
}));

const setTake = createAction("SET_LISTADO_POLIZAS_ROWS_PER_PAGE", (take) => ({
  take,
}));

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_POLIZAS",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const selectFechaDesde = createAction(
  "SELECT_FECHA_DESDE_POLIZAS",
  (fechaDesde) => ({ fechaDesde })
);

const selectFechaHasta = createAction(
  "SELECT_FECHA_HASTA_POLIZAS",
  (fechaHasta) => ({ fechaHasta })
);

const selectEstado = createAction("SELECT_ESTADO_FILTRO_POLIZAS", (estado) => ({
  estado,
}));

const selectNegocio = createAction(
  "SELECT_NEGOCIO_FILTRO_POLIZAS",
  (negocio) => ({
    negocio,
  })
);

const selectCompania = createAction(
  "SELECT_COMPANIA_FILTRO_POLIZAS",
  (compania) => ({
    compania,
  })
);

const setBuscarSoloParaRenovar = createAction(
  "SELECT_BUSCAR_SOLO_RENOVAR_POLIZAS",
  (soloParaRenovar) => ({ soloParaRenovar })
);

const filtrosPolizasInitialState = {
  page: 0,
  take: 10,
  searchCriteria: "",
  estado: "",
  fechaDesde: null,
  fechaHasta: null,
  negocio: "",
  soloParaRenovar: false,
  compania: "",
};

const filtrosPolizasReducer = handleActions(
  {
    [setInitialStatePolizas]: (state, action) => {
      return {
        ...filtrosPolizasInitialState,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        page: 0,
        take: action.payload.take,
      };
    },
    [selectEstado]: (state, action) => {
      return {
        ...state,
        estado: action.payload.estado,
      };
    },
    [selectFechaDesde]: (state, action) => {
      return {
        ...state,
        fechaDesde: action.payload.fechaDesde,
      };
    },
    [selectFechaHasta]: (state, action) => {
      return {
        ...state,
        fechaHasta: action.payload.fechaHasta,
      };
    },
    [selectCompania]: (state, action) => {
      return {
        ...state,
        compania: action.payload.compania,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [selectNegocio]: (state, action) => {
      return {
        ...state,
        negocio: action.payload.negocio,
      };
    },
    [setBuscarSoloParaRenovar]: (state, action) => {
      return {
        ...state,
        soloParaRenovar: action.payload.soloParaRenovar,
      };
    },
  },
  filtrosPolizasInitialState
);

export { filtrosPolizasReducer };
export {
  setInitialStatePolizas,
  selectSearchCriteria,
  selectEstado,
  selectNegocio,
  setPage,
  setTake,
  setBuscarSoloParaRenovar,
  selectCompania,
  selectFechaDesde,
  selectFechaHasta
};
