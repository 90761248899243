import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, polizasPath } from "~constants/referenceNames";
import {
  GetPolizaResponse,
  GetPolizasRequest,
  GetPolizasResponse,
  PatchImpresosPolizaRequest,
  PostSolicitarEndosoRequest,
  PostEndosoResponse,
  PutAplicarEndosoRequest,
  PutRechazarEndosoRequest,
  PostRenovarPolizaRequest,
  PostRenovarPolizaResponse,
  PatchAnularPolizaRequest,
  PolizaResponse,
} from "./types";

let mockActive = false;

const polizas = () => {
  return {
    getPoliza: (id: string): Promise<GetPolizaResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + paths[polizasPath] + "/" + id;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getPolizas: async (
      data: GetPolizasRequest
    ): Promise<GetPolizasResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.estado
        ? parameters + `&Estado=${data.estado}`
        : parameters;

      parameters = data.negocio
        ? parameters + `&Negocio=${data.negocio}`
        : parameters;

      parameters = data.compania
        ? parameters + `&Compania=${data.compania}`
        : parameters;

      parameters = data.fechaDesde
        ? parameters + `&FechaDesde=${data.fechaDesde.toISOString()}`
        : parameters;

      if (data.fechaHasta) {
        data.fechaHasta.setHours(23, 59, 59);
        parameters =
          parameters + `&FechaHasta=${data.fechaHasta.toISOString()}`;
      }
      if (data.soloParaRenovar) {
        parameters += `&SoloParaRenovar=${data.soloParaRenovar}`;
      }

      let url = environment.segurosUrl + paths[polizasPath] + "?" + parameters;

      try {
        if (mockActive) {
          return getPolizasMock(data);
        } else {
          const response = await fetch(url, request);
          errorUnlessOk(response);
          return await response.json();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    patchPoliza: (
      polizaId: string,
      data: PatchImpresosPolizaRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/impresos`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    postEndosar: (
      polizaId: string,
      data: PostSolicitarEndosoRequest
    ): Promise<PostEndosoResponse> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/endosos`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    putAplicarEndoso: (
      polizaId: string,
      endosoId: string,
      data: PutAplicarEndosoRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/endosos/${endosoId}/aplicacion`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    putRechazarEndoso: (
      polizaId: string,
      endosoId: string,
      data: PutRechazarEndosoRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/endosos/${endosoId}/rechazo`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    postValidatePolizaARenovar: (polizaId: string): Promise<Response> => {
      const request = buildRequestWithAuth("POST", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/validate/renovacion`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    postRenovarPoliza: (
      polizaId: string,
      data: PostRenovarPolizaRequest
    ): Promise<PostRenovarPolizaResponse> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/renovar`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    patchAnularPoliza: (
      polizaId: string,
      data: PatchAnularPolizaRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/anular`;
      console.log(url);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { polizas };

const getPolizasMock = (data): GetPolizasResponse => {
  const polizas: PolizaResponse[] = [
    {
      Id: "49d37a99-62e3-4f1a-8cb1-6b3452b4baa3",
      OperacionCreditoId: "123",
      Estado: "Activa",
      Negocio: "Prendarios",
      Titular: "TAPIA HEREDIA RICARDO DAVID",
      DniTitular: "29369441",
      Marca: "VOLKSWAGEN",
      Modelo: "AMAROK 20TD 4X2 DC HIGHLINE PK",
      Anio: 2011,
      Dominio: "KOM076",
      Compania: "Sancor",
      TipoCobertura: "TCPremium",
      NumeroPoliza: "9002484",
      PremioTotal: 0,
      PrimaTotal: 0,
      PolizaVigenciaDesde: new Date("2020-02-06T03:00:00.000Z"),
      PolizaVigenciaHasta: new Date("2020-08-06T03:00:00.000Z"),
      FechaBajaAnticipadaPoliza: new Date("2020-02-06T03:00:00.000Z"),
      FechaVencimientoUltimaCuotaCredito: new Date("2020-08-06T03:00:00.000Z"),
      Renovable: true,
    },
  ];

  return {
    Polizas: polizas,
    TotalRegistros: polizas.length,
  };
};
