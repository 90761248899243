const TODOS = "Pendiente,PendienteCompania";
export const PENDIENTE = "Pendiente";
export const PENDIENTECOMPANIA = "PendienteCompania";

const estadosSolicitudEmisionOptions = {
  [TODOS]: "Todos",
  [PENDIENTE]: "Pendiente de Emisións",
  [PENDIENTECOMPANIA]: "Pendiente en Compañía",
};

const estadosSolicitudEmision = [PENDIENTE, PENDIENTECOMPANIA];

const estadosSolicitudEmisionFilter = [
  { label: estadosSolicitudEmisionOptions[TODOS], value: TODOS },
  {
    label: estadosSolicitudEmisionOptions[PENDIENTE],
    value: PENDIENTE,
  },
  {
    label: estadosSolicitudEmisionOptions[PENDIENTECOMPANIA],
    value: PENDIENTECOMPANIA,
  },
];

export {
  estadosSolicitudEmisionOptions,
  estadosSolicitudEmision,
  estadosSolicitudEmisionFilter,
};
