const validateDominio = (value) => {
  if (value.length > 10) return false;

  const oldCarPlateFormat = "^(101)?[a-zA-Z]{3}[0-9]{3}$";
  const newCarPlateFormat = "^(101)?[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$";
  const oldMotorcyclePlateFormat = "^[0-9]{3}[a-zA-Z]{3}$";
  const newMotorcyclePlateFormat = "^[a-zA-Z][0-9]{3}[a-zA-Z]{3}$";

  const expression = RegExp(
    `${oldCarPlateFormat}|${newCarPlateFormat}|${oldMotorcyclePlateFormat}|${newMotorcyclePlateFormat}`
  );

  return expression.test(value);
};

export default validateDominio;
