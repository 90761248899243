import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

import { useSelector } from "react-redux";

import ItemInputCard from "~business/Cards/ItemInputCard";

const title = "Vehículo";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosVehiculo =
      state.renovacionPolizaReducer.currentPoliza.datosVehiculo;
    return {
      marca: datosVehiculo.marca,
      modelo: datosVehiculo.modelo,
      anio: datosVehiculo.anio,
      codia: datosVehiculo.codia,
      codigoPostal: datosVehiculo.codigoPostal,
      nombreProvincia: datosVehiculo.nombreProvincia,
      nombreLocalidad: datosVehiculo.nombreLocalidad,
      dominio: datosVehiculo.dominio,
      numeroMotor: datosVehiculo.numeroMotor,
      numeroChasis: datosVehiculo.numeroChasis,
    };
  });
/*
 */

const DatosVehiculoCard = ({ isLoading, editable, onBlur, displayError }) => {
  const {
    marca,
    modelo,
    anio,
    codia,
    codigoPostal,
    nombreProvincia,
    nombreLocalidad,
    dominio,
    numeroMotor,
    numeroChasis,
  } = useReduxSelector();

  const localidad =
    nombreProvincia && nombreLocalidad
      ? `(CP ${codigoPostal}) ${nombreLocalidad}, ${nombreProvincia}`
      : `(CP ${codigoPostal})`;

  const vehiculo = marca && modelo ? `(${anio}) ${marca} ${modelo}` : "";
  return (
    <DetalleCard title={title} IconHeader={DriveEtaIcon}>
      <ItemInputCard
        isLoading={isLoading}
        label={"Vehículo"}
        value={vehiculo.toUpperCase()}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"CODIA"}
        value={codia}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Localidad"}
        value={localidad}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Dominio"}
        value={dominio}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Número de Motor"}
        value={numeroMotor}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Número de Chasis"}
        value={numeroChasis}
        readOnly
      />
    </DetalleCard>
  );
};

export default DatosVehiculoCard;
