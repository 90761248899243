import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getDetalleSolicitudEmision, clearData } from "./actions";
import { paths, solicitudesEmisionPath } from "~constants/referenceNames";
import Documentacion from "./Documentacion";
import Historia from "./Historia";
import DetalleSolicitudHistorica from "./DetalleSolicitudHistorica";
import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";
import { estadosSolicitudEmisionOptions } from "../listado/filtros/estados";

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
    errorMessage: state.detalleSolicitudHistoricaReducer.errorMessage,
  }));

const DetalleSolicitudHistoricaContainer = () => {
  const { detalleSolicitudEmision } = useReduxSelector();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getDetalleSolicitudEmision(id));

    return () => {
      dispatch(clearData());
    };
  }, [dispatch, id]);

  const backAction = () => {
    history.push(paths[solicitudesEmisionPath]);
  };

  return (
    <DetailsLayout
      title={"Detalle de la Solicitud"}
      backAction={backAction}
      subtitle={
        detalleSolicitudEmision
          ? estadosSolicitudEmisionOptions[detalleSolicitudEmision.estado]
          : null
      }
      selectedDefault={solicitudesEmisionPath}
      tabsComponent={() => (
        <DetailsTabs
          defaultValue={0}
          labels={[
            <TabIndex text="DETALLE" />,
            <TabIndex text="DOCUMENTACIÓN" />,
            <TabIndex text="HISTORIA" />,
          ]}
          onChange={(e) => {
            setCurrentTab(parseInt(e.target.value));
          }}
        />
      )}
    >
      {currentTab === 0 && <DetalleSolicitudHistorica />}
      {currentTab === 1 && <Documentacion />}
      {currentTab === 2 && <Historia />}
    </DetailsLayout>
  );
};

const TabIndex = ({ text }) => {
  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>{text}</span>
    </>
  );
};

export default DetalleSolicitudHistoricaContainer;
