import React, { memo, useState, Fragment, ChangeEvent } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import getProvincias from "./getProvincias";
import filterItems from "~libs/filterItems";
import SearcherList from "../searcherList/SearcherList";
import ListView from "../listView/ListView";
import localRepository from "../../../localRepository";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

interface VehiculoProvinciaSelectorProps {
  value: any;
  onChange?: (event: { target: { value: string } }) => void;
}

const provincias = getProvincias();

const VehiculoProvinciaSelector: React.FC<VehiculoProvinciaSelectorProps> =
  memo(({ onChange }) => {
    const [filter, setFilter] = useState<string>("");

    const mostUsedProv = localRepository.mostUsedProv
      .get()
      .sort((x: { count: number }, y: { count: number }) => y.count - x.count)
      .slice(0, 3);

    const handleItem = (item: { label: string; value: string | null }) => {
      setFilter("");
      if (onChange) onChange({ target: { value: item.value || "" } });
      localRepository.mostUsedProv.set(item);
    };

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFilter(event.target.value);
    };

    const classes = useStyles();

    return (
      <Fragment>
        <div className={classes.searcher}>
          <SearcherList
            onClear={() => setFilter("")}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar provincia"}
            borderBottomRounded={""}
          />
          <ListView
            onItemSelected={handleItem}
            list={filter === "" ? provincias : filterItems(provincias, filter)}
            mostUsedList={filter === "" ? mostUsedProv : []}
          />
        </div>
      </Fragment>
    );
  });

export default VehiculoProvinciaSelector;
