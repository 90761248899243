import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import ItemLoadingCard from "../ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import Modal from "../Modal";
import FormaPagoStepper from "./FormaPagoStepper";
import { formasPagoOptions } from "~constants/formasPago";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

interface ItemFormaPagoCardProps {
  label: string;
  onChange?: (data: {
    formaPago: { value: string; valid: boolean; focus: boolean };
    cbu?: { value: string | null; valid: boolean; focus: boolean };
    tarjetaCredito?: { value: string | null; valid: boolean; focus: boolean };
    marcaTarjetaCredito?: {
      value: string | null;
      valid: boolean;
      focus: boolean;
    };
  }) => void;
  readOnly?: boolean;
  isLoading?: boolean;
  formaPago: string | null;
}

const ItemFormaPagoCard: React.FC<ItemFormaPagoCardProps> = ({
  label,
  onChange,
  readOnly = false,
  isLoading = false,
  formaPago,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState<boolean>(false);

  const handleChangeValue = ({
    formaPago,
    cbu,
    tarjetaCredito,
    marcaTarjetaCredito,
  }: {
    formaPago: { value: string };
    cbu?: string | null;
    tarjetaCredito?: string | null;
    marcaTarjetaCredito?: string | null;
  }) => {
    setEdit(false);

    if (onChange) {
      onChange({
        formaPago: { value: formaPago.value, valid: true, focus: false },
        cbu: { value: cbu || null, valid: true, focus: false },
        tarjetaCredito: {
          value: tarjetaCredito || null,
          valid: true,
          focus: false,
        },
        marcaTarjetaCredito: {
          value: marcaTarjetaCredito || null,
          valid: true,
          focus: false,
        },
      });
    }
  };

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        <Modal
          opened={edit}
          title="Selector de Forma de Pago"
          onClose={() => setEdit(false)}
        >
          <FormaPagoStepper onChange={handleChangeValue} />
        </Modal>
        {!edit && (
          <>
            <Typography className={classes.value}>
              {formasPagoOptions[formaPago as keyof typeof formasPagoOptions] ||
                ""}
            </Typography>
            {!readOnly && (
              <div className={classes.buttonLink} onClick={() => setEdit(true)}>
                <img alt="" src={IconEdit} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemFormaPagoCard;

// options={formasPagoEndoso}
// valueOptions={formasPagoOptions}
