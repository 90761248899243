import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import moment from "moment";
import { formasPagoOptions } from "../../altaPoliza/constants/formasPago";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentPoliza = state.detallePolizaMultiramaReducer.currentPoliza
      ? state.detallePolizaMultiramaReducer.currentPoliza
      : {};
    const asegurado = state.detallePolizaMultiramaReducer.currentPoliza
      ? state.detallePolizaMultiramaReducer.currentPoliza.asegurado
      : {};
    return {
      ...currentPoliza,
      ...asegurado,
    };
  });

const title = "Datos de Póliza";

const DatosGeneralesCard = ({ isLoading }) => {
  const {
    numeroPoliza,
    nombre,
    apellido,
    numeroDocumento,
    dominio,
    cobertura,
    ramaNombre,
    usuarioNombre,
    compania,
    formaPago,
    cantidadCuotas,
    premioTotal,
    polizaVigenciaDesde,
    polizaVigenciaHasta,
  } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemInputCard
          id={"nroPoliza"}
          isLoading={isLoading}
          label={"Número de Póliza"}
          value={numeroPoliza}
          readOnly
        />
        <ItemInputCard
          id={"nombre"}
          isLoading={isLoading}
          label={"Nombre"}
          value={nombre}
          readOnly
        />
        <ItemInputCard
          id={"apellido"}
          isLoading={isLoading}
          label={"Apellido"}
          value={apellido}
          readOnly
        />
        <ItemInputCard
          id={"dni"}
          isLoading={isLoading}
          label={"Número de Documento"}
          value={numeroDocumento}
          readOnly
        />
        <ItemInputCard
          id={"dominio"}
          isLoading={isLoading}
          label={"Dominio"}
          value={dominio}
          readOnly
        />
        <ItemInputCard
          id={"cobertura"}
          isLoading={isLoading}
          label={"Cobertura"}
          value={cobertura}
          readOnly
        />
        <ItemInputCard
          id={"rama"}
          isLoading={isLoading}
          label={"Rama"}
          value={ramaNombre}
          variant="big"
          readOnly
        />
        <ItemInputCard
          id={"compania"}
          isLoading={isLoading}
          label={"Compañía"}
          value={compania}
          variant="big"
          readOnly
        />
        <ItemInputCard
          id={"usuario"}
          isLoading={isLoading}
          label={"Usuario"}
          value={usuarioNombre}
          variant="big"
          readOnly
        />
        <ItemInputCard
          id={"formaPago"}
          isLoading={isLoading}
          label={"Forma de Pago"}
          value={formasPagoOptions[formaPago]}
          variant="big"
          readOnly
        />
        <ItemInputCard
          id={"cuotas"}
          isLoading={isLoading}
          label={"Cantidad de Cuotas"}
          value={cantidadCuotas}
          variant="big"
          readOnly
        />
        <ItemInputCard
          id={"vigencia"}
          isLoading={isLoading}
          label={"Vigencia"}
          value={`${moment(polizaVigenciaDesde)
            .local()
            .format("DD/MM/YYYY")} a ${moment(polizaVigenciaHasta)
            .local()
            .format("DD/MM/YYYY")}`}
          variant="big"
          readOnly
        />
        <ItemCurrencyCard
          readOnly
          id={"premio"}
          isLoading={isLoading}
          label={"Premio Mensual"}
          value={premioTotal}
        />
      </DetalleCard>
    </>
  );
};

export default DatosGeneralesCard;
