import { useEffect, useState } from "react";
import localRepository from "../../../localRepository";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "../../Auth0/useAuth";
import { postOnboarding } from "../../auth/actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    isOnboardingDone: state.authReducer.validated,
    isValidating: state.authReducer.validating
  }));

const usePostOnboarding = () => {
  const token = localRepository.accessToken.get();
  const { isOnboardingDone, isValidating } = useReduxSelector();

  const dispatch = useDispatch();
  let { authState, renewSession } = useAuth0();

  const [isLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  useEffect(() => {
    const userRequiresOnboarding =
      token &&
      ((authState.user && !authState.user.isAuthorized) || !isLoggedIn);

    if (userRequiresOnboarding && !isOnboardingDone && !isValidating) {
      dispatch(postOnboarding());
      renewSession();
    }

  }, [dispatch, authState, token, isLoggedIn, renewSession, isOnboardingDone, isValidating]);
};

export default usePostOnboarding;
