import { buildRequest, errorUnlessOk } from "./utils";
import environment from "~libs/environment";

interface Marca {
  name: string;
}
interface Modelo {
  name: string;
}
interface Version {
  version: string;
}
interface CodigoPostal {
  codigo: string;
}
interface Localidad {
  localidad: string;
}

const vehiculos = () => {
  return {
    getMarcas: async (): Promise<Marca[]> => {
      const request = buildRequest("GET");

      try {
        const response = await fetch(
          `${environment.vehiclesUrl}/marcas`,
          request
        );
        errorUnlessOk(response);
        return await response.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    getModelos: async (marca: string, anio: string): Promise<Modelo[]> => {
      const request = buildRequest("GET");

      try {
        const response = await fetch(
          `${environment.vehiclesUrl}/marcas/${encodeURIComponent(
            marca
          )}/anios/${encodeURIComponent(anio)}/versiones`,
          request
        );
        errorUnlessOk(response);
        return await response.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };
};

const provincias = () => {
  return {
    getCodigosPostales: async (
      provinciaId: string,
      localidad?: string
    ): Promise<CodigoPostal[]> => {
      const request = buildRequest("GET");

      let finalUrl = `${
        environment.catalogosUrl
      }/provincias/${encodeURIComponent(provinciaId)}/codigospostales`;

      if (localidad) {
        finalUrl += `?localidad=${encodeURIComponent(localidad)}`;
      }

      try {
        const response = await fetch(finalUrl, request);
        errorUnlessOk(response);
        return await response.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    getLocalidades: async (provinciaId: string): Promise<Localidad[]> => {
      const request = buildRequest("GET");

      try {
        const response = await fetch(
          `${environment.catalogosUrl}/provincias/${encodeURIComponent(
            provinciaId
          )}/localidades?format=json`,
          request
        );
        errorUnlessOk(response);
        return await response.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };
};

export { vehiculos, provincias };
