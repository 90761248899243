import React, { useEffect, memo } from "react";
import { useUrlManager } from "./useUrlManager";
import LoadingView from "~styled/loadingView/LoadingView";
import { useAuth0 } from "../../Auth0/useAuth";
import { useSelector, useDispatch } from "react-redux";

const useReduxSelector = () =>
  useSelector((state) => ({
    isAuthorized: state.authReducer.isAuthorized,
    validated: state.authReducer.validated,
  }));

const LoadingViewAuthenticated = memo(() => {
  const { validated } = useReduxSelector();

  const dispatch = useDispatch();
  let { authState, renewSession } = useAuth0();

  useEffect(() => {
    if (authState.user && !authState.user.isAuthorized) {
      renewSession();
    }
  }, [dispatch, authState, renewSession, validated]);

  useUrlManager();

  return <LoadingView />;
});

export default LoadingViewAuthenticated;
