const TODAS = "";
export const MONOTRIBUTISTA = "Monotributista";
export const RESPONSABLEINSCRIPTO = "ResponsableInscripto";
export const IVAEXENTO = "IvaExento";

const condicionesFiscalesOptions = {
  [TODAS]: "Todas",
  [MONOTRIBUTISTA]: "Monotributista",
  [RESPONSABLEINSCRIPTO]: "Responsable Inscripto",
  [IVAEXENTO]: "Iva Exento",
};

const condicionesFiscalesFilter = [
  { label: condicionesFiscalesOptions[TODAS], value: TODAS },
  { label: condicionesFiscalesOptions[MONOTRIBUTISTA], value: MONOTRIBUTISTA },
  {
    label: condicionesFiscalesOptions[RESPONSABLEINSCRIPTO],
    value: RESPONSABLEINSCRIPTO,
  },
  {
    label: condicionesFiscalesOptions[IVAEXENTO],
    value: IVAEXENTO,
  },
];

export { condicionesFiscalesOptions, condicionesFiscalesFilter };
