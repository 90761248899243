const TODOS = "";
export const RC = "RC";
export const TCBASE = "TCBase";
export const TCPREMIUM = "TCPremium";
export const TR = "TR";

const tipoCoberturasOptions = {
  [TODOS]: "Todos",
  [RC]: "Responsabilidad Civil",
  [TCBASE]: "Terceros Completo",
  [TCPREMIUM]: "Terceros Premium",
  [TR]: "Todo Riesgo",
};

const tipoCoberturasFilter = [
  { label: tipoCoberturasOptions[TODOS], value: TODOS },
  { label: tipoCoberturasOptions[RC], value: RC },
  { label: tipoCoberturasOptions[TCBASE], value: TCBASE },
  { label: tipoCoberturasOptions[TCPREMIUM], value: TCPREMIUM },
  { label: tipoCoberturasOptions[TR], value: TR },
];

export { tipoCoberturasOptions, tipoCoberturasFilter };
