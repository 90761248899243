import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { getNovedad } from "./actions";
import { paths, novedadesPath } from "~constants/referenceNames";
import Cuotas from "./Cuotas";
import DetalleNovedad from "./DetalleNovedad";
import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";
import { useHistory } from "react-router-dom";

const DetalleNovedadContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getNovedad(id));
    //eslint-disable-next-line
  }, []);

  const backAction = () => {
    history.push(paths[novedadesPath]);
  };

  return (
    <DetailsLayout
      title={"Detalle de la Novedad"}
      selectedDefault={novedadesPath}
      backAction={backAction}
      tabsComponent={() => (
        <DetailsTabs
          defaultValue={0}
          labels={[<TabIndex text="DETALLE" />, <TabIndex text="CUOTAS" />]}
          onChange={(e) => {
            setCurrentTab(parseInt(e.target.value));
          }}
        />
      )}
    >
      {currentTab === 0 && <DetalleNovedad />}
      {currentTab === 1 && <Cuotas />}
    </DetailsLayout>
  );
};

const TabIndex = ({ text }) => {
  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>{text}</span>
    </>
  );
};

export default DetalleNovedadContainer;
