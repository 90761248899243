import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import getProvincias from "./utils/getProvincias";
import MultipleItemSelectorCard from "~business/Cards/MultipleItemSelectorCard";

import {
  setFecha,
  setHora,
  setCalle,
  setAltura,
  setLocalidad,
  setProvincia,
  setCodigoPostal,
  setSubmitting,
  fetchLocalidades,
  fetchCodigosPostales,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    fecha: state.cargaSiniestroPublicaReducer.fechaSelected,
    hora: state.cargaSiniestroPublicaReducer.horaSelected,
    calle: state.cargaSiniestroPublicaReducer.calleSelected,
    altura: state.cargaSiniestroPublicaReducer.alturaSelected,
    localidad: state.cargaSiniestroPublicaReducer.localidadSelected,
    provincia: state.cargaSiniestroPublicaReducer.provinciaSelected,
    codigoPostal: state.cargaSiniestroPublicaReducer.codigoPostalSelected,
    localidades: state.cargaSiniestroPublicaReducer.localidades,
    codigosPostales: state.cargaSiniestroPublicaReducer.codigosPostales,
  }));

const title = "Fecha y Lugar";

const FechaLugarCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const {
    fecha,
    hora,
    calle,
    altura,
    localidad,
    provincia,
    codigoPostal,
    localidades,
    codigosPostales,
  } = useReduxSelector();

  let timer = null;

  const handleChangeFecha = (fecha) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setFecha(fecha));
    }, 200);
  };

  const handleChangeHora = (hora) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setHora(hora));
    }, 200);
  };

  const handleChangeCalle = (calle) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCalle(calle));
    }, 200);
  };

  const handleChangeAltura = (altura) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setAltura(altura));
    }, 200);
  };

  const handleChangeLocalidad = (localidad) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setLocalidad(localidad));
      dispatch(fetchCodigosPostales(provincia.searchId, localidad.value));
    }, 200);
  };

  const handleChangeProvincia = (provincia) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setProvincia(provincia));
      dispatch(fetchLocalidades(provincia.value));
    }, 200);
  };

  const handleChangeCodigoPostal = (codigoPostal) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCodigoPostal(codigoPostal));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  const provincias = getProvincias();

  return (
    <>
      <DetalleCard title={title} IconHeader={ScheduleIcon}>
        <ItemDateCard
          id={"fecha"}
          isLoading={isLoading}
          label={"Fecha"}
          value={fecha.value}
          onChange={handleChangeFecha}
          onBlur={handleBlur}
          focus={fecha.focus}
          maxDate={new Date()}
          required
        />
        <ItemInputCard
          id={"hora"}
          isLoading={isLoading}
          label={"Hora"}
          value={hora.value}
          onChange={handleChangeHora}
          onBlur={handleBlur}
          focus={hora.focus}
          placeholder={"Ej: 20:45"}
          required
          maxLength={5}
        />
        <ItemInputCard
          id={"calle"}
          isLoading={isLoading}
          label={"Calle"}
          value={calle.value}
          onChange={handleChangeCalle}
          onBlur={handleBlur}
          focus={calle.focus}
          required
        />
        <ItemInputCard
          id={"altura"}
          isLoading={isLoading}
          label={"Altura"}
          value={altura.value}
          onChange={handleChangeAltura}
          onBlur={handleBlur}
          focus={altura.focus}
          required
        />

        <MultipleItemSelectorCard
          id={"provincia"}
          isLoading={isLoading}
          label={"Provincia"}
          value={provincia.value}
          onChange={handleChangeProvincia}
          placeholder={"Seleccionar"}
          onBlur={handleBlur}
          focus={provincia.focus}
          tooltipContent={"Seleccionar Provincia"}
          items={provincias}
        />

        <MultipleItemSelectorCard
          id={"localidad"}
          isLoading={isLoading}
          label={"Localidad"}
          value={localidad.value}
          onChange={handleChangeLocalidad}
          placeholder={"Seleccionar"}
          onBlur={handleBlur}
          focus={localidad.focus}
          tooltipContent={"Seleccionar Localidad"}
          items={localidades}
        />

        <MultipleItemSelectorCard
          id={"codigoPostal"}
          isLoading={isLoading}
          label={"Código Postal"}
          value={codigoPostal.value}
          onChange={handleChangeCodigoPostal}
          placeholder={"Seleccionar"}
          onBlur={handleBlur}
          focus={codigoPostal.focus}
          tooltipContent={"Seleccionar Código Postal"}
          items={codigosPostales}
        />
      </DetalleCard>
    </>
  );
};

export default FechaLugarCard;
