import React from "react";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { makeStyles } from "@material-ui/core/styles";
import EmisionesCard from "./Emisiones/EmisionesCard";
import AnulacionesCard from "./Anulaciones/AnulacionesCard";
import { configuracionPath } from "~constants/referenceNames";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1.5%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },

  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  cardItem: { minWidth: 300 },

  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  btnRechazar: {
    marginRight: "2%",
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ReportesContainer = () => {
  const classes = useStyles();

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Reportes"}
      selectedDefault={configuracionPath}
    >
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.cardsAndCuotasContainer}>
            <CardsColumn>
              <EmisionesCard />
              <AnulacionesCard />
            </CardsColumn>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default ReportesContainer;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
