import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: `${pxToRem(28)} !important`,
    color: theme.palette.primary.main,
    marginBottom: pxToRem(20),
    fontWeight: "bold !important",
  },
  loading: { width: "90%", height: "50%" },
}));

const PrimaryTitle = ({ isLoading, label, marginBottom }) => {
  const classes = useStyles();

  if (isLoading)
    return (
      <div className={classes.root}>
        <div className={classes.loading}>
          <ContentLoader
            height={20}
            width={300}
            speed={2}
            //primaryColor="#f7f8ff"
            //secondaryColor="#6464BB"
          >
            <rect x="50" y="0" rx="4" ry="4" width="250" height="32" />
          </ContentLoader>
        </div>
      </div>
    );
  return (
    <Typography variant="h1" className={classes.root} style={{ marginBottom }}>
      {label}
    </Typography>
  );
};

export default PrimaryTitle;
