import React, { useState, useEffect } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { TableRow, TableCell, IconButton, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SortIcon from "@material-ui/icons/Sort";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import ListSortable from "../coberturasSelector/ListSortable";
import CoberturasSelector from "../coberturasSelector/CoberturasSelector";

const useStyles = makeStyles(theme => ({
  tableContainer: {
    //maxHeight: "65vh",
    backgroundColor: theme.colours.white,
    overflow: "visible"
  },
  table: {},
  tableCell: {
    padding: "0px 0px 0px 20px",
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left"
    //color: theme.colours.violeta
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta
  },
  headers: {
    justifyContent: "center"
  },
  rowTable: {
    fontSize: "1.2rem"
  },
  iconButton: {
    color: theme.colours.brownGrey2,
    padding: 0
  },
  coberturasContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  dash: { display: "flex", justifyContent: "center" }
}));

const SortableItem = sortableElement(
  ({ row, onChange,onClickCobertura, sortMode, onSortMode }) => {
    const classes = useStyles();

    return (
      <TableRow className={classes.rowTable} key={row.idCompania}>
        <TableCell className={classes.tableCell}>
          {row.orden + ". " + row.idCompania}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {sortMode && (
            <div className={classes.coberturasContainer}>
              <ListSortable value={row} onChange={onChange} onClickCobertura={onClickCobertura} />
              <IconButton
                className={classes.iconButton}
                onClick={onSortMode(!sortMode)}
                size="small"
              >
                <AddBoxIcon />
              </IconButton>
            </div>
          )}
          {!sortMode && (
            <div className={classes.coberturasContainer}>
              <CoberturasSelector
                value={row}
                idsCoberturas={row.idsCoberturas}
                sugerencias={row.sugerencias}
                onChangeCoberturas={onChange}
              />
              <IconButton
                className={classes.iconButton}
                onClick={onSortMode(!sortMode)}
                size="small"
              >
                <SortIcon />
              </IconButton>
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  }
);

const EsquemasSortableTable = ({
  esquemas,
  onChangeOrder,
  onChangeCoberturas,
  onClickCobertura
}) => {
  const [items, setItems] = useState(esquemas);
  const [sortMode, setSortMode] = useState(true);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
      if (onChangeOrder) onChangeOrder(newItems);
    }
  };

  useEffect(() => {
    setItems(esquemas);
  }, [esquemas]);

  const handleSortMode = value => () => {
    setSortMode(value);
  };

  const classes = useStyles();
  return (
    <SortableContainer pressDelay={200} onSortEnd={onSortEnd}>
      <div className={classes.tableContainer}>
        <Table size="small" className={classes.table}>
          <TableHead className={classes.headers}>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>
                Compañia
              </TableCell>
              <TableCell className={classes.tableCellHeader}>
                Ids Coberturas
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((value, index) => (
              <SortableItem
                key={`item-${value.idCompania}`}
                index={index}
                row={value}
                onClickCobertura={onClickCobertura}
                onChange={onChangeCoberturas(value)}
                onSortMode={handleSortMode}
                sortMode={sortMode}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </SortableContainer>
  );
};

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ margin: 0, padding: 0 }}>{children}</ul>;
});

export default EsquemasSortableTable;
