const TODOS = "";
export const PRENDARIOS = "Prendarios";
export const MERCADO_ABIERTO = "MercadoAbierto";
export const PAS = "PAS";

const PRENDARIOS_COTIZACIONES = "Prendarios";
const MERCADO_ABIERTO_COTIZACIONES = "MercadoAbierto";

const negociosSegurosOptions = {
  [TODOS]: "Todos",
  [PRENDARIOS]: "Prendarios",
  [MERCADO_ABIERTO]: "Mercado Abierto",
  [PAS]: "PAS",
};

const negociosSeguros = {
  [PRENDARIOS_COTIZACIONES]: "Prendarios",
  [MERCADO_ABIERTO_COTIZACIONES]: "Mercado Abierto"
};

const negocios = [PRENDARIOS, MERCADO_ABIERTO];

const negociosFilter = [
  { label: negociosSegurosOptions[TODOS], value: TODOS },
  { label: negociosSegurosOptions[PRENDARIOS], value: PRENDARIOS },
  { label: negociosSegurosOptions[MERCADO_ABIERTO], value: MERCADO_ABIERTO }
];

export { negociosSegurosOptions, negocios, negociosSeguros, negociosFilter };
