import React, { forwardRef, ReactNode } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Slide, SlideProps } from "@material-ui/core";
import pxToRem from "~libs/pxToRem";

interface ModalProps {
  opened?: boolean;
  onClose: () => void;
  title?: string;
  dialogActions?: ReactNode;
  withPadding?: boolean;
  children: ReactNode;
}

const Transition = forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogActions = withStyles((theme) => ({
  root: {
    justifyContent: "center",
    padding: 24,
  },
}))(DialogActions);

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(
  ({
    children,
    classes,
    onClose,
    ...other
  }: {
    children: ReactNode;
    classes: Record<"root" | "closeButton", string>;
    onClose?: () => void;
  }) => {
    return (
      <DialogTitle className={classes.root} {...other}>
        <Typography
          style={{
            fontSize: pxToRem(18),
            fontWeight: "bold",
            lineHeight: 1.89,
            letterSpacing: 0.45,
            textAlign: "center",
            color: "#663ea8",
          }}
        >
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
);
const StyledDialog = withStyles((theme) => ({
  paper: {
    borderRadius: 12,
    boxShadow: "none",
    width: "100%",
  },
}))(Dialog);

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: pxToRem(24),
    overflow: "hidden",
  },
  dividers: { borderBottom: "none" },
}))(DialogContent);

const Modal: React.FC<ModalProps> = ({
  opened = false,
  onClose,
  title = "",
  dialogActions,
  withPadding = true,
  children,
}) => {
  const classes = useStyles();

  return (
    <StyledDialog
      onClose={onClose}
      open={opened}
      TransitionComponent={Transition}
      transitionDuration={500}
      fullScreen={false}
      //onEntered={() => document.getElementById("dialogcontent1")?.scrollTo(0, 0)}
    >
      <StyledDialogTitle onClose={onClose}>{title}</StyledDialogTitle>
      <StyledDialogContent
        id="dialogcontent1"
        dividers
        classes={withPadding ? undefined : { root: classes.rootWithNoPadding }}
        style={{ overflow: "auto" }}
      >
        {children}
      </StyledDialogContent>
      {dialogActions && (
        <StyledDialogActions>{dialogActions}</StyledDialogActions>
      )}
    </StyledDialog>
  );
};

export default Modal;

const useStyles = makeStyles((theme) => ({
  rootWithNoPadding: {
    padding: 0,
  },
}));
