import React from "react";
import pxToRem from "~libs/pxToRem";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

const CardButton = ({ onClick, color, marginBottom, children }) => {
  return (
    <Card
      style={{
        width: "100%",
        borderRadius: 10,
        boxShadow: "2px 2px 8px 0 #00000012",
        borderLeft: `10px solid ${color}`,
        marginBottom
      }}
    >
      {onClick && (
        <CardActionArea
          style={{
            minHeight: pxToRem(136)
          }}
          onClick={onClick}
        >
          {children}
        </CardActionArea>
      )}
      {!onClick && children}
    </Card>
  );
};

export default CardButton;
