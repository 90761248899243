import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, coberturasPath } from "~constants/referenceNames";
import {
  GetCoberturaResponse,
  GetCoberturasRequest,
  GetCoberturasResponse,
  PostCoberturaRequest,
  PostCoberturaResponse,
  PutCoberturaRequest,
} from "./types";

const coberturas = () => {
  return {
    getCoberturasById: ({
      data,
      id,
    }: {
      data: GetCoberturasRequest;
      id: string;
    }): Promise<GetCoberturaResponse> => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      return fetch(`${environment.segurosUrl}/coberturas/${id}`, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getCoberturas: (
      data: GetCoberturasRequest
    ): Promise<GetCoberturasResponse> => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      return fetch(`${environment.segurosUrl}/coberturas`, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    putCoberturas: (data: PutCoberturaRequest): Promise<Response> => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url =
        environment.segurosUrl +
        paths[coberturasPath] +
        "/" +
        encodeURIComponent(data.id);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postCoberturas: (
      data: PostCoberturaRequest
    ): Promise<PostCoberturaResponse> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[coberturasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { coberturas };
