import React from "react";
import access_denied from "~images/auth/access_denied.svg";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import LinkButton from "~styled/linkButton/LinkButton";
import { useHistory } from "react-router-dom";
import Layout from "~shared/layout/Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "cal(100vh - 100px)",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "cal(100vh - 100px)",
    maxWidth: 600,
  },
  title: {
    color: "#45459c",
    fontSize: 28,
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 800,
    lineHeight: 2.11,
    fontStretch: "normal",
    letterSpacing: "normal",
    paddingBottom: 30,
  },
  subtitle: {
    color: "#434343",
    fontSize: 22,
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: 1.56,
    fontStretch: "normal",
    letterSpacing: "normal",
    paddingBottom: 30,
  },
}));

export const Error404 = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography color="textPrimary" className={classes.title}>
          {"¡Lo sentimos!"}
        </Typography>
        <Typography color="textPrimary" className={classes.subtitle}>
          {"No tenés permisos para"}
          <br /> {"entrar a este sitio o la url no existe"}
        </Typography>
        <img style={{ width: "50%" }} alt="" src={access_denied} />
        <div style={{ padding: 30 }}>
          <LinkButton
            label={"Volver al inicio"}
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
      </div>
    </div>
  );
};

const InvalidUrl = () => {
  return (
    <Layout>
      <Error404 />
    </Layout>
  );
};
export default InvalidUrl;
