import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { setTake, setPage } from "../Filtros/actions";
import { novedadesPath, paths } from "~constants/referenceNames";
import TableLoading from "~styled/tableLoading/TableLoading";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Filtros from "../Filtros/Filtros";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import NumberFormat from "react-number-format";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    width: "100%",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  iconPdfButton: {
    color: "#b00020",
    padding: 0,
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    //color: theme.colours.violeta
  },
  tableCellCenter: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    //fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
    //color: theme.colours.violeta
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.filtrosNovedadesReducer.take,
    page: state.filtrosNovedadesReducer.page,

    novedades: state.novedadesReducer.novedades,
    totalRegistros: state.novedadesReducer.totalRegistros,
    isLoading: state.novedadesReducer.isLoading,
  }));

const ListadoNovedades = () => {
  const { page, take, novedades, totalRegistros, isLoading } =
    useReduxSelector();
  const dispatch = useDispatch();
  const empty = totalRegistros === 0;

  const classes = useStyles();

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const getValueParsed = (value) => {
    if (!value) return <div className={classes.dash}>&mdash;</div>;

    return value;
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Negocio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Tipo"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Compañia"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"N° Póliza"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Dominio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Descargada"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Vigencia"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Premio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"DNI"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Titular"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Documentación"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Detalle"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {!isLoading &&
                  !empty &&
                  novedades.map((novedad) => (
                    <TableRow
                      key={"table-novedades-" + novedad.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell className={classes.tableCell}>
                        <ColorLabelNegocio
                          negocio={novedad.negocio}
                          abbreviated
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(novedad.tipoNovedad)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(novedad.compania)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(novedad.numeroPoliza)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(novedad.dominio)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {format(
                          Date.parse(novedad.fechaCreacionNovedad),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {`${format(
                          Date.parse(novedad.polizaVigenciaDesde),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )} hasta ${format(
                          Date.parse(novedad.polizaVigenciaHasta),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )}`}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {
                          <NumberFormat
                            value={novedad.premioTotal}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            isNumericString
                            prefix="$"
                            decimalScale={2}
                          />
                        }
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(novedad.numeroDocumentoAsegurado)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(novedad.nombreCompletoAsegurado)}
                      </TableCell>
                      <TableCell className={classes.tableCellCenter}>
                        <Tooltip title={"Constancia de Póliza"}>
                          <a
                            href={novedad.urlPoliza}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              disabled={!novedad.urlPoliza}
                              className={classes.iconPdfButton}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </a>
                        </Tooltip>
                        <Tooltip title={"Tarjeta circulación"}>
                          <a
                            href={novedad.urlTarjeta}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              disabled={!novedad.urlTarjeta}
                              className={classes.iconPdfButton}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </a>
                        </Tooltip>
                        <Tooltip title={"Cédula MERCOSUR"}>
                          <a
                            href={novedad.urlCedulaMercosur}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              disabled={!novedad.urlCedulaMercosur}
                              className={classes.iconPdfButton}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </a>
                        </Tooltip>
                        <Tooltip title={"Cuponera"}>
                          <a
                            href={novedad.urlCuponera}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              disabled={!novedad.urlCuponera}
                              className={classes.iconPdfButton}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </a>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCellCenter}>
                        <Tooltip title={"Detalles de la novedad " + novedad.id}>
                          <IconButton
                            className={classes.iconButton}
                            component={Link}
                            to={paths[novedadesPath] + "/" + novedad.id}
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinCotizaciones}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                width: "100%",
              }}
            >
              {!isLoading &&
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              }
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

export default ListadoNovedades;
