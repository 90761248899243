const TODOS = "";
export const PRENDARIOS = "Prendarios";
export const MERCADOABIERTO = "MercadoAbierto";

const negociosOptions = {
  [TODOS]: "Todos",
  [PRENDARIOS]: "Prendarios",
  [MERCADOABIERTO]: "Mercado Abierto",
};

const negocios = [PRENDARIOS, MERCADOABIERTO];

const negociosFilter = [
  { label: negociosOptions[TODOS], value: TODOS },
  {
    label: negociosOptions[PRENDARIOS],
    value: PRENDARIOS,
  },
  {
    label: negociosOptions[MERCADOABIERTO],
    value: MERCADOABIERTO,
  },
];

export { negociosOptions, negocios, negociosFilter };
