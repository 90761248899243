import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { getPoliza } from "./actions";
import { paths, polizasPath } from "~constants/referenceNames";

import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";
import DetallePoliza from "./DetallePoliza";
import Documentacion from "./Documentacion";

const DetallePolizaContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getPoliza(id));
    //eslint-disable-next-line
  }, []);

  const backAction = () => {
    history.push(paths[polizasPath]);
  };

  return (
    <DetailsLayout
      title="Detalle de la Póliza"
      backAction={backAction}
      tabsComponent={() => (
        <DetailsTabs
          defaultValue={0}
          labels={[
            <TabIndex text="DETALLE" />,
            <TabIndex text="DOCUMENTACIÓN" />,
          ]}
          onChange={(e) => {
            setCurrentTab(parseInt(e.target.value));
          }}
        />
      )}
    >
      {currentTab === 0 && <DetallePoliza />}
      {currentTab === 1 && <Documentacion />}
    </DetailsLayout>
  );
};

export default DetallePolizaContainer;

const TabIndex = ({ text }) => {
  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>{text}</span>
    </>
  );
};
