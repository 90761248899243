import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector, useDispatch } from "react-redux";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ItemInputCard from "~business/Cards/ItemInputCard";
import {
  setMarca,
  setModelo,
  setModeloAnio,
  setDominio,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    modelo: state.cargaSiniestroReducer.modeloSelected,
    modeloAnio: state.cargaSiniestroReducer.modeloAnioSelected,
    dominio: state.cargaSiniestroReducer.dominioSelected,
    marca: state.cargaSiniestroReducer.marcaSelected,
    poliza: state.cargaSiniestroReducer.polizaVinculada,
  }));

const title = "Identificación del Automotor";

const DatosAutomotorCard = ({ isLoading }) => {
  const { modelo, modeloAnio, dominio, marca, poliza } = useReduxSelector();
  const dispatch = useDispatch();

  let timer = null;

  const handleChangeModelo = (modelo) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setModelo(modelo));
    }, 200);
  };

  const handleChangeMarca = (marca) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setMarca(marca));
    }, 200);
  };

  const handleChangeModeloAnio = (modeloAnio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setModeloAnio(modeloAnio));
    }, 200);
  };

  const handleChangeDominio = (dominio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setDominio(dominio));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={DriveEtaIcon}>
        {!poliza && (
          <>
            <ItemInputCard
              id={"dominio"}
              isLoading={isLoading}
              label={"Dominio"}
              value={dominio.value}
              onChange={handleChangeDominio}
              onBlur={handleBlur}
              focus={dominio.focus}
              required
            />
            <ItemInputCard
              id={"marca"}
              isLoading={isLoading}
              label={"Marca"}
              value={marca.value}
              onChange={handleChangeMarca}
              onBlur={handleBlur}
              focus={marca.focus}
              required
            />

            <ItemInputCard
              id={"modelo"}
              isLoading={isLoading}
              label={"Modelo"}
              value={modelo.value}
              onChange={handleChangeModelo}
              onBlur={handleBlur}
              focus={modelo.focus}
              required
            />

            <ItemInputCard
              id={"modeloAnio"}
              isLoading={isLoading}
              label={"Año del Modelo"}
              value={modeloAnio.value}
              onChange={handleChangeModeloAnio}
              onBlur={handleBlur}
              focus={modeloAnio.focus}
              required
            />
          </>
        )}

        {poliza && (
          <>
            <ItemInputCard
              id={"dominio"}
              isLoading={isLoading}
              label={"Dominio"}
              value={dominio.value}
              readOnly
            />
            <ItemInputCard
              id={"automovil"}
              isLoading={isLoading}
              label={"Automóvil"}
              value={`(${modeloAnio.value}) ${marca.value} ${modelo.value}`}
              readOnly
            />
          </>
        )}
      </DetalleCard>
    </>
  );
};

export default DatosAutomotorCard;
