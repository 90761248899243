import React, { useState } from "react";

import SigninWithSocialNetworks from "./SigninWithSocialNetworks";

const SIGNIN_SOCIAL = 1;
const SIGNIN_EMAIL_PASSWORD = 2;

const Signin = () => {
  const [currentView, setCurrentView] = useState<number>(SIGNIN_SOCIAL);

  return (
    <>
      {currentView === SIGNIN_SOCIAL && (
        <SigninWithSocialNetworks
          onChangeToEmailAndPasswordLogin={() =>
            setCurrentView(SIGNIN_EMAIL_PASSWORD)
          }
        />
      )}
    </>
  );
};

export default Signin;
