import React, { useEffect, useState } from "react";
import { getSolicitudesEmision } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import ListadoSolicitudesHistoricas from "./ListadoSolicitudesHistoricas";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { solicitudesHistoricasPath } from "~constants/referenceNames";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import { useHistory, useLocation } from "react-router-dom";
import {
  setTake,
  setPage,
  selectSearchCriteria,
  selectFechaDesde,
  selectFechaHasta,
  selectEstado,
} from "./filtros/actions";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.solicitudesHistoricasReducer.isLoading,

    take: state.solicitudesHistoricasFiltrosReducer.take,
    page: state.solicitudesHistoricasFiltrosReducer.page,
    fechaDesde: state.solicitudesHistoricasFiltrosReducer.fechaDesde,
    fechaHasta: state.solicitudesHistoricasFiltrosReducer.fechaHasta,
    searchCriteria: state.solicitudesHistoricasFiltrosReducer.searchCriteria,
    estado: state.solicitudesHistoricasFiltrosReducer.estado,
    displayMercadoAbiertoOnly:
      state.authenticatedViewReducer.displayMercadoAbiertoOnly,
  }));

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  {
    name: "fechaDesde",
    nameUrl: "Desde",
    action: selectFechaDesde,
    isDate: true,
  },
  {
    name: "fechaHasta",
    nameUrl: "Hasta",
    action: selectFechaHasta,
    isDate: true,
  },
  {
    name: "estado",
    nameUrl: "Estado",
    action: selectEstado,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const SolicitudesHistoricasContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentState = useReduxSelector();

  const {
    take,
    page,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    estado,
    displayMercadoAbiertoOnly,
  } = currentState;

  let negocio = displayMercadoAbiertoOnly ? "MercadoAbierto" : null;

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: solicitudesHistoricasPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [dispatch, take, page, fechaDesde, fechaHasta, searchCriteria, estado]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(
        getSolicitudesEmision(
          page * take,
          take,
          searchCriteria,
          fechaDesde,
          fechaHasta,
          null,
          estado,
          negocio
        )
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url]);

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Solicitudes Históricas"}
      selectedDefault={solicitudesHistoricasPath}
    >
      <ListadoSolicitudesHistoricas />
    </SectionLayout>
  );
};

export default SolicitudesHistoricasContainer;
