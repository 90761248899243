import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setTake,
  selectSearchCriteria,
  selectFechaNovedadDesde,
  selectFechaNovedadHasta,
  selectInicioVigenciaDesde,
  selectInicioVigenciaHasta,
  selectNegocio,
  selectCompania,
  selectTipoNovedad,
} from "./actions";
import { calculateDate } from "~libs/dateCalculator";
import Searcher from "~styled/searcher/searcher";
import { Typography } from "@material-ui/core";
import Pagination from "~styled/pagination/Pagination";
import DateRangePicker from "~styled/dateRangePicker/DateRangePicker";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import Selector from "~styled/selector/Selector";
import { negociosFilter, negociosSegurosOptions } from "~constants/negocios";
import { companiasSegurosOptions, companiasFilter } from "~constants/companias";
import {
  tipoNovedadSegurosOptions,
  tiposNovedadesFilter,
} from "~constants/tipoNovedad";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  searchers: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  horizontalSpace: { paddingRight: "10px" },
  horizontalSpaceSearcher: { paddingRight: "10px", width: "100%" },
  searcherAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.novedadesReducer.isLoading,
    totalRegistros: state.novedadesReducer.totalRegistros,

    take: state.filtrosNovedadesReducer.take,
    page: state.filtrosNovedadesReducer.page,
    inicioVigenciaDesde: state.filtrosNovedadesReducer.inicioVigenciaDesde,
    inicioVigenciaHasta: state.filtrosNovedadesReducer.inicioVigenciaHasta,
    fechaNovedadDesde: state.filtrosNovedadesReducer.fechaNovedadDesde,
    fechaNovedadHasta: state.filtrosNovedadesReducer.fechaNovedadHasta,
    searchCriteria: state.filtrosNovedadesReducer.searchCriteria,
    negocio: state.filtrosNovedadesReducer.negocio,
    compania: state.filtrosNovedadesReducer.compania,
    tipoNovedad: state.filtrosNovedadesReducer.tipoNovedad,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    isLoading,
    take,
    inicioVigenciaDesde,
    inicioVigenciaHasta,
    fechaNovedadDesde,
    fechaNovedadHasta,
    searchCriteria,
    negocio,
    compania,
    tipoNovedad,
    page,
    totalRegistros,
  } = useReduxSelector();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangeIntervalInicioDeVigencia = (value) => {
    dispatch(setPage(0));
    dispatch(selectInicioVigenciaDesde(calculateDate(new Date(), value)));
    dispatch(selectInicioVigenciaHasta(new Date()));
  };

  const handleChangeIntervalNovedadObtenida = (value) => {
    dispatch(setPage(0));
    dispatch(selectFechaNovedadDesde(calculateDate(new Date(), value)));
    dispatch(selectFechaNovedadHasta(new Date()));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <>
          <div className={classes.searchers}>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                NEGOCIO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={negociosSegurosOptions[negocio]}
                  options={negociosFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectNegocio)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                COMPAÑIA
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={companiasSegurosOptions[compania]}
                  options={companiasFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectCompania)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                TIPO NOVEDAD
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={tipoNovedadSegurosOptions[tipoNovedad]}
                  options={tiposNovedadesFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectTipoNovedad)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className={classes.filters}>
              <div className={classes.dateSelectorAndTitle}>
                <Typography variant="h3" className={classes.label}>
                  INICIO DE VIGENCIA
                </Typography>
                <div className={classes.horizontalSpace}>
                  <DateRangePicker
                    fechaDesde={inicioVigenciaDesde}
                    fechaHasta={inicioVigenciaHasta}
                    onChangeInterval={handleChangeIntervalInicioDeVigencia}
                    onChangeStartDate={(value) =>
                      handleChange(
                        { target: { value } },
                        selectInicioVigenciaDesde
                      )
                    }
                    onChangeEndDate={(value) =>
                      handleChange(
                        { target: { value } },
                        selectInicioVigenciaHasta
                      )
                    }
                    onClickDisabled={() => {
                      dispatch(queueMessage(messageLoading));
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.filtersContainer}>
            <div className={classes.filters}>
              <div className={classes.searcherAndTitle}>
                <Typography variant="h3" className={classes.label}>
                  BUSCAR
                </Typography>
                <div className={classes.horizontalSpaceSearcher}>
                  <Searcher
                    borderSquared
                    value={searchCriteria}
                    filter={searchCriteria}
                    placeholder="N° póliza, Dominio, Doc Asegurado"
                    onChange={(event) =>
                      handleChange(event, selectSearchCriteria)
                    }
                    onClear={() =>
                      handleChange(
                        { target: { value: "" } },
                        selectSearchCriteria
                      )
                    }
                  />
                </div>
              </div>

              <div className={classes.dateSelectorAndTitle}>
                <Typography variant="h3" className={classes.label}>
                  NOVEDAD OBTENIDA
                </Typography>
                <div className={classes.horizontalSpace}>
                  <DateRangePicker
                    fechaDesde={fechaNovedadDesde}
                    fechaHasta={fechaNovedadHasta}
                    onChangeInterval={handleChangeIntervalNovedadObtenida}
                    onChangeStartDate={(value) =>
                      handleChange(
                        { target: { value } },
                        selectFechaNovedadDesde
                      )
                    }
                    onChangeEndDate={(value) =>
                      handleChange(
                        { target: { value } },
                        selectFechaNovedadHasta
                      )
                    }
                    onClickDisabled={() => {
                      dispatch(queueMessage(messageLoading));
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
              
            {!isLoading &&
              <div className={classes.filters}>
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={(value) =>
                    handleChange({ target: { value } }, setTake)
                  }
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              </div>
            }
          </div>
        </>
      </div>
    </>
  );
};

export default Filtros;
