import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { clearEsPersonaValida, setEsPersonaValida } from "../actions";
import checkIcon from "~assets/icons/ic-check.svg";
import closeIcon from "~assets/icons/ic-close.svg";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    width: "100%",
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  loading: { width: 100, height: 100 },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.multicotizadorReducer.isLoading,
    nombreSelected: state.multicotizadorReducer.nombreSelected,
    apellidoSelected: state.multicotizadorReducer.apellidoSelected,
  }));

const EsPersonaValidaStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { nombreSelected, apellidoSelected, isLoading } = useReduxSelector();

  useEffect(() => {
    dispatch(clearEsPersonaValida());
  }, [dispatch]);

  const failed = !isLoading && !nombreSelected;

  useEffect(() => {
    if (failed) {
      dispatch(setEsPersonaValida({ value: false, selected: true }));
    }
  }, [dispatch, failed]);

  if (isLoading || !nombreSelected) return <LoadingViewGears />;
  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={`¿El seguro es para ${nombreSelected} ${apellidoSelected}?`}
        marginBottom={60}
      />
      <div className={classes.checkboxContainer}>
        <div style={{ marginRight: 5, width: "100%" }}>
          <CheckboxButton
            onClick={() =>
              dispatch(setEsPersonaValida({ value: true, selected: true }))
            }
          >
            <EsPersonaValidaButton label="SI" image={checkIcon} />
          </CheckboxButton>
        </div>

        <div style={{ marginLeft: 5, width: "100%" }}>
          <CheckboxButton
            onClick={() => {
              history.go(-2);
            }}
          >
            <EsPersonaValidaButton label="NO" image={closeIcon} />
          </CheckboxButton>
        </div>
      </div>
    </MulticotizadorContent>
  );
};

export default EsPersonaValidaStep;

const EsPersonaValidaButton = ({ label, isLoading, image }) => {
  const classes = useStyles();
  if (isLoading)
    return (
      <div className={classes.container}>
        <div className={classes.loading}>
          <ContentLoader height={60} width={70} speed={1}>
            <rect x="0" y="0" rx="4" ry="4" width="70" height="40" />
            <rect x="15" y="45" rx="4" ry="4" width="40" height="16" />
          </ContentLoader>
        </div>
      </div>
    );

  return (
    <div className={classes.container}>
      <img alt="" src={image} style={{ height: 58, marginBottom: 17 }} />
      <Typography className={classes.btnLabel}>{label}</Typography>
    </div>
  );
};
