import { createAction, handleActions } from "redux-actions";

const getSiniestroInited = createAction("OBTENER_SINIESTRO_INITED");

const getSiniestroCompleted = createAction(
  "OBTENER_SINIESTRO_COMPLETED",
  (currentSiniestro) => ({ currentSiniestro })
);

const getSiniestroFailed = createAction(
  "OBTENER_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const getSiniestro = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getSiniestroInited());

      const response = await services.api.siniestros().getSiniestro(id);

      dispatch(getSiniestroCompleted(response));
    } catch (error) {
      console.log(error);
      dispatch(getSiniestroFailed(error));
    }
  };
};

const postSiniestroComentarioInited = createAction(
  "POST_COMENTARIO_SINIESTRO_INITED"
);

const postSiniestroComentarioCompleted = createAction(
  "POST_COMENTARIO_SINIESTRO_COMPLETED"
);

const postSiniestroComentarioFailed = createAction(
  "POST_COMENTARIO_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const closeSuccessComentarioPopUp = createAction(
  "CLOSE_SUCCESS_COMENTARIO_POPUP"
);

const postSiniestroComentario = (siniestroId, comentario) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postSiniestroComentarioInited());

      const data = {
        contenidoComentario: comentario,
      };

      await services.api
        .siniestros()
        .postSiniestroComentario(siniestroId, data);

      dispatch(postSiniestroComentarioCompleted());
    } catch (error) {
      console.log(error);
      dispatch(postSiniestroComentarioFailed(error));
    }
  };
};

const clearData = createAction("CLEAR_DATA_SINISTRO", () => ({}));

const detalleSiniestroReducerInitialState = {
  isLoading: false,
  currentSiniestro: null,
  failed: false,
  comentarioNuevo: false,
};

const detalleSiniestroReducer = handleActions(
  {
    [clearData]: (state, action) => {
      return {
        ...detalleSiniestroReducerInitialState,
      };
    },
    [getSiniestroInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getSiniestroCompleted]: (state, action) => {
      return {
        ...state,
        currentSiniestro: action.payload.currentSiniestro,
        isLoading: false,
        failed: false,
      };
    },
    [getSiniestroFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [postSiniestroComentarioInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        comentarioNuevo: false,
      };
    },
    [postSiniestroComentarioCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: false,
        comentarioNuevo: true,
      };
    },
    [postSiniestroComentarioFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
        comentarioNuevo: false,
      };
    },
    [closeSuccessComentarioPopUp]: (state, action) => {
      return {
        ...state,
        comentarioNuevo: false,
      };
    },
  },
  detalleSiniestroReducerInitialState
);

export { detalleSiniestroReducer };

export {
  getSiniestro,
  postSiniestroComentario,
  closeSuccessComentarioPopUp,
  clearData,
};
