import { createAction, handleActions } from "redux-actions";
import { calculateDate } from "~libs/dateCalculator";
import { MERCADO_ABIERTO } from "~constants/negocios";
import { ABANDONADA } from "~constants/multicotizacionEstados";

const setInitialStateCotizaciones = createAction(
  "SET_INITIAL_STATE_FILTRO_COTIZACIONES"
);

const setPage = createAction("SET_LISTADO_COTIZACIONES_PAGE", (page) => ({
  page,
}));

const setTake = createAction(
  "SET_LISTADO_COTIZACIONES_ROWS_PER_PAGE",
  (take) => ({ take })
);

const selectFechaDesde = createAction(
  "SELECT_FECHA_DESDE_FILTRO",
  (fechaDesde) => ({ fechaDesde })
);

const selectFechaHasta = createAction(
  "SELECT_FECHA_HASTA_FILTRO",
  (fechaHasta) => ({ fechaHasta })
);

const selectSearchCriteria = createAction(
  "SELECT_ID_FILTRO",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const selectNegocio = createAction("SELECT_NEGOCIO_FILTRO", (negocio) => ({
  negocio,
}));

const selectEstado = createAction("SELECT_ESTADO_FILTRO", (estado) => ({
  estado,
}));

const filtrosCotizacionesInitialState = {
  page: 0,
  take: 10,
  fechaDesde: calculateDate(new Date(), -7),
  fechaHasta: new Date(),
  searchCriteria: "",
  negocio: MERCADO_ABIERTO,
  estado: ABANDONADA,
};

const filtrosCotizacionesReducer = handleActions(
  {
    [setInitialStateCotizaciones]: (state, action) => {
      return {
        ...filtrosCotizacionesInitialState,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        take: action.payload.take,
        page: 0,
      };
    },
    [selectFechaDesde]: (state, action) => {
      return {
        ...state,
        fechaDesde: action.payload.fechaDesde,
      };
    },
    [selectFechaHasta]: (state, action) => {
      return {
        ...state,
        fechaHasta: action.payload.fechaHasta,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [selectNegocio]: (state, action) => {
      return {
        ...state,
        negocio: action.payload.negocio,
      };
    },
    [selectEstado]: (state, action) => {
      return {
        ...state,
        estado: action.payload.estado,
      };
    },
  },
  filtrosCotizacionesInitialState
);

export { filtrosCotizacionesReducer };
export {
  setInitialStateCotizaciones,
  selectFechaDesde,
  selectFechaHasta,
  selectSearchCriteria,
  selectNegocio,
  selectEstado,
  setPage,
  setTake,
};
