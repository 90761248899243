import React, { useState, useEffect } from "react";
import VehiculoAnioSelector from "~business/vehiculoAnioSelector/VehiculoAnioSelector";
import VehiculoModeloSelector from "~business/vehiculoModeloSelector/vehiculoModeloSelector";

const ANIO_STEP = "ANIO_STEP";
const MODELO_STEP = "MODELO_STEP";

const VehiculoStepper = ({ marca, onChange }) => {
  const [anio, setAnio] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [codia, setCodia] = useState(null);

  useEffect(() => {
    const allCompleted = anio && modelo && marca && codia;
    if (allCompleted && onChange) onChange(anio, modelo, codia);
  }, [marca, modelo, codia, anio, onChange]);

  const handleChangeValue = (event) => {
    const value = event.target.value;
    setModelo(value.label);
    setCodia(value.value);
  };

  const step = getStep(anio, modelo);
  return (
    <div style={{ minHeight: "90vh" }}>
      {step === ANIO_STEP && (
        <VehiculoAnioSelector
          value={{ label: anio, value: anio }}
          onChange={(event) => setAnio(event.target.value)}
        />
      )}
      {step === MODELO_STEP && (
        <VehiculoModeloSelector
          marca={{ label: marca, value: marca }}
          anio={{ label: anio, value: anio }}
          value={{ label: null, value: null }}
          onChange={handleChangeValue}
        />
      )}
    </div>
  );
};

export default VehiculoStepper;

const getStep = (anio, modelo) => {
  if (!anio) return ANIO_STEP;
  if (!modelo) return MODELO_STEP;
};
