import { createAction, handleActions, Action } from "redux-actions";
import localRepository from "../../localRepository";

export interface AuthState {
  authState: any;
  isAuthenticated: boolean;
  isConfirming: boolean;
  isLoading: boolean;
  validating: boolean;
  validated: boolean;
  isAuthorized: boolean;
}

interface AuthStateSyncPayload {
  authState: any;
}

interface PostOnboardingCompletedPayload {
  userIsValid: boolean;
}

interface PostOnboardingFailedPayload {
  error: Error;
}

const initialState: AuthState = {
  authState: null,
  isAuthenticated: false,
  isConfirming: false,
  isLoading: false,
  validating: false,
  validated: false,
  isAuthorized: false,
};

const clearAuthState = createAction("AUTH_STATE_CLEAR");

const authStateSuccess = createAction<AuthStateSyncPayload>(
  "AUTH_STATE_SYNC",
  (authState) => ({
    authState,
  })
);

const authStateSync = (authState: any) => {
  return async (dispatch: any, getState: any, services: any) => {
    try {
      localRepository.accessToken.set(authState.accessToken);
      if (!authState.accessToken) {
        localRepository.clear();
      }

      dispatch(authStateSuccess(authState));
    } catch (error) {
      dispatch(signInFail());
    }
  };
};

const signInInit = createAction("SIGN_IN_INIT");
const signInAction = createAction("SIGN_IN");
const signInSuccess = createAction("SIGN_IN_SUCCESS");
const signInConfirming = createAction("SIGN_IN_CONFIRMING");
const signInFail = createAction("SIGN_IN_FAIL");

const signInWithSocial = (callback: () => void) => {
  return async (dispatch: any, getState: any, services: any) => {
    try {
      dispatch(signInAction());

      callback();
    } catch (error) {
      console.error(error);
    }
  };
};

const postOnboardingInited = createAction("ONBOARDING_INITED");

const postOnboardingCompleted = createAction<PostOnboardingCompletedPayload>(
  "ONBOARDING_COMPLETED",
  (userIsValid) => ({ userIsValid })
);

const postOnboardingFailed = createAction<PostOnboardingFailedPayload>(
  "ONBOARDING_FAILED",
  (error) => ({ error })
);

const postOnboarding = () => {
  return async (dispatch: any, getState: any, services: any) => {
    try {
      const validating = getState().authReducer.validating;
      if (!validating) {
        dispatch(postOnboardingInited());

        const response = await services.api.usuarios().postOnboarding();

        dispatch(postOnboardingCompleted(response.usuarioValido));
      }
    } catch (error) {
      console.error(error);
      dispatch(postOnboardingFailed(error));
    }
  };
};

const authReducer = handleActions<AuthState, any>(
  {
    [clearAuthState.toString()]: (state) => {
      return initialState;
    },
    [postOnboardingCompleted.toString()]: (
      state,
      action: Action<PostOnboardingCompletedPayload>
    ) => {
      return {
        ...state,
        validating: false,
        isAuthorized: action.payload!.userIsValid,
        validated: true,
      };
    },
    [postOnboardingInited.toString()]: (state) => {
      return {
        ...state,
        validating: true,
        validated: false,
      };
    },
    [postOnboardingFailed.toString()]: (state) => {
      return {
        ...state,
        validating: false,
      };
    },
    [authStateSuccess.toString()]: (
      state,
      action: Action<AuthStateSyncPayload>
    ) => {
      return {
        ...state,
        authState: action.payload!.authState,
        isLoading: false,
        isAuthenticated: !!action.payload!.authState,
      };
    },
    [signInInit.toString()]: () => {
      return { ...initialState };
    },
    [signInAction.toString()]: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [signInConfirming.toString()]: (state) => {
      return {
        ...state,
        isConfirming: true,
        isLoading: false,
      };
    },
    [signInSuccess.toString()]: (state) => {
      return {
        ...state,
        isLoading: false,
        isConfirming: true,
      };
    },
    [signInFail]: (state) => {
      return {
        ...state,
        isLoading: false,
        isConfirming: false,
      };
    },
  },
  initialState
);

export default authReducer;
export {
  authStateSync,
  signInWithSocial,
  signInInit,
  postOnboarding,
  clearAuthState,
};
