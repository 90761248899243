import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectorMultiple = ({onChange, options, value}) => {
  const classes = useStyles();
  const defaultValue = "Todos";
  const [items, setItems] = useState([]);

  const handleChange = event => {
    let lastElement = event.target.value[event.target.value.length - 1];

    if(lastElement !== defaultValue){
      let index = event.target.value.indexOf(defaultValue);
      if (index !== -1) {
        event.target.value.splice(index, 1);
      }
    }
    

    if(event.target.value.find(element => element === defaultValue)){
      onChange([]);
    }
    else if(event.target.value[0] === undefined){
      onChange(null);
    }
    else
      onChange(event.target.value);

  };

  useEffect(() => {
    if(value === null)
      setItems([]);
    else if(value[0] === undefined)
      setItems([defaultValue]);
    else
      setItems(value);
  },[value]);

  return (
    <div>
      <FormControl className={classes.container}>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={items}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
         <MenuItem key={defaultValue} value={defaultValue}>
              <Checkbox checked={items.indexOf(defaultValue) > -1} />
              <ListItemText primary={defaultValue} />
            </MenuItem> 
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={items.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectorMultiple;