import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_ESTADISTICAS_INITIAL_STATE");

const getEstadisticasInited = createAction("OBTENER_ESTADISTICAS_INITED");

const getEstadisticasCompleted = createAction(
  "OBTENER_ESTADISTICAS_COMPLETED",
  (response, isAnOldRequest) => {
    let estadisticas = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      estadisticas = response.companias;
      totalRegistros = response.totalRegistros;
    }

    return { estadisticas, totalRegistros, isAnOldRequest };
  }
);
const getEstadisticasFailed = createAction(
  "OBTENER_ESTADISTICAS_FAILED",
  (error) => ({
    error,
  })
);

const getEstadisticas = (
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getEstadisticasInited());
      const response = await services.api.estadisticas().getEstadisticas();
      dispatch(getEstadisticasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getEstadisticasFailed(error));
    }
  };
};

const estadisticasInitialState = {
  isLoading: false,
  estadisticas: null,
  totalRegistros: 0,
};

const estadisticasReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...estadisticasInitialState,
        take: state.take,
        estadisticas: null,
        isLoading: true,
      };
    },
    [getEstadisticasInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getEstadisticasCompleted]: (state, action) => {
      return {
        ...state,
        estadisticas: action.payload.estadisticas,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
        failed: false,
      };
    },
    [getEstadisticasFailed]: (state, action) => {
      return {
        ...state,
        isPageAlreadyLoad: false,
        isLoading: false,
        failed: true,
      };
    },
  },
  estadisticasInitialState
);

export { estadisticasReducer };
export { getEstadisticas, setInitialState };
