import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import ItemLoadingCard from "./ItemLoadingCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: pxToRem(270),
    width: "60%",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
}));

const ItemCustomItem = ({ id, isLoading, label, children, variant }) => {
  const classes = useStyles();

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div
      className={classes.container}
      style={{ padding: variant === "small" ? "1%" : "2%" }}
    >
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

export default ItemCustomItem;
