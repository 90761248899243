const TODOS = "";
export const ABANDONADA = "Abandonada";
export const CON_SOLICITUD = "ConSolicitud";

const estadosMulticotizacionOptions = {
  [TODOS]: "Todos",
  [ABANDONADA]: "Abandonada",
  [CON_SOLICITUD]: "Con Solicitud"
};

const estadosMulticotizacionFilter = [
  { label: estadosMulticotizacionOptions[TODOS], value: TODOS },
  { label: estadosMulticotizacionOptions[ABANDONADA], value: ABANDONADA },
  { label: estadosMulticotizacionOptions[CON_SOLICITUD], value: CON_SOLICITUD }
];

export { estadosMulticotizacionOptions, estadosMulticotizacionFilter };
