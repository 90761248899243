import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ItemInputCard from "~business/Cards/ItemInputCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    return {
      ...currentSiniestro,
    };
  });

const title = "Detalles del Siniestro";

const DetalleSiniestroCard = ({ isLoading }) => {
  const {
    daniosVehiculoAsegurado,
    comoOcurrio,
    lesionesFisicas,
  } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={QuestionAnswerIcon}>
        <ItemInputCard
          id={"lesionesFisicas"}
          isLoading={isLoading}
          label={"Lesiones Físicas"}
          value={lesionesFisicas}
          readOnly
        />
        <ItemInputCard
          id={"comoOcurrio"}
          isLoading={isLoading}
          label={"¿Cómo ocurrió?"}
          value={comoOcurrio}
          readOnly
        />
        <ItemInputCard
          id={"daniosVehiculoAsegurado"}
          isLoading={isLoading}
          label={"Daños al Vehículo Asegurado"}
          value={daniosVehiculoAsegurado}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DetalleSiniestroCard;
