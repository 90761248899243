import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { GetOficinasResponse } from "./types";

const oficinas = () => {
  return {
    getOficinas: (): Promise<GetOficinasResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let url = environment.segurosUrl + "/oficinas";
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { oficinas };
