import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setTake,
  selectSearchCriteria,
  selectEstado,
  selectTipo,
  selectFechaDesde,
  selectFechaHasta,
  descargarReporte,
} from "./actions";
import Searcher from "~styled/searcher/searcher";
import { Typography, Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import { clearData } from "./actions";
import { paths, cargaSiniestroPath } from "~constants/referenceNames";
import Selector from "~styled/selector/Selector";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import DateRangePicker from "~styled/dateRangePicker/DateRangePicker";
import { calculateDate } from "~libs/dateCalculator";
import {
  estadoSiniestrosOptions,
  estadoSiniestrosFilter,
} from "~constants/estadoSiniestros";
import {
  tipoSiniestroOptions,
  tipoSiniestroFilter,
} from "~constants/tipoSiniestro";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
    minWidth: "10px",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: "20px",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  horizontalSpace: { paddingRight: "10px" },
  horizontalSpaceDatePicker: { paddingRight: "10px", minWidth: 250 },
  searcherAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
  },
  estadoSelector: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
  },
  tipoSelector: {
    display: "flex",
    flexDirection: "column",
    width: "235px",
  },
  btnSiniestro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "200px",
    paddingLeft: "15px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.listadoSiniestrosReducer.isLoading,
    totalRegistros: state.listadoSiniestrosReducer.totalRegistros,

    take: state.listadoSiniestrosFiltroReducer.take,
    page: state.listadoSiniestrosFiltroReducer.page,
    searchCriteria: state.listadoSiniestrosFiltroReducer.searchCriteria,
    estado: state.listadoSiniestrosFiltroReducer.estado,
    tipo: state.listadoSiniestrosFiltroReducer.tipo,
    fechaDesde: state.listadoSiniestrosFiltroReducer.fechaDesde,
    fechaHasta: state.listadoSiniestrosFiltroReducer.fechaHasta,
    buttonDescargaEnabled: state.listadoSiniestrosFiltroReducer.buttonDescargaEnabled,
    buttonDescargaText: state.listadoSiniestrosFiltroReducer.buttonDescargaText,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    take,
    page,
    searchCriteria,
    estado,
    tipo,
    totalRegistros,
    fechaDesde,
    fechaHasta,
    isLoading,
    buttonDescargaEnabled,
    buttonDescargaText
  } =
    useReduxSelector();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [dispatch]);

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const handleChangeInterval = (value) => {
    dispatch(setPage(0));
    dispatch(selectFechaDesde(calculateDate(new Date(), value)));
    dispatch(selectFechaHasta(new Date()));
  };

  const handleDescargaReporte = () => {
    let data = {
      fechaDesde,
      fechaHasta,
      searchCriteria,
      estado,
      tipo,
    };
    dispatch(descargarReporte(data));
  };

  return (
    <div className={classes.root}>
      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                BUSCAR
              </Typography>

              <div className={classes.horizontalSpace}>
                <Searcher
                  borderSquared
                  value={searchCriteria}
                  filter={searchCriteria}
                  placeholder="N° Póliza, N° Siniestro Compañía, DNI, Dominio"
                  onChange={(event) =>
                    handleChange(event, selectSearchCriteria)
                  }
                  onClear={() =>
                    handleChange(
                      { target: { value: "" } },
                      selectSearchCriteria
                    )
                  }
                />
              </div>
            </div>

            <div className={classes.horizontalSpaceDatePicker}>
              <Typography variant="h3" className={classes.label}>
                FECHA INGRESADO
              </Typography>
              <DateRangePicker
                fechaDesde={fechaDesde}
                fechaHasta={fechaHasta}
                onChangeInterval={handleChangeInterval}
                onChangeStartDate={(value) =>
                  handleChange({ target: { value } }, selectFechaDesde)
                }
                onChangeEndDate={(value) =>
                  handleChange({ target: { value } }, selectFechaHasta)
                }
                onClickDisabled={() => {
                  dispatch(queueMessage(messageLoading));
                }}
                disabled={isLoading}
                maxDate={new Date()}
              />
            </div>
          </div>

          <div className={classes.btnSiniestro}>
            <Tooltip title="Descargar reporte en archivo Excel">
              <Button
                variant="contained"
                color="primary"
                onClick={handleDescargaReporte}
                disabled={!buttonDescargaEnabled || isLoading}
              >
                {buttonDescargaText}
              </Button>
            </Tooltip>
          </div>

          <div className={classes.btnSiniestro}>
            <Tooltip title="Carga Siniestro">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={paths[cargaSiniestroPath]}
              >
                {"CARGAR SINIESTRO"}
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.filtersContainer}>

          <div className={classes.filters}>
            <div className={classes.estadoSelector}>
              <Typography variant="h3" className={classes.label}>
                ESTADO
              </Typography>
              <div className={classes.horizontalSpace}>
                <Selector
                  value={estadoSiniestrosOptions[estado]}
                  options={estadoSiniestrosFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectEstado)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className={classes.tipoSelector}>
              <Typography variant="h3" className={classes.label}>
                TIPO
              </Typography>
              <div className={classes.horizontalSpace}>
                <Selector
                  value={tipo !== null ? tipoSiniestroOptions[tipo] : "Seleccionar"}
                  options={tipoSiniestroFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectTipo)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          {!isLoading &&
            <Pagination
              rowsPerPage={take}
              onChangeRowsPerPage={(value) =>
                handleChange({ target: { value } }, setTake)
              }
              page={page}
              onChangePage={handleChangePage}
              totalRegistros={totalRegistros}
              options={paginationOptions}
            />
          }
        </div>
      </>
    </div >
  );
};

export default Filtros;
