import React from "react";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import googleIcon from "~images/auth/google.svg";
import SocialButton from "~styled/socialButton/SocialButton";
import { signInWithSocial } from "./actions";
import WaveLayout from "./WaveLayout";

import { useAuth0 } from "../Auth0/useAuth";
import { AuthState } from "./actions";

interface RootState {
  authReducer: AuthState;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const useReduxSelector = () =>
  useTypedSelector((state) => ({
    isLoading: state.authReducer.isLoading,
  }));

const SigninWithSocialNetworks = ({ onChangeToEmailAndPasswordLogin }) => {
  const dispatch = useDispatch();
  const { isLoading } = useReduxSelector();

  const { loginSocial } = useAuth0();

  return (
    <WaveLayout title={"Ingresá con tu cuenta de MG"} isLoading={isLoading}>
      <div style={{ height: 10 }} />
      <SocialButton
        onClick={() => {
          dispatch(signInWithSocial(() => loginSocial("google-oauth2")));
        }}
        label={"Gmail"}
        icon={googleIcon}
        backgroundColor={"#ef4837"}
        backgroundColorIcon={"#d93a2a"}
        marginBottom
        disabled={false}
      />
      <div style={{ height: 40 }} />
    </WaveLayout>
  );
};

export default SigninWithSocialNetworks;
