import React, { memo } from "react";
import Lottie from "lottie-light-react";
import loading from "~assets/animations/loading.json";

const LoadingView = memo(() => {
  const style = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    top: 0,
    left: 0,
    backgroundColor: "white",
  };

  return (
    <div style={style}>
      <div style={{ width: 60 }}>
        <Lottie autoPlay={true} animationData={loading} loop={true} />
      </div>
    </div>
  );
});

export default LoadingView;
