import React, { useState, useEffect } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { makeStyles } from "@material-ui/core/styles";
import ChipCobertura from "~shared/chipCobertura/ChipCobertura";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  sinEsquemas: {
    display: "flex",
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "1em",
    textAlign: "center",
    padding: 20,
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center"
  },
  sortableContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: 0,
    padding: 0
  }
}));

const ListSortable = ({ value, onChange, onClickCobertura }) => {
  const [items, setItems] = useState(value.idsCoberturas);

  useEffect(() => {
    setItems(value.idsCoberturas);
  }, [value]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
      if (onChange) onChange(newItems);
    }
  };

  const handleDelete = value => {
    let index = items.indexOf(value);

    items.splice(index, 1);
    setItems(items);
    if (onChange) onChange(items);
  };

  const handleClick = cobertura => {
    if(onClickCobertura)
      onClickCobertura(value.idCompania, cobertura);
  }

  const classes = useStyles();

  return (
    <SortableContainer pressDelay={200} axis="x" onSortEnd={onSortEnd}>
      {items.length === 0 && (
        <Typography className={classes.sinEsquemas}>
          Sin coberturas seleccionadas
        </Typography>
      )}
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.idCobertura}`}
          index={index}
          value={value}
          onClick={handleClick}
          onDelete={handleDelete}
        />
      ))}
    </SortableContainer>
  );
};

export default ListSortable;

const SortableItem = sortableElement(({ value, onClick, onDelete }) => {
  return (
    <div style={{ padding: "5px 5px 0px 0px" }}>
      <ChipCobertura
        label={value.idCobertura}
        onClick={() => {
          if (onClick) onClick(value);
        }}
        onDelete={() => {
          if (onDelete) onDelete(value);
        }}
        tipoCobertura={value.tipoCobertura}
        clickable={true}
      />
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  const classes = useStyles();
  return <ul className={classes.sortableContainer}>{children}</ul>;
});
