import { errorUnlessOk, buildRequestWithAuth } from "./utils";
import environment from "~libs/environment";

const identidad = () => {
  return {
    getIdentidad: (documento, sexo) => {
      let request = buildRequestWithAuth("GET");
      return fetch(
        `${environment.creditRiskUrl}/personasfisicas/identidad?documento=${documento}&sexo=${sexo}`,
        request
      )
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { identidad };
