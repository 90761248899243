import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { setTake, setPage } from "../Filtros/actions";
import { patchAnularPoliza } from "./../actions";
import { paths, polizasPath } from "~constants/referenceNames";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CancelIcon from "@material-ui/icons/Cancel";
import TableLoading from "~styled/tableLoading/TableLoading";
import Filtros from "../Filtros/Filtros";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import {
  VIGENTE,
  EMITIDANOVIGENTE,
  ANULADA,
  VENCIDA,
} from "~constants/polizaEstados";
import PopupAnular from "./PopupAnular";
import SuccessDialog from "./SuccessDialog";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    width: "100%",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  iconPdfButton: {
    color: "#b00020",
    padding: 0,
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellCenter: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  anularIcon: {
    color: "red",
  },
  negocioColumnStyle: {
    padding: "0.5%",
  },
  dash: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.filtrosPolizasReducer.take,
    page: state.filtrosPolizasReducer.page,

    polizas: state.polizasReducer.polizas,
    totalRegistros: state.polizasReducer.totalRegistros,
    isLoading: state.polizasReducer.isLoading,
    polizaAnulada: state.polizasReducer.polizaAnulada,
    canAnularPolizas: state.authenticatedViewReducer.canAnularPolizas,
  }));

const ListadoPolizas = () => {
  const {
    page,
    take,
    polizas,
    totalRegistros,
    isLoading,
    canAnularPolizas,
    polizaAnulada,
  } = useReduxSelector();
  const flags = useFlags();
  const dispatch = useDispatch();
  const empty = totalRegistros === 0;

  const classes = useStyles();

  const [isPopupAnularOpened, setIsPopupAnularOpened] = useState(false);
  const [currentPoliza, setCurrentPoliza] = useState(null);

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const handleClickAnular = (poliza) => {
    setCurrentPoliza(poliza);
    setIsPopupAnularOpened(true);
  };

  const handleConfirmarRechazo = (motivo, polizaId, fechaAnulacion) => {
    setIsPopupAnularOpened(false);
    dispatch(patchAnularPoliza(polizaId, motivo, fechaAnulacion));
  };

  const handleClosePopupRechazo = () => {
    setIsPopupAnularOpened(false);
    setCurrentPoliza(null);
  };

  const getValueParsed = (value) => {
    if (!value || value === 0)
      return <div className={classes.dash}>&mdash;</div>;

    if (Date.parse(value) && value.length === 28) {
      return format(Date.parse(value), "dd'/'MM'/'yyyy", {
        locale: es,
      });
    }

    return value;
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && currentPoliza !== null && (
            <>
              <PopupAnular
                isOpened={isPopupAnularOpened}
                polizaId={currentPoliza.id}
                compania={currentPoliza.compania}
                nroPoliza={currentPoliza.numeroPoliza}
                titular={currentPoliza.titular}
                onClickAceptar={handleConfirmarRechazo}
                onClose={handleClosePopupRechazo}
              />
              <SuccessDialog
                openDialog={polizaAnulada}
                value={"Póliza anulada con éxito"}
              />
            </>
          )}
          {!isLoading && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.tableCell} ${classes.negocioColumnStyle}`}
                  >
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Negocio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Compañia"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Nº. Póliza"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Estado"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"DNI"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Titular"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Dominio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Vehículo"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Vigencia"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Op."}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Acciones"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {!isLoading &&
                  !empty &&
                  polizas.map((poliza) => (
                    <TableRow
                      key={"table-polizaes-" + poliza.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell
                        className={`${classes.tableCell} ${classes.negocioColumnStyle}`}
                      >
                        <ColorLabelNegocio
                          negocio={poliza.negocio}
                          abbreviated
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.compania)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.numeroPoliza)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.estado)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {poliza.dniTitular}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.titular)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.dominio)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {`(${poliza.anio}) ${
                          poliza.marca !== null ? poliza.marca : ""
                        } ${poliza.modelo}`}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {`${format(
                          Date.parse(poliza.polizaVigenciaDesde),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )}
                          hasta ${format(
                            Date.parse(poliza.polizaVigenciaHasta),
                            "dd'/'MM'/'yyyy",
                            { locale: es }
                          )}`}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {poliza.operacionCreditoId !== "0" ? (
                          getValueParsed(poliza.operacionCreditoId)
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellCenter}>
                        <Tooltip title={"Ver detalle de la Póliza"}>
                          <IconButton
                            className={classes.iconButton}
                            component={Link}
                            to={`${paths[polizasPath]}/${poliza.id}`}
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        {flags["renovacionesLira"] && poliza.renovable && (
                          <Tooltip title={"Renovar Póliza"}>
                            <IconButton
                              className={classes.iconButton}
                              component={Link}
                              to={`${paths[polizasPath]}/${poliza.id}/renovar`}
                            >
                              <AutorenewIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {(poliza.estado === ANULADA ||
                          poliza.estado === VENCIDA) &&
                          canAnularPolizas && (
                            <Tooltip
                              title={`Póliza ${
                                poliza.estado === ANULADA
                                  ? "Anulada"
                                  : "Vencida"
                              }`}
                            >
                              <span>
                                <IconButton
                                  className={`${classes.iconButton}`}
                                  disabled
                                >
                                  <CancelIcon className={`disabled`} />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}

                        {poliza.negocio === "Prendarios" &&
                          canAnularPolizas &&
                          (poliza.estado === EMITIDANOVIGENTE ||
                            poliza.estado === VIGENTE) && (
                            <Tooltip
                              title={"Póliza Prendaria sin Anulación Manual"}
                            >
                              <span>
                                <IconButton
                                  className={`${classes.iconButton}`}
                                  disabled
                                >
                                  <CancelIcon className={`disabled`} />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}

                        {poliza.negocio === "MercadoAbierto" &&
                          canAnularPolizas &&
                          (poliza.estado === EMITIDANOVIGENTE ||
                            poliza.estado === VIGENTE) &&
                          (poliza.compania === "Sancor" ||
                            poliza.compania === "Berkley") && (
                            <Tooltip title={"Póliza con Anulación Automática"}>
                              <span>
                                <IconButton
                                  className={`${classes.iconButton}`}
                                  disabled
                                >
                                  <CancelIcon className={`disabled`} />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}

                        {poliza.negocio === "MercadoAbierto" &&
                          canAnularPolizas &&
                          (poliza.estado === EMITIDANOVIGENTE ||
                            poliza.estado === VIGENTE) &&
                          poliza.compania !== "Sancor" &&
                          poliza.compania !== "Berkley" && (
                            <Tooltip title={"Anular Póliza"}>
                              <IconButton
                                className={`${classes.iconButton}`}
                                onClick={() => handleClickAnular(poliza)}
                              >
                                <CancelIcon
                                  className={`${classes.anularIcon}`}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinCotizaciones}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                width: "100%",
              }}
            >
              {!isLoading &&
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              }
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

export default ListadoPolizas;
