import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Selector from "~styled/selector/Selector";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingBottom: 5
  },

  labelPage: {
    display: "flex",
    minWidth: "115px",
    fontSize: "10px",
    alignItems: "flex-end",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2
  },
  horizontalSpace: { paddingRight: "10px" },
  textItemContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },

  iconDisabledButton: {
    color: theme.colours.grey2
  },
  iconButton: {
    justifyContent: "center",
    alignItems: "flex-end",
    padding: 0,
    color: theme.colours.brownGrey
  }
}));

const Pagination = ({
  rowsPerPage,
  onChangeRowsPerPage,
  page,
  onChangePage,
  totalRegistros,
  options
}) => {
  const intRowsPerPage = parseInt(rowsPerPage);
  const intPage = parseInt(page);
  const intTotalRegistros = parseInt(totalRegistros);

  const leftDisabled = page === 0;
  const rightDisabled = (intRowsPerPage * intPage + intRowsPerPage) >= intTotalRegistros;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textItemContainer}>
        <Typography variant="h3" className={classes.label}>
          ITEMS POR PAG.
        </Typography>
        <div className={classes.horizontalSpace}>
          <Selector
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
            options={options}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "5px"
        }}
      >
        <div>
          <Typography className={classes.labelPage}>
            {"Página " +
              (intTotalRegistros > 0 ? page + 1 : 0) +
              " de " +
              Math.ceil(intTotalRegistros / intRowsPerPage)}
          </Typography>
          <Typography className={classes.labelPage}>
            {"Total de registros " + totalRegistros}
          </Typography>
        </div>
        <IconButton
          onClick={() => {
            if (onChangePage) onChangePage(intPage - 1);
          }}
          className={classes.iconButton}
          disabled={leftDisabled}
          size="small"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            if (onChangePage) onChangePage(intPage + 1);
          }}
          className={classes.iconButton}
          disabled={rightDisabled}
          size="small"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Pagination;
