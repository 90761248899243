import { createAction, handleActions } from "redux-actions";

const selectTokenPermissions = createAction(
  "SELECT_PERMISOS_TOKEN",
  (tokenPermissions) => ({
    tokenPermissions,
  })
);

const selectRoles = createAction("SELECT_ROLES", (roles) => ({
  roles,
}));

const authenticatedViewInitialState = {
  tokenPermissions: [],
  canApplyEndoso: false,
  canUploadImpresos: false,
  roles: [],
  isAdminUser: false,
  canRenovarPolizas: false,
  canAnularPolizas: false
};

const authenticatedViewReducer = handleActions(
  {
    [selectTokenPermissions]: (state, action) => {
      return {
        ...state,
        tokenPermissions: action.payload.tokenPermissions,
        canCreateEndoso: action.payload.tokenPermissions.some(
          (perm) => perm === "polizas:solicitarEndoso"
        ),
        canApplyEndoso: action.payload.tokenPermissions.some(
          (perm) => perm === "polizas:aplicarEndoso"
        ),
        canUploadImpresos: action.payload.tokenPermissions.some(
          (perm) => perm === "polizas:readAll"
        ),
        canAnularPolizas: action.payload.tokenPermissions.some(
          (perm) => perm === "polizas:anular"
        ),
      };
    },
    [selectRoles]: (state, action) => {
      return {
        ...state,
        roles: action.payload.roles,
        isAdminUser: action.payload.roles.some((role) => role === "admin"),
        displayMercadoAbiertoOnly: action.payload.roles.some(
          (role) => role === "oficina"
        ),
        canRenovarPolizas: action.payload.roles.some((role) => role === "admin") || action.payload.roles.some((role) => role === "mesabackoffice")
      };
    },
  },
  authenticatedViewInitialState
);

export { authenticatedViewReducer };
export { selectTokenPermissions, selectRoles };

// const permissions = token.permissions;
// const useIsEnabled = (required) => {
//   return true;
// };

// const canApplyEndoso = useIsEnabled("polizas:aplicarEndoso")
