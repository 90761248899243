import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, siniestrosPath } from "~constants/referenceNames";
import {
  GetSiniestrosRequest,
  GetSiniestrosResponse,
  GetSiniestroResponse,
  PostSiniestroRequest,
  PostSiniestroResponse,
  PatchSiniestroEstadoRequest,
  PatchSiniestroTipoRequest,
  PostSiniestroComentarioRequest,
} from "./types";

const siniestros = () => {
  const dateWithoutTimezone = (date: Date) => {
    const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset);
    return withoutTimezone;
  };

  return {
    getSiniestros: (
      data: GetSiniestrosRequest
    ): Promise<GetSiniestrosResponse> => {
      const fechaDesdeUTCClear = data.fechaDesde
        ? dateWithoutTimezone(data.fechaDesde)
        : "";
      const fechaHastaUTCClear = data.fechaHasta
        ? dateWithoutTimezone(data.fechaHasta)
        : "";

      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      if (data.searchCriteria) {
        parameters += `&SearchCriteria=${data.searchCriteria}`;
      }

      if (data.estado) {
        parameters += `&Estado=${data.estado}`;
      }

      if (data.tipo && data.tipo !== "Seleccionar") {
        parameters += `&Tipo=${data.tipo}`;
      }

      if (fechaDesdeUTCClear) {
        parameters += `&FechaDesde=${fechaDesdeUTCClear.toISOString()}`;
      }

      if (fechaHastaUTCClear) {
        fechaHastaUTCClear.setUTCHours(23, 59, 59);
        parameters += `&FechaHasta=${fechaHastaUTCClear.toISOString()}`;
      }

      const url = `${environment.segurosUrl}${paths[siniestrosPath]}?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getSiniestro: (id: string): Promise<GetSiniestroResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${id}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    postSiniestro: (
      data: PostSiniestroRequest
    ): Promise<PostSiniestroResponse> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    patchSiniestroEstado: (
      siniestroId: string,
      data: PatchSiniestroEstadoRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${siniestroId}/cambiarEstado`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    patchSiniestroTipo: (
      siniestroId: string,
      data: PatchSiniestroTipoRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${siniestroId}/cambiarTipo`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    postSiniestroComentario: (
      siniestroId: string,
      data: PostSiniestroComentarioRequest
    ): Promise<Response> => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${siniestroId}/comentarios`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { siniestros };
