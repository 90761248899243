import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { coberturas } from "../api/coberturas";
import { esquemas } from "../api/esquemas";
import { cotizaciones } from "../api/cotizaciones";
import { estadisticas } from "../api/estadisticas";
import { solicitudesEmision } from "../api/solicitudesEmision";
import { polizasMultirama } from "../api/polizasMultirama";
import { usuarios } from "../api/usuarios";
import { oficinas } from "../api/oficinas";
import { agencias } from "../api/agencias";
import { tiposCobertura } from "../api/tiposCobertura";
import { emisionesManuales } from "../api/emisionesManuales";
import { novedades } from "../api/novedades";
import { polizas } from "../api/polizas";
import { vehiculos, provincias } from "~api/catalogos";
import { multicotizador } from "~api/multicotizador";
import { identidad } from "~api/identidad";
import { reportes } from "~api/reportes";
import { endosos } from "~api/endosos";
import { siniestros } from "~api/siniestros";
import { siniestrosCargaPublica } from "~api/siniestrosCargaPublica";
import { roles } from "~api/roles";
import analyticsMiddleware from "./middlewares/analyticsMiddleware";

const defaultServices = {
  api: {
    multicotizador,
    coberturas,
    polizas,
    novedades,
    esquemas,
    cotizaciones,
    estadisticas,
    solicitudesEmision,
    polizasMultirama,
    emisionesManuales,
    usuarios,
    oficinas,
    roles,
    vehiculos,
    provincias,
    tiposCobertura,
    agencias,
    identidad,
    reportes,
    endosos,
    siniestros,
    siniestrosCargaPublica,
  },
};

export default function configureStore(
  initialState,
  services = defaultServices
) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        thunk.withExtraArgument(services),
        //errorsMiddleware,
        analyticsMiddleware
      )
    )
  );
}
