import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { usuariosPath } from "~constants/referenceNames";
import ListadoUsuarios from "./listadoUsuarios/ListadoUsuarios";
import UsuarioEditor from "./usuarioEditor/UsuarioEditor";
import { getUsuarios, getUsuario, setCurrentUsuario } from "./actions";
import { setTake, setPage } from "./Filtros/actions";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import { selectSearchCriteria } from "./Filtros/actions";
import { useHistory, useLocation } from "react-router-dom";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.usuariosReducer.isLoading,
    failed: state.usuariosReducer.failed,

    failedUsuarioEditor: state.usuarioEditorReducer.failed,

    page: state.filtrosUsuariosReducer.page,
    take: state.filtrosUsuariosReducer.take,
    searchCriteria: state.filtrosUsuariosReducer.searchCriteria,
    getOnlyActivos: state.filtrosUsuariosReducer.getOnlyActivos,

    currentUsuario: state.usuariosReducer.currentUsuario,
  }));

const params = [
  { name: "take", nameUrl: "Take", action: setTake },
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
];

const ListadoUsuariosContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentState = useReduxSelector();
  const {
    page,
    take,
    isLoading,
    searchCriteria,
    currentUsuario,
    failed,
    failedUsuarioEditor,
    getOnlyActivos,
  } = currentState;

  const [openEditor, setOpenEditor] = useState(false);

  const handleEdit = (usuario) => () => {
    dispatch(getUsuario(usuario.id));
    setOpenEditor(true);
  };

  const handleClose = () => {
    dispatch(setCurrentUsuario(null));
    setOpenEditor(false);
  };

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: usuariosPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [history, searchCriteria, page, take]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading && url) {
      dispatch(getUsuarios(page * take, take, searchCriteria, getOnlyActivos));
    }
    //eslint-disable-next-line
  }, [dispatch, url, getOnlyActivos]);

  if (failed || failedUsuarioEditor) return <UnexpectedError />;
  return (
    <SectionLayout Icon={AssignmentIcon} title={"Usuarios"}>
      <div className={classes.root}>
        <div className={classes.body}>
          <ListadoUsuarios onEdit={handleEdit} />
          {openEditor && (
            <UsuarioEditor
              isLoading={!currentUsuario}
              usuario={currentUsuario}
              onClose={handleClose}
            />
          )}
        </div>
      </div>
    </SectionLayout>
  );
};

export default ListadoUsuariosContainer;
