import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const setInitialStateSiniestros = createAction(
  "SET_INITIAL_STATE_FILTRO_SINIESTROS"
);

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_SINIESTROS",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const descargaInited = createAction(
  "GET_REPORTE_SINIESTROS_INITED",
  (data) => ({ data })
);
const descargaCompleted = createAction(
  "GET_REPORTE_SINIESTROS_COMPLETED"
);
const descargaFailed = createAction(
  "GET_REPORTE_SINIESTROS_FAILED",
  (error) => ({ error })
);

const selectEstado = createAction("SELECT_ESTADO_SINIESTROS", (estado) => ({
  estado,
}));

const selectTipo = createAction("SELECT_TIPO_SINIESTROS", (tipo) => ({
  tipo,
}));

const setPage = createAction("SET_LISTADO_SINIESTROS_PAGE", (page) => ({
  page,
}));

const setTake = createAction(
  "SET_LISTADO_SINIESTROS_ROWS_PER_PAGE",
  (take) => ({
    take,
  })
);

const clearData = createAction("CLEAR_DATA_FILTRO_SINIESTROS", () => ({}));

const selectFechaDesde = createAction(
  "SELECT_FECHA_DESDE_FILTRO",
  (fechaDesde) => ({ fechaDesde })
);

const selectFechaHasta = createAction(
  "SELECT_FECHA_HASTA_FILTRO",
  (fechaHasta) => ({ fechaHasta })
);

const listadoSiniestrosFiltroReducerInitialState = {
  take: 10,
  page: 0,
  searchCriteria: "",
  estado: "Ingresado",
  tipo: "",
  fechaDesde: "",
  fechaHasta: "",
  buttonDescargaEnabled: true,
  buttonDescargaText: "DESCARGAR REPORTE",
};

const listadoSiniestrosFiltroReducer = handleActions(
  {
    [setInitialStateSiniestros]: (state, action) => {
      return {
        ...listadoSiniestrosFiltroReducerInitialState,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        take: action.payload.take,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [selectEstado]: (state, action) => {
      return {
        ...state,
        estado: action.payload.estado,
      };
    },
    [selectTipo]: (state, action) => {
      return {
        ...state,
        tipo: action.payload.tipo,
      };
    },
    [selectFechaDesde]: (state, action) => {
      return {
        ...state,
        fechaDesde: action.payload.fechaDesde,
      };
    },
    [selectFechaHasta]: (state, action) => {
      return {
        ...state,
        fechaHasta: action.payload.fechaHasta,
      };
    },
    [descargaInited]: (state, action) => {
      return {
        ...state,
        buttonDescargaEnabled: false,
        buttonDescargaText: "DESCARGANDO...",
      };
    },
    [descargaCompleted]: (state, action) => {
      return {
        ...state,
        buttonDescargaEnabled: true,
        buttonDescargaText: "DESCARGAR REPORTE",
      };
    },
    [descargaFailed]: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload.error.responseStatus.message,
        buttonDescargaEnabled: true,
        buttonDescargaText: "DESCARGAR REPORTE",
      };
    },
    [clearData]: (state, action) => {
      return {
        ...listadoSiniestrosFiltroReducerInitialState,
      };
    },
  },
  listadoSiniestrosFiltroReducerInitialState
);

const descargarReporte = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(descargaInited(data));
      let response = await services.api.reportes().getSiniestros(data);

      // create a fake anchor and click it programatically to
      // force the file to download
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var downloadUrl = URL.createObjectURL(xhttp.response);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = downloadUrl;
          a.download = "";
          a.click();
        }
      };

      xhttp.open("GET", response.url, true);
      xhttp.responseType = "blob";
      xhttp.send();

      dispatch(descargaCompleted());
    } catch (error) {
      console.error(error);
      dispatch(descargaFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al descargar el reporte. Intente nuevamente."
        )
      );
    }

  };
};

export { listadoSiniestrosFiltroReducer };

export {
  setTake,
  setPage,
  clearData,
  selectSearchCriteria,
  selectEstado,
  selectTipo,
  setInitialStateSiniestros,
  selectFechaDesde,
  selectFechaHasta,
  descargarReporte
};
