import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import CancelIcon from "@material-ui/icons/Cancel";
import ItemDateCard from "~business/Cards/ItemDateCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import { useSelector, useDispatch } from "react-redux";
import { setMotivoAnulacion } from "../../actions";
import {
  motivoAnulacionFilter,
  motivoAnulacionOptions,
} from "~constants/endosos";

const title = "Informacion sobre Anulación";

const useReduxSelector = () =>
  useSelector((state) => ({
    motivoAnulacion:
      state.detallePolizaNuevoReducer.motivoAnulacionSelected.value,
  }));

const DatosAnulacionCard = ({ isLoading, displayError, onBlur }) => {
  const { motivoAnulacion } = useReduxSelector();
  const dispatch = useDispatch();

  const handleChangeMotivo = (motivo) => {
    dispatch(setMotivoAnulacion(motivo));
    if (onBlur) onBlur();
  };

  return (
    <DetalleCard title={title} IconHeader={CancelIcon}>
      <ItemDateCard
        readOnly
        isLoading={isLoading}
        label="Fecha Anulación"
        value={new Date()}
      />
      <ItemSelectorCard
        isLoading={isLoading}
        label="Motivo Anulación"
        value={motivoAnulacion}
        options={motivoAnulacionFilter}
        valueOptions={motivoAnulacionOptions}
        onChange={handleChangeMotivo}
        displayError={displayError}
        type="selector"
        variant="big"
      />
    </DetalleCard>
  );
};

export default DatosAnulacionCard;
