export const TRIMESTRAL = 3;
export const CUATRIMESTRAL = 4;
export const SEMESTRAL = 6;
export const ANUAL = 12;

const vigenciasPolizasMultiramaOptions = {
  [TRIMESTRAL]: "Trimestral",
  [CUATRIMESTRAL]: "Cuatrimestral",
  [SEMESTRAL]: "Semestral",
  [ANUAL]: "Anual",
};

const vigenciasPolizasMultirama = [TRIMESTRAL, CUATRIMESTRAL, SEMESTRAL, ANUAL];

const vigenciasPolizasMultiramaFilter = [
  {
    label: vigenciasPolizasMultiramaOptions[TRIMESTRAL],
    value: TRIMESTRAL,
  },
  {
    label: vigenciasPolizasMultiramaOptions[CUATRIMESTRAL],
    value: CUATRIMESTRAL,
  },
  {
    label: vigenciasPolizasMultiramaOptions[SEMESTRAL],
    value: SEMESTRAL,
  },
  {
    label: vigenciasPolizasMultiramaOptions[ANUAL],
    value: ANUAL,
  },
];

export {
  vigenciasPolizasMultiramaOptions,
  vigenciasPolizasMultirama,
  vigenciasPolizasMultiramaFilter,
};
