import { createActions, handleActions } from "redux-actions";

const fetchVehiculoModelos = (marca, anio) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchVehiculoModelosInited({ marca, anio }));

      const response = await services.api.vehiculos().getModelos(marca, anio);

      dispatch(fetchVehiculoModelosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchVehiculoModelosFailed(error));
    }
  };
};

const {
  vehiculoModelosReducer: {
    clear,
    fetchVehiculoModelosInited,
    fetchVehiculoModelosCompleted,
    fetchVehiculoModelosFailed,
  },
} = createActions({
  vehiculoModelosReducer: {
    clear: () => ({}),
    setMarca: (value) => ({ value }),
    setModelo: (value) => ({ value }),
    //
    fetchVehiculoModelosInited: () => ({}),
    fetchVehiculoModelosCompleted: (response) => {
      let modelos = [];

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0
      ) {
        modelos = response.items.map((x) => ({
          value: x.codia,
          label: x.version,
        }));
      }

      return { modelos };
    },
    fetchVehiculoModelosFailed: (error) => ({ error }),
  },
});

const initialState = { isLoading: false, modelos: [] };

export const vehiculoModelosReducer = handleActions(
  {
    [clear]: (state, action) => {
      return { ...initialState };
    },
    [fetchVehiculoModelosInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [fetchVehiculoModelosCompleted]: (state, action) => {
      return {
        ...state,
        modelos: [...action.payload.modelos],
        isLoading: false,
      };
    },
    [fetchVehiculoModelosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  initialState
);

export default vehiculoModelosReducer;
export { clear, fetchVehiculoModelos };
