import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import { useSelector, useDispatch } from "react-redux";
import ItemDateCard from "~business/Cards/ItemDateCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemCustomCard from "~business/Cards/ItemCustomCard";
import { companiasFilter, companiasSegurosOptions } from "~constants/companias";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import { DATOS, FORMA_PAGO } from "~constants/endosos";
import {
  setCobertura,
  setPremio,
  setCotizacionId,
  setFormaPago,
  setCBU,
  setTarjetaCredito,
} from "../../actions";
import ItemCoberturaCard from "~business/Cards/itemCoberturaCard/ItemCoberturaCard";
import ItemFormaPagoCard from "~business/Cards/itemFormaPagoCard/ItemFormaPagoCard";
import ItemInputCard from "~business/Cards/ItemInputCard";

const title = "Datos Generales";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detallePolizaNuevoReducer;
    const currentPoliza = detalle?.currentPoliza;
    const datosPlan = currentPoliza?.datosPlan;

    return {
      canApplyEndoso: state.authenticatedViewReducer.canApplyEndoso,
      numeroPoliza: currentPoliza?.datosPlan.numeroPoliza,
      negocio: currentPoliza?.negocio,
      compania: datosPlan?.compania,
      vigenciaDesde: datosPlan?.polizaVigenciaDesde,
      formaPago: detalle.formaPagoSelected,
      premio: detalle.premioTotalSelected,
      coberturaSeleccionada: detalle.coberturaSelected,
      codia: detalle.codiaSelected,
      anio: detalle.anioSelected,
      codigoPostal: detalle.codigoPostalSelected,
      localidad: detalle.localidadSelected,
      provincia: detalle.provinciaSelected,
      tieneGNC: detalle.tieneGNC,
    };
  });

const DatosGeneralesCard = ({ isLoading, tipo, displayError, onBlur }) => {
  const dispatch = useDispatch();
  const {
    negocio,
    numeroPoliza,
    compania,
    coberturaSeleccionada,
    premio,
    vigenciaDesde,
    formaPago,
    codia,
    anio,
    codigoPostal,
    localidad,
    provincia,
    // uso,
    // condicionFiscal,
    tieneGNC,

    canApplyEndoso,
  } = useReduxSelector();

  const handleChangeCobertura = ({ cobertura, premio, cotizacionId }) => {
    dispatch(setCobertura(cobertura));
    dispatch(setPremio(premio));
    dispatch(setCotizacionId(cotizacionId));
  };

  let timer = null;

  const handleChangePremio = (value) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setPremio(value));
    }, 200);
  };

  const handleChangeFormaPago = ({
    formaPago,
    cbu,
    tarjetaCredito,
    marcaTarjetaCredito,
  }) => {
    dispatch(setFormaPago(formaPago));
    if (cbu.value) {
      dispatch(setCBU(cbu));
    } else {
      dispatch(setTarjetaCredito({ tarjetaCredito, marcaTarjetaCredito }));
    }
  };

  const readOnlyPremio = tipo !== DATOS || !canApplyEndoso;
  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemCustomCard isLoading={isLoading} label={"Negocio"}>
          <ColorLabelNegocio negocio={negocio} />
        </ItemCustomCard>
        <ItemInputCard
          isLoading={isLoading}
          label={"Número de Póliza"}
          value={numeroPoliza}
          readOnly
        />
        <ItemSelectorCard
          isLoading={isLoading}
          label={"Compañía"}
          value={companiasSegurosOptions[compania]}
          options={companiasFilter}
          valueOptions={companiasSegurosOptions}
          variant="big"
          readOnly
        />
        <ItemCoberturaCard
          isLoading={isLoading}
          label={"Cobertura Seleccionada"}
          onChange={handleChangeCobertura}
          cobertura={coberturaSeleccionada.value}
          compania={compania}
          codia={codia.value}
          anio={anio.value}
          codigoPostal={codigoPostal.value}
          localidad={localidad.value}
          provincia={provincia.value}
          //uso={uso}
          //condicionFiscal={condicionFiscal.value}
          tieneGNC={tieneGNC.value}
          formaDePago={formaPago.value}
          displayError={!coberturaSeleccionada.valid}
          readOnly={coberturaSeleccionada.valid}
        />
        <ItemCurrencyCard
          isLoading={isLoading}
          label={"Premio"}
          value={premio.value}
          onChange={handleChangePremio}
          focus={premio.focus}
          onBlur={onBlur}
          displayError={displayError}
          readOnly={readOnlyPremio}
          required
        />
        <ItemFormaPagoCard
          isLoading={isLoading}
          label={"Forma de Pago"}
          formaPago={formaPago.value}
          onChange={handleChangeFormaPago}
          readOnly={tipo !== FORMA_PAGO}
        />
        <ItemDateCard
          readOnly
          isLoading={isLoading}
          label={"Vigencia Desde"}
          value={vigenciaDesde}
        />
      </DetalleCard>
    </>
  );
};

export default DatosGeneralesCard;
