import { useState, useRef } from "react";
import getArea from "./getArea";
import validatePhone from "./validatePhone";

const MOBILE_PHONES_DIGITS_LENGTH = 13;

const usePhone = (number = "") => {
  const inputRef = useRef();

  const [value, setValue] = useState({
    number,
    isMobile:
      number.length === MOBILE_PHONES_DIGITS_LENGTH || number.length === 0,
    area: getArea(number),
    touched: false,
    error: null,
  });

  const onNumberChange = (event) => {
    const rawNumber = event.target.value;
    const number = rawNumber.replace(/\s|\(|\)|-|_/g, "");

    const area = getArea(number);

    const error = validatePhone(number, value.isMobile, area);

    setValue((current) => ({
      ...current,
      touched: false,
      area,
      error,
      number: number,
    }));
  };

  const onNumberBlur = () => {
    const error = validatePhone(value.number, value.isMobile, value.area);

    setValue((current) => ({ ...current, error, touched: true }));
  };

  const onIsMobileChange = (event) => {
    const isMobile = event.target.value;

    setValue((current) => ({
      ...current,
      number: "",
      touched: false,
      isMobile,
    }));

    if (inputRef.current) setTimeout(() => inputRef.current.focus(), 0);
  };

  const setTouched = (touched) => {
    setValue((current) => ({ ...current, touched }));
  };

  return [
    inputRef,
    value,
    setTouched,
    onNumberChange,
    onNumberBlur,
    onIsMobileChange,
  ];
};

export default usePhone;
