import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { WebAuth } from "auth0-js";
import environment from "~libs/environment";
import { authStateSync } from "~components/auth/actions";
import { useDispatch } from "react-redux";
import localRepository from "../../localRepository";

interface AuthState {
  accessToken: string | null;
  idToken: string | null;
  expiresAt: number;
  user: any;
}

interface AuthContextValue {
  auth0: WebAuth;
  authState: AuthState;
  updateAuthState: Dispatch<SetStateAction<AuthState>>;
}

const generateAuth = (): WebAuth =>
  new WebAuth({
    domain: environment.auth0.domain,
    clientID: environment.auth0.clientId,
    redirectUri: `${environment.myUrl}/callback`,
    responseType: "token id_token",
    scope: "openid profile email offline_access",
    audience: `http://mg-group.com.ar/apis`,
  });

const Auth0Context = createContext<AuthContextValue | null>(null);

const useAuthState = (): [AuthState, Dispatch<SetStateAction<AuthState>>] => {
  const initialState: AuthState = {
    accessToken: localRepository.accessToken.get(),
    idToken: null,
    expiresAt: 0,
    user: null,
  };

  const [authState, setAuthState] = useState<AuthState>(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authStateSync(authState));
  }, [dispatch, authState]);

  return [authState, setAuthState];
};

const useContextValue = (): AuthContextValue => {
  const [authState, updateAuthState] = useAuthState();
  return {
    auth0: generateAuth(),
    authState,
    updateAuthState,
  };
};

interface Auth0ProviderProps {
  children: ReactNode;
}

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({ children }) => {
  const value = useContextValue();
  return (
    <Auth0Context.Provider value={value}>{children} </Auth0Context.Provider>
  );
};

export const useAuth0Context = (): AuthContextValue | null => {
  return useContext(Auth0Context);
};
