import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import GalleryWithLightbox from "~styled/gallery/GalleryWithLightBox";
import PhotoIcon from "@material-ui/icons/Photo";
import {
  setFotoFrontalFileName,
  setFotoTraseraFileName,
  setFotoLateralIzquierdoFileName,
  setFotoLateralDerechoFileName,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";

const title = "Fotos del Vehículo";

const partes = {
  frontal: setFotoFrontalFileName,
  trasera: setFotoTraseraFileName,
  lateralIzquierdo: setFotoLateralIzquierdoFileName,
  lateralDerecho: setFotoLateralDerechoFileName,
};

const useReduxSelector = () =>
  useSelector((state) => {
    const detalleSolicitud = state.detalleSolicitudEmisionReducer
      ? state.detalleSolicitudEmisionReducer.detalleSolicitudEmision
      : {};
    return {
      fotos: detalleSolicitud.fotosVehiculo,
    };
  });

const FotosVehiculoCard = ({ isLoading, editable, IconHeader }) => {
  const dispatch = useDispatch();
  const { fotos } = useReduxSelector();

  if (isLoading)
    return (
      <DetalleCard
        isLoading={isLoading}
        title={title}
        IconHeader={IconHeader}
      />
    );

  let photos = [];

  if (fotos) {
    photos = fotos.map((fotoVehiculo) => ({
      src: fotoVehiculo,
      thumbnail: fotoVehiculo,
      thumbnailWidth: 80,
      thumbnailHeight: 80,
    }));
  }

  const handleAdded = (event, parte) => {
    const action = partes[parte];
    const value = `temp/${event.target.value}`;
    dispatch(
      action({
        value: value,
        valid: true,
      })
    );
  };

  const handleRemoved = (event, parte) => {
    const action = partes[parte];
    dispatch(
      action({
        value: null,
        valid: false,
      })
    );
  };

  const photosLoaded = photos.length > 0;

  return (
    <DetalleCard isLoading={isLoading} title={title} IconHeader={PhotoIcon}>
      {photosLoaded && <GalleryWithLightbox imagenes={photos} />}
      {!photosLoaded && (
        <>
          <ItemFileUploaderCard
            label={"Parte Frontal"}
            onAdded={(event) => handleAdded(event, "frontal")}
            onRemoved={(event) => handleRemoved(event, "frontal")}
            acceptImage={true}
            acceptPdf={false}
          />
          <ItemFileUploaderCard
            label={"Parte Trasera"}
            onAdded={(event) => handleAdded(event, "trasera")}
            onRemoved={(event) => handleRemoved(event, "trasera")}
            acceptImage={true}
            acceptPdf={false}
          />
          <ItemFileUploaderCard
            label={"Parte Trasera"}
            onAdded={(event) => handleAdded(event, "lateralIzquierdo")}
            onRemoved={(event) => handleRemoved(event, "lateralIzquierdo")}
            acceptImage={true}
            acceptPdf={false}
          />
          <ItemFileUploaderCard
            label={"Parte Trasera"}
            onAdded={(event) => handleAdded(event, "lateralDerecho")}
            onRemoved={(event) => handleRemoved(event, "lateralDerecho")}
            acceptImage={true}
            acceptPdf={false}
          />
        </>
      )}
    </DetalleCard>
  );
};

export default FotosVehiculoCard;
