import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemCustomCard from "~business/Cards/ItemCustomCard";
import { formasPagoOptions, formasPagoFilter } from "~constants/formasPago";
import { companiasFilter, companiasSegurosOptions } from "~constants/companias";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";

const title = "Datos Generales";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosPlan = state.detallePolizaNuevoReducer.currentPoliza
      ? state.detallePolizaNuevoReducer.currentPoliza.datosPlan
      : {};
    const currentPoliza = state.detallePolizaNuevoReducer.currentPoliza;

    return {
      ...datosPlan,
      negocio: currentPoliza?.negocio,
      numeroPoliza: currentPoliza?.datosPlan.numeroPoliza,
      compania: datosPlan.compania,
      coberturaSeleccionada: currentPoliza?.coberturaId,
      premio: datosPlan.premioTotal,
      vigenciaDesde: datosPlan.polizaVigenciaDesde,
      formaPago: datosPlan.formaPago,
    };
  });

const DatosGeneralesCard = ({ isLoading, onBlur, displayError }) => {
  const {
    negocio,
    compania,
    coberturaSeleccionada,
    premio,
    vigenciaDesde,
    formaPago,
    numeroPoliza,
  } = useReduxSelector();

  //TODO: abstraer componente mostrar tarjeta de credito
  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemCustomCard isLoading={isLoading} label={"Negocio"}>
          <ColorLabelNegocio negocio={negocio} />
        </ItemCustomCard>
        <ItemInputCard
          isLoading={isLoading}
          label={"Número de Póliza"}
          value={numeroPoliza}
          readOnly
        />
        <ItemSelectorCard
          isLoading={isLoading}
          label={"Compañía"}
          value={compania}
          options={companiasFilter}
          valueOptions={companiasSegurosOptions}
          variant="big"
          readOnly
        />
        <ItemInputCard
          isLoading={isLoading}
          label={"Cobertura Seleccionada"}
          value={coberturaSeleccionada}
          readOnly
        />
        <ItemCurrencyCard
          isLoading={isLoading}
          label={"Premio Total"}
          value={premio}
          readOnly
        />
        <ItemSelectorCard
          isLoading={isLoading}
          label={"Forma de Pago"}
          options={formasPagoFilter}
          valueOptions={formasPagoOptions}
          value={formaPago}
          variant="big"
          readOnly
        />
        <ItemDateCard
          readOnly
          isLoading={isLoading}
          label={"Vigencia Desde"}
          value={vigenciaDesde}
        />
      </DetalleCard>
    </>
  );
};

export default DatosGeneralesCard;
