export const UNA = 1;
export const DOS = 2;
export const TRES = 3;
export const CUATRO = 4;
export const CINCO = 5;
export const SEIS = 6;
export const SIETE = 7;
export const OCHO = 8;
export const NUEVE = 9;
export const DIEZ = 10;
export const ONCE = 11;
export const DOCE = 12;

const cuotasOptions = {
  [UNA]: "Una",
  [DOS]: "Dos",
  [TRES]: "Tres",
  [CUATRO]: "Cuatro",
  [CINCO]: "Cinco",
  [SEIS]: "Seis",
  [SIETE]: "Siete",
  [OCHO]: "Ocho",
  [NUEVE]: "Nueve",
  [DIEZ]: "Diez",
  [ONCE]: "Once",
  [DOCE]: "Doce",
};

const cuotas = [
  UNA,
  DOS,
  TRES,
  CUATRO,
  CINCO,
  SEIS,
  SIETE,
  OCHO,
  NUEVE,
  DIEZ,
  ONCE,
  DOCE,
];

const cuotasFilter = [
  {
    label: cuotasOptions[UNA],
    value: UNA,
  },
  {
    label: cuotasOptions[DOS],
    value: DOS,
  },
  {
    label: cuotasOptions[TRES],
    value: TRES,
  },
  {
    label: cuotasOptions[CUATRO],
    value: CUATRO,
  },
  {
    label: cuotasOptions[CINCO],
    value: CINCO,
  },
  {
    label: cuotasOptions[SEIS],
    value: SEIS,
  },
  {
    label: cuotasOptions[SIETE],
    value: SIETE,
  },
  {
    label: cuotasOptions[OCHO],
    value: OCHO,
  },
  {
    label: cuotasOptions[NUEVE],
    value: NUEVE,
  },
  {
    label: cuotasOptions[DIEZ],
    value: DIEZ,
  },
  {
    label: cuotasOptions[ONCE],
    value: ONCE,
  },
  {
    label: cuotasOptions[DOCE],
    value: DOCE,
  },
];

const getPeriodoEnMeses = (periodo) => {
  if (periodo === "Anual") return 12;
  if (periodo === "Semestral") return 6;
  if (periodo === "Cuatrimestral") return 4;
  if (periodo === "Trimestral") return 3;

  return 0;
};

export { cuotasOptions, cuotas, cuotasFilter, getPeriodoEnMeses };
