import React, { memo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NumberFormat from "react-number-format";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import {
  continuarSolicitud,
  recotizar,
} from "../../../views/multicotizador/actions";
import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { paths, multicotizadorPath } from "~constants/referenceNames";
import { stages } from "../../../views/multicotizador/useCurrentStep";
import { calculateDate } from "~libs/dateCalculator";
import { PRENDARIOS } from "~constants/negocios";

const useStyles = makeStyles((theme) => ({
  compania: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 15,
    display: "block",
    flex: 1,
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  importeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  },
  importe: {
    textAlign: "center",
    fontWeight: 800,
    color: theme.colours.violeta,
  },
  content: {
    paddingBottom: 60,
  },
  sentence: {
    fontWeight: 600,
    lineHeight: 1.33,
    color: theme.colours.grey5,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    bottom: "0",
    justifyContent: "center",
    height: 141,
    //width: "100vw",
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #f5f5fa 35%)`,
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    tiposCobertura: state.tipoCoberturaReducer.tiposCobertura,

    multicotizacionInitialStage:
      state.multicotizadorReducer.multicotizacionInitialStage,
  }));

const CotizacionDetail = memo(
  ({ isOpened, onClose, detalleCotizacion, compania, cotizacion }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const cotizacionId = useParams().id;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const newLinesDeclared = cotizacion.descripcion.includes("\n");

    const { tiposCobertura, multicotizacionInitialStage } = useReduxSelector();

    const tipoCobertura = tiposCobertura.find((tipo) => {
      return tipo.id === cotizacion.tipoPlanComercial;
    });

    const retormarSolicitud =
      new Date() < calculateDate(new Date(detalleCotizacion.fecha), 14) &&
      new Date().getMonth() === new Date(detalleCotizacion.fecha).getMonth();

    const handleContinuarSolicitud = () => {
      if (retormarSolicitud)
        dispatch(
          continuarSolicitud(cotizacionId, { ...cotizacion, ...compania })
        );
      else dispatch(recotizar(cotizacionId));
    };

    useEffect(() => {
      if (
        multicotizacionInitialStage === stages.recotizar ||
        multicotizacionInitialStage === stages.continuarSolicitud
      )
        history.push(paths[multicotizadorPath]);
    }, [history, multicotizacionInitialStage]);

    const canContinueSolicitud = detalleCotizacion.negocio !== PRENDARIOS;

    return (
      <Dialog
        open={isOpened}
        scroll="paper"
        onClose={onClose}
        fullScreen={fullScreen}
        fullWidth={true}
      >
        <DialogTitle
          style={{
            padding: 0,
          }}
          disableTypography
        >
          <div style={{ display: "flex" }}>
            <div style={{ width: 48 }} />
            <Typography variant={"h5"} className={classes.compania}>
              {compania.compania}
            </Typography>
            <div style={{ width: 48 }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="Close"
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Typography variant={"subtitle1"} className={classes.tipoPoliza}>
            {cotizacion.nombre}
          </Typography>
          <Typography variant={"subtitle1"} className={classes.tipoPoliza}>
            {tipoCobertura.nombre}
          </Typography>
          <div className={classes.importeContainer}>
            <Typography variant={"h3"} className={classes.importe}>
              <NumberFormat
                value={cotizacion.importe}
                displayType={"text"}
                decimalScale={0}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </Typography>
            <Typography variant={"subtitle1"} className={classes.tipoPoliza}>
              {"\xa0/mes"}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {newLinesDeclared &&
            convertStringIntoSentencesWithNewLines(cotizacion.descripcion).map(
              (sentence, index) => (
                <DialogContentText
                  key={`poliza-item-sentence-${index}`}
                  className={classes.sentence}
                >
                  {sentence}
                </DialogContentText>
              )
            )}
          {!newLinesDeclared &&
            convertStringIntoSentencesWithOutNewLines(
              cotizacion.descripcion
            ).map((sentence, index) => (
              <DialogContentText
                key={`poliza-item-sentence-${index}`}
                className={classes.sentence}
              >
                {sentence}
              </DialogContentText>
            ))
            }

          <DialogContentText  className={classes.sentence}>
          Suma Asegurada: <NumberFormat
                  value={cotizacion.sumaAsegurada}
                  displayType={"text"}
                  decimalScale={0}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
          </DialogContentText>
            
        </DialogContent>
        {canContinueSolicitud && (
          <div className={classes.buttonContainer}>
            <Button
              className={classes.contratar}
              onClick={handleContinuarSolicitud}
              variant="contained"
              color="primary"
            >
              {retormarSolicitud ? "ELEGIR ESTA PÓLIZA" : "RECOTIZAR"}
            </Button>
          </div>
        )}
      </Dialog>
    );
  }
);

export default CotizacionDetail;

const convertStringIntoSentencesWithNewLines = (description) => {
  return description.replace(/\n/g, "|").split("|");
};

const convertStringIntoSentencesWithOutNewLines = (description) => {
  return description
    .replace(/([.])\s*(?=[A-Z])/g, "$1|")
    .split("|")
    .map(convertFirstletterToUpperCase);
};

const convertFirstletterToUpperCase = (sentence) =>
  sentence && sentence.length > 2
    ? sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()
    : sentence;

/*
      onClick={() => handleContinuarSolicitud(cotizacion)}

  const handleContinuarSolicitud = (cotizacion) => {
    //dispatch(continuarSolicitud(id));
  };
*/
