import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const useUrlManager = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Redirect to home at the first time
    const url = location.pathname; //sessionStorage.getItem("urlSelected");
    if (url !== "/signin" && url !== "/callback") {
      sessionStorage.setItem(
        "urlSelected",
        location.pathname + location.search
      );
    }
  }, [dispatch, location.pathname, location.search]);
};
