import { createActions, handleActions } from "redux-actions";

const {
  business: {
    codigosPostales: {
      clearCodigosPostales,
      fetchCodigosPostalesInited,
      fetchCodigosPostalesCompleted,
      fetchCodigosPostalesFailed,
    },
  },
} = createActions({
  business: {
    codigosPostales: {
      clearCodigosPostales: () => ({}),
      fetchCodigosPostalesInited: () => ({}),
      fetchCodigosPostalesCompleted: (response) => ({ response }),
      fetchCodigosPostalesFailed: (error) => ({ error }),
    },
  },
});

const fetchCodigosPostales = (provinciaId, localidad) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCodigosPostalesInited());
      const response = await services.api
        .provincias()
        .getCodigosPostales(provinciaId, localidad);
      dispatch(fetchCodigosPostalesCompleted(response.codigosPostales));
    } catch (error) {
      console.error(error);
      dispatch(fetchCodigosPostalesFailed(error));
    }
  };
};

const initialState = {
  codigosPostales: [],
  isLoading: false,
};

const codigoPostalSelectorReducer = handleActions(
  {
    [clearCodigosPostales]: () => ({ ...initialState }),
    [fetchCodigosPostalesInited]: () => {
      return {
        ...initialState,
        isLoading: true,
      };
    },
    [fetchCodigosPostalesCompleted]: (state, action) => {
      const codigosPostales = action.payload.response.map((item) => ({
        value: item,
        label: item,
      }));
      return {
        ...initialState,
        codigosPostales: codigosPostales,
        isLoading: false,
      };
    },
    [fetchCodigosPostalesFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default codigoPostalSelectorReducer;
export {
  clearCodigosPostales,
  fetchCodigosPostalesInited,
  fetchCodigosPostalesCompleted,
  fetchCodigosPostalesFailed,
  fetchCodigosPostales,
};
