import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import UnexpectedError from "~styled/errorPages/UnexpectedError";

import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import ListadoPolizasMultirama from "./listadoPolizasMultirama/ListadoPolizasMultirama";
import { useLocation, useHistory } from "react-router-dom";

import {
  selectSearchCriteria,
  selectFechaVigenciaDesde,
  selectFechaVigenciaHasta,
  setTake,
  setPage,
  selectRama,
  selectCompania,
  selectEstado,
} from "./filtros/actions";
import { getPolizasMultirama } from "./listadoPolizasMultirama/actions";

import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";

import { polizasMultiramaPath } from "~constants/referenceNames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.listadoPolizaMultiramaFiltroReducer.take,
    page: state.listadoPolizaMultiramaFiltroReducer.page,
    searchCriteria: state.listadoPolizaMultiramaFiltroReducer.searchCriteria,
    compania: state.listadoPolizaMultiramaFiltroReducer.compania,
    rama: state.listadoPolizaMultiramaFiltroReducer.rama,
    fechaVigenciaHasta:
      state.listadoPolizaMultiramaFiltroReducer.fechaVigenciaHasta,
    fechaVigenciaDesde:
      state.listadoPolizaMultiramaFiltroReducer.fechaVigenciaDesde,
    estado: state.listadoPolizaMultiramaFiltroReducer.estado,
    failed: state.listadoPolizaMultiramaReducer.failed,
    polizaAnulada: state.listadoPolizaMultiramaReducer.polizaAnulada,
  }));

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  {
    name: "fechaVigenciaDesde",
    nameUrl: "FechaVigenciaDesde",
    action: selectFechaVigenciaDesde,
    isDate: true,
  },
  {
    name: "rama",
    nameUrl: "Rama",
    action: selectRama,
  },
  { name: "estado", nameUrl: "Estado", action: selectEstado },
  {
    name: "compania",
    nameUrl: "Compania",
    action: selectCompania,
  },
  {
    name: "fechaVigenciaHasta",
    nameUrl: "FechaVigenciaHasta",
    action: selectFechaVigenciaHasta,
    isDate: true,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const PolizasMultiramaContainer = () => {
  const currentState = useReduxSelector();

  const {
    take,
    page,
    searchCriteria,
    compania,
    rama,
    estado,
    fechaVigenciaHasta,
    fechaVigenciaDesde,
    failed,
    polizaAnulada,
  } = currentState;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: polizasMultiramaPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [
    history,
    fechaVigenciaHasta,
    fechaVigenciaDesde,
    searchCriteria,
    compania,
    estado,
    rama,
    page,
    take,
  ]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(
        getPolizasMultirama({
          skip: take * page,
          take,
          searchCriteria,
          compania,
          rama,
          estado,
          fechaVigenciaDesde,
          fechaVigenciaHasta,
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url, polizaAnulada]);

  if (failed) return <UnexpectedError />;

  return (
    <>
      <SectionLayout
        Icon={AssignmentIcon}
        title={"Pólizas Multirama"}
        selectedDefault={polizasMultiramaPath}
      >
        <div className={classes.root}>
          <div className={classes.body}>{<ListadoPolizasMultirama />}</div>
        </div>
      </SectionLayout>
    </>
  );
};

export default PolizasMultiramaContainer;
