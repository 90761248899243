export const TODOS = "Pendiente,PendienteCompania,DatosIncompletos";
export const PENDIENTE = "Pendiente";
export const PENDIENTECOMPANIA = "PendienteCompania";
export const DATOSINCOMPLETOS = "DatosIncompletos";

const estadosSolicitudEmisionOptions = {
  [TODOS]: "Todos",
  [PENDIENTE]: "Pendiente de Emisión",
  [PENDIENTECOMPANIA]: "Pendiente en Compañía",
  [DATOSINCOMPLETOS]: "Datos Incompletos",
};

const estadosSolicitudEmision = [
  PENDIENTE,
  PENDIENTECOMPANIA,
  DATOSINCOMPLETOS,
];

const estadosSolicitudEmisionFilter = [
  { label: estadosSolicitudEmisionOptions[TODOS], value: TODOS },
  {
    label: estadosSolicitudEmisionOptions[PENDIENTE],
    value: PENDIENTE,
  },
  {
    label: estadosSolicitudEmisionOptions[PENDIENTECOMPANIA],
    value: PENDIENTECOMPANIA,
  },
  {
    label: estadosSolicitudEmisionOptions[DATOSINCOMPLETOS],
    value: DATOSINCOMPLETOS,
  },
];

export {
  estadosSolicitudEmisionOptions,
  estadosSolicitudEmision,
  estadosSolicitudEmisionFilter,
};
