import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import AseguradoCard from "./cards/AseguradoCard";
import DatosGeneralesCard from "./cards/DatosGeneralesCard";
import DatosVehiculoCard from "./cards/DatosVehiculoCard";
import ImpresosCard from "./cards/ImpresosCard";
import DatosAnulacionCard from "./cards/DatosAnulacionCard";
import {
  getPoliza,
  setPremio,
  setCobertura,
  setSubmitting,
  setEmail,
  setTelefono,
  setDomicilio,
  setDominio,
  setNumeroMotor,
  setNumeroChasis,
  setMotivoAnulacion,
  setTipo,
  clear,
  setPolizaFileName,
  postEndosar,
} from "../actions";
import Selector from "~styled/selector/Selector";
import pxToRem from "~libs/pxToRem";
import {
  tipoEndosoOptions,
  tipoEndosoFilter,
  ANULACION,
} from "~constants/endosos";
import ProcessLayout from "~styled/layouts/processLayout/ProcessLayout";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paths, polizasPath } from "~constants/referenceNames";
import SuccessDialog from "../SuccessDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "0% 2% 1% 2%",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
    paddingRight: pxToRem(4),
  },
  selector: {
    width: "100%",
    maxWidth: 300,
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  cardItem: { minWidth: 300 },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detallePolizaNuevoReducer,
    canUploadImpresos: state.authenticatedViewReducer.canUploadImpresos,
    canApplyEndoso: state.authenticatedViewReducer.canApplyEndoso,
  }));

const PolizaEndoso = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  const {
    currentPoliza,
    isLoading,
    coberturaSelected,
    premioTotalSelected,
    cotizacionId,
    emailSelected,
    telefonoSelected,
    domicilioSelected,
    dominioSelected,
    numeroMotorSelected,
    numeroChasisSelected,
    anioSelected,
    marcaSelected,
    modeloSelected,
    codiaSelected,
    provinciaSelected,
    localidadSelected,
    codigoPostalSelected,
    urlPolizaSelected,
    urlTarjetaSelected,
    urlCedulaMercosurSelected,
    urlCuponeraSelected,
    motivoAnulacionSelected,
    formaPagoSelected,
    cbuSelected,
    marcaTarjetaCreditoSelected,
    tarjetaCreditoSelected,
    tieneGNC,
    submitting,
    polizaHasChanged,
    polizaUpdated,
    canApplyEndoso,
    tipo,
  } = useReduxSelector();

  useEffect(() => {
    if (!isLoading && !currentPoliza) dispatch(getPoliza(id));
    //eslint-disable-next-line
  }, [currentPoliza]);

  const inputs = [
    { setFocusFunction: setCobertura, ...coberturaSelected },
    { setFocusFunction: setPremio, ...premioTotalSelected },
    { setFocusFunction: setMotivoAnulacion, ...motivoAnulacionSelected },
    { setFocusFunction: setEmail, ...emailSelected },
    { setFocusFunction: setTelefono, ...telefonoSelected },
    { setFocusFunction: setDomicilio, ...domicilioSelected },
    { setFocusFunction: setDominio, ...dominioSelected },
    { setFocusFunction: setNumeroMotor, ...numeroMotorSelected },
    { setFocusFunction: setNumeroChasis, ...numeroChasisSelected },
    { setFocusFunction: setPolizaFileName, ...urlPolizaSelected },
  ];

  const handleSubmitForm = () => {
    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          ...inputToFocus,
          focus: true,
        })
      );

    if (inputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    const getChanged = (field) => (field?.endosado ? field.value : null);

    const data = {
      tipo: tipo,
      CoberturaId: getChanged(coberturaSelected),
      PremioTotal: getChanged(premioTotalSelected),
      EmailAsegurado: getChanged(emailSelected),
      TelefonoAsegurado: getChanged(telefonoSelected),
      DomicilioAsegurado: getChanged(domicilioSelected),
      //Datos Vehiculo
      Anio: getChanged(anioSelected),
      Dominio: getChanged(dominioSelected),
      Codia: getChanged(codiaSelected),
      Marca: getChanged(marcaSelected),
      Modelo: getChanged(modeloSelected),
      NumeroChasis: getChanged(numeroChasisSelected),
      NumeroMotor: getChanged(numeroMotorSelected),
      TieneGNC: getChanged(tieneGNC),
      //localidad
      provincia: getChanged(provinciaSelected),
      localidad: getChanged(localidadSelected),
      codigoPostal: getChanged(codigoPostalSelected),

      //impresos
      UrlPoliza: getChanged(urlPolizaSelected),
      UrlTarjeta: getChanged(urlTarjetaSelected),
      UrlCedula: getChanged(urlCedulaMercosurSelected),
      UrlCuponera: getChanged(urlCuponeraSelected),

      //Anulacion
      MotivoAnulacion: getChanged(motivoAnulacionSelected),

      //Forma de Pago changed
      FormaPago: getChanged(formaPagoSelected),
      DatosPago: getChanged(cbuSelected) ?? getChanged(tarjetaCreditoSelected),
      MarcaTarjetaCredito: getChanged(marcaTarjetaCreditoSelected),
      cotizacionId: getChanged(cotizacionId),
    };

    dispatch(postEndosar(id, data));
  };

  const handleSolicitarEndoso = () => {
    dispatch(setSubmitting(true));
    if (polizaHasChanged) handleSubmitForm();
    else dispatch(queueMessage("No ha realizado cambios."));
  };

  const handleBlur = () => {
    const inputToFocus = inputs.find((input) => input.focus);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          ...inputToFocus,
          focus: false,
        })
      );

    //dispatch(setSubmitting(false));
  };

  const endosarText = canApplyEndoso ? "Aplicar Endoso" : "Solicitar Endoso";
  const classes = useStyles();
  return (
    <ProcessLayout
      title="Endosar Póliza"
      onBackClick={() => {
        history.replace(`${paths[polizasPath]}/${id}`);
      }}
      onCloseClick={() => {
        //history.goBack(); //to sync history with back button
        history.replace(`${paths[polizasPath]}/${id}`);
      }}
    >
      <SuccessDialog
        openDialog={polizaUpdated}
        onClose={() => {
          history.goBack(); //to sync history with back button
          history.replace(`${paths[polizasPath]}/${id}`);
        }}
      />
      <div className={classes.header}>
        <div className={classes.filterContainer}>
          <Typography className={classes.caption}>
            {"Tipo de Endoso"}
          </Typography>
          <div className={classes.selector}>
            <Selector
              id={id}
              value={tipoEndosoOptions[tipo]}
              options={tipoEndosoFilter}
              valueOptions={tipoEndosoOptions}
              onChange={(value) => dispatch(setTipo(value))}
              disabled={polizaHasChanged}
              onClickDisabled={() => {
                dispatch(
                  queueMessage(
                    "Ha realizado cambios. Confirmar endoso o deshacer cambios"
                  )
                );
              }}
              variant="big"
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {polizaHasChanged && (
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: 16, height: 40 }}
              onClick={() => dispatch(clear())}
            >
              Deshacer
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 16, height: 40 }}
            onClick={handleSolicitarEndoso}
          >
            {endosarText}
          </Button>
        </div>
      </div>
      <div className={classes.cardsAndCuotasContainer}>
        <CardsColumn>
          <div className={classes.cardItem}>
            <DatosGeneralesCard
              isLoading={isLoading || !currentPoliza}
              tipo={tipo}
              displayError={submitting}
              onBlur={handleBlur}
            />
          </div>
          <div className={classes.cardItem}>
            <DatosVehiculoCard
              isLoading={isLoading || !currentPoliza}
              tipo={tipo}
              displayError={submitting}
              onBlur={handleBlur}
            />
          </div>
        </CardsColumn>

        <CardsColumn>
          {tipo === ANULACION && (
            <div className={classes.cardItem}>
              <DatosAnulacionCard
                isLoading={isLoading || !currentPoliza}
                displayError={submitting}
                onBlur={handleBlur}
              />
            </div>
          )}

          <div className={classes.cardItem}>
            <AseguradoCard
              isLoading={isLoading || !currentPoliza}
              tipo={tipo}
              displayError={submitting}
              onBlur={handleBlur}
            />
          </div>

          <div className={classes.cardItem}>
            <ImpresosCard
              isLoading={isLoading || !currentPoliza}
              idPoliza={id}
              tipo={tipo}
            />
          </div>
        </CardsColumn>
      </div>
    </ProcessLayout>
  );
};

export default PolizaEndoso;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
