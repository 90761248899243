import { queueMessage } from "~shared/snackbarsProvider/actions";
import { PENDIENTE } from "~constants/endosos";

const baseEndososPoliza = "ListadoEndosos";

const endososInitialState = {
  page: 0,
  take: 10,
  searchCriteria: "",
  estado: PENDIENTE,
  endosos: [],
  totalRegistros: 0,
};

export const listadoEndososReducer = (state = endososInitialState, action) => {
  const isThisReducer = action.type.includes(baseEndososPoliza);
  if (action.reducer && isThisReducer) return action.reducer(state, action);

  return state;
};

export const clearListadoEndosos = () => {
  return {
    type: `${baseEndososPoliza}/clearListadoEndosos`,
    payload: {},
    reducer: (state, action) => ({
      ...endososInitialState,
    }),
  };
};

export const selectSearchCriteria = (value) => {
  return {
    type: `${baseEndososPoliza}/selectSearchCriteria`,
    payload: { value },
    reducer: (state, action) => ({
      ...state,
      searchCriteria: action.payload.value,
    }),
  };
};

export const selectEstado = (value) => {
  return {
    type: `${baseEndososPoliza}/selectEstado`,
    payload: { value },
    reducer: (state, action) => ({
      ...state,
      estado: action.payload.value,
    }),
  };
};
export const setPage = (value) => {
  return {
    type: `${baseEndososPoliza}/setPage`,
    payload: { value },
    reducer: (state, action) => ({
      ...state,
      page: action.payload.value,
    }),
  };
};
export const setTake = (value) => {
  return {
    type: `${baseEndososPoliza}/setTake`,
    payload: { value },
    reducer: (state, action) => ({
      ...state,
      take: action.payload.value,
    }),
  };
};

const getEndososInited = (value) => {
  return {
    type: `${baseEndososPoliza}/getEndososInited`,
    reducer: (state, action) => ({
      ...state,
      isLoading: true,
    }),
  };
};

const getEndososCompleted = ({ total, items }) => {
  return {
    type: `${baseEndososPoliza}/getEndososCompleted`,
    reducer: (state, action) => ({
      ...state,
      endosos: items,
      totalRegistros: total,
      isLoading: false,
      failed: false,
    }),
  };
};

const getEndososFailed = (value) => {
  return {
    type: `${baseEndososPoliza}/getEndososFailed`,
    reducer: (state, action) => ({
      ...state,
      isLoading: false,
      failed: true,
    }),
  };
};

export const getEndosos = ({ take, skip, estado, searchCriteria }) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getEndososInited());

      const response = await services.api
        .endosos()
        .getEndosos({ take, skip, estado, searchCriteria });

      dispatch(getEndososCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getEndososFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al actualizar la póliza. Intente nuevamente."
        )
      );
    }
  };
};
