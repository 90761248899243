import { useQuery } from "react-query";
import { useState } from "react";
import { cotizaciones } from "~api/cotizaciones";
import { multicotizador } from "~api/multicotizador";
import { PostMulticotizacionRequest } from "~api/types";

const MAX_REQUESTS = 10;

interface UseSegurosProps {
  codia: number;
  anio: number;
  codigoPostal: string;
  localidad: string;
  provincia: number;
  uso?: string;
  condicionFiscal?: string;
  tieneGNC?: boolean;
  formaDePago?: string;
}

interface UseSegurosReturn {
  isLoading: boolean;
  cotizacionId: string;
  cotizaciones?: any;
  error?: unknown;
}

const useSeguros = ({
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
}: UseSegurosProps): UseSegurosReturn => {
  const [postCotizacionLoading, postCotizacionResponse, postCotizacionError] =
    useQuerySegurosPostPedidoCotizaciones({
      codia,
      anio,
      codigoPostal,
      localidad,
      provincia,
      uso,
      condicionFiscal,
      tieneGNC,
      formaDePago,
    });

  const [cotizacionesLoading, cotizacionesResponse, cotizacionesError] =
    useQuerySegurosGetCotizaciones(postCotizacionResponse?.cotizacionId);

  return {
    isLoading: postCotizacionLoading || cotizacionesLoading,
    cotizacionId: postCotizacionResponse?.cotizacionId,
    cotizaciones: cotizacionesResponse,
    error: postCotizacionError || cotizacionesError,
  };
};

export default useSeguros;

interface UseQuerySegurosPostProps {
  codia: number;
  anio: number;
  codigoPostal: string;
  localidad: string;
  provincia: number;
  uso?: string;
  condicionFiscal?: string;
  tieneGNC?: boolean;
  formaDePago?: string;
}

type UseQuerySegurosPostReturn = [boolean, any, unknown];

const useQuerySegurosPostPedidoCotizaciones = ({
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
}: UseQuerySegurosPostProps): UseQuerySegurosPostReturn => {
  const postCotizaciones = async () => {
    const data: PostMulticotizacionRequest = {
      ModeloVehiculo: codia,
      AnioVehiculo: anio,
      CodigoPostal: codigoPostal,
      Localidad: localidad,
      ProvinciaId: provincia,
      Negocio: "MercadoAbierto",
      UsoDelVehiculo: uso,
      CondicionFiscal: condicionFiscal,
      TieneGNC: tieneGNC,
      Periodo: "Anual",
      FormaPago: formaDePago,
    };

    return await multicotizador().postCotizacion(data);
  };

  const { isLoading, data, error } = useQuery(
    [
      "postCotizacionesEndoso",
      {
        codia,
        anio,
        codigoPostal,
        localidad,
        provincia,
        uso,
        condicionFiscal,
        tieneGNC,
        formaDePago,
      },
    ],
    postCotizaciones,
    {
      staleTime: 1000 * 60 * 15,
    }
  );

  return [isLoading, data, error];
};

type UseQuerySegurosGetReturn = [boolean, any, unknown];

const useQuerySegurosGetCotizaciones = (
  cotizacionId: string
): UseQuerySegurosGetReturn => {
  const [polizasRequestCount, setPolizasRequestCount] = useState(0);

  const isPending = polizasRequestCount <= MAX_REQUESTS;

  const getCotizaciones = async () => {
    setPolizasRequestCount((count) => count + 1);
    return await cotizaciones().getDetalleCotizacion(cotizacionId);
  };

  const { isLoading, data, error } = useQuery(
    ["getCotizacionesEndoso", { cotizacionId }],
    getCotizaciones,
    {
      enabled: !!cotizacionId && polizasRequestCount <= MAX_REQUESTS,
      refetchInterval: 1000 * 2 * polizasRequestCount,
    }
  );

  return [isPending || isLoading, data, error];
};
