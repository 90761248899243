import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../Auth0/useAuth";
import { Redirect } from "react-router-dom";

const Signout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout, dispatch]);

  const urlSelected = sessionStorage.getItem("urlSelected");

  return <Redirect to={urlSelected} />;
};

export default Signout;
