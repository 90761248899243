import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import UsuariosSelector from "./UsuariosSelector";
import ItemInputCard from "~business/Cards/ItemInputCard";

import ItemSelectorCard from "~business/Cards/ItemSelectorCard";

import {
  companiasSegurosOptions,
  companiasFilter,
} from "../constants/companias";

import {
  setRama,
  setUsuario,
  setCompania,
  setCobertura,
  clearUsuario,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    rama: state.altaPolizaMultiramaReducer.ramaSelected,
    compania: state.altaPolizaMultiramaReducer.companiaSelected,
    ramasFilter: state.altaPolizaMultiramaReducer.ramasFilter,
    ramasOptions: state.altaPolizaMultiramaReducer.ramasOptions,
    usuario: state.altaPolizaMultiramaReducer.usuarioSelected,
    cobertura: state.altaPolizaMultiramaReducer.coberturaSelected,
    isLoadingUsuarios: state.altaPolizaMultiramaReducer.isLoadingUsuarios,
  }));

const title = "Datos Generales";

const DatosGeneralesCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const {
    rama,
    ramasFilter,
    ramasOptions,
    usuario,
    compania,
    cobertura,
    isLoadingUsuarios,
  } = useReduxSelector();

  let timer = null;

  const handleChangeRama = (rama) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setRama(rama));
    }, 200);
  };

  const handleChangeCompania = (compania) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCompania(compania));
    }, 200);
  };

  const handleChangeCobertura = (cobertura) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCobertura(cobertura));
    }, 200);
  };

  const handleChangeUsuario = (event) => {
    dispatch(setUsuario({ ...event.target.value, selected: true }));
  };

  const handleClearUsuario = () => {
    dispatch(clearUsuario());
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemSelectorCard
          id={"rama"}
          isLoading={isLoading}
          label={"Rama"}
          type="selector"
          onChange={handleChangeRama}
          value={rama.value}
          options={ramasFilter}
          valueOptions={ramasOptions}
          variant="big"
        />

        <ItemSelectorCard
          id={"compania"}
          isLoading={isLoading}
          label={"Compañía"}
          type="selector"
          onChange={handleChangeCompania}
          value={compania.value}
          options={companiasFilter}
          valueOptions={companiasSegurosOptions}
          variant="big"
        />

        <UsuariosSelector
          isLoading={isLoadingUsuarios}
          value={usuario}
          onChange={handleChangeUsuario}
          onClear={handleClearUsuario}
        />
        <ItemInputCard
          id={"cobertura"}
          isLoading={isLoading}
          label={"Cobertura"}
          value={cobertura.value}
          onChange={handleChangeCobertura}
          onBlur={handleBlur}
          focus={cobertura.focus}
          required
        />
      </DetalleCard>
    </>
  );
};

export default DatosGeneralesCard;
