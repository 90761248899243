import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import AssignmentIcon from "@material-ui/icons/AssignmentIndSharp";
import { endososPath } from "~constants/referenceNames";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import { useEffect } from "react";
import useEndososParamsToFilters from "./useEndososParamsToFilters";
import { getEndosos } from "./actions";
import ListadoEndosos from "./ListadoEndosos";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
  tableContainer: {
    padding: "5vh",
  },
  table: {
    backgroundColor: theme.colours.white,
    borderRadius: "8px 8px 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonFilter: {
    //display: "flex",
    width: "10vw",
    height: "5vh",
    borderRadius: "4px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    endosos: state.listadoEndososReducer.endosos,
    totalRegistros: state.listadoEndososReducer.totalRegistros,
    isLoading: state.listadoEndososReducer.isLoading,
    failed: state.listadoEndososReducer.failed,

    take: state.listadoEndososReducer.take,
    page: state.listadoEndososReducer.page,
    searchCriteria: state.listadoEndososReducer.searchCriteria,
    estado: state.listadoEndososReducer.estado,
  }));

const EndososContainer = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    failed,
    page,
    take,
    searchCriteria,
    estado,
  } = useReduxSelector();

  const classes = useStyles();

  useEffect(() => {
    //return () => dispatch(clearListadoEndosos());
  }, [dispatch]);

  const url = useEndososParamsToFilters(page, take, searchCriteria, estado);

  useEffect(() => {
    const data = { skip: page * take, take, searchCriteria, estado };
    if (!isLoading && url) {
      dispatch(getEndosos(data));
    }
    //eslint-disable-next-line
  }, [dispatch, url]);

  if (failed) return <UnexpectedError />;

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Endosos"}
      selectedDefault={endososPath}
    >
      <div className={classes.root}>
        <div className={classes.body}>
          <ListadoEndosos />
        </div>
      </div>
    </SectionLayout>
  );
};

export default EndososContainer;
