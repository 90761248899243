import React, { useState, useEffect, memo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  compania: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 8,
    display: "block",
    flex: 1,
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  polizaTitle: {
    fontSize: pxToRem(12),
  },
  content: {
    paddingBottom: 50,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5%",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    margin: "0 10px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    margin: "0 10px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  sentenceTitle: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  motivoSelector: {
    position: "absolute",
    width: "90%",
  },
}));

const PopupComentario = memo(
  ({ isOpened, onClose, siniestroId, onClickAceptar }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [comentario, setComentario] = useState("");
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
      setEnabled(comentario !== null && comentario !== "");
    }, [comentario]);

    const handleChangeComentario = (comentario) => {
      setComentario(comentario);
    };

    return (
      <div className={classes.root}>
        <Dialog
          open={isOpened}
          scroll="body"
          onClose={onClose}
          fullScreen={fullScreen}
        >
          <DialogTitle
            style={{
              padding: 0,
            }}
            disableTypography
          >
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ width: 48 }} />
              <Typography variant={"h5"} className={classes.compania}>
                {"Agregar Comentario"}
              </Typography>

              <div style={{ width: 48 }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClose}
                  aria-label="Close"
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>

          <DialogContent
            style={{ marginBottom: "10px" }}
            className={classes.content}
          >
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"COMENTARIO"}
            </Typography>
            <div className={classes.motivoSelector}>
              <TextField
                id={"comentario"}
                size="small"
                style={{ width: "100%" }}
                onChange={(event) => handleChangeComentario(event.target.value)}
                value={comentario}
                variant="outlined"
              />
            </div>
          </DialogContent>

          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonBack}
              onClick={onClose}
              variant="contained"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.button}
              onClick={() => onClickAceptar(siniestroId, comentario)}
              variant="contained"
              color="primary"
              disabled={!enabled}
            >
              CONFIRMAR
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
);

export default PopupComentario;
