import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const ERROR_CODES = {
  CoberturaNoPerteneceCompania:
    "Alguna de las coberturas ingresadas no es válida para la compañía"
};

const postEsquemasInited = createAction("MODIFICAR_ESQUEMAS_INITED");
const postEsquemasCompleted = createAction("MODIFICAR_ESQUEMAS_COMPLETED");
const postEsquemasFailed = createAction("MODIFICAR_ESQUEMAS_FAILED", error => ({
  error
}));

const patchEsquemasInited = createAction("MODIFICAR_COBERTURA_ESQUEMAS_PRENDARIOS_INITED");
const patchEsquemasCompleted = createAction("MODIFICAR_COBERTURA_ESQUEMAS_PRENDARIOS_COMPLETED");
const patchEsquemasFailed = createAction("MODIFICAR_COBERTURA_ESQUEMAS_PRENDARIOS_FAILED", error => ({
  error
}));

const getEsquemasInited = createAction("OBTENER_ESQUEMAS_INITED");
const getEsquemasCompleted = createAction(
  "OBTENER_ESQUEMAS_COMPLETED",
  (response, lastEsquemaSelected) => {
    let esquemas = [];
    let esquemaSelected = null;
    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.total !== 0
    ) {
      esquemas = response.items;

      esquemaSelected = !lastEsquemaSelected
        ? esquemas[0]
        : esquemas.find(esquema => {
          return esquema.id === lastEsquemaSelected;
        });
    }

    return { esquemas, esquemaSelected };
  }
);
const getEsquemasFailed = createAction("OBTENER_ESQUEMAS_FAILED", error => ({
  error
}));

const updateEsquemas = createAction("UPDATE_ESQUEMAS", esquemas => ({
  esquemas
}));

const setEsquemaSelected = createAction(
  "SET_ESQUEMA_SELECTED",
  esquemaSelected => ({
    esquemaSelected
  })
);

const setHasChanged = createAction("SET_HAS_CHANGED", hasChanged => ({
  hasChanged
}));

const postEsquemas = (id, esquemasCotizacion) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postEsquemasInited({ id, esquemasCotizacion }));

      const data = {
        id: id,
        esquemasCotizacion: esquemasCotizacion
      };

      await services.api.esquemas().postEsquemas(data);

      dispatch(postEsquemasCompleted());
    } catch (error) {
      let message = ERROR_CODES[error.responseStatus.message]
        ? ERROR_CODES[error.responseStatus.message]
        : "Ocurrió un error inesperado. Intente nuevamente.";
      dispatch(queueMessage(message));
      dispatch(postEsquemasFailed(error));
    }
  };
};

const patchEsquemas = data => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchEsquemasInited());

      await services.api.esquemas().patchEsquemas(data);

      dispatch(patchEsquemasCompleted());
    } catch (error) {
      let message = ERROR_CODES[error.responseStatus.message]
        ? ERROR_CODES[error.responseStatus.message]
        : "Ocurrió un error inesperado. Intente nuevamente.";
      dispatch(queueMessage(message));
      dispatch(postEsquemasFailed(error));
    }
  };
};

const esquemasInitialState = {
  data: [],
  hasChanged: false,
  esquemaSelected: null,
  lastEsquemaSelected: null,
  failed: false
};

const esquemasReducer = handleActions(
  {
    [postEsquemasInited]: (state, action) => {
      return {
        ...state
      };
    },
    [postEsquemasCompleted]: (state, action) => {
      return {
        ...state,
        hasChanged: false
      };
    },
    [postEsquemasFailed]: (state, action) => {
      return {
        ...state
      };
    },
    [patchEsquemasInited]: (state, action) => {
      return {
        ...state
      };
    },
    [patchEsquemasCompleted]: (state, action) => {
      return {
        ...state,
        hasChanged: false
      };
    },
    [patchEsquemasFailed]: (state, action) => {
      return {
        ...state
      };
    },
    [getEsquemasInited]: (state, action) => {
      return {
        ...state,
        esquemaSelected: null,
        hasChanged: false
      };
    },
    [getEsquemasCompleted]: (state, action) => {
      return {
        ...state,
        esquemas: [...action.payload.esquemas],
        esquemaSelected: action.payload.esquemaSelected,
        failed: false
      };
    },
    [getEsquemasFailed]: (state, action) => {
      return {
        ...state,
        failed: true
      };
    },
    [updateEsquemas]: (state, action) => {
      return {
        ...state,
        esquemas: action.payload.esquemas,
        hasChanged: true
      };
    },
    [setEsquemaSelected]: (state, action) => {
      return {
        ...state,
        esquemaSelected: action.payload.esquemaSelected,
        lastEsquemaSelected:
          action.payload.esquemaSelected && action.payload.esquemaSelected.id
      };
    },
    [setHasChanged]: (state, action) => {
      return {
        ...state,
        hasChanged: action.payload.hasChanged
      };
    }
  },
  esquemasInitialState
);

const getEsquemas = token => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getEsquemasInited());
      const response = await services.api.esquemas(token).getEsquemas();

      dispatch(
        getEsquemasCompleted(
          response,
          getState().esquemasReducer.lastEsquemaSelected
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(getEsquemasFailed(error));
    }
  };
};

export { esquemasReducer };
export {
  postEsquemas,
  getEsquemas,
  updateEsquemas,
  setEsquemaSelected,
  setHasChanged,
  patchEsquemas
};
