import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import ContentLoader from "react-content-loader";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "77.7vw",
  },
  rootHeaderDetalle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: pxToRem(8),
  },
  mainDataItemContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 60,
  },
  nombreColumn: {
    display: "flex",
    flexDirection: "column",
    paddingRight: pxToRem(40),
  },
  clientName: {
    fontFamily: "Nunito",
    fontSize: "22px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#423d5c",
  },
});

const HeaderDetallePolizas = ({
  isLoading,
  nombre,
  numeroDocumento,
  numeroPoliza,
  operacion,
  negocio,
  estado,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.rootHeaderDetalle}>
      {!isLoading && (
        <div className={classes.nombreColumn}>
          <Typography className={classes.clientName}>{nombre}</Typography>

          <ColorLabelNegocio negocio={negocio} />
        </div>
      )}
      <HeaderDetalleItem
        isLoading={isLoading}
        caption="DNI"
        value={numeroDocumento}
      />
      <HeaderDetalleItem
        isLoading={isLoading}
        caption="Número Poliza"
        value={numeroPoliza}
      />
      <HeaderDetalleItem
        isLoading={isLoading}
        caption="Estado"
        value={estado}
      />
      {operacion !== null && operacion !== 0 && (
        <HeaderDetalleItem
          isLoading={isLoading}
          caption="Número Operación"
          value={operacion}
        />
      )}
    </div>
  );
};

export default HeaderDetallePolizas;

const useStylesHeaderDetalleItem = makeStyles((theme) => ({
  mainDataItemContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 30,
    justifyContent: "space-evenly",
  },
  mainDataTitle: {
    fontFamily: "Nunito",
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  mainDataValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#423d5c",
  },
}));

const HeaderDetalleItem = ({ isLoading, caption, value }) => {
  const classes = useStylesHeaderDetalleItem();

  if (isLoading)
    return (
      <div className={classes.mainDataItemContainer}>
        <div style={{ width: "100%", height: "100%" }}>
          <ContentLoader
            style={{ width: "100%", height: "100%" }}
            height={30}
            width={200}
            speed={1}
            backgroundColor="#f7f8ff"
            foregroundColor="#d9e8df"
          >
            <rect x="7" y="0" rx="8" ry="8" width="143" height="8" />
            <rect x="7" y="15" rx="8" ry="8" width="143" height="8" />
          </ContentLoader>
        </div>
      </div>
    );

  return (
    <div className={classes.mainDataItemContainer}>
      <Typography className={classes.mainDataTitle}>{caption}</Typography>
      <Typography className={classes.mainDataValue}>{value}</Typography>
    </div>
  );
};
