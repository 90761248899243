import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { setLocalidad, clearLocalidad, setCodigoPostal } from "../actions";

import { fetchCodigosPostales } from "~business/codigoPostalSelector/actions";
import LocalidadSelector from "~business/localidadSelector/LocalidadSelector";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";
import localRepository from "../../../../localRepository";

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
}));
const useReduxSelector = () =>
  useSelector((state) => {
    return {
      provinciaId: state.multicotizadorReducer.provinciaSelected,
      codigosPostales: state.codigoPostalSelectorReducer.codigosPostales,
    };
  });

const LocalidadStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [localidad, setValueLocalidad] = useState(null);
  const { provinciaId, codigosPostales } = useReduxSelector();

  useEffect(() => {
    dispatch(clearLocalidad());
  }, [dispatch]);

  useEffect(() => {
    if (codigosPostales && codigosPostales.length === 1)
      dispatch(setCodigoPostal(codigosPostales[0].value));

    if (localidad) {
      dispatch(setLocalidad(localidad));
      localRepository.mostUsedLoc.set({
        label: localidad,
        value: localidad,
        provId: provinciaId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigosPostales]);

  const handleSelection = (event) => {
    const localidad = event.target.value;
    dispatch(fetchCodigosPostales(provinciaId, localidad));
    setValueLocalidad(localidad);
  };

  return (
    <>
      <div className={classes.cotizador}>
        <div className={classes.title}>
          <PrimaryTitle label={"¿En qué localidad?"} marginBottom={32} />
        </div>
        <LocalidadSelector
          label={"Ingresá o busca la localidad"}
          onSelected={handleSelection}
          provinciaId={provinciaId}
        />
      </div>
    </>
  );
};

export default LocalidadStep;
