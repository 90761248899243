import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const setInitialState = createAction("SET_DETALLE_POLIZA_INITIAL_STATE");

const setCurrentPoliza = createAction(
  "SET_CURRENT_USUARIO",
  (currentPoliza) => ({
    currentPoliza,
  })
);

const getPolizaInited = createAction("OBTENER_POLIZA_INITED");

const getPolizaCompleted = createAction(
  "OBTENER_POLIZA_COMPLETED",
  (currentPoliza) => ({ currentPoliza })
);

const getPolizaFailed = createAction("OBTENER_POLIZA_FAILED", (error) => ({
  error,
}));

const setPolizaFileName = createAction(
  "SET_POLIZA_FILENAME",
  (polizaFileNameSelected) => ({
    polizaFileNameSelected,
  })
);
const setTarjetaFileName = createAction(
  "SET_TARJETA_FILENAME",
  (tarjetaFileNameSelected) => ({
    tarjetaFileNameSelected,
  })
);

const setCedulaMercosurFileName = createAction(
  "SET_CEDULA_MERCOSUR_FILENAME",
  (cedulaMercosurFileNameSelected) => ({
    cedulaMercosurFileNameSelected,
  })
);

const setCuponeraFileName = createAction(
  "SET_CUPONERA_FILENAME",
  (cuponeraFileNameSelected) => ({
    cuponeraFileNameSelected,
  })
);

const getPoliza = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizaInited());

      const response = await services.api.polizas().getPoliza(id);

      dispatch(getPolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizaFailed(error));
    }
  };
};

const patchPolizaInited = createAction("UPDATE_POLIZA_INITED");
const patchPolizaCompleted = createAction(
  "UPDATE_POLIZA_COMPLETED",
  (response) => {}
);
const patchPolizaFailed = createAction("UPDATE_POLIZA_FAILED", (error) => ({
  error,
}));

const patchPoliza = (polizaId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchPolizaInited());

      const response = await services.api.polizas().patchPoliza(polizaId, data);

      dispatch(patchPolizaCompleted(response));
      dispatch(
        queueMessage(
          "Póliza actualizada correctamente",
          "success",
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(patchPolizaFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al actualizar la póliza. Intente nuevamente."
        )
      );
    }
  };
};

const detallePolizaInitialState = {
  currentPoliza: null,
  isLoading: false,
  failed: false,

  polizaFileNameSelected: "",
  tarjetaFileNameSelected: "",
  cedulaMercosurFileNameSelected: "",
  cuponeraFileNameSelected: "",
  polizaActualizada: false,
};

const detallePolizaReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...detallePolizaInitialState,
        take: state.take,
        polizas: null,
      };
    },
    [setCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentUsuario: action.payload.currentUsuario,
      };
    },
    [getPolizaInited]: (state, action) => {
      return {
        ...detallePolizaInitialState,
      };
    },
    [getPolizaCompleted]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.currentPoliza,
        isLoading: false,
        failed: false,
      };
    },
    [getPolizaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [setPolizaFileName]: (state, action) => {
      return {
        ...state,
        polizaFileNameSelected: action.payload.polizaFileNameSelected,
      };
    },
    [setTarjetaFileName]: (state, action) => {
      return {
        ...state,
        tarjetaFileNameSelected: action.payload.tarjetaFileNameSelected,
      };
    },
    [setCedulaMercosurFileName]: (state, action) => {
      return {
        ...state,
        cedulaMercosurFileNameSelected:
          action.payload.cedulaMercosurFileNameSelected,
      };
    },
    [setCuponeraFileName]: (state, action) => {
      return {
        ...state,
        cuponeraFileNameSelected: action.payload.cuponeraFileNameSelected,
      };
    },
    [patchPolizaInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [patchPolizaCompleted]: (state, action) => {
      return {
        ...state,
        polizaActualizada: true,
        isLoading: false,
      };
    },
    [patchPolizaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },

  detallePolizaInitialState
);

export { detallePolizaReducer };
export {
  getPoliza,
  setInitialState,
  setCurrentPoliza,
  patchPoliza,
  setPolizaFileName,
  setTarjetaFileName,
  setCedulaMercosurFileName,
  setCuponeraFileName,
};
