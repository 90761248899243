import { createActions, handleActions } from "redux-actions";

export const fetchVehiculosMarcas = (documento, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchVehiculosMarcasInited());
      const response = await services.api.vehiculos().getMarcas();
      dispatch(fetchVehiculosMarcasCompleted(response));
    } catch (error) {
      dispatch(fetchVehiculosMarcasFailed(error));
    }
  };
};

const {
  vehiculoMarcasReducer: {
    clear,
    fetchVehiculosMarcasInited,
    fetchVehiculosMarcasCompleted,
    fetchVehiculosMarcasFailed
  }
} = createActions({
  vehiculoMarcasReducer: {
    clear: () => ({}),
    setMarca: value => ({ value }),
    setModelo: value => ({ value }),
    //
    fetchVehiculosMarcasInited: () => ({}),
    fetchVehiculosMarcasCompleted: response => {
      let marcas = [];

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0
      ) {
        marcas = response.map(x => ({ value: x, label: x }));
      }

      return { marcas };
    },
    fetchVehiculosMarcasFailed: error => ({ error })
  }
});

const initialState = {
  marcas: [],
  isLoading: false
};

export const vehiculoMarcasReducer = handleActions(
  {
    [clear]: () => {
      return {
        ...initialState
      };
    },
    [fetchVehiculosMarcasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [fetchVehiculosMarcasCompleted]: (state, action) => {
      return {
        ...state,
        marcas: [...action.payload.marcas],
        isLoading: false
      };
    },
    [fetchVehiculosMarcasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default vehiculoMarcasReducer;
export { clear };
