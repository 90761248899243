import localRepository from "../localRepository";

const ERROR_CODES: { [key: number]: string } = {
  400: "BadRequest",
  401: "Unauthorized",
  409: "Conflict",
};

export const headersBackendSeguros: HeadersInit = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Client-Id": "Lira",
};

export const errorUnlessOk = (response: Response): Promise<Response> => {
  return new Promise((resolve, reject) => {
    if (Object.keys(ERROR_CODES).includes(`${response.status}`)) {
      if (isJsonResponse(response)) {
        response.json().then((json) => reject(json));
      } else {
        reject({
          responseStatus: {
            errorCode: ERROR_CODES[response.status],
            message: response.statusText,
          },
        });
      }
    } else if (!response.ok) {
      reject({
        responseStatus: {
          errorCode: `GenericError-StatusCode-${response.status}`,
          message: "",
        },
      });
    } else {
      resolve(response);
    }
  });
};

const isJsonResponse = (response: Response): boolean => {
  const contentType = response.headers.get("content-type");
  return contentType !== null && contentType.indexOf("application/json") !== -1;
};

export const convertToQueryString = (params: { [key: string]: any }): string =>
  Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

interface RequestInitWithRetry extends RequestInit {
  retries?: number;
  retryDelay?: number;
}

export const buildRequest = (
  verb: string,
  data?: any,
  headers: HeadersInit = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
): RequestInitWithRetry => {
  const request: RequestInitWithRetry = {
    method: verb,
    headers: headers,
    retries: 2,
    retryDelay: 30000,
  };
  if ((verb === "POST" || verb === "PUT" || verb === "PATCH") && data) {
    request.body = JSON.stringify(data);
  }
  return request;
};

export const buildRequestWithAuth = (
  verb: string,
  data?: any,
  headers: HeadersInit = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
): RequestInitWithRetry => {
  const request = buildRequest(verb, data, headers);
  const token = localRepository.accessToken.get();
  if (token) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return request;
};

export const isError = (e: any): e is Error => {
  return (
    e &&
    e.stack &&
    e.message &&
    typeof e.stack === "string" &&
    typeof e.message === "string"
  );
};

export const parseError = (error: any, functionName: string) => {
  if (isError(error)) {
    return { message: error.message, functionName };
  } else {
    return error;
  }
};
