import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { negociosSegurosOptions } from "~constants/negocios";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    borderRadius: "8px  8px  0px 0px ",
    borderBottom: "solid 1px #CCCCE4",
    justifyContent: "center",
  },
  tab: { fontWeight: 800 },
});

const TabSelector = ({ list, value, onChange }) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    if (onChange) onChange(newValue);
  };

  return (
    <Paper className={classes.root}>
      {value && (
        <Tabs
          key={"TabSelector"}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
        >
          {list.map((value, index) => (
            <Tab
              className={classes.tab}
              key={"TabSelector-" + index + value.id}
              value={value.idCompania ? value.idCompania : value.id}
              label={
                value.nombreCompania
                  ? value.nombreCompania
                  : negociosSegurosOptions[value.id]
              }
            />
          ))}
        </Tabs>
      )}
    </Paper>
  );
};

export default TabSelector;
