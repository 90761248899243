import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_INITIAL_STATE_SINIESTROS");

const getSiniestrosInited = createAction("OBTENER_SINIESTROS_INITED");

const getSiniestrosCompleted = createAction(
  "OBTENER_SINIESTROS_COMPLETED",
  (response) => {
    let siniestros = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      siniestros = response.siniestros;
      totalRegistros = response.totalRegistros;
    }

    return { siniestros, totalRegistros };
  }
);

const getSiniestrosFailed = createAction(
  "OBTENER_SINIESTROS_FAILED",
  (error) => ({
    error,
  })
);

const closeSuccessEstadoPopUp = createAction("CLOSE_SUCCESS_ESTADO_POPUP");

const patchSiniestroTipoInited = createAction("PATCH_TIPO_SINIESTRO_INITED");

const patchSiniestroTipoCompleted = createAction(
  "PATCH_TIPO_SINIESTRO_COMPLETED"
);

const patchSiniestroTipoFailed = createAction(
  "TIPO_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const patchSiniestroEstadoInited = createAction(
  "PATCH_ESTADO_SINIESTRO_INITED"
);

const patchSiniestroEstadoCompleted = createAction(
  "PATCH_ESTADO_SINIESTRO_COMPLETED"
);

const patchSiniestroEstadoFailed = createAction(
  "PATCH_ESTADO_SINIESTRO_FAILED",
  (error) => ({
    error,
  })
);

const getSiniestros = ({ skip, take, searchCriteria, estado, tipo,
  fechaDesde,
  fechaHasta }) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getSiniestrosInited());

      const data = {
        skip,
        take,
        searchCriteria,
        estado,
        tipo,
        fechaDesde,
        fechaHasta,
      };

      const response = await services.api.siniestros().getSiniestros(data);

      dispatch(getSiniestrosCompleted(response));
    } catch (error) {
      console.log(error);
      dispatch(getSiniestrosFailed(error));
    }
  };
};

const patchSiniestroEstado = (
  siniestroId,
  estado,
  motivo,
  comentarioRechazo,
  numeroSiniestroCompania
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchSiniestroEstadoInited());

      const data = {
        estado,
        motivo,
        comentarioRechazo,
        numeroSiniestroCompania,
      };

      await services.api.siniestros().patchSiniestroEstado(siniestroId, data);

      dispatch(patchSiniestroEstadoCompleted());
    } catch (error) {
      console.log(error);
      dispatch(patchSiniestroEstadoFailed(error));
    }
  };
};

const patchSiniestroTipo = (siniestroId, tipo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchSiniestroTipoInited());

      const data = {
        tipo,
      };

      console.log(data);

      await services.api.siniestros().patchSiniestroTipo(siniestroId, data);

      dispatch(patchSiniestroTipoCompleted());
    } catch (error) {
      console.log(error);
      dispatch(patchSiniestroTipoFailed(error));
    }
  };
};

const listadoSiniestrosInitialState = {
  siniestros: null,
  isLoading: false,
  failed: false,
  totalRegistros: 0,
  siniestroModificado: false,
};

const listadoSiniestrosReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...listadoSiniestrosInitialState,
      };
    },
    [getSiniestrosInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getSiniestrosCompleted]: (state, action) => {
      return {
        ...state,
        totalRegistros: action.payload.totalRegistros,
        siniestros: action.payload.siniestros,
        isLoading: false,
        failed: false,
      };
    },
    [getSiniestrosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [patchSiniestroEstadoInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        siniestroModificado: false,
      };
    },
    [patchSiniestroEstadoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        siniestroModificado: true,
      };
    },
    [patchSiniestroEstadoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        siniestroModificado: false,
      };
    },
    [patchSiniestroTipoInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        siniestroModificado: false,
      };
    },
    [patchSiniestroTipoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        siniestroModificado: true,
      };
    },
    [patchSiniestroTipoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        siniestroModificado: false,
      };
    },
    [closeSuccessEstadoPopUp]: (state, action) => {
      return {
        ...state,
        siniestroModificado: false,
      };
    },
  },
  listadoSiniestrosInitialState
);

export { listadoSiniestrosReducer };

export {
  getSiniestros,
  closeSuccessEstadoPopUp,
  patchSiniestroEstado,
  patchSiniestroTipo,
};
