import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clear } from "./actions";
import AgenciaStep from "./steps/AgenciaStep";
import MarcaStep from "./steps/MarcaStep";
import ModeloStep from "./steps/ModeloStep";
import AnioStep from "./steps/AnioStep";
import ProvinciaStep from "./steps/ProvinciaStep";
import CodigoPostalStep from "./steps/CodigoPostalStep";
import UsoStep from "./steps/UsoStep";
import CondicionFiscalStep from "./steps/CondicionFiscalStep";
import TieneGncStep from "./steps/TieneGncStep";
import FormaDePagoStep from "./steps/FormaDePagoStep";
import ListadoCotizacionesStep from "./steps/listadoCotizacionesStep";
import Layout from "~shared/layout/Layout";
import LocalidadStep from "./steps/LocalidadStep";
import DniStep from "./steps/DniStep";
import SexoStep from "./steps/SexoStep";
import EsPersonaValidaStep from "./steps/EsPersonaValidaStep";
import FechaNacimientoStep from "./steps/FechaNacimientoStep";
import ContactoNombreStep from "./steps/ContactoNombreStep";
import DireccionStep from "./steps/DireccionStep";
import ContactoTelefonoStep from "./steps/ContactoTelefonoStep";
import ContactoEmailStep from "./steps/ContactoEmailStep";
import DominioStep from "./steps/DominioStep";
import NumeroChasisStep from "./steps/NumeroChasisStep";
import MotorStep from "./steps/MotorStep";
import DetallePolizaStep from "./steps/DetallePolizaStep";
import FinalStep from "./steps/FinalStep";
import { useCurrentStep, steps } from "./useCurrentStep";
import useGetIdentidad from "./useGetIdentidad";
import DatosPagoStep from "./steps/DatosPagoStep";
import { SendPostSolicitudEmisionStep } from "./steps/SendPostSolicitudEmisionStep";
import { SendPostCotizacionesStep } from "./steps/SendPostCotizacionesStep";
import FotoTraseraVehiculoStep from "./steps/FotoTraseraVehiculoStep";
import FotoLateralIzquierdoVehiculoStep from "./steps/FotoLateralIzquierdoVehiculoStep";
import FotoLateralDerechoVehiculoStep from "./steps/FotoLateralDerechoVehiculoStep";
import FotoFrenteVehiculoStep from "./steps/FotoFrenteVehiculoStep";
import CuilStep from "./steps/CuilStep";
import FechaVigenciaDesdeStep from "./steps/FechaVigenciaDesdeStep";
import MarcaTarjetaCreditoStep from "./steps/MarcaTarjetaCreditoStep";

const MulticotizadorForm = () => {
  const dispatch = useDispatch();

  const [position, currentState] = useCurrentStep();

  useEffect(() => {
    return function cleanup() {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useGetIdentidad(currentState);

  return (
    <>
      {position && (
        <Layout title={"Multicotizador"}>
          {position === steps.agenciaStep && <AgenciaStep />}
          {position === steps.marcaStep && <MarcaStep />}
          {position === steps.anioStep && <AnioStep />}
          {position === steps.modeloStep && <ModeloStep />}
          {position === steps.provinciaStep && <ProvinciaStep />}
          {position === steps.localidadStep && <LocalidadStep />}
          {position === steps.codigoPostalStep && <CodigoPostalStep />}
          {position === steps.usoStep && <UsoStep />}
          {position === steps.condicionFiscalStep && <CondicionFiscalStep />}
          {position === steps.tieneGNCStep && <TieneGncStep />}
          {position === steps.formaDePagoStep && <FormaDePagoStep />}

          {position === steps.sendPostCotizacionesStep && (
            <SendPostCotizacionesStep />
          )}
          {position === steps.listadoCotizacionesStep && (
            <ListadoCotizacionesStep />
          )}
          {position === steps.detallePolizaStep && <DetallePolizaStep />}

          {position === steps.dniStep && <DniStep />}
          {position === steps.sexoStep && <SexoStep />}
          {position === steps.esPersonaValidaStep && <EsPersonaValidaStep />}
          {position === steps.fechaNacimientoStep && <FechaNacimientoStep />}
          {position === steps.nombreStep && <ContactoNombreStep />}
          {position === steps.cuilStep && <CuilStep />}
          {position === steps.direccionStep && <DireccionStep />}
          {position === steps.telefonoStep && <ContactoTelefonoStep />}
          {position === steps.emailStep && <ContactoEmailStep />}
          {position === steps.dominioStep && <DominioStep />}
          {position === steps.numeroChasisStep && <NumeroChasisStep />}
          {position === steps.motorStep && <MotorStep />}
          {position === steps.fotoFrenteVehiculoStep && (
            <FotoFrenteVehiculoStep />
          )}
          {position === steps.fotoTraseraVehiculoStep && (
            <FotoTraseraVehiculoStep />
          )}
          {position === steps.fotoLateralIzquierdoVehiculoStep && (
            <FotoLateralIzquierdoVehiculoStep />
          )}
          {position === steps.fotoLateralDerechoVehiculoStep && (
            <FotoLateralDerechoVehiculoStep />
          )}

          {position === steps.datosPagoStep && <DatosPagoStep />}
          {position === steps.marcaTarjetaCreditoStep && (
            <MarcaTarjetaCreditoStep />
          )}
          {position === steps.sendPostSolicitudEmisionStep && (
            <SendPostSolicitudEmisionStep />
          )}
          {position === steps.fechaVigenciaDesdeStep && (
            <FechaVigenciaDesdeStep />
          )}

          {position === steps.finalStep && <FinalStep />}
        </Layout>
      )}
      {!position && <>RESULT???</>}
    </>
  );
};

export default MulticotizadorForm;
