import React, { useState, useEffect } from "react";
import VehiculoProvinciaSelector from "~styled/vehiculoProvinciaSelector/VehiculoProvinciaSelector";
import LocalidadSelector from "../../localidadSelector/LocalidadSelector";
import CodigoPostalSelector from "../../codigoPostalSelector/CodigoPostalSelector";

const LOCALIDAD_STEP = "LOCALIDAD";
const PROVINCIA_STEP = "PROVINCIA";
const CODIGO_POSTAL_STEP = "CODIGO_POSTAL";

interface LocalidadStepperProps {
  onChange?: (
    localidad: string,
    provincia: string,
    codigoPostal: string
  ) => void;
}

interface CustomChangeEvent {
  target: { value: string };
}

const LocalidadStepper: React.FC<LocalidadStepperProps> = ({ onChange }) => {
  const [localidad, setLocalidad] = useState<string | null>(null);
  const [provincia, setProvincia] = useState<string | null>(null);
  const [codigoPostal, setCodigoPostal] = useState<string | null>(null);

  useEffect(() => {
    const allCompleted = localidad && provincia && codigoPostal;
    if (allCompleted && onChange) onChange(localidad, provincia, codigoPostal);
  }, [localidad, provincia, codigoPostal, onChange]);

  const step = getStep(localidad, provincia, codigoPostal);

  return (
    <div style={{ minHeight: "90vh" }}>
      {step === PROVINCIA_STEP && (
        <VehiculoProvinciaSelector
          value={provincia}
          onChange={(event: CustomChangeEvent) =>
            setProvincia(event.target.value)
          }
        />
      )}
      {step === LOCALIDAD_STEP && (
        <LocalidadSelector
          label="Localidad selector"
          provinciaId={provincia}
          onSelected={(event: React.ChangeEvent<HTMLInputElement>) =>
            setLocalidad(event.target.value)
          }
        />
      )}
      {step === CODIGO_POSTAL_STEP && (
        <CodigoPostalSelector
          label={provincia}
          provinciaId={provincia}
          localidad={localidad ? localidad : ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCodigoPostal(event.target.value)
          }
        />
      )}
    </div>
  );
};

export default LocalidadStepper;

const getStep = (
  localidad: string | null,
  provincia: string | null,
  codigoPostal: string | null
): string => {
  if (!provincia) return PROVINCIA_STEP;
  if (!localidad) return LOCALIDAD_STEP;
  if (!codigoPostal) return CODIGO_POSTAL_STEP;
  return CODIGO_POSTAL_STEP;
};
