import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import SecurityIcon from "@material-ui/icons/Security";

import { cuotasFilter, cuotasOptions } from "./cuotas";

import {
  vigenciasSolicitudEmisionFilter,
  vigenciasSolicitudEmisionOptions,
} from "./vigencias";
import { useDispatch, useSelector } from "react-redux";
import {
  setVigenciaDesde,
  setNumeroPoliza,
  setPremioTotal,
  setSumaAsegurada,
  setCantidadCuotas,
  setVigencia,
} from "../actions";
import ItemNumericCard from "~business/Cards/ItemNumericCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import ItemDateCard from "~business/Cards/ItemDateCard";

const moment = require("moment");

const title = "Datos para la Póliza";

/*
 */

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detalleSolicitudEmisionReducer;
    return {
      numeroPoliza: detalle.numeroPolizaSelected,
      premioTotal: detalle.premioTotalSelected,
      sumaAsegurada: detalle.sumaAseguradaSelected,
      cantidadCuotas: detalle.cantidadCuotasSelected,
      vigencia: detalle.vigenciaSelected,
      vigenciaDesde: detalle.vigenciaDesdeSelected,
      vigenciaHasta: detalle.vigenciaHastaSelected,
      negocio: detalle.detalleSolicitudEmision.negocio,
      cuotasSelected: detalle.cuotasSelected,
      codigoOrganizador: detalle.codigoOrganizadorSelected,
      codigoProductor: detalle.codigoProductorSelected,
      codigoVendedor: detalle.codigoVendedorSelected,
    };
  });

const DatosPolizaCard = ({ isLoading, editable, displayError, onBlur }) => {
  const dispatch = useDispatch();

  const {
    numeroPoliza,
    premioTotal,
    sumaAsegurada,
    cantidadCuotas,
    vigencia,
    vigenciaDesde,
    vigenciaHasta,
    negocio,
    cuotasSelected,
    codigoOrganizador,
    codigoProductor,
    codigoVendedor,
  } = useReduxSelector();

  let timer = null;
  const handleChangeNumeroPoliza = (nroPoliza) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroPoliza(nroPoliza));
    }, 200);
  };

  const handleChangePremioTotal = (premio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setPremioTotal(premio));
    }, 200);
  };

  const handleChangeSumaAsegurada = (sumaAsegurada) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setSumaAsegurada(sumaAsegurada));
    }, 200);
  };

  const handleChangeCantidadCuotas = (cantidadCuotas) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCantidadCuotas(cantidadCuotas));
    }, 200);
  };

  const handleChangeVigencia = (vigencia) => {
    dispatch(setVigencia(vigencia));
  };

  const handleChangeVigenciaDesde = (vigenciaDesde) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setVigenciaDesde(vigenciaDesde));
    }, 200);

    if (negocio === "Prendarios") {
      for (let i = 1; i <= 6; i++) {
        if (!cuotasSelected[i]) return;
        cuotasSelected[i].fechaVencimiento.value = moment(
          vigenciaDesde.value
        ).add(i, "months");
      }
    }
  };

  const isPrendarios = negocio === "Prendarios";
  return (
    <DetalleCard title={title} IconHeader={SecurityIcon}>
      {/* <ItemInputCard
        id={"nroPoliza"}
        isLoading={isLoading}
        label={"Número de Póliza"}
        value={numeroPoliza.value}
        onChange={handleChangeNumeroPoliza}
        onBlur={onBlur}
        focus={numeroPoliza.focus}
      /> */}
      <ItemNumericCard
        id={"nroPoliza"}
        isLoading={isLoading}
        label={"Número de Póliza"}
        value={numeroPoliza.value}
        onChange={handleChangeNumeroPoliza}
        onBlur={onBlur}
        focus={numeroPoliza.focus}
        helpText={"Ingrese solo números"}
      />
      <ItemCurrencyCard
        id={"premioTotal"}
        isLoading={isLoading}
        label={"Premio Total"}
        value={premioTotal.value}
        type="currency"
        onChange={handleChangePremioTotal}
        onBlur={onBlur}
        displayError={displayError}
        focus={premioTotal.focus}
        required
      />
      <ItemCurrencyCard
        id={"sumaAsegurada"}
        isLoading={isLoading}
        label={"Suma Asegurada"}
        value={sumaAsegurada.value}
        onChange={handleChangeSumaAsegurada}
        onBlur={onBlur}
        displayError={displayError}
        focus={sumaAsegurada.focus}
        required
      />
      {!isPrendarios && (
        <ItemSelectorCard
          id={"cantidadCuotas"}
          label={"Cantidad Cuotas"}
          isLoading={isLoading}
          value={cantidadCuotas.value}
          onChange={handleChangeCantidadCuotas}
          options={cuotasFilter}
          valueOptions={cuotasOptions}
          type="selector"
          variant="big"
        />
      )}
      {!isPrendarios && (
        <ItemSelectorCard
          id={"vigencia"}
          isLoading={isLoading}
          label={"Vigencia"}
          value={vigencia.value}
          onChange={handleChangeVigencia}
          options={vigenciasSolicitudEmisionFilter}
          valueOptions={vigenciasSolicitudEmisionOptions}
          type="selector"
          variant="big"
        />
      )}
      <ItemDateCard
        id="vigenciaDesde"
        label="Vigencia Desde"
        isLoading={isLoading}
        value={vigenciaDesde.value}
        onChange={handleChangeVigenciaDesde}
        onBlur={onBlur}
        displayError={displayError}
        focus={vigenciaDesde.focus}
      />
      <ItemDateCard
        id="vigenciaHasta"
        label="Vigencia Hasta"
        isLoading={isLoading}
        value={vigenciaHasta.value}
        readOnly
      />
      {codigoOrganizador && (
        <ItemNumericCard
          label="Código organizador"
          isLoading={isLoading}
          value={codigoOrganizador}
          readOnly
        />
      )}
      {codigoProductor && (
        <ItemNumericCard
          label="Código productor"
          isLoading={isLoading}
          value={codigoProductor}
          readOnly
        />
      )}
      {codigoVendedor && (
        <ItemNumericCard
          label="Código vendedor"
          isLoading={isLoading}
          value={codigoVendedor}
          readOnly
        />
      )}
    </DetalleCard>
  );
};

export default DatosPolizaCard;
