import {v4 as uuidv4} from 'uuid';
import environment from "./environment";
import { buildRequestWithAuth } from "~api/utils";
import { errorUnlessOk, buildRequest } from "~api/utils";
import AWS from "aws-sdk";

const fileService = (directory) => {
  return {
    upload: async (file, load, progress) => {
      const key = uuidv4();
      const fileExtension = file.name.split(".").pop();
      const fileName = `${key}.${fileExtension}`.toLowerCase();
      let request = buildRequestWithAuth("GET");
      let response = await fetch(
        `${environment.presignerUrl}/sign?key=${directory}/${fileName}&verb=put&bucket=${environment.bucketName}`,
        request
      );

      response = await errorUnlessOk(response);
      const { url } = await response.json();
      fetchWithProgress(file, fileName, "put", url, load, progress);
      return fileName;
    },
    uploadPublico: async (file, load, progress) => {
      const s3 = new AWS.S3({
        accessKeyId: environment.awsS3.apiKey,
        secretAccessKey: environment.awsS3.apiSecret,
      });

      const key = uuidv4();
      const fileExtension = file.name.split(".").pop();
      const fileName = `${directory}/${key}.${fileExtension}`.toLowerCase();
      const url = environment.bucketUrl;
      const params = {
        Bucket: environment.bucketName,
        Key: fileName,
        Body: file,
      };

      try {
        s3.putObject(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
            fetchWithProgress(file, fileName, "put", url, load, progress);
          }
        });
      } catch (error) {
        console.log(error);
      }
      return fileName;
    },
    delete: async (fileName) => {
      let request = buildRequestWithAuth("GET");
      let response = await fetch(
        `${environment.presignerUrl}/sign?key=${directory}/${fileName}&verb=delete&bucket=${environment.bucketName}`,
        request
      );
      response = await errorUnlessOk(response);
      const { url } = await response.json();

      const requestDelete = buildRequest("DELETE");
      response = await fetch(url, requestDelete);
      await errorUnlessOk(response);
    },

    download: async (fileName) => {
      let request = buildRequestWithAuth("GET");
      let response = await fetch(
        `${environment.presignerUrl}/sign?key=${directory}/${fileName}&verb=get&bucket=${environment.bucketName}`,
        request
      );
      const { url } = await response.json();
      const requestGet = buildRequest("GET");
      response = await fetch(url, requestGet);

      await errorUnlessOk(response);
      return response;
    },
    copy: async (source, destBucket, destObject) => {
      var s3 = new AWS.S3({
        credentials: {
          accessKeyId: environment.awsS3alt.apiKey,
          secretAccessKey: environment.awsS3alt.apiSecret,
        },
      });
      var params = {
        CopySource: encodeURI(source),
        Bucket: destBucket,
        Key: destObject,
      };

      s3.copyObject(params, function (err, data) {
        if (err) throw err;
      });
    },
  };
};

const fetchWithProgress = (file, fileName, verb, url, load, progress) => {
  var xhr = new XMLHttpRequest();
  xhr.upload.onprogress = (e) => {
    progress(e.lengthComputable, e.loaded, e.total);
  };

  xhr.open(verb, url);

  xhr.setRequestHeader("Content-Type", "application/octet-stream");
  xhr.onload = (e) => {
    if (xhr.status === 200) {
      load(fileName);
    } else {
      throw Error("error at UploadFile - fetchWithProgress");
    }
  };
  xhr.onerror = (e) => {
    console.error(e);
    throw Error("error at UploadFile - fetchWithProgress");
  };
  xhr.send(file);
};

export default fileService;
