import environment from "~libs/environment";
import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";

export interface GetRolesResponse {
  Items: string[];
}

const roles = () => {
  return {
    getRoles: (): Promise<GetRolesResponse> => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = environment.segurosUrl + "/roles";

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { roles };
