import React, { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import CurrencyInputField from "~styled/currencyInputField/CurrencyInputField";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import CurrencyInput from "~styled/input/CurrencyInput";
import ItemLoadingCard from "./ItemLoadingCard";
import NumberFormat from "react-number-format";
import IconEdit from "~assets/icons/ic_edit.svg";
import useField from "~hooks/useField";

const useStyles = makeStyles((theme) => ({
  containerSmall: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1%",
    borderBottom: "1px solid #8B8B8B",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  textFieldValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    width: "100%",
  },
  numericFieldValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    width: "100%",
  },
  datePicker: {
    width: "100%",
  },
  dash: { display: "flex", justifyContent: "center" },
  icon: {
    color: "#6240a5",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  inputSmall: {
    padding: "2%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  selector: {
    width: "100%",
  },
  fileUploadContainer: {
    paddingTop: "2%",
    display: "flex",
    flexDirection: "column",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const errorText = "Requerido";

const ItemCurrencyCard = ({
  id,
  label,
  value,
  onChange,
  required,
  displayError,
  focus,
  onBlur,
  readOnly,
  isLoading,
  size,
}) => {
  const classes = useStyles();
  const validateFunction = useCallback(handleValidate(required), [required]);

  const [localValue, setValue, handleEdit, reference] = useField(
    value,
    validateFunction,
    focus,
    isLoading,
    readOnly
  );

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const handleChangeValue = (value) => {
    setValue((prev) => ({ ...prev, value: value }));
    if (onChange)
      onChange({
        value: value,
        valid: validateFunction(value),
        focus: false,
      });
  };

  const showError = !localValue.valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div
      className={size === "small" ? classes.containerSmall : classes.container}
    >
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {localValue.editing && (
          <CurrencyInputField
            id={id}
            value={localValue.value}
            onChange={(event) => handleChangeValue(event.target.value)}
            onBlur={handleBlur}
            inputProps={{
              className: size === "small" ? classes.inputSmall : classes.input,
            }}
            hasError={showError}
            required={required}
            reference={reference}
            InputProps={{
              inputComponent: CurrencyInput,
            }}
          />
        )}
        {!localValue.editing && (
          <>
            <Typography className={classes.value}>
              {getValueParsed(value)}
            </Typography>
            {!readOnly && (
              <div
                className={classes.buttonLink}
                onClick={() => handleEdit(true)}
              >
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
          </>
        )}

        {!localValue.valid && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                color: "#f77221",
              }}
            >
              <InfoOutlinedIcon style={{ width: 26, height: 26 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemCurrencyCard;

const handleValidate = (required) => (value) => {
  const isDataValid = value && value > 0;

  return isDataValid;
};

const getValueParsed = (value) => {
  if (!value) return <span>&mdash;</span>;

  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$ "}
    />
  );
};
