import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DescriptionIcon from "@material-ui/icons/Description";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";

const title = "Documentación";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentPoliza = state.detallePolizaMultiramaReducer.currentPoliza
      ? state.detallePolizaMultiramaReducer.currentPoliza
      : {};
    return {
      ...currentPoliza,
    };
  });

const ImpresosCard = ({ isLoading }) => {
  const {
    ubicacionPoliza,
    ubicacionCuponera,
    ubicacionTarjeta,
    ubicacionCedulaMercosur,
  } = useReduxSelector();

  if (isLoading)
    return (
      <DetalleCard
        isLoading={isLoading}
        title={title}
        IconHeader={DescriptionIcon}
      />
    );

  return (
    <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
      <>
        <ItemFileUploaderCard
          isLoading={isLoading}
          label={"Póliza"}
          value={ubicacionPoliza}
          readOnly
        />
        {ubicacionCuponera && (
          <ItemFileUploaderCard
            isLoading={isLoading}
            label={"Cuponera"}
            value={ubicacionCuponera}
            readOnly
          />
        )}
        {ubicacionTarjeta && (
          <ItemFileUploaderCard
            isLoading={isLoading}
            label={"Tarjeta"}
            value={ubicacionTarjeta}
            readOnly
          />
        )}
        {ubicacionCedulaMercosur && (
          <ItemFileUploaderCard
            isLoading={isLoading}
            label={"Cédula del Mercosur"}
            value={ubicacionCedulaMercosur}
            readOnly
          />
        )}
      </>
    </DetalleCard>
  );
};

export default ImpresosCard;
