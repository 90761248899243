import React from "react";
import Lottie from "lottie-light-react";
import gears from "~assets/animations/gears.json";
import Typography from "@material-ui/core/Typography";

const LoadingViewGears = ({ title }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div style={{ width: 210 }}>
        <Lottie loop={true} autoPlay={true} animationData={gears} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          maxWidth: 500,
        }}
      >
        {title && (
          <Typography
            variant="h6"
            style={{
              padding: 30,
              color: "#503181",
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            {title}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default LoadingViewGears;
