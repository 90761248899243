import React, { useEffect, useState } from "react";

import ItemDetalleEndosoCard from "~business/Cards/ItemDetalleEndosoCard";

import {
  ANULACION,
  motivoAnulacionOptions,
  tieneGNCOptions,
  FORMA_PAGO,
  DATOS,
  tipoEndosoOptions,
} from "~constants/endosos";
import { formasPagoOptions } from "~constants/formasPago";

import { putAplicarEndoso, putRechazarEndoso, getPoliza } from "../actions";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ItemCustomCard from "~business/Cards/ItemCustomCard";
import { getNombreProvincia } from "~styled/vehiculoProvinciaSelector/getProvincias";
import ColorLabel from "~styled/colorLabel/ColorLabel";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemDatosPagoCard from "~business/Cards/ItemDatosPagoCard";
import ItemCoberturaCard from "~business/Cards/itemCoberturaCard/ItemCoberturaCard";

const moment = require("moment");

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detallePolizaNuevoReducer,
    polizaUpdated: state.detallePolizaNuevoReducer.polizaUpdated,
    canApplyEndoso: state.authenticatedViewReducer.canApplyEndoso,
  }));

const estados = { Aplicado: "success", Rechazado: "danger", Pendiente: "info" };

const DetalleEndosoCard = ({ isLoading, endoso, previousEndoso }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [premio, setPremio] = useState(endoso?.premioTotal);
  const currentState = useReduxSelector();
  const { canApplyEndoso, polizaUpdated } = currentState;

  useEffect(() => {
    if (polizaUpdated) dispatch(getPoliza(id));
  }, [dispatch, id, polizaUpdated]);

  const handleAplicarEndoso = () => {
    const value = premio?.value;
    dispatch(putAplicarEndoso(id, endoso.orden, { PremioTotal: value }));
  };
  const handleRechazarEndoso = () => {
    dispatch(putRechazarEndoso(id, endoso.orden));
  };

  if (isLoading)
    return (
      <>
        <ItemDetalleEndosoCard isLoading />
        <ItemDetalleEndosoCard isLoading />
        <ItemDetalleEndosoCard isLoading />
      </>
    );

  const canApply = endoso?.estado === "Pendiente" && canApplyEndoso;
  return (
    <>
      <ItemCustomCard label={"Estado"} variant="small">
        <ColorLabel text={endoso?.estado} variant={estados[endoso?.estado]} />
      </ItemCustomCard>
      <ItemDetalleEndosoCard
        label={"Tipo"}
        value={tipoEndosoOptions[endoso.tipo]}
      />
      <ItemDetalleEndosoCard
        label={"Solicitado Por"}
        value={endoso?.solicitadoPor}
      />
      <ItemDetalleEndosoCard
        label={"Aplicado Por"}
        value={endoso?.aplicadoPor}
      />
      <ItemDetalleEndosoCard
        label={"Fecha de Solicitud"}
        value={
          endoso.fechaSolicitud &&
          moment(endoso.fechaSolicitud).format("DD/MM/YYYY HH:mm") + " HS"
        }
      />
      <ItemDetalleEndosoCard
        label={"Fecha de Aplicación"}
        value={
          endoso.fechaAplicacion &&
          moment(endoso.fechaAplicacion).format("DD/MM/YYYY HH:mm") + " HS"
        }
      />
      {endoso.tipo === DATOS && (
        <>
          {endoso.premioTotal && (
            <>
              <ItemCoberturaCard
                label={"Cobertura"}
                compania={endoso.compania}
                cobertura={endoso.coberturaId}
                readOnly
                size="small"
              />
              <ItemCurrencyCard
                label={"Premio Total"}
                value={endoso.premioTotal}
                onChange={(value) => setPremio(value)}
                readOnly={!canApply}
                size="small"
              />
            </>
          )}
          <ItemDetalleEndosoCard
            label={"E-mail"}
            value={endoso.emailAsegurado}
          />
          <ItemDetalleEndosoCard
            label={"Teléfono"}
            value={endoso.telefonoAsegurado}
          />
          <ItemDetalleEndosoCard
            label={"Domicilio"}
            value={endoso.domicilioAsegurado}
          />
          <ItemDetalleEndosoCard label={"Modelo"} value={endoso.modelo} />
          <ItemDetalleEndosoCard label={"Codia"} value={endoso.codia} />
          <ItemDetalleEndosoCard
            label={"Provincia"}
            value={getNombreProvincia(endoso.provincia)}
          />
          <ItemDetalleEndosoCard label={"Localidad"} value={endoso.localidad} />
          <ItemDetalleEndosoCard
            label={"Código Postal"}
            value={endoso.codigoPostal}
          />
          <ItemDetalleEndosoCard label={"Dominio"} value={endoso.dominio} />
          <ItemDetalleEndosoCard
            label={"Número de Motor"}
            value={endoso.numeroMotor}
          />
          <ItemDetalleEndosoCard
            label={"Número de Chasis"}
            value={endoso.numeroChasis}
          />
          <ItemDetalleEndosoCard
            label={"Tiene GNC"}
            value={
              endoso.tieneGNC !== null ? tieneGNCOptions[endoso.tieneGNC] : null
            }
          />
        </>
      )}
      {endoso.tipo === ANULACION && (
        <>
          {endoso.fechaAplicacion && (
            <ItemDetalleEndosoCard
              label={"Fecha Anulación"}
              value={getValueParsed(endoso.fechaAplicacion)}
            />
          )}
          <ItemDetalleEndosoCard
            label={"Motivo"}
            value={motivoAnulacionOptions[endoso.motivoAnulacion]}
          />
        </>
      )}
      {endoso.tipo === FORMA_PAGO && (
        <>
          <ItemDetalleEndosoCard
            label={"Forma de pago"}
            value={formasPagoOptions[endoso.formaPago]}
          />
          <ItemDatosPagoCard
            label={"Datos de Pago"}
            idDatosPago={endoso?.informacionPagoId}
            readOnly
            size="small"
          />
        </>
      )}
      {canApply && (
        <ItemCustomCard variant="small">
          <div style={{ width: "10rem", paddingRight: "0.5rem" }}>
            <CallToActionButton
              size="small"
              variant="outlined"
              color="secondary"
              label={"Rechazar"}
              onClick={handleRechazarEndoso}
            />
          </div>
          <div style={{ width: "10rem", paddingLeft: "0.5rem" }}>
            <CallToActionButton
              size="small"
              variant="outlined"
              color="secondary"
              label={"Aplicar"}
              onClick={handleAplicarEndoso}
            />
          </div>
        </ItemCustomCard>
      )}
    </>
  );
};

export default DetalleEndosoCard;

const getValueParsed = (value, format = "DD/MM/YYYY") => {
  const extendedFormat = format === "DD/MM/YYYY" ? "" : " HS";
  return moment(value).format(format) + extendedFormat;
};
