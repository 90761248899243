const isCBUValid = (CBU) => {
  if (CBU.length !== 22) return false;

  if (CBU.split("").every((elem) => elem === "0")) return false;
  const codigoBanco = CBU.substring(0, 8);
  const cuenta = CBU.substring(8, 23);
  return validatorCodigoBanco(codigoBanco) && validatorCuenta(cuenta);
};

const validatorCodigoBanco = (codigoBanco) => {
  const coeficientes = "7139713";
  return validatorBloque(codigoBanco, coeficientes);
};

const validatorCuenta = (cuenta) => {
  const coeficientes = "3971397139713";
  return validatorBloque(cuenta, coeficientes);
};

const validatorBloque = (bloque, coeficientes) => {
  let suma = 0;
  const digitoVerificador = parseInt(bloque[bloque.length - 1]);

  coeficientes.split("").forEach((digit, index) => {
    suma = suma + bloque[index] * digit;
    return suma;
  });

  const dif = 10 - (suma % 10);

  if (dif === digitoVerificador && digitoVerificador !== 0) return true;
  if (dif === 10 && digitoVerificador === 0) return true;
  return false;
};

export default isCBUValid;
