import React, { useState, useEffect, memo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import Divider from "@material-ui/core/Divider";
import Selector from "~styled/selector/Selector";
import DialogContent from "@material-ui/core/DialogContent";
import {
  tipoSiniestroOptions,
  tipoSiniestroFilter,
} from "~constants/tipoSiniestro";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  compania: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 8,
    display: "block",
    flex: 1,
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  polizaTitle: {
    fontSize: pxToRem(12),
  },
  content: {
    paddingBottom: 50,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5%",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    margin: "0 10px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    margin: "0 10px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  sentenceTitle: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  motivoSelector: {
    position: "absolute",
    width: "90%",
  },
}));

const PopupTipo = memo(
  ({
    isOpened,
    onClose,
    siniestroId,
    compania,
    nroPoliza,
    nombre,
    tipo,
    onClickAceptar,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [tipoSiniestro, setTipoSiniestro] = useState(null);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
      setEnabled(tipoSiniestro !== null && tipoSiniestro !== "");
    }, [tipoSiniestro]);

    const handleChangeTipo = (tipo) => {
      setTipoSiniestro(tipo);
    };

    const tipoOptions = tipoSiniestroFilter.filter(
      (option) => option.value !== tipo
    );

    return (
      <div className={classes.root}>
        <Dialog
          open={isOpened}
          scroll="body"
          onClose={onClose}
          fullScreen={fullScreen}
        >
          <DialogTitle
            style={{
              padding: 0,
            }}
            disableTypography
          >
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ width: 48 }} />
              <Typography variant={"h5"} className={classes.compania}>
                {"Cambiar Tipo de Siniestro"}
              </Typography>

              <div style={{ width: 48 }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClose}
                  aria-label="Close"
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Compañía:</span> {compania}
            </Typography>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Número de Póliza:</span>{" "}
              {nroPoliza}
            </Typography>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Asegurado:</span> {nombre}
            </Typography>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Tipo Actual:</span> {tipo}
            </Typography>
            <DialogContent className={classes.content}>
              <Divider />
              <Typography
                variant={"subtitle2"}
                className={classes.sentenceTitle}
              >
                {"NUEVO TIPO"}
              </Typography>
              <div className={classes.motivoSelector}>
                <Selector
                  id={"tipo"}
                  value={tipoSiniestroOptions[tipoSiniestro]}
                  options={tipoOptions}
                  onChange={handleChangeTipo}
                  variant={"big"}
                />
              </div>
            </DialogContent>
          </DialogTitle>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonBack}
              onClick={onClose}
              variant="contained"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.button}
              onClick={() => onClickAceptar(siniestroId, tipoSiniestro)}
              variant="contained"
              color="primary"
              disabled={!enabled}
            >
              CONFIRMAR
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
);

export default PopupTipo;
