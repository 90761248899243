import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import SecurityIcon from "@material-ui/icons/Security";

import { cuotasFilter, cuotasOptions } from "./cuotas";

import {
  vigenciasSolicitudEmisionFilter,
  vigenciasSolicitudEmisionOptions,
} from "./vigencias";
import { useDispatch, useSelector } from "react-redux";
import {
  setVigenciaDesde,
  setNumeroPoliza,
  setPremioTotal,
  setSumaAsegurada,
  setCantidadCuotas,
  setVigencia,
  setCobertura,
} from "../actions";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import ItemCustomCard from "~business/Cards/ItemCustomCard";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";

const moment = require("moment");

const title = "Datos sobre la nueva Póliza";

/*
 */

const useReduxSelector = () =>
  useSelector((state) => {
    const detallePoliza = state.renovacionPolizaReducer.currentPoliza;
    return {
      numeroPoliza: state.renovacionPolizaReducer.numeroPolizaSelected,
      premioTotal: state.renovacionPolizaReducer.premioTotalSelected,
      sumaAsegurada: state.renovacionPolizaReducer.sumaAseguradaSelected,
      cantidadCuotas: state.renovacionPolizaReducer.cantidadCuotasSelected,
      vigencia: state.renovacionPolizaReducer.vigenciaSelected,
      vigenciaDesde: state.renovacionPolizaReducer.vigenciaDesdeSelected,
      vigenciaHasta: state.renovacionPolizaReducer.vigenciaHastaSelected,
      negocio: detallePoliza.negocio,
      cuotasSelected: state.renovacionPolizaReducer.cantidadCuotasSelected,
      compania: detallePoliza.datosPlan.compania,
      coberturaNombre: detallePoliza.coberturaNombre,
      coberturaId: detallePoliza.coberturaId,
      renuevaA: detallePoliza.datosPlan.numeroPoliza,
      operacionId: detallePoliza.operacionCreditoId,
      coberturaSelected: state.renovacionPolizaReducer.coberturaSelected,
      coberturasOptions: state.renovacionPolizaReducer.coberturasOptions,
      coberturasFilter: state.renovacionPolizaReducer.coberturasFilter,
    };
  });

const DatosPolizaCard = ({ isLoading, editable, displayError, onBlur }) => {
  const dispatch = useDispatch();

  const {
    numeroPoliza,
    premioTotal,
    sumaAsegurada,
    cantidadCuotas,
    vigencia,
    vigenciaDesde,
    vigenciaHasta,
    negocio,
    compania,
    cuotasSelected,
    renuevaA,
    coberturaSelected,
    coberturasOptions,
    coberturasFilter,
    operacionId,
  } = useReduxSelector();

  let timer = null;
  const handleChangeNumeroPoliza = (nroPoliza) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroPoliza(nroPoliza));
    }, 200);
  };

  const handleChangePremioTotal = (premio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setPremioTotal(premio));
    }, 200);
  };

  const handleChangeSumaAsegurada = (sumaAsegurada) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setSumaAsegurada(sumaAsegurada));
    }, 200);
  };

  const handleChangeCantidadCuotas = (cantidadCuotas) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCantidadCuotas(cantidadCuotas));
    }, 200);
  };

  const handleChangeCobertura = (cobertura) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCobertura(cobertura));
    }, 200);
  };

  const handleChangeVigencia = (vigencia) => {
    dispatch(setVigencia(vigencia));
  };

  const handleChangeVigenciaDesde = (vigenciaDesde) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setVigenciaDesde(vigenciaDesde));
    }, 200);

    if (negocio === "Prendarios") {
      for (let i = 1; i <= 6; i++) {
        if (!cuotasSelected[i]) return;
        cuotasSelected[i].fechaVencimiento.value = moment(
          vigenciaDesde.value
        ).add(i, "months");
      }
    }
  };

  const isPrendarios = negocio === "Prendarios";
  return (
    <DetalleCard title={title} IconHeader={SecurityIcon}>
      <ItemCustomCard isLoading={isLoading} label={"Negocio"}>
        <ColorLabelNegocio negocio={negocio} />
      </ItemCustomCard>
      {isPrendarios && (
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"N° Operación"}
          value={operacionId}
        />
      )}
      <ItemInputCard
        readOnly
        isLoading={isLoading}
        label={"Compañía"}
        value={compania}
      />
      <ItemInputCard
        readOnly
        isLoading={isLoading}
        label={"Renueva A"}
        value={`Póliza N° ${renuevaA}`}
      />
      <ItemSelectorCard
        id={"coberturaSeleccionada"}
        label={"Cobertura"}
        isLoading={isLoading}
        value={coberturaSelected.value}
        onChange={handleChangeCobertura}
        options={coberturasFilter}
        valueOptions={coberturasOptions}
        type="selector"
        variant="normal"
      />
      <ItemInputCard
        id={"nroPoliza"}
        isLoading={isLoading}
        label={"Número de Póliza"}
        value={numeroPoliza.value}
        onChange={handleChangeNumeroPoliza}
        onBlur={onBlur}
        focus={numeroPoliza.focus}
      />
      <ItemCurrencyCard
        id={"premioTotal"}
        isLoading={isLoading}
        label={"Premio Total"}
        value={premioTotal.value}
        type="currency"
        onChange={handleChangePremioTotal}
        onBlur={onBlur}
        displayError={displayError}
        focus={premioTotal.focus}
        required
      />
      <ItemCurrencyCard
        id={"sumaAsegurada"}
        isLoading={isLoading}
        label={"Suma Asegurada"}
        value={sumaAsegurada.value}
        onChange={handleChangeSumaAsegurada}
        onBlur={onBlur}
        displayError={displayError}
        focus={sumaAsegurada.focus}
        required
      />
      {!isPrendarios && (
        <ItemSelectorCard
          id={"cantidadCuotas"}
          label={"Cantidad Cuotas"}
          isLoading={isLoading}
          value={cantidadCuotas.value}
          onChange={handleChangeCantidadCuotas}
          options={cuotasFilter}
          valueOptions={cuotasOptions}
          type="selector"
          variant="big"
        />
      )}
      {!isPrendarios && (
        <ItemSelectorCard
          id={"vigencia"}
          isLoading={isLoading}
          label={"Vigencia"}
          value={vigencia.value}
          onChange={handleChangeVigencia}
          options={vigenciasSolicitudEmisionFilter}
          valueOptions={vigenciasSolicitudEmisionOptions}
          type="selector"
          variant="big"
        />
      )}
      <ItemDateCard
        id="vigenciaDesde"
        label="Vigencia Desde"
        isLoading={isLoading}
        value={vigenciaDesde.value}
        onChange={handleChangeVigenciaDesde}
        onBlur={onBlur}
        displayError={displayError}
        focus={vigenciaDesde.focus}
      />
      <ItemDateCard
        id="vigenciaHasta"
        label="Vigencia Hasta"
        isLoading={isLoading}
        value={vigenciaHasta.value}
        readOnly
      />
    </DetalleCard>
  );
};

export default DatosPolizaCard;

/*
<DetalleSolicitudCardItemEditable
                  caption="Número de Póliza"
                  onChange={handleChangeNumeroPoliza}
                  onBlur={handleBlur}
                  value={numeroPolizaSelected.value}
                  id={"nroPoliza"}
                  displayError={submitting}
                  focus={numeroPolizaSelected.focus}
                />
                <DetalleSolicitudCardItemEditable
                  caption="Premio Total"
                  onChange={handleChangePremioTotal}
                  onBlur={handleBlur}
                  type={"currency"}
                  value={premioTotalSelected.value}
                  id={"premioTotal"}
                  displayError={submitting}
                  focus={premioTotalSelected.focus}
                  required
                />
                <DetalleSolicitudCardItemEditable
                  caption="Suma Asegurada"
                  onChange={handleChangeSumaAsegurada}
                  onBlur={handleBlur}
                  type={"currency"}
                  value={sumaAseguradaSelected.value}
                  id={"sumaAsegurada"}
                  displayError={submitting}
                  focus={sumaAseguradaSelected.focus}
                  required
                />
                {detalleSolicitudEmision.negocio !== "Prendarios" && (
                  <DetalleSolicitudCardItemEditable
                    type={"selector"}
                    caption="Cantidad cuotas"
                    onChange={handleChangeCantidadCuotas}
                    onBlur={handleBlur}
                    value={cantidadCuotasSelected.value}
                    id={"cantidadCuotas"}
                    options={cuotasFilter}
                    valueOptions={cuotasOptions}
                    variant="big"
                  />
                )}
                {detalleSolicitudEmision.negocio !== "Prendarios" && (
                  <DetalleSolicitudCardItemEditable
                    type={"selector"}
                    caption="Vigencia"
                    onChange={handleChangeVigencia}
                    onBlur={handleBlur}
                    value={vigenciaSelected.value}
                    id={"vigencia"}
                    options={vigenciasSolicitudEmisionFilter}
                    valueOptions={vigenciasSolicitudEmisionOptions}
                    variant="big"
                  />
                )}
                <DetalleSolicitudCardItemEditable
                  caption="Vigencia Desde"
                  type="date"
                  value={vigenciaDesdeSelected.value}
                  onChange={handleChangeVigenciaDesde}
                  onBlur={handleBlur}
                  id={"vigenciaDesde"}
                  displayError={submitting}
                  focus={vigenciaDesdeSelected.focus}
                />
                <DetalleSolicitudCardItem
                  caption="Vigencia Hasta"
                  value={moment(vigenciaHastaSelected.value).format(
                    "DD/MM/YYYY"
                  )}
                />*/
