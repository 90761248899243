import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDetalleCotizacion } from "./actions";

const MAX_REQUESTS = 20;

const useReduxSelector = () =>
  useSelector((state) => ({
    polizasRequestCount: state.multicotizadorReducer.polizasRequestCount,
    cotizacionId: state.multicotizadorReducer.cotizacionId,
  }));

const useGetCotizaciones = () => {
  const dispatch = useDispatch();

  const { polizasRequestCount, cotizacionId } = useReduxSelector();

  const isPending = polizasRequestCount <= MAX_REQUESTS;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isPending && cotizacionId) {
        dispatch(getDetalleCotizacion(cotizacionId));
      }
    }, polizasRequestCount * 1000);
    return () => clearTimeout(timer);
  }, [dispatch, isPending, cotizacionId, polizasRequestCount]);

  return isPending;
};

export default useGetCotizaciones;
