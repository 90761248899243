import "date-fns";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//usar forwardRef ?
const DatePicker = ({
  fullWidth,
  onChange,
  onKeyDown,
  value,
  minDate,
  maxDate,
  className,
  label,
  id,
  error,
  onBlur,
  reference,
}) => {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = useState(value);
  //const [showError, setShowError] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) onChange(date);
  };

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <MuiPickersUtilsProvider style={{ width: "100%" }} utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth={fullWidth}
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id={id}
        label={label}
        value={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        invalidDateMessage={"Formato de fecha incorrecto"}
        onKeyDown={onKeyDown}
        //onError={handleError}
        error={error}
        //inputRef={reference}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
