export const homePath = "HOME";
export const coberturasPath = "COBERTURAS";
export const esquemasPath = "ESQUEMAS";
export const multicotizacionesPath = "COTIZACIONES";
export const salirPath = "SALIR";
export const usuariosPath = "USUARIOS";
export const solicitudesEmisionPath = "SOLICITUDES PENDIENTES";
export const solicitudesPendientesSucursalPath = "SOLICITUDES INCOMPLETAS";
export const solicitudesEmisionBackendPath = "SOLICITUDES EMISION";
export const solicitudesHistoricasPath = "SOLICITUDES HISTORICAS";
export const novedadesPath = "NOVEDADES";
export const polizasPath = "POLIZAS";
export const endososPath = "ENDOSOS";
export const polizasNuevoPath = "POLIZAS_NUEVO";
export const multicotizadorPath = "MULTICOTIZADOR";
export const configuracionPath = "CONFIGURACION";
export const estadisticasPath = "ESTADISTICAS";
export const reportesPath = "REPORTES";
export const polizasMultiramaPath = "POLIZAS MULTIRAMA";
export const altaPolizaMultiramaPath = "POLIZAS MULTIRAMA DETALLE";
export const siniestrosPath = "SINIESTROS";
export const cargaSiniestroPath = "CARGA SINIESTRO";
export const datosPagoPath = "DATOS PAGO";
export const cargaPublicaPath = "CARGA PUBLICA";

const paths = {
  [homePath]: "/",
  [coberturasPath]: "/coberturas",
  [esquemasPath]: "/esquemascotizaciones",
  [multicotizacionesPath]: "/riesgos/automotores/multicotizaciones",
  [usuariosPath]: "/usuarios",
  [solicitudesEmisionPath]: "/riesgos/automotores/solicitudesPendientes",
  [solicitudesEmisionBackendPath]: "/riesgos/automotores/solicitudesEmision",
  [solicitudesPendientesSucursalPath]:
    "/riesgos/automotores/solicitudesPendientes",
  [solicitudesHistoricasPath]: "/riesgos/automotores/solicitudesHistoricas",
  [novedadesPath]: "/novedades",
  [polizasPath]: "/riesgos/automotores/polizas",
  [endososPath]: "/riesgos/automotores/endosos",
  [polizasNuevoPath]: "/riesgos/automotores/polizas/nuevo",
  [multicotizadorPath]: "/riesgos/automotores/multicotizador/:position?",
  [configuracionPath]: "/configuracion",
  [reportesPath]: "/reportes",
  [estadisticasPath]: "/estadisticas",
  [datosPagoPath]: "/informacionpago",
  [altaPolizaMultiramaPath]: "/polizasMultirama/altaPolizaMultirama",
  [polizasMultiramaPath]: "/polizasMultirama",
  [siniestrosPath]: "/siniestros",
  [cargaSiniestroPath]: "/siniestros/cargaSiniestro",
  [salirPath]: "/signout",
  [cargaPublicaPath]: "/denuncia-tu-siniestro",
};

export { paths };
