import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import PeopleIcon from "@material-ui/icons/People";
import Delete from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Button } from "@material-ui/core";
import ItemInputCard from "~business/Cards/ItemInputCard";
import MultipleItemSelectorCard from "~business/Cards/MultipleItemSelectorCard";
import { siniestrosCompanias } from "~constants/companiasSiniestros";

import {
  setTerceros,
  setSubmitting,
} from "../actions";

const useStyles = makeStyles((theme) => ({
  btnDelete: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "8px",
  },
  btnAdd: {
    marginTop: "5px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      terceros: state.cargaSiniestroReducer.terceros,
      tipo: state.cargaSiniestroReducer.tipoSelected.value,
    };
  });

const title = "Datos de Terceros";

const DatosTercerosCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const {
    terceros,
    tipo,
  } = useReduxSelector();

  const CHOQUE = "Choque";

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  const addTercero = () => {
    const nuevosTerceros = [...terceros, {
      nombre: { value: '', focus: false, valid: true },
      apellido: { value: '', focus: false, valid: true },
      numeroDocumento: { value: '', focus: false, valid: true },
      compania: { value: '', focus: false, valid: true },
      numeroPoliza: { value: '', focus: false, valid: true },
      telefono: { value: '', focus: false, valid: true },
      dominio: { value: '', focus: false, valid: true },
      marca: { value: '', focus: false, valid: true },
      modelo: { value: '', focus: false, valid: true },
      danios: { value: '', focus: false, valid: true },
    }];

    dispatch(setTerceros(nuevosTerceros));
  };

  const deleteTercero = (index) => {
    const nuevosTerceros = terceros.filter((_, i) => i !== index);

    dispatch(setTerceros(nuevosTerceros));
  };

  const manejarCambio = (index, value, name) => {
    //const valor = { ...value, "valid": name !== "dominio" || value.value.trim() !== "" ? true : false };
    const nuevosTerceros = terceros.map((tercero, i) =>
      i === index ? { ...tercero, [name]: value } : tercero
    );
    dispatch(setTerceros(nuevosTerceros));
  };

  return (
    <>
      {tipo === CHOQUE && (
        <DetalleCard title={title} IconHeader={PeopleIcon}>
          {terceros.map((tercero, index) => (
            <div key={index}>
              <div className={classes.btnDelete}>
                <Tooltip title={"Eliminar tercero"}>
                  <span>
                    <Button startIcon={<Delete />} variant="contained" color="primary" onClick={() => deleteTercero(index)}>{"Eliminar"}</Button>
                  </span>
                </Tooltip>
              </div>
              <ItemInputCard
                label={"Nombre"}
                value={tercero.nombre.value}
                onChange={(e) => manejarCambio(index, e, "nombre")}
                onBlur={handleBlur}
                focus={tercero.nombre.focus}
              />
              <ItemInputCard
                label={"Apellido"}
                value={tercero.apellido.value}
                onChange={(e) => manejarCambio(index, e, "apellido")}
                onBlur={handleBlur}
                focus={tercero.apellido.focus}
              />
              <ItemInputCard
                label={"DNI"}
                value={tercero.numeroDocumento.value}
                onChange={(e) => manejarCambio(index, e, "numeroDocumento")}
                onBlur={handleBlur}
                focus={tercero.numeroDocumento.focus}
              />
              <MultipleItemSelectorCard
                label={"Compañía"}
                value={tercero.compania.value}
                onChange={(e) => manejarCambio(index, e, "compania")}
                onBlur={handleBlur}
                focus={tercero.compania.focus}
                tooltipContent={"Seleccionar Compañía"}
                items={siniestrosCompanias}
                placeholder={"Seleccionar"}
              />
              <ItemInputCard
                label={"Número de Póliza"}
                value={tercero.numeroPoliza.value}
                onChange={(e) => manejarCambio(index, e, "numeroPoliza")}
                onBlur={handleBlur}
                focus={tercero.numeroPoliza.focus}
              />
              <ItemInputCard
                label={"Teléfono"}
                value={tercero.telefono.value}
                onChange={(e) => manejarCambio(index, e, "telefono")}
                onBlur={handleBlur}
                focus={tercero.telefono.focus}
              />
              <ItemInputCard
                label={"Dominio"}
                value={tercero.dominio.value}
                onChange={(e) => manejarCambio(index, e, "dominio")}
                onBlur={handleBlur}
                focus={tercero.dominio.focus}
                required
              />
              <ItemInputCard
                label={"Marca"}
                value={tercero.marca.value}
                onChange={(e) => manejarCambio(index, e, "marca")}
                onBlur={handleBlur}
                focus={tercero.marca.focus}
              />
              <ItemInputCard
                label={"Modelo"}
                value={tercero.modelo.value}
                onChange={(e) => manejarCambio(index, e, "modelo")}
                onBlur={handleBlur}
                focus={tercero.modelo.focus}
              />
              <ItemInputCard
                label={"Daños al Vehículo"}
                value={tercero.danios.value}
                onChange={(e) => manejarCambio(index, e, "danios")}
                onBlur={handleBlur}
                focus={tercero.danios.focus}
                multiline
              />
            </div>
          ))}
          <Button variant="contained" className={classes.btnAdd} color="primary" onClick={addTercero}>Agregar Tercero</Button>
        </DetalleCard >
      )}
    </>
  );
};

export default DatosTercerosCard;
