import React from "react";
import {
  negociosSegurosOptions,
  PRENDARIOS,
  MERCADO_ABIERTO,
} from "~constants/negocios";
import ColorLabel from "../colorLabel/ColorLabel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dash: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

const ColorLabelNegocio = ({ negocio, abbreviated }) => {
  const classes = useStyles();

  const isPrendarios = negocio === PRENDARIOS;

  const isMercadoAbierto = negocio === MERCADO_ABIERTO;

  return (
    <>
      {isPrendarios && (
        <ColorLabel
          variant={"info"}
          text={abbreviated ? "PR" : negociosSegurosOptions[PRENDARIOS]}
        />
      )}
      {isMercadoAbierto && (
        <ColorLabel
          variant={"otros"}
          text={abbreviated ? "MA" : negociosSegurosOptions[MERCADO_ABIERTO]}
        />
      )}
      {!isPrendarios && !isMercadoAbierto && (
        <ColorLabel
          text={
            abbreviated ? (
              <span className={classes.dash}>&mdash;</span>
            ) : (
              "Indeterminado"
            )
          }
        />
      )}
    </>
  );
};

export default ColorLabelNegocio;
