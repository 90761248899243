import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReceiptIcon from "@material-ui/icons/Receipt";

const title = "Tiempo de respuesta cotizaciones (MM:SS)";

const useStyles = makeStyles((theme) => ({
    cardBody: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "2%",
        borderBottom: "1px solid #8B8B8B",
    },
    index: {
        paddingTop: "5%",
    },
    cardTitle: {
        fontFamily: "Nunito",
        fontSize: "1.3em",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.36,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6240a5",
    },
    caption: {
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.38,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#8B8B8B",
    },
    value: {
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.38,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#424242",
    },
    row1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "baseline",
    }
}));
const useReduxSelector = () =>
    useSelector((state) => ({
        estadisticasInitialStage:
            state.estadisticasReducer.estadisticasInitialStage,

        estadisticas: state.estadisticasReducer.estadisticas,
        totalRegistros: state.cotizacionesReducer.totalRegistros,
        isLoading: state.cotizacionesReducer.isLoading,
    }));


const DatosTiempoCard = ({ isLoading }) => {

    const { estadisticas } = useReduxSelector();
    const classes = useStyles();

    const hhmmss = (secs) => {
        return new Date(secs * 1000).toISOString().substr(14, 5);
    }

    const camelCaseSplit = (name) => {
        // for design reasons convert string "camelCase" to -> "camel Case" and replace by " .."
        return (name.length < 8) ? (name) : (name.replace(/([a-z0-9])([A-Z])/g, '$1 $2'))
    }

    return (
        <>
            <DetalleCard isLoading={isLoading} title={title} IconHeader={ReceiptIcon}>
                <>
                    {
                        estadisticas && (
                            estadisticas.map((stats, index) => {
                                return (
                                    < div className={classes.cardBody}>
                                        <div className={classes.index}>
                                            <Typography className={classes.cardTitle}>{`${index + 1}°`}</Typography>
                                        </div>
                                        <div
                                            className={classes.row1}
                                        >
                                            <Typography className={classes.caption}>
                                                {"Compañia de Seguro"}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {camelCaseSplit(stats.id)}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.row1}
                                        >
                                            <Typography className={classes.caption}>
                                                {"Mínimo"}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {hhmmss(stats.min)}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.row1}
                                        >
                                            <Typography className={classes.caption}>
                                                {"Máximo"}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {hhmmss(stats.max)}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.row1}
                                        >
                                            <Typography className={classes.caption}>
                                                {"Promedio"}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {hhmmss(stats.avg)}
                                            </Typography>
                                        </div>

                                        <div
                                            className={classes.buttonLink}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "#f77221",
                                                paddingLeft: 4,
                                                cursor: "pointer",
                                            }}
                                        >
                                        </div>
                                    </div>
                                )
                            })
                        )
                    }
                </>
            </DetalleCard>
        </>
    );
};

export default DatosTiempoCard;
