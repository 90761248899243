import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";

const useCheckboxButtonStyles = makeStyles(theme => ({
  button: props => ({
    background: "#fff",
    borderRadius: 8,
    border: 0,
    color: "#000",
    display: "flex",
    marginBottom: props.marginBottom,
    boxShadow: "0 6px 6px 0 #a28ac933",
    padding: 0,
    textTransform: "none",
    "&:disabled": {
      backgroundColor: "#616161",
      color: "#C4C4C4"
    },
    "&:focus": {
      backgroundColor: "#fff"
    },
    "&:hover": {
      backgroundColor: "#EBEBEB",
      [theme.breakpoints.mobile]: {
        backgroundColor: "#fff"
      }
    },
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      border: "3px solid transparent"
    }
  }),
  buttonChecked: {
    "&::before": {
      borderRadius: 8,
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      border: `3px solid ${theme.palette.primary.main} !important`
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: 3,
      right: 3,
      borderTop: `20px solid ${theme.palette.primary.main}`,
      borderLeft: `20px solid transparent`,
      width: 0
    }
  },
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end"
  }
}));

const CheckboxButton = ({
  onClick,
  checked,
  marginBottom,
  disabled,
  children
}) => {
  const classes = useCheckboxButtonStyles({
    marginBottom
  });

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      size="large"
      color="primary"
      fullWidth
      className={
        checked
          ? [classes.button, classes.buttonChecked].join(" ")
          : classes.button
      }
      classes={{
        label: classes.buttonLabel
      }}
    >
      {children}
    </Button>
  );
};

const useCheckboxLargeLabelStyles = makeStyles(theme => ({
  root: props => ({
    borderRadius: 8,
    height: 154,
    backgroundImage: `url(${props.image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    padding: 21,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flex: 1,
    width: "100%"
  })
}));

const CheckboxLargeLabel = ({ image, label, topLabel, subLabel }) => {
  const classes = useCheckboxLargeLabelStyles({
    image
  });
  const theme = useTheme();

  return (
    <div className={classes.root}>
      {topLabel && (
        <ColorLabel variant={"otros"} text={topLabel} marginBottom={35} />
      )}
      <Typography
        variant="caption"
        noWrap
        color="primary"
        style={{
          fontWeight: "bold",
          lineHeight: "1.5",
          fontSize: theme.typography.pxToRem(20),
          letterSpacing: 0.2,
          textAlign: "left"
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: 600,
          lineHeight: "1.5",
          fontSize: theme.typography.pxToRem(12),
          letterSpacing: 0.2,
          color: "#424242",
          textAlign: "left"
        }}
      >
        {subLabel}
      </Typography>
    </div>
  );
};

export { CheckboxButton, CheckboxLargeLabel };
