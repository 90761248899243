import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { setTake, setPage } from "../Filtros/actions";
import {
  multicotizadorPath,
  paths,
  multicotizacionesPath,
} from "~constants/referenceNames";
import TableLoading from "~styled/tableLoading/TableLoading";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Filtros from "../Filtros/Filtros";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import { recotizar } from "../../../multicotizador/actions";
import { PRENDARIOS } from "~constants/negocios";
import {
  TableBackofficeCell,
  TableBackoffice,
} from "~styled/tableBackoffice/TableBackoffice";
import { stages } from "../../../multicotizador/useCurrentStep";
import {
  calculateDate,
  minDate,
  getLastDayOfThisMonth,
} from "~libs/dateCalculator";
import { estadosMulticotizacionOptions } from "~constants/multicotizacionEstados";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    //maxHeight: "65vh",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => ({
    multicotizacionInitialStage:
      state.multicotizadorReducer.multicotizacionInitialStage,

    take: state.filtrosCotizacionesReducer.take,
    page: state.filtrosCotizacionesReducer.page,

    cotizaciones: state.cotizacionesReducer.cotizaciones,
    totalRegistros: state.cotizacionesReducer.totalRegistros,
    isLoading: state.cotizacionesReducer.isLoading,
  }));

const titles = [
  "Negocio",
  "Ingresó",
  "Válida Hasta",
  "Origen",
  "Estado",
  "Cotizó",
  "E-Mail",
  "Teléfono",
  "Vehículo",
  "Dominio",
  "C.P.",
  "Acciones",
];

const COTIZACION_VIGENCIA = 14;

const ListadoCotizaciones = () => {
  const {
    page,
    take,
    cotizaciones,
    totalRegistros,
    isLoading,
    multicotizacionInitialStage,
  } = useReduxSelector();
  const dispatch = useDispatch();
  const history = useHistory();
  const empty = totalRegistros === 0;

  const classes = useStyles();

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const handleRecotizar = (id) => {
    dispatch(recotizar(id));
  };

  useEffect(() => {
    if (multicotizacionInitialStage === stages.recotizar)
      history.push(paths[multicotizadorPath]);
  }, [history, multicotizacionInitialStage]);

  const hasToRecotizar = (cotizacion) => {
    const precioVigente =
      new Date() <
        calculateDate(new Date(cotizacion.fecha), COTIZACION_VIGENCIA) &&
      new Date().getMonth() === new Date(cotizacion.fecha).getMonth();

    return cotizacion.negocio !== PRENDARIOS && !precioVigente;
  };

  const getHelpTextContinueSolicitudDenied = (cotizacion) => {
    const precioVigente =
      new Date() <
        calculateDate(new Date(cotizacion.fecha), COTIZACION_VIGENCIA) &&
      new Date().getMonth() === new Date(cotizacion.fecha).getMonth();

    if (precioVigente) return "Ir a Continuar Cotización";

    return "Ir a Recotizar";
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && (
            <TableBackoffice titles={titles}>
              {!empty &&
                cotizaciones.map((cotizacion) => (
                  <TableRow
                    key={"table-cotizaciones-" + cotizacion.id}
                    style={{ textDecoration: "none" }}
                  >
                    <TableBackofficeCell type="custom">
                      <ColorLabelNegocio
                        negocio={cotizacion.negocio}
                        abbreviated
                      />
                    </TableBackofficeCell>

                    <TableBackofficeCell value={cotizacion.fecha} type="date" />
                    <TableBackofficeCell
                      value={minDate([
                        getLastDayOfThisMonth(new Date(cotizacion.fecha)),
                        calculateDate(
                          new Date(cotizacion.fecha),
                          COTIZACION_VIGENCIA
                        ),
                      ])}
                      format="DD/MM/YYYY"
                      type="date"
                    />
                    <TableBackofficeCell
                      value={cotizacion.origen}
                      type="text"
                    />
                    <TableBackofficeCell
                      value={estadosMulticotizacionOptions[cotizacion.estado]}
                      type="text"
                    />
                    <TableBackofficeCell
                      value={cotizacion.usuarioNombre}
                      type="text"
                    />
                    <TableBackofficeCell
                      value={cotizacion.usuarioEmail}
                      type="text"
                    />
                    <TableBackofficeCell
                      value={
                        cotizacion.usuarioTelefono ? (
                          cotizacion.usuarioTelefono
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )
                      }
                      type="text"
                    />
                    <TableBackofficeCell
                      value={`(${cotizacion.anio}) ${cotizacion.marca} ${cotizacion.modelo}`}
                      type="text"
                    />
                    <TableBackofficeCell
                      value={
                        cotizacion.dominio ? (
                          cotizacion.dominio
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )
                      }
                      type="text"
                    />
                    <TableBackofficeCell
                      value={cotizacion.codigoPostal}
                      type="text"
                    />
                    <TableBackofficeCell type="custom" centered>
                      <div className={classes.actionButtons}>
                        {cotizacion.negocio === PRENDARIOS && (
                          <ActionButtonTable
                            component={"link"}
                            Icon={InfoOutlinedIcon}
                            title={"Ver Detalle de Cotización"}
                            to={`${paths[multicotizacionesPath]}/${cotizacion.id}`}
                          />
                        )}
                        {cotizacion.negocio !== PRENDARIOS && (
                          <ActionButtonTable
                            component={"button"}
                            Icon={ArrowForwardIcon}
                            title={getHelpTextContinueSolicitudDenied(
                              cotizacion
                            )}
                            color={
                              "#663ea8"
                              //hasToRecotizar(cotizacion) ? "#663ea8" : "#4aba7c"
                            }
                            onClick={() => {
                              if (hasToRecotizar(cotizacion))
                                handleRecotizar(cotizacion.id);
                              else
                                history.push(
                                  `${paths[multicotizacionesPath]}/${cotizacion.id}`
                                );
                            }}
                          />
                        )}
                      </div>
                    </TableBackofficeCell>
                  </TableRow>
                ))}
            </TableBackoffice>
          )}
          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinCotizaciones}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                width: "100%",
              }}
            >
              {!isLoading &&
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              }
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

export default ListadoCotizaciones;

const useStylesActionButton = makeStyles((theme) => ({
  buttonLink: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  button: {
    //color: "#663ea8",
    padding: 0,
  },
}));

//TENER EN CUENTA PARA LLEVARLO A BUSINESS
const ActionButtonTable = ({
  title,
  to,
  Icon,
  disabled,
  onClick,
  component,
  color,
}) => {
  const classes = useStylesActionButton();
  return (
    <>
      {component === "link" && (
        <Tooltip title={title}>
          <IconButton
            className={classes.buttonLink}
            style={{ color: color }}
            component={Link}
            to={to}
          >
            <Icon />
          </IconButton>
        </Tooltip>
      )}
      {component === "button" && (
        <Tooltip title={title}>
          <div style={{ cursor: "pointer" }}>
            <IconButton
              className={classes.button}
              disabled={disabled}
              onClick={onClick}
              style={{ color: color }}
            >
              <Icon />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </>
  );
};

/*
const getEstado = (cotizacion) => {
  const precioVigente =
    new Date() <
      calculateDate(new Date(cotizacion.fecha), COTIZACION_VIGENCIA) &&
    new Date().getMonth() === new Date(cotizacion.fecha).getMonth();

  if (cotizacion.solicitudEmisionId) return "Completa";
  if (cotizacion.cotizacionARecotizar) return "Recotizada";
  if (precioVigente) return "Vigente";
  return "Vencida";
};
*/
