import React from "react";
import pxToRem from "~libs/pxToRem";
import MuiButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  group: props => ({
    background: "#fff",
    borderRadius: 14,
    border: 0,
    color: "#000",
    height: 112,
    display: "flex",
    marginBottom: props.marginBottom,
    boxShadow: "0 6px 6px 0 #a28ac933",
    textTransform: "none"
  }),
  buttonBorder: {
    borderRadius: 14,
    justifyContent: "flex-start",
    paddingLeft: 20,
    "&:not(:last-child)": {
      borderRight: 0
    },
    "&:not(:first-child)::before": {
      content: "' '",
      borderLeft: "1px solid #eee",
      position: "absolute",
      height: "70%",
      left: 0,
      top: "15%"
    }
  }
}));

const ButtonGroup = ({ children, marginBottom }) => {
  const classes = useStyles({ marginBottom });

  return (
    <MuiButtonGroup
      variant="text"
      fullWidth
      className={classes.group}
      classes={{ groupedText: classes.buttonBorder }}
    >
      {children}
    </MuiButtonGroup>
  );
};

const ButtonGroupLabel = ({ label, subLabel, icon }) => {
  //const theme = useTheme();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", textAlign: "left" }}
    >
      <img
        src={icon}
        alt=""
        style={{
          width: 31,
          height: 31,
          marginBottom: 6
        }}
      />
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: "bold",
          lineHeight: "1.5",
          fontSize: pxToRem(18),
          letterSpacing: pxToRem(0.2),
          textAlign: "left",
          textTransform: "none"
          //color: theme.palette.primary.light
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: 600,
          lineHeight: "1",
          fontSize: pxToRem(19),
          letterSpacing: pxToRem(0.2),
          textAlign: "left",
          textTransform: "none",
          color: "#616161"
        }}
      >
        {subLabel}
      </Typography>
    </div>
  );
};

export { ButtonGroup, ButtonGroupLabel, Button };
