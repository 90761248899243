import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";

import PersonIcon from "@material-ui/icons/Person";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import ItemEmailCard from "~business/Cards/ItemEmailCard";

const title = "Originador";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle =
      state.detalleSolicitudEmisionReducer.detalleSolicitudEmision;
    const datosOriginador = detalle.datosOriginador;
    return {
      nombreCompleto: datosOriginador.nombre,
      email: datosOriginador.email,
      telefono: datosOriginador.telefono,
      oficina: datosOriginador.nombreOficina,
      agencia: datosOriginador.nombreAgencia,
      fechaCreacion: detalle.fechaCreacion,
      fechaCotizacion: detalle.fechaCotizacion,
      origen: datosOriginador.origen,
      negocio: detalle.negocio,
      operacionId: detalle.operacionId,
    };
  });

const OriginadorCard = ({ isLoading }) => {
  const {
    nombreCompleto,
    email,
    telefono,
    oficina,
    agencia,
    fechaCreacion,
    fechaCotizacion,
    origen,
    negocio,
    operacionId,
  } = useReduxSelector();

  return (
    <DetalleCard title={title} IconHeader={PersonIcon}>
      {negocio && negocio === "MercadoAbierto" && (
        <>
          <ItemInputCard
            isLoading={isLoading}
            label={"Nombre Completo"}
            value={nombreCompleto}
            readOnly
          />
          <ItemEmailCard
            isLoading={isLoading}
            label={"Email"}
            value={email}
            readOnly
          />
          <ItemInputCard
            isLoading={isLoading}
            label={"Teléfono"}
            value={telefono}
            readOnly
          />
        </>
      )}
      {negocio && negocio === "Prendarios" && (
        <ItemInputCard
          isLoading={isLoading}
          label={"Operación"}
          value={operacionId}
          readOnly
        />
      )}

      <ItemInputCard
        isLoading={isLoading}
        label={"Oficina"}
        value={oficina}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Canal Originador"}
        value={agencia}
        readOnly
      />
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha Creación"}
        value={fechaCreacion}
        format="DD/MM/YYYY HH:mm"
        readOnly
      />
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha de Cotización"}
        value={fechaCotizacion}
        type="date"
        format="DD/MM/YYYY HH:mm"
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Origen"}
        value={origen}
        readOnly
      />
    </DetalleCard>
  );
};

export default OriginadorCard;
