import React, { useState } from "react";
import { es } from 'react-date-range/dist/locale/index';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from "react-date-range";

const DateSelector = ({ startDay, endDay, onChange, maxDate }) => {
  const [ranges, setRanges] = useState([{
    startDate: startDay ? startDay : new Date(),
    endDate: endDay ? endDay : new Date(),
    key: 'selection'
  }]);

  const handleChange = (item) => {
    setRanges([item.selection]);
    if(onChange) onChange(item.selection.startDate, item.selection.endDate);
  }

  return (
    <DateRange
      showDateDisplay={false}
      ranges={ranges}
      onChange={handleChange}
      moveRangeOnFirstSelection={false}
      locale={es}
      maxDate={maxDate}
    />
  );
};
export default DateSelector;
