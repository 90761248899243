import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ItemInputCard from "~business/Cards/ItemInputCard";

const moment = require("moment");

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    return {
      ...currentSiniestro,
    };
  });

const title = "Fecha y Lugar";

const FechaLugarCard = ({ isLoading }) => {
  const {
    provincia,
    localidad,
    codigoPostal,
    calle,
    altura,
    fechaSiniestro,
    hora,
  } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={ScheduleIcon}>
        <ItemInputCard
          id={"fechaLugar"}
          isLoading={isLoading}
          label={"Fecha y Horario"}
          value={`${moment(fechaSiniestro).format("DD/MM/YYYY")} - ${hora}`}
          readOnly
        />
        <ItemInputCard
          id={"ubicacion"}
          isLoading={isLoading}
          label={"Ubicación"}
          value={`${calle} ${altura}, ${localidad}, ${provincia}`}
          readOnly
        />
        <ItemInputCard
          id={"codigoPostal"}
          isLoading={isLoading}
          label={"Código Postal"}
          value={codigoPostal}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default FechaLugarCard;
