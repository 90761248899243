import { useEffect } from "react";
import { fetchIdentidad } from "./actions";
import { useDispatch } from "react-redux";

const useGetIdentidad = ({ dniSelected, sexoSelected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (dniSelected && sexoSelected.selected)
      dispatch(fetchIdentidad(dniSelected, sexoSelected.value));
  }, [dispatch, dniSelected, sexoSelected]);
};

export default useGetIdentidad;
