import {
  buildRequest,
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import {
  PostMulticotizacionRequest,
  PostMulticotizacionResponse,
  PutMulticotizacionRequest,
  PostRecotizacionResponse,
  GetMulticotizacionResponse,
  PostSolicitudEmisionRequest,
  PostSolicitudEmisionResponse,
} from "./types";

const localhost = false;

const multicotizador = () => {
  return {
    postCotizacion: (
      data: PostMulticotizacionRequest
    ): Promise<PostMulticotizacionResponse> => {
      let request = buildRequestWithAuth("POST", data, headersBackendSeguros);

      let url = `${environment.segurosUrl}/riesgos/automotores/multicotizaciones`;
      if (localhost)
        url = `${environment.localhostUrl}/riesgos/automotores/multicotizaciones`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    putCotizacion: (
      idMulticotizacion: string,
      data: PutMulticotizacionRequest
    ): Promise<Response> => {
      let request = buildRequest("PUT", data, headersBackendSeguros);

      let url = `${environment.segurosUrl}/riesgos/automotores/multicotizaciones/${idMulticotizacion}`;
      if (localhost)
        url = `${environment.localhostUrl}/riesgos/automotores/multicotizaciones${idMulticotizacion}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postRecotizacion: (
      idMulticotizacion: string
    ): Promise<PostRecotizacionResponse> => {
      let request = buildRequestWithAuth("POST", null, headersBackendSeguros);

      let url = `${environment.segurosUrl}/riesgos/automotores/multicotizaciones/${idMulticotizacion}/recotizaciones`;
      if (localhost)
        url = `${environment.localhostUrl}/riesgos/automotores/multicotizaciones/${idMulticotizacion}/recotizaciones`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getPolizas: (
      idMulticotizacion: string
    ): Promise<GetMulticotizacionResponse> => {
      let request = buildRequest("GET", null, headersBackendSeguros);

      let url = `${environment.segurosUrl}/riesgos/automotores/multicotizaciones/${idMulticotizacion}`;
      if (localhost)
        url = `${environment.localhostUrl}/riesgos/automotores/multicotizaciones/${idMulticotizacion}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postSolicitudEmision: (
      data: PostSolicitudEmisionRequest
    ): Promise<PostSolicitudEmisionResponse> => {
      let request = buildRequestWithAuth("POST", data, headersBackendSeguros);

      return fetch(
        `${environment.segurosUrl}/riesgos/automotores/solicitudesEmision`,
        request
      )
        .then(errorUnlessOk)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { multicotizador };
