import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearchCriteria,
  selectFechaDesde,
  selectFechaHasta,
  selectEstado,
} from "./actions";
import { setPage, setTake } from "./actions";
import { calculateDate } from "~libs/dateCalculator";
import Searcher from "~styled/searcher/searcher";
import { Typography } from "@material-ui/core";
import Pagination from "~styled/pagination/Pagination";
import DateRangePicker from "~styled/dateRangePicker/DateRangePicker";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import {
  estadosSolicitudEmisionFilter,
  estadosSolicitudEmisionOptions,
} from "./estados";
import Selector from "~styled/selector/Selector";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: "1%",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    minWidth: 250,
  },
  horizontalSpace: { paddingRight: "10px", minWidth: 250 },
  horizontalSpaceSearcher: { paddingRight: "10px", minWidth: 250 },
  textItemContainer: { display: "flex", flexDirection: "column" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.solicitudesPendientesSucursalReducer.isLoading,
    totalRegistros: state.solicitudesPendientesSucursalReducer.totalRegistros,

    take: state.solicitudesPendientesSucursalFiltrosReducer.take,
    page: state.solicitudesPendientesSucursalFiltrosReducer.page,
    fechaDesde: state.solicitudesPendientesSucursalFiltrosReducer.fechaDesde,
    fechaHasta: state.solicitudesPendientesSucursalFiltrosReducer.fechaHasta,
    searchCriteria:
      state.solicitudesPendientesSucursalFiltrosReducer.searchCriteria,
    estado: state.solicitudesPendientesSucursalFiltrosReducer.estado,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    isLoading,
    take,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    page,
    estado,
    totalRegistros,
  } = useReduxSelector();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangeInterval = (value) => {
    dispatch(setPage(0));
    dispatch(selectFechaDesde(calculateDate(new Date(), value)));
    dispatch(selectFechaHasta(new Date()));
  };

  const handleChangeEstado = (value) => {
    dispatch(setPage(0));
    dispatch(selectEstado(value));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <>
        <div className={classes.filters}>
          <div className={classes.textItemContainer}>
            <Typography variant="h3" className={classes.label}>
              BUSCAR
            </Typography>
            <div className={classes.horizontalSpaceSearcher}>
              <Searcher
                borderSquared
                value={searchCriteria}
                filter={searchCriteria}
                placeholder="Nombre titular, Dominio"
                onChange={(event) => handleChange(event, selectSearchCriteria)}
                onClear={() =>
                  handleChange({ target: { value: "" } }, selectSearchCriteria)
                }
              />
            </div>
          </div>
          <div className={classes.dateSelectorAndTitle}>
            <Typography variant="h3" className={classes.label}>
              FECHA
            </Typography>
            <div className={classes.horizontalSpace}>
              <DateRangePicker
                fechaDesde={fechaDesde}
                fechaHasta={fechaHasta}
                onChangeInterval={handleChangeInterval}
                onChangeStartDate={(value) =>
                  handleChange({ target: { value } }, selectFechaDesde)
                }
                onChangeEndDate={(value) =>
                  handleChange({ target: { value } }, selectFechaHasta)
                }
                onClickDisabled={() => {
                  dispatch(queueMessage(messageLoading));
                }}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className={classes.textItemContainer}>
            <Typography variant="h3" className={classes.label}>
              ESTADO
            </Typography>

            <div className={classes.horizontalSpace}>
              <Selector
                value={estadosSolicitudEmisionOptions[estado.join()]}
                options={estadosSolicitudEmisionFilter}
                onChange={handleChangeEstado}
                onClickDisabled={() => {
                  dispatch(queueMessage(messageLoading));
                }}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </>
      
      {!isLoading &&
        <div className={classes.filtersContainer}>
          <div className={classes.filters}></div>
            <Pagination
              rowsPerPage={take}
              onChangeRowsPerPage={(value) =>
                handleChange({ target: { value } }, setTake)
              }
              page={page}
              onChangePage={handleChangePage}
              totalRegistros={totalRegistros}
              options={paginationOptions}
            />
        </div>
    }      
    </div>
  );
};

export default Filtros;
