import React, { useEffect } from "react";
import { useAuth0 } from "./useAuth";
import LoadingView from "~styled/loadingView/LoadingView";

interface CallbackProps {
  location: {
    hash: string;
  };
}

export const Callback: React.FC<CallbackProps> = (props) => {
  const { handleAuthentication } = useAuth0();
  const { location } = props;

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication();
    } else {
      console.log("Error detected in the URL hash.");
    }
  }, [handleAuthentication, location]);

  return <LoadingView />;
};
