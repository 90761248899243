import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentPoliza, clearCurrentPoliza } from "../actions";
import DetalleCotizacionTable from "~business/cotizaciones/detalleCotizacionTable/DetalleCotizacionTable";
import useGetCotizaciones from "../useGetCotizaciones";

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleCotizacion: state.multicotizadorReducer.detalleCotizacion,
  }));

const ListadoCotizacionesStep = () => {
  const dispatch = useDispatch();
  const { detalleCotizacion } = useReduxSelector();

  useEffect(() => {
    dispatch(clearCurrentPoliza());
    //eslint-disable-next-line
  }, [dispatch]);

  const handleOpen = (poliza) => {
    dispatch(
      setCurrentPoliza({ value: poliza, open: true, contratada: false })
    );
  };

  const isPending = useGetCotizaciones();

  return (
    <DetalleCotizacionTable
      isPending={isPending}
      onClick={handleOpen}
      detalleCotizacion={detalleCotizacion}
    />
  );
};

export default ListadoCotizacionesStep;
