import { createAction, handleActions } from "redux-actions";
import { calculateDate } from "~libs/dateCalculator";

const setPage = createAction("SET_LISTADO_NOVEDADES_PAGE", (page) => ({
  page,
}));

const setTake = createAction("SET_LISTADO_NOVEDADES_ROWS_PER_PAGE", (take) => ({
  take,
}));

const setInitialStateNovedades = createAction(
  "SET_INITIAL_STATE_FILTRO_NOVEDADES"
);

const selectNegocio = createAction(
  "SELECT_NEGOCIO_FILTRO_NOVEDADES",
  (negocio) => ({
    negocio,
  })
);

const selectSearchCriteria = createAction(
  "SELECT_SEARCH_CRITERIA_FILTRO_NOVEDADES",
  (searchCriteria) => ({
    searchCriteria,
  })
);

const selectCompania = createAction(
  "SELECT_COMPANIA_FILTRO_NOVEDADES",
  (compania) => ({
    compania,
  })
);

const selectTipoNovedad = createAction(
  "SELECT_TIPO_NOVEDAD_FILTRO_NOVEDADES",
  (tipoNovedad) => ({
    tipoNovedad,
  })
);

const selectInicioVigenciaDesde = createAction(
  "SELECT_FECHA_INICIO_VIGENCIA_DESDE_FILTRO_NOVEDADES",
  (inicioVigenciaDesde) => ({ inicioVigenciaDesde })
);

const selectInicioVigenciaHasta = createAction(
  "SELECT_FECHA_INICIO_VIGENCIA_HASTA_FILTRO_NOVEDADES",
  (inicioVigenciaHasta) => ({ inicioVigenciaHasta })
);

const selectFechaNovedadDesde = createAction(
  "SELECT_FECHA_NOVEDAD_DESDE_FILTRO_NOVEDADES",
  (fechaNovedadDesde) => ({ fechaNovedadDesde })
);

const selectFechaNovedadHasta = createAction(
  "SELECT_FECHA_NOVEDAD_HASTA_FILTRO_NOVEDADES",
  (fechaNovedadHasta) => ({ fechaNovedadHasta })
);

const filtrosNovedadesInitialState = {
  page: 0,
  take: 10,
  searchCriteria: "",
  inicioVigenciaDesde: "",
  inicioVigenciaHasta: "",
  fechaNovedadDesde: calculateDate(new Date(), -30),
  fechaNovedadHasta: new Date(),
  negocio: "",
  compania: "",
  tipoNovedad: "",
};

const filtrosNovedadesReducer = handleActions(
  {
    [setInitialStateNovedades]: (state, action) => {
      return {
        ...filtrosNovedadesInitialState,
      };
    },
    [setPage]: (state, action) => {
      return {
        ...state,
        page: action.payload.page,
      };
    },
    [setTake]: (state, action) => {
      return {
        ...state,
        page: 0,
        take: action.payload.take,
      };
    },
    [selectFechaNovedadDesde]: (state, action) => {
      return {
        ...state,
        fechaNovedadDesde: action.payload.fechaNovedadDesde,
      };
    },
    [selectFechaNovedadHasta]: (state, action) => {
      return {
        ...state,
        fechaNovedadHasta: action.payload.fechaNovedadHasta,
      };
    },
    [selectInicioVigenciaDesde]: (state, action) => {
      return {
        ...state,
        inicioVigenciaDesde: action.payload.inicioVigenciaDesde,
      };
    },
    [selectInicioVigenciaHasta]: (state, action) => {
      return {
        ...state,
        inicioVigenciaHasta: action.payload.inicioVigenciaHasta,
      };
    },
    [selectCompania]: (state, action) => {
      return {
        ...state,
        compania: action.payload.compania,
      };
    },
    [selectTipoNovedad]: (state, action) => {
      return {
        ...state,
        tipoNovedad: action.payload.tipoNovedad,
      };
    },
    [selectSearchCriteria]: (state, action) => {
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    },
    [selectNegocio]: (state, action) => {
      return {
        ...state,
        negocio: action.payload.negocio,
      };
    },
  },
  filtrosNovedadesInitialState
);

export { filtrosNovedadesReducer };
export {
  setInitialStateNovedades,
  setPage,
  setTake,
  selectSearchCriteria,
  selectFechaNovedadDesde,
  selectFechaNovedadHasta,
  selectInicioVigenciaDesde,
  selectInicioVigenciaHasta,
  selectNegocio,
  selectCompania,
  selectTipoNovedad,
};
