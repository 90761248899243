import React, { useEffect } from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { formasPagoOptions, formasPagoFilter } from "../constants/formasPago";

import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDominioCard from "~business/Cards/ItemDominioCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import {
  vigenciasPolizasMultiramaFilter,
  vigenciasPolizasMultiramaOptions,
} from "../constants/vigencias";
import { cuotasFilter, cuotasOptions } from "../constants/cuotas";
import {
  setNumeroPoliza,
  setDominio,
  setVigencia,
  setVigenciaDesde,
  setPremio,
  setSubmitting,
  setCantidadCuotas,
  setFormaPago,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => {
    const ramaSelected = state.altaPolizaMultiramaReducer.ramaSelected
      ? state.altaPolizaMultiramaReducer.ramaSelected
      : {};

    return {
      numeroPoliza: state.altaPolizaMultiramaReducer.numeroPolizaSelected,
      nombreAsegurado: state.altaPolizaMultiramaReducer.aseguradoNombreSelected,
      apellidoAsegurado:
        state.altaPolizaMultiramaReducer.aseguradoApellidoSelected,
      dni: state.altaPolizaMultiramaReducer.aseguradoNumeroDocumentoSelected,
      dominio: state.altaPolizaMultiramaReducer.dominioSelected,
      premio: state.altaPolizaMultiramaReducer.premioSelected,
      cuotas: state.altaPolizaMultiramaReducer.cuotasSelected,
      vigencia: state.altaPolizaMultiramaReducer.vigenciaSelected,
      vigenciaDesde: state.altaPolizaMultiramaReducer.vigenciaDesdeSelected,
      vigenciaHasta: state.altaPolizaMultiramaReducer.vigenciaHastaSelected,
      formaPago: state.altaPolizaMultiramaReducer.formaPagoSelected,
      aseguradoFromDocumento: state.altaPolizaMultiramaReducer.asegurado,
      ...ramaSelected,
    };
  });

const title = "Datos para la Póliza";

const DatosPolizaCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const {
    numeroPoliza,
    dominio,
    cuotas,
    vigencia,
    vigenciaDesde,
    vigenciaHasta,
    premio,
    formaPago,
    tieneDominio,
  } = useReduxSelector();

  useEffect(() => {
    dispatch(setVigenciaDesde(vigenciaDesde));
  }, [dispatch, vigenciaDesde]);

  let timer = null;

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  const handleChangeNumeroPoliza = (nroPoliza) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroPoliza(nroPoliza));
    }, 200);
  };

  const handleChangeDominio = (dominio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setDominio(dominio));
    }, 200);
  };

  const handleChangePremio = (premio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setPremio(premio));
    }, 200);
  };

  const handleChangeCantidadCuotas = (cantidadCuotas) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCantidadCuotas(cantidadCuotas));
    }, 200);
  };

  const handleChangeFormaPago = (formaPago) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setFormaPago(formaPago));
    }, 200);
  };

  const handleChangeVigencia = (vigencia) => {
    dispatch(setVigencia(vigencia));
  };

  const handleChangeVigenciaDesde = (vigenciaDesde) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setVigenciaDesde(vigenciaDesde));
    }, 200);
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemInputCard
          id={"nroPoliza"}
          isLoading={isLoading}
          label={"Número de Póliza"}
          value={numeroPoliza.value}
          onChange={handleChangeNumeroPoliza}
          onBlur={handleBlur}
          focus={numeroPoliza.focus}
          required
        />

        {tieneDominio && (
          <ItemDominioCard
            id={"dominio"}
            isLoading={isLoading}
            label={"Dominio"}
            value={dominio.value}
            onChange={handleChangeDominio}
            onBlur={handleBlur}
            focus={dominio.focus}
            required
          />
        )}
        <ItemSelectorCard
          id={"cantidadCuotas"}
          label={"Cantidad de Cuotas"}
          isLoading={isLoading}
          value={cuotas.value}
          onChange={handleChangeCantidadCuotas}
          options={cuotasFilter}
          valueOptions={cuotasOptions}
          type="selector"
          variant="big"
        />
        <ItemSelectorCard
          id={"formaPago"}
          label={"Forma de Pago"}
          isLoading={isLoading}
          value={formaPago.value}
          onChange={handleChangeFormaPago}
          options={formasPagoFilter}
          valueOptions={formasPagoOptions}
          type="selector"
          variant="big"
        />
        <ItemSelectorCard
          id={"vigencia"}
          isLoading={isLoading}
          label={"Vigencia"}
          value={vigencia.value}
          onChange={handleChangeVigencia}
          options={vigenciasPolizasMultiramaFilter}
          valueOptions={vigenciasPolizasMultiramaOptions}
          type="selector"
          variant="big"
        />
        <ItemDateCard
          id={"vigenciaDesde"}
          label={"Vigencia Desde"}
          isLoading={isLoading}
          value={vigenciaDesde.value}
          onChange={handleChangeVigenciaDesde}
          onBlur={handleBlur}
        />
        <ItemDateCard
          id="vigenciaHasta"
          label="Vigencia Hasta"
          isLoading={isLoading}
          value={vigenciaHasta.value}
          readOnly
        />
        <ItemCurrencyCard
          id={"premio"}
          isLoading={isLoading}
          label={"Premio Mensual"}
          value={premio.value}
          onChange={handleChangePremio}
          onBlur={handleBlur}
          focus={premio.focus}
          required
        />
      </DetalleCard>
    </>
  );
};

export default DatosPolizaCard;
