import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import configureStore from "./redux/configureStore";
import { Auth0Provider } from "./components/Auth0/AuthContext";
import { Provider } from "react-redux";
import { initAnalytics } from "~libs/amplitudeHelper";

const store = configureStore({});

initAnalytics();

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider>
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);
