const getVehiculosUsos = () => {
  let result = [
    {
      label: "Uso particular",
      value: "Particular"
    },
    {
      label: "Transp. de mercaderias, herramientas y fletes",
      value: "MercaderiaHerramientasFletes"
    },
    {
      label: "Transporte de combustibles",
      value: "Combustibles"
    },
    {
      label: "Transporte de cargas peligrosas",
      value: "CargasPeligrosas"
    },
    {
      label: "Servicios especiales de pasajeros",
      value: "ServiciosEspeciales"
    },
    {
      label: "Taxi o remis",
      value: "TaxiRemis"
    },
    {
      label: "Transporte público",
      value: "TransportePublico"
    }
  ];

  return result;
};

export default getVehiculosUsos;
