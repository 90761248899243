import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { formasPagoOptions } from "~constants/formasPago";
import DetalleSolicitudCardItem from "../DetalleSolicitudCardItem";
import getMarcasTarjetaCredito from "~components/business/marcaTarjetaCreditoSelector/getMarcasTarjetaCredito";
import NumberFormat from "react-number-format";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },

  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },

  dash: { display: "flex", justifyContent: "center" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
    isLoading: state.detalleSolicitudHistoricaReducer.isLoading,
    loadingError: state.detalleSolicitudHistoricaReducer.loadingError,
    errorMessage: state.detalleSolicitudHistoricaReducer.errorMessage,
  }));

const getPeriodoEnMeses = (periodo) => {
  if (periodo === "Anual") return 12;
  if (periodo === "Semestral") return 6;
  if (periodo === "Cuatrimestral") return 4;
  if (periodo === "Trimestral") return 3;

  return 0;
};

const AseguradoCard = () => {
  const classes = useStyles();
  const { detalleSolicitudEmision } = useReduxSelector();
  const [premio, setPremio] = useState(0);
  const [resumenDatosPago, setResumenDatosPago] = useState("");

  useEffect(() => {
    if (!detalleSolicitudEmision) return;

    setPremio(
      getPeriodoEnMeses(detalleSolicitudEmision.datosEmision.periodo) *
        detalleSolicitudEmision.datosEmision.precioInformado
    );

    let resumenDatosPagoTemp = "";

    let marcaTCElement = getMarcasTarjetaCredito().find(
      (element) =>
        element.value ===
        detalleSolicitudEmision.datosEmision.marcaTarjetaCredito
    );

    let ciaTC =
      detalleSolicitudEmision.datosEmision.marcaTarjetaCredito && marcaTCElement
        ? marcaTCElement.label
        : "";

    let ultimosDigitosDatosPago =
      detalleSolicitudEmision.datosEmision.ultimosDigitosDatosPago;

    resumenDatosPagoTemp +=
      detalleSolicitudEmision.datosEmision.formaPago ===
      "DebitoConTarjetaCredito"
        ? `${ciaTC} `
        : "";

    resumenDatosPagoTemp +=
      ultimosDigitosDatosPago !== null ? `*****${ultimosDigitosDatosPago}` : "";

    setResumenDatosPago(resumenDatosPagoTemp);
    //eslint-disable-next-line
  }, [detalleSolicitudEmision]);

  return (
    <div>
      {detalleSolicitudEmision.datosEmision && (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <VerifiedUserIcon className={classes.cardIcon} />
            <Typography className={classes.cardTitle}>
              {"Datos para Emisión"}
            </Typography>
          </div>
          <DetalleSolicitudCardItem
            caption="Compañía"
            value={detalleSolicitudEmision.datosEmision.compania}
          />
          <DetalleSolicitudCardItem
            caption="Cobertura Seleccionada"
            value={detalleSolicitudEmision.datosEmision.coberturaSeleccionada}
          />
          {detalleSolicitudEmision.codigoOrganizador &&
            <DetalleSolicitudCardItem
              caption="Código organizador"
              value={detalleSolicitudEmision.codigoOrganizador}
            />
          }
          {detalleSolicitudEmision.codigoProductor &&
            <DetalleSolicitudCardItem
              caption="Código productor"
              value={detalleSolicitudEmision.codigoProductor}
            />
          }
          {detalleSolicitudEmision.codigoVendedor &&
            <DetalleSolicitudCardItem
              caption="Código vendedor"
              value={detalleSolicitudEmision.codigoVendedor}
            />
          }
          <DetalleSolicitudCardItem
            caption="Precio"
            value={
              detalleSolicitudEmision.datosEmision.precioInformado ? (
                <NumberFormat
                  id="importeSolicitado"
                  displayType={"text"}
                  value={detalleSolicitudEmision.datosEmision.precioInformado}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={0}
                  prefix={"$"}
                />
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Periodo"
            value={
              detalleSolicitudEmision.datosEmision.periodo !== null ? (
                detalleSolicitudEmision.datosEmision.periodo
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Premio total"
            value={
              premio !== null ? (
                <NumberFormat
                  value={premio}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  isNumericString
                  prefix="$"
                  decimalScale={0}
                />
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Vigencia Desde"
            value={moment(detalleSolicitudEmision.vigenciaDesde).format(
              "DD/MM/YYYY"
            )}
          />
          <DetalleSolicitudCardItem
            caption="Forma de pago"
            value={
              detalleSolicitudEmision.datosEmision.formaPago !== null ? (
                formasPagoOptions[
                  detalleSolicitudEmision.datosEmision.formaPago
                ]
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          {(detalleSolicitudEmision.datosEmision.formaPago === "DebitoConCBU" ||
            detalleSolicitudEmision.datosEmision.formaPago ===
              "DebitoConTarjetaCredito") && (
            <DetalleSolicitudCardItem
              caption="Datos de Pago"
              value={resumenDatosPago}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AseguradoCard;
