import { createAction, handleActions } from "redux-actions";

const setInitialState = createAction("SET_USUARIOS_INITIAL_STATE");

const setCurrentUsuario = createAction(
  "SET_CURRENT_USUARIO",
  (currentUsuario) => ({
    currentUsuario,
  })
);

const setUsuarios = createAction("SET_USUARIOS", (value) => ({ value }));

const getUsuariosInited = createAction("OBTENER_USUARIOS_INITED");

const getUsuariosCompleted = createAction(
  "OBTENER_USUARIOS_COMPLETED",
  (response, isAnOldRequest) => {
    let usuarios = [];
    let totalRegistros = 0;

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      usuarios = response.usuarios;
      totalRegistros = response.totalRegistros;
    }

    return { usuarios, totalRegistros, isAnOldRequest };
  }
);
const getUsuariosFailed = createAction("OBTENER_USUARIOS_FAILED", (error) => ({
  error,
}));

const getUsuarios = (skip, take, searchCriteria, getOnlyActivos = true) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getUsuariosInited());

      const data = {
        skip: skip,
        take: take,
        searchCriteria: searchCriteria,
        getOnlyActivos: getOnlyActivos,
      };
      const response = await services.api.usuarios().getUsuarios(data);

      dispatch(getUsuariosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getUsuariosFailed(error));
    }
  };
};

const putUsuariosInited = createAction("ACTUALIZAR_USUARIOS_INITED");

const putUsuariosCompleted = createAction("ACTUALIZAR_USUARIOS_COMPLETED");
const putUsuariosFailed = createAction(
  "ACTUALIZAR_USUARIOS_FAILED",
  (error) => ({
    error,
  })
);

const putUsuarios = (id, roles, oficinaId, activo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(putUsuariosInited());

      const data = { id, roles, oficinaId, activo };

      const response = await services.api.usuarios().putUsuarios(data);

      dispatch(putUsuariosCompleted(response));

      //reload user list
      const state = getState().filtrosUsuariosReducer;

      dispatch(
        getUsuarios(state.take * state.page, state.take, state.searchCriteria, state.getOnlyActivos)
      );
    } catch (error) {
      console.error(error);
      dispatch(putUsuariosFailed(error));
    }
  };
};

const stateUsuarioChanged = createAction("STATE_USUARIO_CHANGED", (id, activo) => ({id, activo}));

const changeStateUsuario = (id, roles, oficinaId, activo) => {
  return async (dispatch, getState, services) => {
    try {
      const data = { id, roles, oficinaId, activo };
      dispatch(stateUsuarioChanged(id, activo));
      await services.api.usuarios().putUsuarios(data);
    } catch (error) {
      console.error(error);
      //if error, force to reload
      const state = getState().filtrosUsuariosReducer;
      dispatch(getUsuarios(state.take * state.page, state.take, state.searchCriteria, state.getOnlyActivos));
    }    
  }
}

const getUsuarioInited = createAction("OBTENER_USUARIO_INITED");

const getUsuarioCompleted = createAction(
  "OBTENER_USUARIO_COMPLETED",
  (currentUsuario) => ({
    currentUsuario,
  })
);
const getUsuarioFailed = createAction("OBTENER_USUARIO_FAILED", (error) => ({
  error,
}));

const getUsuario = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getUsuarioInited());

      const response = await services.api.usuarios().getUsuario(id);

      dispatch(getUsuarioCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getUsuarioFailed(error));
    }
  };
};

const usuariosInitialState = {
  isLoading: false,
  failed: false,
  currentUsuario: null,
  usuarios: null,
  totalRegistros: 0,
};

const usuariosReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...usuariosInitialState,
        usuarios: null,
      };
    },
    [setCurrentUsuario]: (state, action) => {
      return {
        ...state,
        currentUsuario: action.payload.currentUsuario,
      };
    },
    [getUsuariosInited]: (state, action) => {
      return {
        ...state,
        totalRegistros: 0,
        isLoading: true,
      };
    },
    [getUsuariosCompleted]: (state, action) => {
      return {
        ...state,
        usuarios: action.payload.usuarios,
        totalRegistros: action.payload.totalRegistros,
        isLoading: false,
        failed: false,
      };
    },
    [getUsuariosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },

    [putUsuariosInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [putUsuariosCompleted]: (state, action) => {
      return {
        ...state,
      };
    },
    [putUsuariosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },

    [getUsuarioInited]: (state, action) => {
      return {
        ...state,
        currentUsuario: null,
      };
    },
    [getUsuarioCompleted]: (state, action) => {
      return {
        ...state,
        currentUsuario: action.payload.currentUsuario,
        failed: false,
      };
    },
    [getUsuarioFailed]: (state, action) => {
      return {
        ...state,
        failed: true,
      };
    },
    [setUsuarios]: (state, action) => {
      return {
        ...state,
        usuarios: action.payload.value,
      };
    },
    [stateUsuarioChanged]: (state, action) => {
      return {
        ...state,
        usuarios: state.usuarios.map(
          (usuario) => usuario.id === action.payload.id ? 
            { ...usuario, activo: action.payload.activo }
            : usuario
        )
      }
    }
  },
  usuariosInitialState
);

export { usuariosReducer };
export {
  getUsuarios,
  setInitialState,
  setUsuarios,
  setCurrentUsuario,
  putUsuarios,
  getUsuario,
  changeStateUsuario,
};
