const calculateDate = (initialDate, countDays) => {
  const result = initialDate;
  result.setHours(0);
  result.setMinutes(0);
  var pastDate = result.getDate() + countDays;

  result.setDate(pastDate);
  return result;
};

const getDateFormatted = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!

  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "/" + mm + "/" + yyyy;
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const getDay = () => {
  return new Date().getDay() - 1;
};
const getDaysOfThisMonth = () => {
  return new Date().getDate() - 1;
};

const getDaysOfLastMonth = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  return getDay() + today.getDate() - 1;
};

const minDate = (dates) => {
  return new Date(Math.min.apply(null, dates));
};

const getLastDayOfThisMonth = (date) => {
  //const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay;
};

export {
  calculateDate,
  getDateFormatted,
  isValidDate,
  getDay,
  getDaysOfThisMonth,
  getDaysOfLastMonth,
  minDate,
  getLastDayOfThisMonth,
};
