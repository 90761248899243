import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import fileService from "~libs/fileService";

const subidaArchivosInfoautoInitialState = {
  tautos30FileNameSelected: null,
  extradFileNameSelected: null,
  gruposFileNameSelected: null,
  archivosSubidos: false,
};

const setTautos30FileName = createAction(
  "SET_TAUTOS30_FILENAME",
  (tautos30FileNameSelected) => ({
    tautos30FileNameSelected,
  })
);

const setExtradFileName = createAction(
  "SET_EXTRAD_FILENAME",
  (extradFileNameSelected) => ({
    extradFileNameSelected,
  })
);

const setGruposFileName = createAction(
  "SET_GRUPOS_FILENAME",
  (gruposFileNameSelected) => ({
    gruposFileNameSelected,
  })
);

const setSubmitting = createAction("SET_SUBMITTING", (submitting) => ({
  submitting,
}));

const moveArchivosInfoauto = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(uploadArchivosInfoautoInited());

      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      let suffixFilename = `${yyyy}-${mm}-${dd}`;

      const fileServiceToMove = fileService("");
      await fileServiceToMove.copy(
        `${data.sourceBucket}/${data.tautos30Filename}`,
        data.destBucket,
        `${suffixFilename}-tautos30.dbf`
      );

      await fileServiceToMove.copy(
        `${data.sourceBucket}/${data.extradFilename}`,
        data.destBucket,
        `${suffixFilename}-extrad.dbf`
      );

      await fileServiceToMove.copy(
        `${data.sourceBucket}/${data.gruposFilename}`,
        data.destBucket,
        `${suffixFilename}-grupos.dbf`
      );

      dispatch(uploadArchivosInfoautoCompleted());
    } catch (error) {
      dispatch(uploadArchivosInfoautoFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al subir los archivos. Intente nuevamente."
        )
      );
    }
  };
};

const uploadArchivosInfoautoInited = createAction(
  "UPLOAD_ARCHIVOS_INFOAUTO_INITED"
);

const uploadArchivosInfoautoCompleted = createAction(
  "UPLOAD_ARCHIVOS_INFOAUTO_COMPLETED"
);

const uploadArchivosInfoautoFailed = createAction(
  "UPLOAD_ARCHIVOS_INFOAUTO_FAILED",
  (error) => ({
    error,
  })
);

const subidaArchivosInfoautoReducer = handleActions(
  {
    [setTautos30FileName]: (state, action) => {
      return {
        ...state,
        tautos30FileNameSelected: action.payload.tautos30FileNameSelected,
      };
    },
    [setExtradFileName]: (state, action) => {
      return {
        ...state,
        extradFileNameSelected: action.payload.extradFileNameSelected,
      };
    },
    [setGruposFileName]: (state, action) => {
      return {
        ...state,
        gruposFileNameSelected: action.payload.gruposFileNameSelected,
      };
    },
    [uploadArchivosInfoautoInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [uploadArchivosInfoautoCompleted]: (state, action) => {
      return {
        ...state,
        archivosSubidos: true,
      };
    },
    [uploadArchivosInfoautoFailed]: (state, action) => {
      return {
        ...state,
      };
    },
  },
  subidaArchivosInfoautoInitialState
);

export {
  subidaArchivosInfoautoReducer,
  setTautos30FileName,
  setExtradFileName,
  setGruposFileName,
  setSubmitting,
  moveArchivosInfoauto as moveArchivosInfoautoFinalDest,
};
