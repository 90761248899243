const TODOS = "";
export const VIGENTE = "Vigente";
export const EMITIDANOVIGENTE = "EmitidaNoVigente";
export const VENCIDA = "Vencida";
export const ANULADA = "Anulada";

const estadosPolizasOptions = {
  [TODOS]: "Todos",
  [VIGENTE]: "Vigente",
  [EMITIDANOVIGENTE]: "Emitida No Vigente",
  [VENCIDA]: "Vencida",
  [ANULADA]: "Anulada",
};

const estadosPolizasFilter = [
  { label: estadosPolizasOptions[TODOS], value: TODOS },
  { label: estadosPolizasOptions[VIGENTE], value: VIGENTE },
  { label: estadosPolizasOptions[EMITIDANOVIGENTE], value: EMITIDANOVIGENTE },
  { label: estadosPolizasOptions[VENCIDA], value: VENCIDA },
  { label: estadosPolizasOptions[ANULADA], value: ANULADA },
];

export { estadosPolizasOptions, estadosPolizasFilter };
