import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const moment = require("moment");

const setSubmitting = createAction("SET_SUBMITTING", (submitting) => ({
  submitting,
}));

const setCurrentPoliza = createAction(
  "SET_CURRENT_USUARIO",
  (currentPoliza) => ({
    currentPoliza,
  })
);

const getPolizaInited = createAction("OBTENER_POLIZA_RENOVAR_INITED");

const getPolizaCompleted = createAction(
  "OBTENER_POLIZA_RENOVAR_COMPLETED",
  (currentPoliza) => ({ currentPoliza })
);

const getPolizaFailed = createAction(
  "OBTENER_POLIZA_RENOVAR_FAILED",
  (error) => ({
    error,
  })
);

const getCoberturasInited = createAction("OBTENER_COBERTURAS_INITED");

const getCoberturasCompleted = createAction(
  "OBTENER_COBERTURAS_COMPLETED",
  (coberturas) => ({ coberturas })
);

const getCoberturasFailed = createAction(
  "OBTENER_COBERTURAS_FAILED",
  (error) => ({
    error,
  })
);

const getCoberturas = (compania, negocio) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getCoberturasInited());

      const response = await services.api
        .esquemas()
        .getCoberturas(compania, negocio);

      dispatch(getCoberturasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getCoberturasFailed(error));
    }
  };
};

const setPremioTotal = createAction(
  "SET_PREMIO_TOTAL",
  (premioTotalSelected) => ({ premioTotalSelected })
);

const setVigenciaDesde = createAction(
  "SET_VIGENCIA_DESDE",
  (vigenciaDesdeSelected) => ({
    vigenciaDesdeSelected,
  })
);
const setVigenciaHasta = createAction(
  "SET_VIGENCIA_HASTA",
  (vigenciaHastaSelected) => ({
    vigenciaHastaSelected,
  })
);

const setSumaAsegurada = createAction(
  "SET_SUMA_ASEGURADA",
  (sumaAseguradaSelected) => ({ sumaAseguradaSelected })
);

const setVigencia = createAction("SET_VIGENCIA", (vigenciaSelected) => ({
  vigenciaSelected,
}));

const setCantidadCuotas = createAction(
  "SET_CANTIDAD_CUOTAS",
  (cantidadCuotasSelected) => ({
    cantidadCuotasSelected,
  })
);

const setCobertura = createAction("SET_COBERTURA", (coberturaSelected) => ({
  coberturaSelected,
}));

const setNumeroPoliza = createAction(
  "SET_NUMERO_POLIZA",
  (numeroPolizaSelected) => ({
    numeroPolizaSelected,
  })
);

const setPolizaFileName = createAction(
  "SET_POLIZA_FILENAME",
  (polizaFileNameSelected) => ({
    polizaFileNameSelected,
  })
);
const setTarjetaFileName = createAction(
  "SET_TARJETA_FILENAME",
  (tarjetaFileNameSelected) => ({
    tarjetaFileNameSelected,
  })
);

const setCedulaMercosurFileName = createAction(
  "SET_CEDULA_MERCOSUR_FILENAME",
  (cedulaMercosurFileNameSelected) => ({
    cedulaMercosurFileNameSelected,
  })
);

const setCuponeraFileName = createAction(
  "SET_CUPONERA_FILENAME",
  (cuponeraFileNameSelected) => ({
    cuponeraFileNameSelected,
  })
);

const setIsProcessingPoliza = createAction(
  "SET_PROCESSING_POLIZA",
  (isProcessingPoliza) => ({
    isProcessingPoliza,
  })
);
const setIsProcessingTarjeta = createAction(
  "SET_PROCESSING_TARJETA",
  (isProcessingTarjeta) => ({
    isProcessingTarjeta,
  })
);
const setIsProcessingCedulaMercosur = createAction(
  "SET_PROCESSING_CEDULA_MERCOSUR",
  (isProcessingCedulaMercosur) => ({
    isProcessingCedulaMercosur,
  })
);
const setIsProcessingCuponera = createAction(
  "SET_PROCESSING_CUPONERA",
  (isProcessingCuponera) => ({
    isProcessingCuponera,
  })
);

const getPoliza = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizaInited());
      const response = await services.api.polizas().getPoliza(id);

      dispatch(getPolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizaFailed(error));
    }
  };
};

const postRenovarPolizaInited = createAction("PROCESAR_RENOVAR_POLIZA_INITED");
const postRenovarPolizaCompleted = createAction(
  "PROCESAR_RENOVAR_POLIZA_COMPLETED",
  (response) => {}
);
const postRenovarPolizaFailed = createAction(
  "PROCESAR_RENOVAR_POLIZA_FAILED",
  (error) => ({
    error,
  })
);

const postRenovarPoliza = (polizaId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postRenovarPolizaInited());

      const response = await services.api
        .polizas()
        .postRenovarPoliza(polizaId, data);

      dispatch(postRenovarPolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postRenovarPolizaFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al renovar la póliza. Intente nuevamente."
        )
      );
    }
  };
};

const postValidatePolizaInited = createAction(
  "PROCESAR_VALIDATE_POLIZA_INITED"
);
const postValidatePolizaCompleted = createAction(
  "PROCESAR_VALIDATE_POLIZA_COMPLETED",
  (response) => {}
);
const postValidatePolizaFailed = createAction(
  "PROCESAR_VALIDATE_POLIZA_FAILED",
  (error) => ({
    error,
  })
);

const setCuota = createAction("SET_CUOTA_RENOVACION", (cuotaSelected) => ({
  cuotaSelected,
}));

const clearCuotas = createAction("CLEAR_CUOTAS_RENOVACION", () => ({}));

const clearData = createAction("CLEAR_DATA_RENOVACION", () => ({}));

const postValidatePoliza = (polizaId) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postValidatePolizaInited());

      const response = await services.api
        .polizas()
        .postValidatePolizaARenovar(polizaId);

      dispatch(postValidatePolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postValidatePolizaFailed(error));
    }
  };
};

const renovacionPolizaInitialState = {
  currentPoliza: null,
  isLoading: true,
  failed: false,

  numeroPolizaSelected: { value: "", valid: false, focus: false },
  vigenciaDesdeSelected: { value: new Date(), valid: true, focus: false },
  vigenciaHastaSelected: { value: new Date(), valid: false, focus: false },
  vigenciaSelected: { value: 6, valid: true },
  cantidadCuotasSelected: { value: 6, valid: true },
  sumaAseguradaSelected: { value: 0, valid: false, focus: false },
  premioTotalSelected: { value: 0, valid: false, focus: false },
  coberturaSelected: { value: "", valid: false },
  coberturasOptions: null,
  coberturasFilter: [],
  cuotasSelected: {},

  polizaFileNameSelected: { value: null, valid: false },
  tarjetaFileNameSelected: { value: null, valid: false },
  cedulaMercosurFileNameSelected: { value: null, valid: false },
  cuponeraFileNameSelected: { value: null, valid: false },
  isProcessingPoliza: false,
  isProcessingTarjeta: false,
  isProcessingCedulaMercosur: false,
  isProcessingCuponera: false,
  polizaActualizada: false,
  submitting: false,
  polizaValidaParaRenovacion: true,
  polizaRenovada: false,
  validatingPoliza: true,
};

const renovacionPolizaReducer = handleActions(
  {
    // [setInitialState]: (state, action) => {
    //   return {
    //     ...detallePolizaInitialState,
    //     take: state.take,
    //     polizas: null,
    //   };
    // },
    [setCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentUsuario: action.payload.currentUsuario,
      };
    },
    [getPolizaInited]: (state, action) => {
      return {
        ...renovacionPolizaInitialState,
        isLoading: true,
      };
    },
    [getPolizaCompleted]: (state, action) => {
      var vigencia = moment(
        action.payload.currentPoliza.datosPlan.polizaVigenciaHasta
      ).diff(
        moment(action.payload.currentPoliza.datosPlan.polizaVigenciaDesde),
        "months"
      );

      return {
        ...state,
        currentPoliza: action.payload.currentPoliza,
        isLoading: false,
        failed: false,
        vigenciaSelected: {
          value: vigencia,
          valid: true,
          focus: false,
        },
        vigenciaDesdeSelected: {
          value: moment(
            action.payload.currentPoliza.datosPlan.polizaVigenciaHasta
          ),
          valid: true,
          focus: false,
        },
        vigenciaHastaSelected: {
          value: moment(
            action.payload.currentPoliza.datosPlan.polizaVigenciaHasta
          ).add(vigencia, "months"),
          valid: true,
          focus: false,
        },
        cantidadCuotasSelected: {
          ...state.cantidadCuotasSelected,
          value:
            action.payload.currentPoliza.negocio === "Prendarios"
              ? 6
              : action.payload.currentPoliza.cantidadCuotas,
        },
        coberturaSelected: {
          value: action.payload.currentPoliza.coberturaId,
          valid: true,
          focus: false,
        },
      };
    },
    [getPolizaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [postRenovarPolizaInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [postRenovarPolizaCompleted]: (state, action) => {
      return {
        ...state,
        polizaRenovada: true,
        isLoading: false,
      };
    },
    [postRenovarPolizaFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [postValidatePolizaInited]: (state, action) => {
      return {
        ...state,
        validatingPoliza: true,
      };
    },
    [postValidatePolizaCompleted]: (state, action) => {
      return {
        ...state,
        validatingPoliza: false,
        polizaValidaParaRenovacion: true,
      };
    },
    [postValidatePolizaFailed]: (state, action) => {
      return {
        ...state,
        polizaValidaParaRenovacion: false,
        validatingPoliza: false,
      };
    },
    [getCoberturasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getCoberturasCompleted]: (state, action) => {
      let coberturasFilter = action.payload.coberturas.items.map(
        (cobertura) => ({
          label: `${cobertura.nombre} (Id ${cobertura.id})`,
          value: cobertura.id,
        })
      );

      let coberturasOptions = {};

      action.payload.coberturas.items.forEach((cobertura) => {
        coberturasOptions[
          cobertura.id
        ] = `${cobertura.nombre} (Id ${cobertura.id})`;
      });

      return {
        ...state,
        isLoading: false,
        coberturasFilter,
        coberturasOptions,
      };
    },
    [getCoberturasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    },
    [setSubmitting]: (state, action) => {
      return {
        ...state,
        submitting: action.payload.submitting,
        premioTotalSelected: { ...state.premioTotalSelected, focus: false },
        numeroPolizaSelected: { ...state.numeroPolizaSelected, focus: false },
        vigenciaDesdeSelected: { ...state.vigenciaDesdeSelected, focus: false },
        vigenciaHastaSelected: { ...state.vigenciaHastaSelected, focus: false },
      };
    },
    [setVigenciaDesde]: (state, action) => {
      const vigenciaDesde = action.payload.vigenciaDesdeSelected;
      const vigenciaHasta = new Date(vigenciaDesde.value);
      const vigencia = state.vigenciaSelected;

      return {
        ...state,
        vigenciaDesdeSelected: vigenciaDesde,
        vigenciaHastaSelected: {
          value: new Date(
            vigenciaHasta.setMonth(vigenciaHasta.getMonth() + vigencia.value)
          ),
          valid: true,
          focus: false,
        },
      };
    },
    [setVigenciaHasta]: (state, action) => {
      return {
        ...state,
        vigenciaHastaSelected: action.payload.vigenciaHastaSelected,
      };
    },
    [setVigencia]: (state, action) => {
      const vigencia = action.payload.vigenciaSelected;
      const vigenciaDesde = state.vigenciaDesdeSelected;
      return {
        ...state,
        vigenciaSelected: vigencia,
        vigenciaHastaSelected: {
          value: moment(vigenciaDesde.value).add(vigencia.value, "months"),
          valid: true,
          focus: false,
        },
      };
    },
    [setCantidadCuotas]: (state, action) => {
      return {
        ...state,
        cantidadCuotasSelected: action.payload.cantidadCuotasSelected,
      };
    },
    [setCobertura]: (state, action) => {
      return {
        ...state,
        coberturaSelected: action.payload.coberturaSelected,
      };
    },
    [setNumeroPoliza]: (state, action) => {
      return {
        ...state,
        numeroPolizaSelected: action.payload.numeroPolizaSelected,
      };
    },
    [setPremioTotal]: (state, action) => {
      return {
        ...state,
        premioTotalSelected: action.payload.premioTotalSelected,
      };
    },
    [setSumaAsegurada]: (state, action) => {
      return {
        ...state,
        sumaAseguradaSelected: action.payload.sumaAseguradaSelected,
      };
    },
    [setPolizaFileName]: (state, action) => {
      return {
        ...state,
        polizaFileNameSelected: action.payload.polizaFileNameSelected,
      };
    },
    [setTarjetaFileName]: (state, action) => {
      return {
        ...state,
        tarjetaFileNameSelected: action.payload.tarjetaFileNameSelected,
      };
    },
    [setCedulaMercosurFileName]: (state, action) => {
      return {
        ...state,
        cedulaMercosurFileNameSelected:
          action.payload.cedulaMercosurFileNameSelected,
      };
    },
    [setCuponeraFileName]: (state, action) => {
      return {
        ...state,
        cuponeraFileNameSelected: action.payload.cuponeraFileNameSelected,
      };
    },
    [setIsProcessingPoliza]: (state, action) => {
      return {
        ...state,
        isProcessingPoliza: action.payload.isProcessingPoliza,
      };
    },
    [setIsProcessingTarjeta]: (state, action) => {
      return {
        ...state,
        isProcessingTarjeta: action.payload.isProcessingTarjeta,
      };
    },
    [setIsProcessingCedulaMercosur]: (state, action) => {
      return {
        ...state,
        isProcessingCedulaMercosur: action.payload.isProcessingCedulaMercosur,
      };
    },
    [setIsProcessingCuponera]: (state, action) => {
      return {
        ...state,
        isProcessingCuponera: action.payload.isProcessingCuponera,
      };
    },
    [setCuota]: (state, action) => {
      let id = action.payload.cuotaSelected.id;
      state.cuotasSelected[id] = action.payload.cuotaSelected;
      return {
        ...state,
        cuotasSelected: state.cuotasSelected,
      };
    },
    [clearCuotas]: (state, action) => {
      return {
        ...state,
        cuotasSelected: {},
      };
    },
    [clearData]: (state, action) => {
      return {
        ...renovacionPolizaInitialState,
      };
    },
  },

  renovacionPolizaInitialState
);

export { renovacionPolizaReducer };
export {
  getPoliza,
  //   setInitialState,
  setCurrentPoliza,
  setSubmitting,
  setVigencia,
  setVigenciaDesde,
  setVigenciaHasta,
  setCantidadCuotas,
  setNumeroPoliza,
  setPremioTotal,
  setSumaAsegurada,
  setPolizaFileName,
  setTarjetaFileName,
  setCedulaMercosurFileName,
  setCuponeraFileName,
  setIsProcessingPoliza,
  setIsProcessingTarjeta,
  setIsProcessingCedulaMercosur,
  setIsProcessingCuponera,
  postRenovarPoliza,
  getCoberturas,
  setCobertura,
  postValidatePoliza,
  setCuota,
  clearCuotas,
  clearData,
};
